import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { Form, Row, Col, Input, Button, Select,Typography,  message, Alert, Spin } from 'antd';
import { getCompanyWalletLU, getTransfer, saveTransfer, getAmount } from './api';
import { connect } from 'react-redux';
import Loader from '../loader.component';
import apiCalls from '../../api/apiCalls';
import { setCurrentAction } from '../../reducers/actionsReducer';
import { validateContentRule } from '../../utils/custom.validator';
import { publishShowActions } from '../grid.component/subscribir';
import NumberFormat from 'react-number-format';

const { TextArea } = Input;
const { Text } = Typography;

const TransferDetails = (props) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const useDivRef = React.useRef(null);
    const [transferObject, setTransferObject] = useState({});
    const [type] = useState(props.match.params.type)
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [companyWallentLU, setCompanyWalletLu] = useState([]);
    const [toWalletList, setToWalletList] = useState([]);
    const [coinLu, setCoinLu] = useState([]);
    const [fromWallet, setFromWallet] = useState(null);
    const [amount, setAmount] = useState(null);
    const [isAmountLoading, setIsAmountLoading] = useState(false);
    useEffect(() => {
        publishShowActions(false);
        if (props.match.params.type !== "view") {
            CompanyWallentLU();
        }
        loadData();
    }, []);//eslint-disable-line react-hooks/exhaustive-deps

    const loadData = async () => {
        setLoading(true)
        let response = await getTransfer(props.match.params.id);
        if (response.ok) {
            setTransferObject(response.data)
            form.setFieldsValue({ ...response.data });
            setLoading(false)
        } else {
            setLoading(false)
            setErrorMsg(response.status === 401 ? (response.data.message) : response.data)
        }
    }

    const CompanyWallentLU = async() => {
        let res = await getCompanyWalletLU();
        if(res.ok){
            setCompanyWalletLu(res.data);
            setErrorMsg(null)
        }else{
            setErrorMsg(apiCalls.isErrorDispaly(res))
        }
    }

    const saveTransferInfo = async (values) => {
        setIsLoading(true);
        setErrorMsg(null);
        if(values.amount===0||values.amount==="0"){
           window.scrollTo(0, 0);
            setIsLoading(false);
            setErrorMsg("Amount must be greater than zero");
        }
        else if(parseFloat(typeof values.amount == "string" ? values.amount?.replace(/,/g, '') : values.amount)  > amount) {
           window.scrollTo(0, 0);
            setIsLoading(false);
            setErrorMsg("Insufficient balance");
            return;
        }
        else {
            let fromWallets = values.fromWallet;
            if (fromWallets) {
                companyWallentLU?.fromWallet.forEach((item) => {
                  if(item.wallet === values.fromWallet) {
                    values.fromWallet = item.walletName ? item.walletName : null;
                    values.fromNetWork = item.network ? item.network : null;
                    values.fromAddress = item.address ? item.address : null;
                    values.fromVault = item.vaultId ? item.vaultId : null;
                  }
                });
            }
            let toWallet = values.toWallet;
            if (toWallet) {
                companyWallentLU?.toWallet.forEach((item) => {
                  if(item.wallet === values.toWallet) {
                    values.toWallet = item.walletName ? item.walletName : null;
                    values.toNetWork = item.network ? item.network : null;
                    values.toAddress = item.address ? item.address : null;
                    values.toVault = item.vaultId ? item.vaultId : null;
                  }
                });
            }
            values.createdBy = transferObject?.createdby ? transferObject?.createdby : props.userConfig.userName;
            values.modifiedBy = transferObject?.modifiedBy ? transferObject?.modifiedBy : props.userConfig.userName;
            values.id = props.match.params.id;
            values.createdDate = new Date();
            values.modifiedDate = transferObject?.modifiedDate ;
            values.state = transferObject.state ? transferObject.state : "Submitted";
            values.info = JSON.stringify(props.trackAuditLogData);
            values.customerId = props.userConfig?.id;
            setLoading(false)
            let response = await saveTransfer(values);
            if (response.ok) {
                setIsLoading(false)
                message.destroy()
                message.success({
                    content: 'Transaction saved successfully',
                    className: 'custom-msg',
                    duration: 4
                });
                apiCalls.trackEvent({
                    Action: props.match.params.id === "00000000-0000-0000-0000-000000000000" ? "Transfer added" : "Transfer Updated",
                    Feature: "Transfer",
                    Remarks: props.match.params.id === "00000000-0000-0000-0000-000000000000" ? "Transfer added" : "Transfer Updated",
                    FullFeatureName: "Transfer",
                    userName: props.userConfig.userName,
                    id: props.userConfig.id
                });
                history.push('/internalWalletTransfer');
                setErrorMsg(null);
            } else {
                window.scrollTo(0, 0);
                setIsLoading(false)
                setErrorMsg(apiCalls.isErrorDispaly(response))
            }
        }

    }


    const numberValidator = async function (rule, value, callback) {
        if (value) {
            if (typeof value === "number") {
                value = value.toString();
            }
            if (
                (value.indexOf(".") > -1 && value.split(".")[0].length >= 11) ||
                (value.indexOf(".") < 0 && value.length >= 11)
            ) {
                throw new Error("Amount exceeded");
            } 
            else if (value.indexOf(".") > -1 && value.split(".")[0].length === 0) {
                throw new Error("Please enter amount");
            }
            else {
                callback();
            }
        }
        else if(value === 0 && typeof value === "number") {
            callback();
        }
        else if(!value) {
            throw new Error("Is required");
        }
    };

    const handleFromWalletChange = async (e) => {
        if (e) {
            companyWallentLU?.fromWallet.forEach((item) => {
                if (item.wallet === e) {
                    setFromWallet(item.walletName);
                }
            })
        }
        setAmount("");
        form.setFieldsValue({ toWallet: null, coin: null, amount: "" });
        setToWalletList([]);
        let toWalletLookUp = [];
        let coinDataLookUp = [];
        let _FliteredData = companyWallentLU.fromWallet?.filter(function (o) {
            return o.wallet === e;
        });
        if (_FliteredData.length > 0) {
            companyWallentLU.toWallet?.filter(function (item) {
                if (_FliteredData[0].network === item.network && _FliteredData[0].walletName !== item.walletName) {
                    toWalletLookUp.push(item);
                }
                return false;
            })
            companyWallentLU?.coins?.filter(function (val) {
                if (_FliteredData[0].network === val.network) {
                    coinDataLookUp.push(val);
                }
                return false;
            })
            setToWalletList(toWalletLookUp);
            setCoinLu(coinDataLookUp);
        }
    };

    const handleCoinChange = async (e) => {
        setIsAmountLoading(true);
        let res = await getAmount(fromWallet,e);
        if(res.ok){
            setIsAmountLoading(false);
            setErrorMsg(null)
            setAmount(res.data || 0);
            form.setFieldsValue({ amount: res.data || 0 });
        }else{
            setErrorMsg(apiCalls.isErrorDispaly(res))
        }
    }
    const backToTransfers = () => {
        history.push({pathname: '/internalWalletTransfer' });
    }
    const fromWalletLu = companyWallentLU.fromWallet?.map((item, indx) => (
        <Select.Option key={indx} value={item.wallet}>
          {item.wallet}
        </Select.Option>
      ));
      const toWalletLu = toWalletList?.map((item, inx) => (
        <Select.Option key={inx} value={item.wallet}>
          {item.wallet}
        </Select.Option>
      ));
      const getCoinLu = coinLu?.map((item, index) => (
        <Select.Option key={index} value={item.wallet}>
          {item.wallet}
        </Select.Option>
      ));
    return (<>
          <div ref={useDivRef}></div>
        {loading && <Loader />}
        {errorMsg !== undefined && errorMsg !== null && (
            <Alert className="w-100 mb-16" type="error" showIcon description={errorMsg} />
        )}
        <Form
            initialValues={transferObject}
            form={form}
            name="advanced_search"
            className="ant-advanced-search-form"
            onFinish={saveTransferInfo}
            autoComplete="off"
        >
            <Row gutter={24}>
                 <Col xs={24} sm={12} md={12} lg={12} xxl={12}>
                    <Form.Item
                        name="fromWallet"
                        label="From Wallet"
                        className="input-label"
                        rules={[
                            {
                                required: true,
                                message: 'Is required',
                            },
                        ]}
                    >
                        <Select
                            disabled={type === "view"}                            
                            placeholder="Select From Wallet"
                            className="cust-input"
                            onChange={(e) => {
                                handleFromWalletChange(e);
                            }}
                            dropdownClassName="select-drpdwn"
                        >
                            {fromWalletLu}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xxl={12}>
                    <Form.Item
                        name="toWallet"
                        label="To Wallet"
                        className="input-label"
                        rules={[
                            {
                                required: true,
                                message: 'Is required',
                            },
                            {
                                whitespace: true,
                                message: 'Is required'
                            },
                        ]}
                    >
                        <Select
                            disabled={type === "view" ? true : false}
                            placeholder="Select To Wallet"
                            className="cust-input"
                            dropdownClassName="select-drpdwn"
                        >
                            {toWalletLu}
                        </Select>
                    </Form.Item>
                </Col>

                 <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                    <Form.Item
                        name="coin"
                        label="Coin"
                        className="input-label"
                        rules={[
                            {
                                required: true,
                                message: 'Is required',
                            },
                            {
                                whitespace: true,
                                message: 'Is required'
                            },
                        ]}
                    >
                        <Select
                            disabled={type === "view" ? true : false}
                            placeholder="Select Coin"
                            className="cust-input"
                            dropdownClassName="select-drpdwn"
                            onChange={(e) => {
                                handleCoinChange(e);
                            }}
                        >
                            {getCoinLu}
                        </Select>
                    </Form.Item>
                </Col>
                {isAmountLoading && <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                    <div className='transfer-right mt-16'>
                        <Text className="fs-14 text-white-30 label">Amount<span style={{ color: "red" }} >*</span></Text>
                        <NumberFormat
                            disabled
                            decimalScale={8}
                            className="cust-input to-input"
                            displayType={'input'}
                            customInput={Input}
                            thousandSeparator={true}
                            prefix={""}
                            allowNegative={false}
                            maxLength={20}
                        />
                        {isAmountLoading && <Spin size="small" />}
                    </div>
                </Col>}
                {!isAmountLoading && <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                    <Form.Item
                        name="amount"
                        label="Amount"
                        className="input-label"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                type: "number",
                                validator: numberValidator
                            }
                        ]}
                    >
                        {isAmountLoading ? <Spin className={'inputSpinner'} /> :
                            <NumberFormat
                                disabled={type === "view"}
                                decimalScale={8}
                                className="cust-input"
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="Amount"
                                allowNegative={false}
                                maxLength={20}
                            />}
                    </Form.Item>
                </Col>}

            </Row>
            <Row gutter={24}>
                 <Col xs={24} sm={24} md={12} lg={16} xxl={12}>
                    <Form.Item
                        name="remarks"
                        label="Remarks"
                        className="input-label"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: "Is required"
                            },
                            {
                                validator: validateContentRule
                            }
                        ]}
                    >
                        <TextArea
                            placeholder="Remarks"
                            showCount={500}
                            maxLength={500}
                            rows={3}
                            disabled={type === "view" ? true : false}
                        />
                    </Form.Item>
                </Col>
            </Row>
       
            <div className="text-right mt-24">
                    <Button type="primary" className="primary-btn" htmlType="submit" //disabled={btnDisabled}
                        loading={isLoading}> Save
                    </Button>
                <Button
                    type="primary"
                    className="primary-btn cancel-btn"
                    style={{ margin: '0 8px' }}
                    onClick={backToTransfers}
                >
                    Cancel
                </Button>
            </div>
        </Form>
    </>)
}

const connectStateToProps = ({ oidc, userConfig }) => {
    return { oidc, userConfig: userConfig.userProfileInfo, trackAuditLogData: userConfig.trackAuditLogData }
}
const connectDispatchToProps = dispath => {
    return {
        setAction: (val) => {
            dispath(setCurrentAction(val))
        },
        dispath
    }
}
export default connect(connectStateToProps, connectDispatchToProps)(TransferDetails);