import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Button, Alert, Form, DatePicker, Modal, Tooltip, Input, Empty, Typography, message } from "antd";
import List from "../grid.component";
import Loader from '../loader.component';
import { userNameLuSearch, getFeatureLuSearch, getAuditLogInfo, getRetry } from './api';
import { setBreadcrumb } from '../../reducers/breadcrumbReducer';
import moment from 'moment';
import { updateCurrentScreen } from "../../reducers/permissionsReducer"
import { setCurrentAction } from '../../reducers/actionsReducer';
import apiCalls from "../../api/apiCalls";
import { EyeOutlined } from '@ant-design/icons';
import { publishShowActions } from "../grid.component/subscribir";
import config from "../../config";
const { Option } = Select;
const { Text } = Typography;
class AuditLogs extends Component {
  formRef = React.createRef();
  formDateRef = React.createRef();
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      userData: [],
      featureData: [],
      selection: [],
      value: "",
      modal: false,
      selectedTimespan: "",
      timeSpanfromdate: "",
      timeSpantodate: "",
      customFromdata: "",
      customTodate: "",
      isCustomDate: false,
      message: "",
      warningMsg: null,
      error: null,
      selectedFeature: [],
      selectedUserId: "",
      actions: [],
      cryptoModal: false,
      retryModal: false,
      logRowData: {},
      showModal:false,
      searchObj: {
        timeSpan: "Last 1 Day",
        userName: "All Users",
        feature: "All Features",
        action: "All Actions",
        fromdate: "",
        todate: "",
      },

      searchObjCopy: {
        timeSpan: "Last 1 Day",
        userName: "00000000-0000-0000-0000-000000000000",
        feature: "All Features",
        action: "All Actions",
        fromdate: "",
        todate: "",
      },
      timeListSpan: ["Last 1 Day", "Last One Week", "Custom"],
      gridUrl: process.env.REACT_APP_GRID_API + "AuditLogs",
    };
    this.gridRef = React.createRef();
  }

  gridColumns = [
    { field: "date", title: "Date", filter: true, isShowTime: true, filterType: "date", width: 240,},
    { field: "action", title: "Action", filter: true, width: 180 },
    { field: "userName", title: "User Name", filter: true, width: 230 },
    { field: "description", title: "Description", filter: true, width: 260 },
    { field: "state", title: "Status", filter: true, width: 160 },
    {
      field: "", title: "", width: 100,
      customCell: (props) => (
        <td>
          <div className="d-flex align-center">

            {props.dataItem.actionType !== 'System Action' && <Tooltip title="More Info">
              <span
                className="icon md info c-pointer ml-8"
                onClick={() => this.showMoreAuditLogs(props)}
              />
            </Tooltip>}
          </div>
        </td>)

    },
    {
      field: "", title: "", width: 100,
      customCell: (props) => (
        <td>
          <div className="d-flex align-center">

            <Tooltip title="More Info">
              <EyeOutlined onClick={()=>this.showDetails(props)} />
            </Tooltip>
          </div>
        </td>)

    },
  ]
  showgridColumns = [
    { field: "userName", title: "User Name", filter: false,width: 240,},
    { field: "action", title: "Action", filter: false, width: 180 },
    { field: "description", title: "Description", filter: false, width: 180 },   
    { field: "feature", title: "Feature", filter: false, width: 180 },
    { field: "state", title: "State", filter: false, width: 180 },
  ]

 

  componentDidMount = () => {
    this.TransactionFeatureSearch();
    this.props.dispatch(updateCurrentScreen('auditlogs'));
    publishShowActions(true);
  };

  showDetails=(props)=>{
     this.setState({...this.state, 
     innerGridUrl:process.env.REACT_APP_GRID_API + `AuditLogs/AuditLogs/${props.dataItem?.id}`,
     showModal:true,
  },)
  }
  update = (e) => {
    const items = e.dataItem;
    const val = items.id;
    this.props.dispatch(setBreadcrumb({ key: '/auditlogs/' + val, val: items.userName }))
    this.props.history.push("/auditlogs/" + val);
  };

  TransactionUserSearch = async (userVal) => {
    let response = await userNameLuSearch(userVal);
    if (response.ok) {
      let userData = response.data;
      userData.unshift({
        code: "All Users",
        defaultValue: null,
        id: "00000000-0000-0000-0000-000000000000",
        name: "All Users",
        recorder: 0
      })
      this.setState({
        userData: userData,
        warningMsg:null
      });
    }else{
      this.setState({...this.state,warningMsg:apiCalls.isErrorDispaly(response)})
    }
  };

  handleInputChange = (prop, e) => {
    this.setState({ errorMsg: null });
    const rowChecked = prop.dataItem;
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowChecked.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowChecked.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowChecked,
      selection: selection,
      errorMsg: null,
      error: null
    });

  };

  TransactionFeatureSearch = async () => {
    let response = await getFeatureLuSearch();
    if (response.ok) {
      this.setState({...this.state,warningMsg:null})
      let featureData = response.data;
      featureData.unshift({
        id: "All Features",
        groupName: "All Features",
        admin: "admin",
        name: "All Features",
      })
      this.state.actions?.push({ name: "All Actions", id: "All Actions" });
      this.setState({ featureData });
    }else{
      this.setState({...this.state,warningMsg:apiCalls.isErrorDispaly(response)})
    }
  };

  handleUserChange = (event) => {
    if (event.target.value != null && event.target.value.length > 2) {
      let userVal = event.target.value
      this.TransactionUserSearch(userVal);
    }
  }

  handleTimeSpan = (val, id) => {
    let { searchObj } = this.state;
    searchObj[id] = val;
    if (val === "Custom") {
      this.setState({ ...this.state, modal: true, isCustomDate: true, searchObj: searchObj })
    } else {
      this.setState({ ...this.state, searchObj: { ...searchObj, fromdate: '', todate: '' }, isCustomDate: false, customFromdata: "", customTodate: "" });
    }
  }

  handleChange = (val, id) => {
    this.formRef.current.setFieldsValue({ action: "All Actions" });
    const _selectedObj = this.state.featureData.find(item => item.groupName === val)
    let { searchObj } = this.state;
    searchObj[id] = _selectedObj && _selectedObj.groupName.indexOf("Admin") > -1 && 'admin' || _selectedObj.groupName.indexOf("User") > -1 && 'user' || _selectedObj.groupName.indexOf("3rd") > -1 &&  "3rd Party" || "All Features" || "All Features";
    searchObj.action = "All Actions";
    let _actions = [];
    if (val === "All Features") {
      _actions?.push({ name: "All Actions", id: "All Actions" });
      searchObj.action = "All Actions";
    }
    else {
      _actions = this.state.featureData?.filter((item) => { 
        if (item.groupName === val){
          return item.name
        } 
        return false;
         })
    }
    this.setState({ ...this.state, searchObj: searchObj, actions: _actions });
  };

  handleActionDropChange = (val, id) => {
    let { searchObjCopy, searchObj } = this.state;
    searchObjCopy[id] = val || "All Actions";
    searchObj[id] = val || "All Actions";
    this.setState({ ...this.state, searchObjCopy: searchObjCopy, searchObj });
  };

  handleUserDropChange = (val, id) => {
    const _selectedUserObj = this.state.userData.find(item => item.code === val)
    let { searchObjCopy, searchObj } = this.state;
    searchObjCopy[id] = _selectedUserObj.id;
    searchObj[id] = _selectedUserObj.name;
    this.setState({ ...this.state, searchObjCopy: searchObjCopy, searchObj });
  };


  handleDateChange = (prop, val) => {
    let { searchObj, customFromdata, customTodate } = this.state;
    searchObj[val] = new Date(prop);
    this.setState({ ...this.state, searchObj, fromdate: customFromdata, todate: customTodate });
  };

  datePopup = (prop, val) => {
    let { searchObj, timeSpanfromdate, timeSpantodate, customFromdata, customTodate } = this.state;
    searchObj.fromdate = new Date(timeSpanfromdate)
    searchObj.fromdate = new Date(timeSpantodate)
    searchObj.fromdate = moment(timeSpanfromdate).format('DD/MM/YYYY');
    searchObj.todate = moment(timeSpantodate).format('DD/MM/YYYY');
    this.formDateRef.current.setFieldsValue({ fromdate: customFromdata, todate: customTodate })
    this.setState({ ...this.state, modal: true, searchObj });
  }

  handleOk = (values) => {
    let { selectedTimespan, timeSpanfromdate, timeSpantodate, customFromdata, customTodate } = this.state;
    if (new Date(moment(values.fromdate).format('MM/DD/YYYY')).getTime() > new Date(moment(values.todate).format('MM/DD/YYYY')).getTime()) {
      this.setState({ ...this.state, message: 'Start date must be less than or equal to the end date.' })
      return
    }
    customFromdata = values.fromdate;
    customTodate = values.todate;
    values.fromdate = moment(values.fromdate).format('MM/DD/YYYY');
    values.todate = moment(values.todate).format('MM/DD/YYYY');
    timeSpanfromdate = values.fromdate;
    timeSpantodate = values.todate;
    selectedTimespan = moment(timeSpanfromdate).format('DD/MM/YYYY') + " - " + moment(timeSpantodate).format('DD/MM/YYYY');
    this.formRef.current.setFieldsValue({ ...this.state, selectedTimespan })
    this.setState({ ...this.state, selectedTimespan, timeSpanfromdate, timeSpantodate, customFromdata, customTodate, modal: false, message: '' });
  };

  handleCancel = e => {
    let { searchObj, customFromdata, customTodate } = this.state;
    if (customFromdata && customTodate) {
      this.setState({ modal: false, selection: [], check: false, message: '' });
    } else {
      this.setState({ ...this.state, searchObj: { ...searchObj, timeSpan: "Last 1 Day", fromdate: '', todate: '' }, modal: false, selection: [], check: false, isCustomDate: false, message: '' });
      this.formRef.current.setFieldsValue({ ...searchObj, timeSpan: "Last 1 Day", fromdate: '', todate: '' })
      this.formDateRef.current.resetFields();
    }
  }

  handleSearch = () => {
    let { searchObj, timeSpanfromdate, timeSpantodate, searchObjCopy } = this.state;
    if (searchObj.timeSpan === "Custom") {
      searchObjCopy.fromdate = moment(timeSpanfromdate).format('MM-DD-YYYY');
      searchObjCopy.todate = moment(timeSpantodate).format('MM-DD-YYYY');
    }
    searchObjCopy.feature = searchObj.feature;
    searchObjCopy.timeSpan = searchObj.timeSpan;
    searchObjCopy.action = searchObj.action === 'Buy/Sell' ? 'Buy and Sell' : searchObj.action;
    this.setState({ ...this.state, searchObjCopy }, () => { this.gridRef.current.refreshGrid(); });

    apiCalls.trackEvent({
      Type: "Admin",
      Action: "Auditlogs grid page view",
      Username: this.props.userConfig?.userName,
      MemeberId: this.props.userConfig?.id,
      Feature: "Auditlogs",
      Remarks: "Auditlogs grid page view",
      Duration: 1,
      Url: window.location.href,
      FullFeatureName: "Auditlogs"
    });

  };

  handleFeatureChange(value, id) {
    let { searchObj } = this.state;
    searchObj[id] = value;
    this.setState({ ...this.state, searchObj: searchObj });
  }
  showMoreAuditLogs = async (props) => {
    this.setState({
      ...this.state,
      cryptoModal: true, isLoading: true
    });
    let res = await getAuditLogInfo(props?.dataItem.id);
    if (res.ok) {
      this.setState({
        ...this.state, logRowData: res.data, isLoading: false,warningMsg:null
      })
    }else{
      this.setState({...this.state,warningMsg:apiCalls.isErrorDispaly(res)})
    }
  };

  retry = () => {
    if (!this.state.check) {
      this.setState({ ...this.state, warningMsg: 'Please select the one record' })
    }
    else if (this.state.selectedObj.action.indexOf("Create Vault & Crypto Wallets") > -1 && this.state.selectedObj.action.indexOf("Fail") > -1) {
      this.setState({ retryModal: true, warningMsg: null, error: null });
    }
    else {
      this.setState({ ...this.state, warningMsg: 'Please select Create Vault & Crypto Wallets records only' })
    }
  }

  handleRetrySave = async () => {
    this.setState({ ...this.state, isdiabledLoading: true, warningMsg: null })
    if (!this.isLoading) {
      this.isLoading = true;
      let response = await getRetry(this.props.userConfig?.id);
      if (response.ok) {
        this.gridRef.current.refreshGrid();
        this.success(
          "Record retry successfully"
        );
        this.isLoading = false;
        this.setState({
          ...this.state,
          retryModal: false,
          selection: [],
          check: false,
          isLoading: false,
          isdiabledLoading: false,
          error:null
        });
      } else {
        this.setState({
          ...this.state,
          isLoading: false,
          selection: [],
          check: false,
          isdiabledLoading: false,
          error:apiCalls.isErrorDispaly(response)
        });
        this.isLoading = false;
      }
    }
    apiCalls.trackEvent({
      Action:
        " Record Retry Successfully",
      Feature: "AuditLogs",
      Remarks:
        " Record Retry Successfully",
      FullFeatureName: "AuditLogs"
    });
  };

  success = (msg) => {
    message.destroy();
    message.success({
      content: msg,
      className: "custom-msg",
      duration: 3
    });
  };

  handleRetryCancel = (e) => {
    this.setState({
      ...this.state,
      retryModal: false,
      selection: [],
      check: false,
      error: null,
      warningMsg: null
    });
  };

  onActionClick = (key) => {
    const actions = {
      Retry: "retry",
    }
    this[actions[key]]();
  }

  handleCancelModal=()=>{
    this.setState({...this.state,showModal:false})
    setTimeout(()=>{
      publishShowActions(true);
    },300)
  }
  renderWarningAlert = () => {
    const { warningMsg } = this.state;
    if (warningMsg !== undefined && warningMsg !== null) {
      return (
        <Alert
          className="w-100 mb-16 align-center"
          type="warning"
          description={warningMsg}
          showIcon
        />
      );
    }
    return null;
  };
  renderCustomDateInput = () => {
    const { isCustomDate } = this.state;
    if (isCustomDate) {
      return (
        <Col sm={24} md={7} className="px-8">
          <Form.Item
            name="selectedTimespan"
            className="input-label"
            label="Selected timespan"
          >
            <Input
              disabled
              className="cust-input cust-adon"
              addonAfter={<i className="icon md date c-pointer" onClick={(e) => { this.datePopup(e, 'searchObj') }} />}
            />
          </Form.Item>
        </Col>
      );
    }
    return null;
  };

  getCryptoModaldata=(lable,modalVal)=>(
    <div className="coin-info">
              <Text>{lable}</Text>
              <Text>{modalVal || "-"}</Text>
            </div>
  )
  render() {
    const { gridUrl, searchObj, featureData, userData, timeListSpan, searchObjCopy, actions, logRowData, isLoading, error,innerGridUrl,showModal } = this.state;
    const options2 = userData.map((d) => (
      <Option key={d.name} value={d.code}>{d.name}</Option>
    ));
    const options3 = timeListSpan.map((d) => (
      <Option key={d} value={d}>{d}</Option>
    ));
    let FeaturData = featureData.map((item) => {
      return item?.groupName
    })
    const uniqueNames = FeaturData?.filter((val, id, array) => {
      return array.indexOf(val) === id;
    });
    const options4 = uniqueNames?.map((d) => (
      <Option key={d} value={d}>{d}</Option>
    ));
    const options5 = actions?.map((d) => (
      <Option key={d.id} value={d.name}>{d.name}</Option>
    ));
    return (
      <>
        {this.renderWarningAlert()}
        <div>
          <Form
            className="ant-advanced-search-form form form-bg search-bg pt-8"
            autoComplete="off"
            ref={this.formRef}
            initialValues
          >
            <Row style={{ alignItems: 'flex-end' }}>
              <Col sm={24} md={4} className="px-8">
                <Form.Item
                  name="timeSpan"
                  className="input-label mb-0"
                  label="Time Span"
                >
                  <Select
                    className="cust-input w-100 bgwhite"
                    showSearch
                    defaultValue="Last 1 Day"
                    onChange={(e) => this.handleTimeSpan(e, 'timeSpan')}
                    placeholder="Time Span"
                  >
                    {options3}
                  </Select>
                </Form.Item>
              </Col>
              {this.renderCustomDateInput()}

              <Col sm={24} md={5} className="px-8">
                <Form.Item
                  name="userName"
                  className="input-label mb-0"
                  label="User Name"
                >
                  <Select
                    defaultValue="All Users"
                    className="cust-input w-100 bgwhite"
                    showSearch
                    onKeyUp={(event) => this.handleUserChange(event, "userName")}
                    onChange={(e) => this.handleUserDropChange(e, 'userName')}
                    placeholder="Select Users"
                  >
                    {options2}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={24} md={6} className="px-8">
                <Form.Item
                  name="feature"
                  className="input-label mb-0"
                  label="Features"
                >
                  <Select
                    defaultValue="All Features"
                    className="cust-input w-100 bgwhite"
                    style={{ width: 200 }}
                    onChange={(e) => this.handleChange(e, 'feature')}
                  >
                    {options4}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={24} md={6} className="px-8">
                <Form.Item
                  name="action"
                  className="input-label mb-0"
                  label="Actions"
                >
                  <Select
                    defaultValue="All Actions"
                    className="cust-input w-100 bgwhite"
                    style={{ width: 200 }}
                    onChange={(e) => this.handleActionDropChange(e, 'action')}
                  >
                    {options5}
                  </Select>

                </Form.Item>
              </Col>
              <Col sm={24} md={3} lg={3} xl={3} xxl={3} className="px-8 text-right">
             
             <Button
               type="primary"
               className="primary-btn px-24 search-btn mt-20"
               htmlType="submit"
               onClick={this.handleSearch}
             >Search
             </Button>
            
           </Col>
            </Row>
          </Form>
        </div>
        <List className="auditlogs-grid"
          showActionBar={true} onActionClick={(key) => this.onActionClick(key)} pKey={"auditlogs"}
          url={gridUrl} additionalParams={searchObjCopy} ref={this.gridRef}
          key={gridUrl}
          columns={this.gridColumns}
        />
          <Modal
          title="Audit Details"
          destroyOnClose={true}
          visible={showModal}
          closeIcon={<Tooltip title="Close"><span className="icon md x c-pointer" onClick={this.handleCancelModal} /></Tooltip>}
          footer={null}
          width={800}
        >
         <List className="auditlogs-grid"
          url={innerGridUrl}
          columns={this.showgridColumns}
          showActionBar={true}
        />
        </Modal>
        <Modal
          title="Custom Dates"
          visible={this.state.modal}
          closeIcon={<Tooltip title="Close"><span className="icon md x c-pointer" onClick={this.handleCancel} /></Tooltip>}
          footer={null}
        >
          <div className="">
            {this.state.stateLoading && <Loader />}
            {this.state?.message && <Alert showIcon type="info" description={this.state?.message} closable={false} />}
            <Form
              className="ant-advanced-search-form"
              autoComplete="off"
              onFinish={(e) => this.handleOk(e, "timeSpan")}
              ref={this.formDateRef}
            >
              <Row gutter={24} className="mb-24 pb-24 border-bottom">
                <Col xs={24} sm={24} md={12} >
                  <Form.Item
                    name="fromdate"
                    className="input-label"
                    label="Start Date"
                    rules={[
                      { required: true, message: "Is required" }
                    ]}
                  >
                    <DatePicker
                      format={config?.dateFormates?.dateFormate}
                      onChange={(e) => this.handleDateChange(e, 'fromdate')}
                      className="cust-input" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="todate"
                    className="input-label"
                    label="End Date"
                    rules={[
                      { required: true, message: "Is required" }, {
                        type: "date", validator: async (rule, value, callback) => {
                          if (value) {
                            if (new Date(value) < moment(searchObj.fromdate).format(config?.dateFormates?.dateFormate)) {
                              throw new Error("Start date must be less than or equal to the end date.")
                            } else {
                              callback();
                            }
                          }
                        }
                      }
                    ]}
                  >
                    <DatePicker
                      className="cust-input"
                      onChange={(e) => this.handleDateChange(e, 'todate')}
                      format={config?.dateFormates?.dateFormate} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item className="mb-0">
                <div className="text-right">
                  <Button type="primary" className="primary-btn cancel-btn mr-8" onClick={this.handleCancel} > Cancel</Button>
                  <Button type="primary" key="submit" className="primary-btn" htmlType="submit"> Ok</Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <Modal
          title="Audit Logs Details"
          visible={this.state.cryptoModal}
          className="crypto-list"
          destroyOnClose
          closeIcon={
            <Tooltip title="Close">
              <span
                className="icon md x c-pointer"
                onClick={() =>
                  this.setState({ ...this.state, cryptoModal: false })
                }
              />
            </Tooltip>
          }
          footer={
            <Button
              type="primary"
              className="primary-btn cancel-btn"
              onClick={() =>
                this.setState({ ...this.state, cryptoModal: false })
              }>
              Close
            </Button>
          }>
          {isLoading && (
            <Loader />
          ) ||
          ( <>{(isLoading && ((logRowData?.browser == null) || (logRowData?.location == null) || (logRowData?.ip == null) || logRowData?.deviceType == null))
            && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Data"} />
              || <>
              {this.getCryptoModaldata("IP Address",logRowData?.ip)}
              {this.getCryptoModaldata("City",logRowData?.location?.city)}
              {this.getCryptoModaldata("State",logRowData?.location?.state)}
              {this.getCryptoModaldata("Country",logRowData?.location?.countryName)}
              {this.getCryptoModaldata("Postal Code",logRowData?.location?.postal)}
              {this.getCryptoModaldata("Latitude",logRowData?.location?.latitude)}
              {this.getCryptoModaldata("Browser",logRowData?.browser)}
              {logRowData?.deviceType?.type &&<div className="coin-info">
                  <Text>Device Type</Text>
                  <Text style={{ textTransform: 'capitalize' }}>{logRowData?.deviceType?.type}</Text>
                </div>}
              {this.getCryptoModaldata("Device Name",logRowData?.deviceType?.name)}
              {this.getCryptoModaldata("Device Version",logRowData?.logRowData?.deviceType?.version?.replace("null", ""))}
                </>}
            </>)}

        </Modal>
        <Modal
          title="Confirm Retry?"
          visible={this.state.retryModal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.handleRetryCancel} />
            </Tooltip>
          }
          footer={
            <>
              <Button
                type="primary"
                className="primary-btn cancel-btn"
                onClick={this.handleRetryCancel}
              >
                No
              </Button>
              <Button
                type="primary"
                className="primary-btn"
                onClick={this.handleRetrySave}
                loading={this.state.isdiabledLoading}
              >
                Yes
              </Button>
            </>
          }
        >
          {error !== undefined && error !== null && (
            <Alert type="error" className="mb-16" showIcon description={error} />
          )}
          <p className="fs-16 mb-0">
            Do you really want to Retry?

          </p>
        </Modal>
      </>
    );
  }
}

const connectStateToProps = ({ userConfig }) => {
  return { userConfig: userConfig.userProfileInfo }
}

const connectDispatchToProps = dispatch => {
  return {
    setAction: (val) => {
      dispatch(setCurrentAction(val))
    },
    dispatch
  }
}

export default connect(connectStateToProps, connectDispatchToProps)(AuditLogs);