

import React, { Component } from "react";
import { Form, Row, Col, Input, Button, Select, message, Alert } from "antd";
import { validateContentRule } from '../../utils/custom.validator'
import { setCurrentAction } from '../../reducers/actionsReducer';
import { getAlertsLu, getAlert, updateAlert, saveAlert, getNameandEmail } from "./api";
import { connect } from "react-redux";
import apiCalls from "../../api/apiCalls";
import Loader from '../loader.component';
import { publishShowActions } from "../grid.component/subscribir";
import { Link } from "react-router-dom";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
const { TextArea } = Input;
const { Option } = Select;
class AddAlert extends Component {
  constructor (props) {
    super(props);
    this.state = {
      feature: [],
      alertNumber: [],
      type: [],
      email: [],
      name: [],
      state: [],
      isLoading: false,
      alertObject: {},
      customerId: {},
      btnDisabled: false,
      alertObj: { feature: "", type: "", name: "", email: "", state: "", alertNumber: "" },
      errorMsg: null,
      btnLoading: false,
    };
    this.formRef = React.createRef();
  }
  componentDidMount = () => {
    this.getLookUpData();
    if (this.props.match.params.id !== "00000000-0000-0000-0000-000000000000") {
      this.props.setAction('edit');
      this.loadData();
    } else {
      this.loadData("00000000-0000-0000-0000-000000000000");
    }
    publishShowActions(false);
    setTimeout(() => this.setState({ ...this.state, isLoading: false }), 3000);
  };

  getNameLookup = async (email, name) => {
    let response = await getNameandEmail(
      email === null ? null : email,
      name === null ? null : name
    );
    if (response.ok) {
      if (email) {
        this.setState({ email: response.data });
      } else {
        this.setState({ name: response.data });
      }
    } else {
      message.destroy()
      message.error({
        content:apiCalls.isErrorDispaly(response),
        className: 'custom-msg',
        duration: 0.5
      });
    }
  };

  loadData = async () => {
    this.setState({ ...this.state, loading: true, error: null });
    let response = await getAlert(this.props.match.params.id);
    if (response.ok) {
      this.setState({ ...this.state, customerId: response.data.customerId })
      this.setState({ ...this.state, alertObject: response.data })
      this.formRef.current.setFieldsValue(response.data)
    } else {
      message.destroy()
      message.error({
        content:apiCalls.isErrorDispaly(response),
        className: 'custom-msg',
        duration: 0.5
      });
    }
    this.setState({ ...this.state, loading: false, error: null });
  }
  redirectCaseView = () => {

    const items = this.state.alertObject;
    const val = items.caseId;
    this.props.history.push("/cases/1/" + val + "/view");
    this.props.dispatch(
      setBreadcrumb({ key: "/cases/1/" + val + "/view", val: items.caseNumber })
    );
  }
  handleChange = (val, type) => {
    if (type === 'email') {
      const obj = this.state.email?.find((item) => item.email === val);
      this.state.email.email=val;
      this.setState({ ...this.state, email: obj })
      this.setState({ ...this.state, customerId: obj.id })
      this.formRef.current.setFieldsValue({ name: obj.name })
    }
    else if (type === "feature") {
      this.setState(prevState => {
        let alertObj = Object.assign({}, prevState.alertObj);
        alertObj.feature = val;
        return { alertObj };
      })
    }
    else if (type === "type") {
      this.setState(prevState => {
        let alertObj = Object.assign({}, prevState.alertObj);
        alertObj.type = val;
        return { alertObj };
      })
    } else {
      const obj = this.state.name?.find((item) => item.name === val);
      this.state.name.name=val;
      this.setState({ ...this.state, name: obj })
      this.setState({ ...this.state, customerId: obj.id })
      this.formRef.current.setFieldsValue({ email: obj?.email })

    }
  }

  handleSearch = (e, type) => {
    if (e.target.value != null && e.target.value.length > 2) {
      if (type === "email") {
        const email = e.target.value;
        this.getNameLookup(email, null);
      } else {
        const name = e.target.value;
        this.getNameLookup(null, name);
      }
    }
  };



  getLookUpData = async () => {
    let response = await getAlertsLu();
    if (response.ok) {
      this.setState({
        ...this.state,
        feature: response.data.futurelookUp,
        type: response.data.typelookUp,
        state: response.data.statelookUp
      });
    } else {
      message.destroy()
      message.error({
        content:apiCalls.isErrorDispaly(response),
        className: 'custom-msg',
        duration: 0.5
      });
    }
  };

  saveBankInfo = async (values) => {
    this.setState({ ...this.state, btnLoading: true })
    let obj = Object.assign({});
    obj.id = this.props.match.params.id;
    obj.customerId = this.state.customerId;
    obj.alertNumber = values.alertNumber;
    obj.alert = values.alert;
    obj.transactionId = values.transactionId;
    obj.description = values.description;
    obj.type = values.type;
    obj.feature = values.feature;
    obj.userName = values.userName;
    obj.state = values.state;
    obj.remarks = values.remarks;
    obj.createdBy = this.props.userConfig.userName;
    obj.createdDate = new Date();
    obj.modifiedBy = this.props.userConfig.userName;
    obj.modifiedDate = new Date();
    obj.status = true;
    if (obj.id === "00000000-0000-0000-0000-000000000000") {
      this.setState({ ...this.state, btnDisabled: true });
      let response = await saveAlert(obj);
      if (response.ok) {
        this.setState({ ...this.state, btnDisabled: false, btnLoading: false, errorMsg: null });
        message.destroy();
        message.success({
          content: 'Alert details saved successfully',
          className: "custom-msg",
          duration: 3
        })
        this.props.history.push('/alerts')
      }
      else {
        this.setState({ ...this.state, btnDisabled: false, errorMsg:apiCalls.isErrorDispaly(response), btnLoading: false });

      }
    } else {
      this.setState({ ...this.state, btnDisabled: true, btnLoading: true });
      obj.info = JSON.stringify(this.props.trackAuditLogData);
      let response = await updateAlert(obj);
      if (response.ok) {
        this.setState({ ...this.state, btnDisabled: false, btnLoading: false, errorMsg: null });
        message.destroy();
        message.success({
          content: 'Alert details saved successfully',
          className: "custom-msg",
          duration: 3
        })
        this.props.history.push('/alerts')

      }
      else {
        this.setState({ ...this.state, btnDisabled: false, errorMsg: apiCalls.isErrorDispaly(response), btnLoading: false });
      }
    }

    apiCalls.trackEvent({

      Action: "Alert updated",
      Feature: "Alerts",
      Remarks: "Alert updated",
      FullFeatureName: "Alerts",
      userName: this.props.userConfig.userName,
      id: this.props.userConfig.id
    });
  }

  backToAlerts = () => {
    this.props.history.push({
      pathname: "/alerts"
    });
  };
   renderOptions = (items) => {
    return items.map((item, indx) => (
        <Option key={indx} value={item}>
            {item}
        </Option>
    ));
};
  render() {
    const { feature, name, email, type, alertObject, btnLoading } = this.state;
    return (
      <>
        <div>
          {this.state.errorMsg !== undefined && this.state.errorMsg !== null && (
            <Alert className="w-100 mb-16 align-center" type="error" showIcon description={this.state.errorMsg} />
          )}

          <Form
            name="advanced_search"
            initialValues={alertObject}
            className="ant-advanced-search-form"
            onFinish={this.saveBankInfo}
            ref={this.formRef}

            autoComplete="off"
          >
            {feature.length > 0 ?
              <Row gutter={24}>

                <Col xs={18} sm={18} md={9} lg={6} xxl={6}>
                  <Form.Item
                    name="alertNumber"
                    label="Alert Number"
                    className="input-label"
                  >
                    <Input
                      placeholder="Alert Number"
                      className="cust-input"
                      maxLength={50}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={18} sm={18} md={9} lg={12} xxl={12}>
                  
                  <Form.Item
                    name="alert"
                    label="Alert Title"
                    className="input-label"
                    rules={[
                      {
                        required: true,
                        message: 'Is required'
                      },
                      {
                        whitespace: true,
                        message: 'Is required'
                      }, {
                        validator: validateContentRule
                      }
                    ]}
                  >
                    <Input
                      placeholder="Alert Title"
                      className="cust-input"
                      maxLength={500}
                      disabled={this.props.match.params.type === "disabled"}
                    />
                  </Form.Item>
                </Col>
                <Col xs={18} sm={18} md={9} lg={6} xxl={6}>
                  <Form.Item
                    name="feature"
                    label="Features"
                    className="input-label"
                    rules={[
                      {
                        required: true,
                        message: 'Is required'
                      },
                      {
                        whitespace: true,
                        message: 'Is required'
                      }, {
                        validator: validateContentRule
                      }
                    ]}
                  >
                    <Select
                      className="cust-input"
                      onChange={(e) => this.handleChange(e, "feature")}
                      placeholder="Select Feature"
                      disabled={this.props.match.params.type === "disabled"}

                    >
                      {this.renderOptions(feature)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={18} sm={18} md={9} lg={6} xxl={6}>
                  <Form.Item
                    name="name"
                    label="Customer Name"
                    className="input-label"
                    rules={[
                      {
                        required: true,
                        message: 'Is required'
                      },
                      {
                        whitespace: true,
                        message: 'Is required'
                      }, {
                        validator: validateContentRule
                      }
                    ]}
                  >
                    <Select
                      showSearch
                      className="cust-input"
                      onKeyUp={(e) => this.handleSearch(e, "name")}
                      onChange={(e) => this.handleChange(e, "name")}
                      placeholder="Select Customer Name"
                      disabled={this.props.match.params.type === "disabled"}
                    >
                      {name.map((item, indx) => (
                        <Option key={indx} value={item.name}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={18} sm={18} md={9} lg={6} xxl={6}>
                  <Form.Item
                    name="email"
                    label="Customer Email"
                    className="input-label"
                    rules={[
                      {
                        required: true,
                        message: 'Is required'
                      },
                      {
                        whitespace: true,
                        message: 'Is required'
                      }, {
                        validator: validateContentRule
                      }
                    ]}
                  >
                    <Select
                      showSearch
                      className="cust-input"
                      onKeyUp={(e) => this.handleSearch(e, "email")}
                      onChange={(e) => this.handleChange(e, "email")}
                      placeholder="Select Customer Email"
                      disabled={this.props.match.params.type === "disabled"}
                    >
                      {email.map((item, indx) => (
                        <Option key={indx} value={item.email}>
                          {item.email}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>



                <Col xs={18} sm={18} md={9} lg={6} xxl={6}>
                  <Form.Item
                    name="type"
                    label="Type"
                    className="input-label"
                    rules={[
                      {
                        required: true,
                        message: 'Is required'
                      },
                      {
                        whitespace: true,
                        message: 'Is required'
                      }, {
                        validator: validateContentRule
                      }
                    ]}
                  >
                    <Select
                      className="cust-input"
                      onChange={(e) => this.handleChange(e, "type")}
                      placeholder="Select Type"
                      disabled={this.props.match.params.type === "disabled"}
                    >
                      {this.renderOptions(type)}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={18} sm={18} md={9} lg={6} xxl={6}>
                  <Form.Item
                    name="transactionId"
                    label="Transaction ID"
                    className="input-label"
                    rules={[

                      {
                        whitespace: true,
                        message: 'Is required'
                      }, {
                        validator: validateContentRule
                      }
                    ]}
                  >
                    <Input
                      placeholder="Transaction ID"
                      className="cust-input"
                      maxLength={50}
                      disabled={this.props.match.params.type === "disabled"}
                    />
                  </Form.Item>
                </Col>

                <Col xs={48} sm={48} md={24} lg={30} xxl={30}>
                  <Form.Item
                    name="description"
                    label="Alert Description"
                    className="input-label"
                  
                    rules={[
                      {
                        required: true,
                        message: 'Is required'
                      },
                      {
                        whitespace: true,
                        message: 'Is required'
                      }, {
                        validator: validateContentRule
                      }
                    ]}
                  >
                    <TextArea
                      placeholder="Alert Description"
                      rows={2}

                      maxLength={200}
                      showCount
                      disabled={this.props.match.params.type === "disabled"}

                    />
                  </Form.Item>
                </Col>
                {alertObject.caseNumber !== null && <Col xs={18} sm={18} md={9} lg={6} xxl={6}>

                  <Form.Item className='mt-24 ml-8'>
                    <label className="kpi-label d-block">Case Number</label>
                    <Link
                      className="fw-600 fs-20 c-pointer"
                      onClick={this.redirectCaseView}
                    >{alertObject.caseNumber}</Link>
                  </Form.Item>
                </Col>}

                <Col xs={48} sm={48} md={24} lg={30} xxl={30}>
                  <Form.Item
                    name="remarks"
                    label="Remarks"
                    className="input-label"
                    rules={[
                      {
                        whitespace: true,
                        message: 'Is required'
                      }, {
                        validator: validateContentRule
                      }
                    ]}
                  >
                    <TextArea
                      placeholder="Remarks"
                      rows={4}

                      maxLength={500}
                      showCount
                      disabled={this.props.match.params.type === "disabled"}

                    />
                  </Form.Item>
                </Col>
              </Row>
              : <Loader />}
            <div className="text-right mt-24">
              {this.props.match.params.type !== 'disabled' &&
                <Button type="primary" loading={btnLoading} className="primary-btn" htmlType="submit"
                >
                  Save
                </Button>}
              <Button
                type="primary"
                className="primary-btn cancel-btn"
                style={{ margin: "0 8px" }}
                onClick={this.backToAlerts}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  }
}
const connectStateToProps = ({ userConfig }) => {
  return { userConfig: userConfig.userProfileInfo,
    trackAuditLogData: userConfig.trackAuditLogData };
};
const connectDispatchToProps = dispatch => {
  return {
    setAction: (val) => {
      dispatch(setCurrentAction(val))
    },
    dispatch
  }
}
export default connect(connectStateToProps, connectDispatchToProps)(AddAlert);
