import React, { Component } from "react";
import { Upload, Typography, Modal, Tooltip, Button, Alert, Spin,Form,message,Row,Col} from "antd";
import { connect } from "react-redux";
import DocumentPreview from "../components/shared/documentPreview";
import apiCalls from "../api/apiCalls";
import { ApiControllers } from "../api/config";

const { Dragger } = Upload;
const { Text } = Typography;

const EllipsisMiddle = ({ suffixCount, children }) => {
  const start = children?.slice(0, children.length - suffixCount).trim();
  const suffix = children?.slice(-suffixCount).trim();
  return (
    <Text className="btn-textstyle upload-size docnames cust-pop-doc" ellipsis={{ suffix }}>
      {start}
    </Text>
  );
};
class DocumentsUploade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidFile: true,
      docReplyObjs: props.docReplyObjs ? [...props.docReplyObjs] : [],
      docPreviewDetails: null,
      errorMessage: null,
      loader:false,
      uploadErrorMessage:null,
      deletedRecord:[],
      docViewObj:props.docViewObj ? [...props.docViewObj] : [],
      popupdoc : [],
      popUpDocu:props.docViewObj ? [...props.docViewObj] : [],
      isDeleteVisible:false,
      btnloading:false,
      doc:{},
      deletebtn:false,
    };
  }   
  formref = React.createRef();
  uploadErrorMsg=(errormsg)=>{
    this.setState({...this.state,uploadErrorMessage:errormsg})
   }
  handleUpload = ({ file }) => {
    this.setState({...this.state,uploadErrorMessage:this.state.uploadErrorMessage})
    if (file.status === 'done' && this.state.isValidFile) {
       let docObj = [...this.state.docReplyObjs]
       let documentsObj=[...this.state.popUpDocu]
       let popupdocs=[...this.state.popupdoc]
      docObj.push({ id: file.response.id, fileName: file.response.fileName, state: 'submitted', fileSize:file.size })
      documentsObj.push({id: file.response.id, fileName: file.response.fileName, state: 'submitted', fileSize:file.size})
      popupdocs.push({ id: file.response.id, fileName: file.response.fileName, state: 'submitted', fileSize:file.size })
      this.setState({ ...this.state, docReplyObjs: [...docObj],popUpDocu:[...documentsObj],popupdoc:[...popupdocs],loader:false,errorMessage:null })
    }    
    else if (!this.state.isValidFile) {
      return Upload.LIST_IGNORE;
    }
  };

  beforeUpload = (file) => {
    const fileType = {
        "image/png": true,
        "image/jpg": true,
        "image/jpeg": true,
        "image/PNG": true,
        "image/JPG": true,
        "image/JPEG": true,
        "application/pdf": true,
        "application/PDF": true,
    };

    const isFileName = file.name.split(".").length <= 2;
    let isValidFile = false;
    let uploadErrorMessage = null;

    if (fileType[file.type] && isFileName) {
        isValidFile = true;
    } else {
        uploadErrorMessage = isFileName
            ? "File is not allowed. You can upload jpg, png, jpeg and PDF files"
            : "File don't allow double extension";
    }

    this.setState({ errorMessage: null, uploadErrorMessage, isValidFile, loader: isValidFile });

    return isValidFile;
};


  isErrorDispaly = (objValue) => {
    if (objValue.data && typeof objValue.data === "string") {
      return objValue.data;
    } else if (
      objValue.originalError &&
      typeof objValue.originalError.message === "string"
    ) {
      return objValue.originalError.message;
    } else {
      return "Something went wrong please try again!";
    }
  };

   formatBytes=(bytes, decimals = 2)=>{
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed()) + ' ' + sizes[i];
    }
    documentDelete=(file,idx)=>{  
      this.setState({...this.state,isDeleteVisible:true,doc:file,})
    }
    CloseDeletePop=()=>{
      this.setState({...this.state,isDeleteVisible:false,})
    }
    deleteDocument = async (doc, idx, isAdd) => {
      const docObj = this.props.previewModal ? [...this.state.popupdoc] : [...this.state.docViewObj];
      const deleterec = Object.assign([], doc ? doc : this.state.doc);
      this.setState({ ...this.state, deletebtn: true });
  
      const isSubmitted = (this.state.doc.state || doc.state) === 'submitted';
  
      for (let i in docObj) {
          if (docObj[i].id === (this.state.doc.id || doc.id)) {
              if (isSubmitted) {
                  docObj[i].state = 'Deleted';
                  deleterec.state = 'Deleted';
                  this.setState({ ...this.state, deletedRecord: deleterec });
                  this.state.deletedRecord.push(this.state.doc || doc);
              } else {
                  docObj[i].state = 'Deleted';
                  this.state.deletedRecord.push(this.state.doc || doc);
              }
              docObj.splice(Number(i), 1); // Convert i to a number
              break;
          }
      }
  
      if (!this.props.previewModal) {
          this.UploadDocumentsSave(docObj);
          this.setState({ ...this.state, docReplyObjs: [...docObj], popUpDocu: [...docObj], doc: [], deletebtn: true });
      } else {
          this.setState({ ...this.state, docReplyObjs: [...docObj], popupdoc: [...docObj], doc: [] });
      }
  };
  
  docPreviewClose = () => {
    this.setState({ ...this.state, previewModal: false, docPreviewDetails: null });
  };
  docPreviewOpen = (data) => {
    this.setState({ ...this.state, previewModal: true, docPreviewDetails: { id: data.id, fileName: data.fileName } });
  };
  backToRiskData = () => {
    this.setState({ ...this.state, iframeView: false });
  };
  UploadDocumentsSave = async (docObj) => { 
      this.setState({...this.state,loader:false,btnloading:true})
           let saveObj={
               id: this.props.docID,
               ScreenName: this.props.screenTitle,
               ModifiedBy:this.props.ModifiedBy,
               adminDocumentDetails:this.props.previewModal ? this.state.popupdoc : this.state.deletedRecord || this.state.docReplyOBJS || []             
             }
         let res = await  apiCalls.docUploade(saveObj)
         if (res.ok) {
          if(!this.props.previewModal){
            this.props.attachmentUpdate([...docObj])
          }
             
            if(this.props.previewModal){
                this.state.docViewObj.push(...this.state.popupdoc) 
                message.destroy();             
              message.success({
                content: 'Document uploaded successfully',
                className: "custom-msg",
                duration: 3 
              })          
            } else {
              this.setState({...this.state,docViewObj:[...this.state.popUpDocu]})
              message.destroy();             
              message.success({
                content: 'Document deleted successfully',
                className: "custom-msg",
                duration: 3 
              })
            }        
            this.setState({...this.state,loader:false,btnloading:false,popupdoc:[],isDeleteVisible:false,doc:[],deletedRecord:[],deletebtn:false,uploadErrorMessage:null})        
           this.props?.pop_up_cancel(this.state.popupdoc)
         }else {
          this.setState({...this.state, isDeleteVisible:false, doc:[], loader:false,btnloading:false,deletebtn:false, errorMessage: apiCalls.isErrorDispaly(res)})
         } 
   }
   closeModal=()=>{
    this.setState({...this.state,popupdoc:[],docReplyObjs:[],uploadErrorMessage:null})
    this.props.pop_up_cancel();
   }
 getErrorMsg=()=>(
  this.state.errorMessage !== null && (
    <Alert type="error" description={this.state.errorMessage} showIcon />
  )
 )
 getUploadErrorMsg=()=>(
  this.state.uploadErrorMessage !== null && (
    <Alert type="error" description={this.state.uploadErrorMessage} showIcon />
  )
 )
 renderDocumentPreviewCom=()=>(
  this.state.previewModal && this.props.previewModal && (
    <DocumentPreview
      previewModal={this.state.previewModal}
      handleCancle={this.docPreviewClose}
      upLoadResponse={this.state.docPreviewDetails}
    />
  )
 )
  render() {
    if(this.state.docViewObj!==this.props.docViewObj && this.props.docViewObj !=null){
       this.setState({...this.state, docViewObj:this.props.docViewObj})
    }  
    return (
      <>
        {this.getErrorMsg()}       
         { 
           <Form
            ref={this.formref}
            onFinish={this.UploadDocumentsSave}
            className="risk-popup"
          >
         <Modal   
          visible={this.props.previewModal}
          title={this.props.screenTitle}
          closeIcon={
            <Tooltip title="Close">
              <span
                className="icon md x c-pointer risk-close"
                onClick={()=>this.closeModal()}
              />
            </Tooltip>
          }
       
          footer={   
              <Form.Item className="mb-0">
          <div className="text-right">
            <Button
              type="primary"
              className="primary-btn cancel-btn mr-8"
             onClick={()=>this.closeModal()}
            >
              {" "}
              Cancel{" "}
            </Button>
            <Button
              type="primary"
              className="primary-btn"
              htmlType="submit"
              loading={this.state.btnloading}
              onClick={()=>this.UploadDocumentsSave()}
              disabled={this.state.popupdoc.length==0 ? true : false }
            >
              Save{" "}
            </Button>
          </div>
        </Form.Item>
        }
        >
           {this.getUploadErrorMsg()}  
             
            
                <Form.Item>
           
                <div className='mb-24 mt-8'>
          <Spin spinning={this.state.loader}>
          <Dragger
            accept=".pdf,.jpg,.jpeg,.png, .PDF, .JPG, .JPEG, .PNG"
            className="upload mt-4"
            multiple={false}
            action={
              process.env.REACT_APP_UPLOAD_API +
              "api/v1/" +
              ApiControllers.common +
              `UploadFileNew?screenName=${this.props.screenTitle}&fieldName=uploadfile&tableName=Common.Documents`
            }
            showUploadList={false}
            beforeUpload={(props) => {
              this.beforeUpload(props);
            }}
            onChange={(props) => {
              this.handleUpload(props);
            }}
            headers={{ Authorization: `Bearer ${this.props?.user?.deviceToken}` }}
          >
            <p className="ant-upload-drag-icon">
              <span className="icon xxxl doc-upload" />
            </p>
            <p className="ant-upload-text upload-title">
              Drag and drop or browse to choose file
            </p>
            <p  className="ant-upload-hint uplaod-inner">PNG, JPG, JPEG and PDF files are allowed</p>
          </Dragger>
          </Spin>
        <div className="docfile-container">

          {this.state.popupdoc?.map((file, idx1) => (
            file.recordStatus !== "Deleted" && (
              <div key={idx1} className="riskdocfile uploaddoc-margin cust-upload-style">
                <span
                  className={`icon xl ${(file?.fileName?.slice(-3) === "zip" ? "file" : "") ||
                    (file?.fileName?.slice(-3) === "pdf" ? "file" : "image")
                    } mr-16`}
                />
                <div
                  className="docdetails c-pointer pr-8"
                  onClick={() => this.docPreviewOpen(file)}
                >
                  <EllipsisMiddle suffixCount={6} >{file?.fileName}</EllipsisMiddle>
                  <span className="file-sizestyle" >
                    {this.formatBytes(file?.fileSize)}
                  </span>
                </div>
                <span
                  className="icon md close c-pointer"
                  onClick={() =>
                    this.deleteDocument(file, idx1, true)
                  }
                />
              </div>
            )
          ))}
        </div>
        </div>
        {this.renderDocumentPreviewCom()}
               </Form.Item>
        </Modal> </Form>

         }
             {<Row>
            {this.state.docViewObj?.map((file, idx1) => (
              file.recordStatus !== "Deleted" && (
                <Col xs={24} sm={24} md={12} lg={8} xxl={8} className="">
                <div key={idx1} 
               className="docfile mr-8 mt-16 d-flex align-center"
                >
                  <span
                    className={`icon xl ${(file?.fileName?.slice(-3) === "zip" ? "file" : "") ||
                      (file?.fileName?.slice(-3) === "pdf" ? "file" : "image")
                      } mr-16`}
                  />
                  <div
                    className="docdetails c-pointer pr-8"
                    onClick={() => this.docPreviewOpen(file)}
                  >
                    <EllipsisMiddle suffixCount={6} >{file?.fileName}</EllipsisMiddle>
                    <span className="file-sizestyle" >
                      {this.formatBytes(file?.fileSize)}
                    </span>
                  </div>
                  <span
                    className="icon md close c-pointer"
                    onClick={() =>this.documentDelete(file, idx1, true)}
                  />
                  
                </div>  </Col>
              )
            ))}
            </Row>}
            <Modal
             visible={this.state.isDeleteVisible}
             title={"Confirm Delete"}
             closeIcon={
               <Tooltip title="Close">
                 <span
                   className="icon md x c-pointer risk-close"
                   onClick={this.CloseDeletePop}
                 />
               </Tooltip>
             }
             footer={
             <Form.Item className="mb-0">
                  <div className="text-right">
                    <Button
                      type="primary"
                      className="primary-btn cancel-btn mr-8"
                      onClick={this.CloseDeletePop}
                    >
                      {" "}
                      No{" "}
                    </Button>
                    <Button
                      type="primary"
                      className="primary-btn"
                      htmlType="submit"
                      onClick={()=>this.deleteDocument()}
                      loading={this.state.deletebtn}
                    >
                      Yes{" "}
                    </Button>
                  </div>
                </Form.Item>}>
                <p className="mb-0"> Are you sure, do you really want to delete ?</p>
            </Modal>
            {this.state.previewModal && !this.props.previewModal&& (
                      <DocumentPreview
                        previewModal={this.state.previewModal}
                        handleCancle={this.docPreviewClose}
                        upLoadResponse={this.state.docPreviewDetails}
                      />
                    )}
                  </>
                );
              }
}
const connectStateToProps = ({ oidc, userConfig }) => {
  return {
    userConfig: userConfig.userProfileInfo,
    user: oidc
  };
};
export default connect(connectStateToProps)(DocumentsUploade);