import { bankApi } from "../../api/index";
import { ApiControllers } from "../../api/config";
const getPyrrosTransactionDetails = (banktransId) => {
    return bankApi.get(ApiControllers.bank + `GetPyrrosTransactionDetails/${banktransId}`);
}
const getEvolveTransactionDetails = (banktransId) => {
    return bankApi.get(ApiControllers.bank + `GetEvolveTransactionDetails/${banktransId}`);
}
const getOpenTransactionDetails = (banktransId) => {
    return bankApi.get(ApiControllers.bank + `GetopenpaydTransactionDetails/${banktransId}`);
}
const userNameLuSearch = (email, name) => {
    return bankApi.get(
        ApiControllers.bank + `GetUsersNames/${email}/${name}`);
};
const getBankTransactionSearch = () => {
    return bankApi.get(ApiControllers.bank + "GetTransactionLookUp");
};
const pyrrosUserNameLuSearch = (email, name) => {
    return bankApi.get(
        ApiControllers.bank + `GetPyrrosUsersNames/${email}/${name}`);
}
const evolveUserNameLuSearch = (email, name) => {
    return bankApi.get(
        ApiControllers.bank + `GetEvolveUsersNames/${email}/${name}`);
}
const openpaydUserNameLuSearch = (email, name) => {
    return bankApi.get(
        ApiControllers.bank + `OpenpaydUserNames/${email}/${name}`);
}


export { getBankTransactionSearch, userNameLuSearch, getPyrrosTransactionDetails, getEvolveTransactionDetails, pyrrosUserNameLuSearch, evolveUserNameLuSearch,getOpenTransactionDetails,openpaydUserNameLuSearch };
