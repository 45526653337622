import React, { useEffect, useState } from "react";
import apiCalls from "../../api/apiCalls";

import {
	Typography,
	Button,
	Row,
	Col,
	Alert,
	Spin,
	Empty
} from "antd";

function SharedInfo({ id, type, screen }) {
	const {Text } = Typography;

	const [loader, setLoader] = useState(false);
	const [infoVal, setInfoVal] = useState(null);
	const [error, setError] = useState(null);
	const [errorType, setErrorType] = useState("error");

	useEffect(() => {
		setInfoVal(null);
		getInfoData(id, type);
		setError(null);
		setErrorType("error");
	}, [id, type]);

	const refreshData = async (docId, docType) => {
		setLoader(true);
		let response = await apiCalls.refreshCall(docId, screen);
		if (response) {
			getInfoData(docId, docType);
			setLoader(false);
		} 
	};

	const getInfoData = async (objId, objType) => {
		setLoader(true);
		let response = await apiCalls.getInfoVal(objId, objType);
		if (response.ok) {
			setLoader(false);
			setInfoVal(response.data);
		} else {
			setLoader(false);
			setError("Some error occur, try later");
		}
	};
	const handlerefresh = () => {
      refreshData(id, type);
	};

	return (
		<>
			<div className=" mb-16 text-right">
				<Button className="primary-btn" onClick={handlerefresh}>
					Refresh
				</Button>
			</div>
			<Spin spinning={loader}>
				{loader === false && error && (
					<Alert
						closable={false}
						type={errorType}
						description={error || "No data here"}
						onClose={() => setError(null)}
						showIcon
					/>
				)}
{
	infoVal?.length === 0 && <Empty
	image={Empty.PRESENTED_IMAGE_SIMPLE}
	description="No Data Found"></Empty>
}
				{infoVal?.length > 0 &&
					infoVal.map((item) => (
						<>
							<div className="case-ribbon">
								<Row gutter={[8, 8]} wrap={true}>
									<div className="ml-16 mb-16" style={{ flex: 1 }}>
										<div
											className="fw-600 text-white-30 fs-16 l-height-normal"
											style={{ wordBreak: "break-all" }}>
											<Text>{item.label}</Text>
										</div>
									</div>
								</Row>
								<Row gutter={[8, 8]} wrap={true}>
									<Col span={12}>
										<div className="ribbon-item">
											<div className="ml-16" style={{ flex: 1 }}>
												<Text className="fw-300 text-white-50 fs-12 text-captz">
													Type
												</Text>
												<div
													className="fw-600 text-white-30 fs-14 l-height-normal"
													style={{ wordBreak: "break-all" }}>
													<Text>{item.type}</Text>
												</div>
											</div>
										</div>
									</Col>
									<Col span={12}>
										<div className="ribbon-item">
											<div className="ml-16" style={{ flex: 1 }}>
												<Text className="fw-300 text-white-50 fs-12 text-captz">
													Address
												</Text>
												<div
													className="fw-600 text-white-30 fs-14 l-height-normal"
													style={{ wordBreak: "break-all" }}>
													<Text>{item.address}</Text>
												</div>
											</div>
										</div>
									</Col>
								</Row>
								<Row gutter={[8, 8]} wrap={true}>
									<Col span={12}>
										<div className="ribbon-item">
											<div className="ml-16" style={{ flex: 1 }}>
												<Text className="fw-300 text-white-50 fs-12 text-captz">
													Score
												</Text>
												<div
													className="fw-600 text-white-30 fs-16 l-height-normal"
													style={{ wordBreak: "break-all" }}>
													<Text>{item.score}</Text>
												</div>
											</div>
										</div>
									</Col>
									<Col span={12}>
										<div className="ribbon-item">
											<div className="ml-16" style={{ flex: 1 }}>
												<Text className="fw-300 text-white-50 fs-12 text-captz">
													Value
												</Text>
												<div
													className="fw-600 text-white-30 fs-16 l-height-normal"
													style={{ wordBreak: "break-all" }}>
													<Text>{item.value}</Text>
												</div>
											</div>
										</div>
									</Col>
								</Row>
							</div>
						</>
					))}
			</Spin>
		</>
	);
}

export default SharedInfo;
