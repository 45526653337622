import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Button, message, Table, Tooltip, Modal, Alert, Spin } from "antd";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { getAddandEdit, saveControl, activeInactive } from "./api"
import { connect } from 'react-redux';
import Loader from '../loader.component';
import apiCalls from '../../api/apiCalls';
import { setCurrentAction } from '../../reducers/actionsReducer';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined, LoadingOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import { validateContentRule } from "../../utils/custom.validator";
import { publishShowActions } from "../grid.component/subscribir";
import { uuidv4 } from "../documents.component/api";
import Moment from "react-moment";
import config from "../../config";
const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const obj = {
  controlCodeCategoryId: '',
  codeKey: '',
  codeValue: '',
  recordStatus: null,
  status: "Active"
}
const ControCodesDetails = (props) => {
  const [controlObj, setControlObj] = useState(obj);
  const [addControl, setAddControl] = useState([]);
  const [editedControl, setEditedControl] = useState([])
  const [controlCode, setControlCode] = useState({});
  const [selection, setSelection] = useState([]);
  const [statusModal, setStausModal] = useState(false);
  const [addCodeModal, setAddCodeModal] = useState(false);
  const [addModalCodeKey, setAddModalCodeKey] = useState();
  const [form] = Form.useForm();
  const [formCodeName] = Form.useForm();
  const history = useHistory();
  const [isEdit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(false);
  const [type, setType] = useState(false);
  const [error, setError] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false); 
  const [btnDis, setBtnDis] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  const [editControlDetails, setEditControlDetails] = useState(false)
  const [handleModalKeyInput,setHandleModalKeyInput]=useState(false)
  const [handleModalValueInput,setHandleModalValueInput]=useState(false)
  const [addCodeObj, setAddCodeObj] = useState({})
  useEffect(() => {

    publishShowActions(false);

    window.scrollTo(0, 0);
    setMode(props.match.params.mode);
    setType(props.match.params.type);
    if (props.match.params.mode || props.match.params.code) {
      props.setAction(null);
    } else if (props.match.params.id !== "00000000-0000-0000-0000-000000000000") {
      props.setAction('edit');
    } else {
      props.setAction('add');
    }
    if (props.match.params.id !== "00000000-0000-0000-0000-000000000000") {
      loadData();
      setEdit(true);
    }
    setAddControl([...addControl])
  }, [])//eslint-disable-line react-hooks/exhaustive-deps
  const loadData = async () => {
    setLoading(true);
    let response = await getAddandEdit(props.match.params.id);
    if (response.ok) {
      setLoading(false);
      setError(null)
      setControlObj(response.data);
      let editControlCode = response.data.controlCodes;
      for (var i = 0; i <= editControlCode.length - 1; i++) {
        editControlCode[i].key = i;
        editControlCode[i].index = i;
      }
      setAddControl(editControlCode);
      form.setFieldsValue({ ...response.data });
    } else {
      setError(apiCalls.isErrorDispaly(response));
      setLoading(false);
    }
  };

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: "",
      dataIndex: "",
      render: (row) => {
        return <td className="text-center">
          <label className="text-center custom-checkbox">
            <input
              name="check"
              type="checkbox"
              checked={selection.indexOf(row.id) > -1}
              onChange={(e) => handleInputChange(row, e)}
              disabled={(mode == 'view' ? true : false) || (type == 'disabled' ? true : false)} />
            <span></span>
          </label>
        </td>;
      },
      width: 50,
      className: 'drag-visible',
    },
    {
      title: "Code Key",
      dataIndex: "codeKey",
    },
    {
      title: "Code Value",
      dataIndex: "codeValue",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];

  const handleInputChange = (key, e) => {
    if (e.target.checked === false) {
      setSelection([])
    } else {
      setWarningMsg(null)
      let _selection = [...addControl];
      const idx = _selection.indexOf(key.id);
      if (selection) {
        _selection = [];
      }
      if (idx > -1) {
        _selection.splice(idx, 1);
      } else {
        _selection.push(key.id);
      }
      setSelection(_selection);
    }
  };

  const SortableItem = sortableElement(pros => <tr {...pros} />);
  const SortableContainer = sortableContainer(propsval => <tbody {...propsval} />);
  const DraggableContainer = propval => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...propval}
    />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const dataSource = [...addControl]
     // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const dataSource = [...addControl];
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      setAddControl(newData);
    }
  }
 
  const saveControlCode = async (values) => {
    setBtnDis(true);
    values.id = props?.match?.params.id;
    if (!isEdit) {
      values.createdBy = props.userConfig?.userName;
    } else {
      values.modifiedBy = props.userConfig?.userName;
    }
    values.controlCodes = addControl.map(item => { return { ...item, id: item.recordStatus === "Added" ? "00000000-0000-0000-0000-000000000000" : item.id }; });
    values.status = obj.status;
    values.info = JSON.stringify(props.trackAuditLogData);
    values.customerId = props?.userConfig?.id;
    let response = await saveControl(values, props.match.params.id);
    if (response.ok) {
      setBtnDis(false);
      setWarningMsg(null)
      props.setAction(null);
      message.destroy();
      message.success({
        content: 'Control code saved successfully',
        className: 'custom-msg',
        duration: 3
      });
      history.push('/controlcodes');
      setLoading(false);
    } else {
      message.destroy();
      setWarningMsg(apiCalls.isErrorDispaly(response))
      window.scrollTo(0, 0);
      setLoading(false);
      setBtnDis(false);
    }
    apiCalls.trackEvent({ "Action": 'Control code Updated ', "Feature": 'Control codes', "Remarks": "Control code Updated", "FullFeatureName": 'Control codes', "userName": props.userConfig.userName, id: props.userConfig.id });
  };

  const backToMastergrid = () => { 

    history.push({ pathname: "/controlcodes", }); 

  };
 
  const getControlCode = (id) => {
    return addControl.find(item => item.id === id);
  };
  const showErrorMessage = (msg) => {
    message.destroy();
    setWarningMsg(msg)
  }
  const statusUpdate = () => {
    if (selection.length === 0 || selection.length > 1) {
      showErrorMessage("Please select the one record");
      return;
    }
    let _selectedObj = getControlCode(selection[0]);
    if (_selectedObj?.userCreated === "system" || _selectedObj?.userCreated === "System") {
      showErrorMessage("System record shouldn't allow to status change");
      setSelection([]);
      return;
    }
    if (_selectedObj.recordStatus === "Added") {
      showErrorMessage("Status change not allowed on newly added records");
      setSelection([]);
      return;
    }
    setControlCode(getControlCode(selection[0]));
    setStausModal(true);

  };




  const disableRecord = async () => {
    setEditControlDetails(false);
    setBtnDisabled(false);
    setBtnDisabled(true);
    let _selectedObj = getControlCode(selection[0]);
    let _obj = {
      "Id": [_selectedObj.id],
      "TableName": "Common.ControlCode",
      "ModifiedBy": props.oidc?.user?.profile?.name,
      "Status": [_selectedObj.status],
      "info": JSON.stringify(props.trackAuditLogData),
      "customerId": props?.userConfig?.id
    };

    const response = await activeInactive(_obj);
    if (response.ok) {
      setControlCode(_selectedObj);
      setStausModal(false);
      setError(null)
      setSelection([]);
      message.success({
        content: 'Record ' + (_selectedObj.status === 'Active' && 'deactivated' || 'activated') + ' successfully',
        className: 'custom-msg',
        duration: 3
      });
      setBtnDisabled(false);
    }else{
      setError(apiCalls.isErrorDispaly(response));
    }
    let _items = [...addControl];
    let _item = getControlCode(_selectedObj.id);
    if (_item) {
      for (let i = 0; i < _items.length; i++) {
        if (_items[i].id === _item.id) {
          _items[i] = { ..._item, status: _item.status === "Active" ? "Inactive" : "Active" };
        }
      }
    }
    
    setAddControl(_items);
    setSelection([]);
    apiCalls.trackEvent({
      Action:
        " Record " +
        (_selectedObj.status === "Active"
          ? "deactivated"
          : "activated"),
      Feature: "Control codes",
      Remarks:
        " Record " +
        (_selectedObj.status === "Active"
          ? "deactivated"
          : "activated"),
      FullFeatureName: "Control codes",
      userName: props.userConfig.userName,
      id: props.userConfig.id
    });
  };
  const handleAddCodeModalCancel = () => {
    setAddModalCodeKey(null)
    setHandleModalKeyInput(false)
    setAddCodeModal(false)
    setSelection([])
    
  }
 
  const handleAddCodeKeyChange = (e) => {
    let data = e.target.value;
    setAddModalCodeKey(data);
    setHandleModalKeyInput(true)
  }
  const addCodeUpdate = () => {
    setBtnDisabled(false);
    setEditControlDetails(false);
    setAddCodeModal(true)
    formCodeName.resetFields()
  }
  const editCodeUpdate = () => {
    setBtnDisabled(false);
    setWarningMsg(null)
    if (selection.length === 0 || selection.length > 1 ) {
      showErrorMessage("Please select the one record");
      return;
    } else {
      setEditControlDetails(true);
      setEditedControl([...addControl])
    }
    let _selectedObj = getControlCode(selection[0]);
    if(_selectedObj.status==="Active"){
      formCodeName.setFieldsValue({ codeKey: _selectedObj.codeKey,codeValue: _selectedObj.codeValue })
      setAddCodeObj(_selectedObj)
      setAddCodeModal(true)
    }
    else{
      setEditControlDetails(false);
      showErrorMessage("Record is inactive so you can't edit");
      setSelection([])
    }
   

  }
  const saveAddCodeNameRecord = () => {
    setBtnDisabled(true);
    let objs = {
      codeKey: addModalCodeKey,
      codeValue: addModalCodeKey,
      controlCodeCategoryId: "cd569739-6ec4-4800-a695-75446ba732cc",
      createdDate: new Date(),
      id: editControlDetails === true ? addCodeObj.id : uuidv4(),
      index: 0,
      key: 0,
      no: 0,
      status: "Active",
      recordStatus :addCodeObj?.recordStatus,
      text: null,
    }
    if (editControlDetails) {
      setBtnDisabled(true);
      objs.id = addCodeObj.id;
      for (let i = 0; i < addControl.length; i++) {
          setBtnDisabled(true);
          if (addControl[i].id === objs.id) {
              if (handleModalKeyInput) {
                  objs.codeKey = addModalCodeKey || addCodeObj?.codeKey;
              } else {
                  objs.codeKey = addCodeObj.codeKey;
              }
              if (handleModalValueInput) {
                  objs.codeValue = addCodeObj?.codeKey;
              } else {
                  objs.codeValue = addModalCodeKey || addCodeObj?.codeKey;
              }
              objs.recordStatus = objs.recordStatus !== "Added" ? "Modified" : "Added";
              objs.modifiedBy = props?.userConfig.firstName + props?.userConfig.lastName;
              objs.customerId = props?.userConfig.id;
              addControl[i] = objs;
              setBtnDisabled(true);
              setEditControlDetails(false);
              setEditedControl([...addControl]);
              setTimeout(() => setBtnDisabled(false), 300);
              setTimeout(() => setAddCodeModal(false), 200);
              break;
          }
      }
      setSelection([]);
  }  
    else {
      setBtnDisabled(true);
      setEditControlDetails(false);
      objs.recordStatus = "Added";
      objs.customerId=props?.userConfig.id;
      setTimeout(() => setBtnDisabled(false), 300)
      addControl.push(objs);
     setAddControl([...addControl])
     setTimeout(() => setAddCodeModal(false), 200)
     
    }
    setHandleModalKeyInput(false)
    setHandleModalValueInput(false)
  }

  const handleCancel = () => {
    setStausModal(false);
    setSelection([]);
  };

  function fieldDisableMode() {
    return (mode == "view" || mode == "disabled") ? true : false;
  }
  const antIcon = () => {
    (
      <LoadingOutlined
        style={{ fontSize: 18, color: "#fff", marginRight: "16px" }}
        spin
      />
    );
  }
  return (
    <>
      {error !== undefined && error !== null && <Alert type="error" className="mb-16" showIcon message={error} />}
      {loading ? <Loader /> : <Form
        form={form}
        onFinish={saveControlCode}
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="controlCategoryCode"
              label="Category"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Is required",
                },
                {
                  whitespace: true,
                  message: 'Is required'
                },
                {
                  validator: validateContentRule
                }
              ]}             
            >
             
              <Input
                placeholder="Enter Category"
                className="cust-input"
                maxLength={20}
                disabled={fieldDisableMode()} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
            <Form.Item
              name="description"
              label="Description"
              className="input-label"           
            >
              <Input
                placeholder="Enter Description"
                multiple
                className="cust-input"
                maxLength={100}
                disabled={fieldDisableMode()} />
            </Form.Item>
          </Col>
        </Row>
        {mode != "view" && <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className="mb-16"
        >
          <div></div>
          <ul className="admin-actions mb-0">
            <li>
              <Tooltip placement="top" title="Add">
                <Link className="icon md add mr-0" onClick={addCodeUpdate}></Link>
              </Tooltip>
            </li>
            <li>
              <Tooltip placement="top" title="Edit">
                <Link className="icon md edit mr-0" onClick={editCodeUpdate}></Link>
              </Tooltip>
            </li>
            <li>
              <Tooltip placement="top" title="Enable/Disable">
                <Link className="icon md status mr-0" onClick={statusUpdate}></Link>
              </Tooltip>
            </li>
          </ul>
        </div>}

        
        {warningMsg !== undefined && warningMsg !== null && warningMsg !== "" &&
          <div style={{ width: '100%' }}><Alert type="warning" className=" w-100 mb-16" showIcon description={warningMsg} /></div>}

        {!loading && addControl.length !== 0 && <div className="checkbox-active responsive_table">
          <Table key="table" columns={columns} dataSource={editControlDetails === true && editedControl || [...addControl]} bordered pagination={false} rowKey="index"
            components={{
              body: {
                wrapper: (prop) => DraggableContainer(prop),
                row: (propsrow) => DraggableBodyRow(propsrow),
              },
            }} />
        </div>}
        <Row gutter={24}>
					{controlObj?.userCreated && (
						<Col xs={24} sm={24} md={12} lg={12} xxl={12}>
							<div className="record-history d-flex align-center ml-0">
							<div className="record-history-text">
								{controlObj?.userCreated} created this on {"  "}
								<Moment format={config?.dateFormates?.dateTimeFormate}>
									{controlObj?.createdDate
										&& apiCalls.convertUTCToLocalTime(
                      controlObj?.createdDate
										  )
										|| controlObj?.createdDate}
								</Moment></div>
							</div>
						</Col>
					)}

					{controlObj?.modifiedBy && (
						<Col xs={24} sm={24} md={12} lg={12} xxl={12}>
							<div className="record-history d-flex align-center ml-0">
								<div className="record-history-text">
									{controlObj?.modifiedBy} modified this on {" "}
									<Moment format={config?.dateFormates?.dateTimeFormate}>
										{controlObj?.modifiedDate
											&& apiCalls.convertUTCToLocalTime(
                        controlObj?.modifiedDate
											  )
											|| controlObj?.modifiedDate}
									</Moment>
								</div>
							</div>
						</Col>
					)}
				</Row>
        <div className="text-right mt-24">
          {mode != "view" &&
            <Button
              loading={btnDis}
              type="primary"
              className="primary-btn"
              htmlType="submit">
              {loading && <Spin indicator={antIcon} />}
              Save
            </Button>}

          <Button type="primary" className="primary-btn cancel-btn" style={{ margin: '0 8px' }}
            onClick={backToMastergrid}>Cancel</Button>
        </div>
      </Form>}
      <Modal
        title={controlCode.status === 'Active' ? 'Confirm Deactivate?' : 'Confirm Activate?'}
        visible={statusModal}
        closeIcon={<Tooltip title="Close"><span className="icon md x c-pointer" onClick={handleCancel} /></Tooltip>}
        footer={<>
          <Button
            type="primary"
            className="primary-btn cancel-btn"
            onClick={handleCancel}
          >
            No
          </Button>
          <Button type="primary" className="primary-btn" onClick={disableRecord} loading={btnDisabled}>
            Yes
          </Button>
        </>}
      >
        <p className="fs-16 mb-0">Do you really want to {controlCode.status === 'Active' ? 'deactivate?' : 'activate?'}</p>
      </Modal>

      <Modal
        title={controlObj.controlCategoryCode }
        visible={addCodeModal}
        destroyOnClose={true}
        closeIcon={<Tooltip title="Close"><span className="icon md x c-pointer" onClick={handleAddCodeModalCancel} /></Tooltip>}
        footer={null}
        className="cust-doc"
      >
        <Form form={formCodeName}
        name="advanced_search"
        className="ant-advanced-search-form "
        onFinish={saveAddCodeNameRecord}
        autoComplete="off"
        >
           <Row gutter={24} className="control-code-mdl-pop">
              <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                <Form.Item
                  name="codeKey"
                  label="Code Key"
                  className="input-label"
                  onChange={(e) => handleAddCodeKeyChange(e)}
                
                  rules={[
                    {
                      required: true,
                      message: "Is required",
                    },
                    {
                      whitespace: true,
                      message: 'Is required'
                    },
                    {
                      validator: validateContentRule
                    }
                  ]}
                >
                  <Input
                    placeholder="Code Key"
                    className="cust-input"
                    maxLength={20} />
                </Form.Item>
              </Col>
            </Row>
             <div className="text-right mt-24">

            <Button
              type="primary"
              className="primary-btn cancel-btn"
              style={{ margin: '0 8px' }}
              onClick={handleAddCodeModalCancel}
            >
              Cancel
            </Button>
            <Button type="primary" className="primary-btn" htmlType="submit"
              loading={btnDisabled}
            >
              Save</Button>
          </div>
        </Form>
       
      </Modal>
    </>
  );
}

const connectDispatchToProps = dispath => {
  return {
    dispath,
    setAction: (val) => {
      dispath(setCurrentAction(val))
    },
  }
}
const connectStateToProps = ({ oidc, userConfig }) => {
  return { oidc, userConfig: userConfig.userProfileInfo, trackAuditLogData: userConfig.trackAuditLogData }
}
export default connect(connectStateToProps, connectDispatchToProps)(ControCodesDetails);