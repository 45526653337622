import {bankApi } from "../../api/index";
import { ApiControllers } from "../../api/config";
const getPyrrosDeposit = (CustomerId) => {
     return bankApi.get(
     ApiControllers.bank + `GetPyrrosDepositDetails/${CustomerId}`
 );
};
const getOpenPayd = (CustomerId) => {
    return bankApi.get(
    ApiControllers.bank + `GetOpenpaydDepositDetails/${CustomerId}`
);
};
const getEvolveDeposit = (CustomerId) => {
    return bankApi.get(
    ApiControllers.bank + `GetEvolveDepositDetails/${CustomerId}`
);
};
const saveBankDeposit=(obj)=>{
	return bankApi.put(ApiControllers.bank + `PyrrosDepositStatusChange`,obj);
}
const saveEvolveBankDeposit=(obj)=>{
	return bankApi.put(ApiControllers.bank + `EvolveDepositStatusChange`,obj);
}
export {
    getPyrrosDeposit,getEvolveDeposit,saveBankDeposit,saveEvolveBankDeposit,getOpenPayd
};

