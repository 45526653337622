

import React, { Component } from 'react';
import { Row, Col, Typography, Alert} from 'antd';
import Loader from '../loader.component';
import { handleWithdrawCrypto, handleWithdrawFait } from '../../reducers/withdrawReducer';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import { getWithdrawFait,getWithdraw } from './api'
import { setCurrentAction } from '../../reducers/actionsReducer';
import apiCalls from '../../api/apiCalls';
import { publishShowActions } from '../grid.component/subscribir';
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import Info from "../info/Info";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { bytesToSize } from "../../utils/service";
import { updateCurrentScreen } from "../../reducers/permissionsReducer";
import DocumentPreview from '../shared/documentPreview';
import DocumentsUploade from '../../utils/documents';
import { PlusCircleOutlined } from '@ant-design/icons';
import Notes from '../../utils/notes';
import { getFileURL } from '../customers.component/api';
import config from "../../config";


const { Title ,Text} = Typography;
const EllipsisMiddle = ({ suffixCount, children }) => {
    const start = children?.slice(0, children.length - suffixCount).trim();
    const suffix = children?.slice(-suffixCount).trim();
    return (
        <Text
            className="mb-0 fs-14 docnames c-pointer d-block"
            style={{ maxWidth: "100% !important" }}
            ellipsis={{ suffix }}>
            {start}
        </Text>
    );
};
class WithdrawCryptoSummary extends Component {
    constructor (props) {
        super(props);
        this.state = {
            tabType: this.props.match.params.type,
            fiatObj: {},
            loading: false,
            error: null,
            previewPath: null,
            docId:null,
            previewModal: false,
            files: [],
            isModalVissible:false,
            docReplyObjs:[],
            docViewObj:[],
            docpreviewdata:false,
            withdrawCrypto:[]
        }
    }
    componentDidMount() {
        publishShowActions(false);
        this.props.setAction(null)
        this.setState({ tabType: this.props.match.params.type })
        if (this.state.tabType === 'fiat') {
            this.setState({ ...this.state, loading: true })
            this.loadData();
        }
        else {
           this.getCryptoData()
            apiCalls.trackEvent({
                Type: "Admin",
                Action: "Withdraw Crypto details page view",
                Username: this.props.userProfileInfo?.userName,
                MemeberId: this.props.userProfileInfo?.id,
                Feature: "Withdraw",
                Remarks: "Withdraw Crypto details page view",
                Duration: 1,
                Url: window.location.href,
                FullFeatureName: "Withdraw"

            });
        }

    }
    loadData = async () => {
        this.setState({ ...this.state, loading: true, error: null })
        let response = await getWithdrawFait(this.props.match.params.id);
        if (response.ok) {
            this.setState({ fiatObj: response.data, docViewObj:response.data.adminDocuments, loading: false ,docReplyObjs:response.data.adminDocuments})
            let fileDetails = response.data.docRepositoriesv;
            if (fileDetails) {
                let filesArray = [];
                if (fileDetails.length !== 0) {
                    fileDetails.forEach((item) => {
                        let obj = {};
                        obj.id = item.id;
                        obj.name = item.documentName;
                        obj.size = item.remarks;
                        obj.response = [item.path];
                        filesArray.push(obj);
                        this.setState({ ...this.state, files: filesArray })
                    });
                }
            }
        } else {
            this.setState({ ...this.state, loading: false, error:apiCalls.isErrorDispaly(response) })
        }
        apiCalls.trackEvent({
            Type: "Admin",
            Action: "Withdraw Fiat details page view",
            Username: this.props.userProfileInfo?.userName,
            MemeberId: this.props.userProfileInfo?.id,
            Feature: "Withdraw",
            Remarks: "Withdraw Fiat details page view",
            Duration: 1,
            Url: window.location.href,
            FullFeatureName: "Withdraw"
        });

    }
    getCryptoData=async()=>{
    this.setState({...this.state,loading:true})
    let response = await getWithdraw(this.props.match.params.id);
    if(response.ok){
    this.setState({...this.state,docViewObj:response.data.adminDocuments,withdrawCrypto:response.data,loading:false})
    }else{       
     this.setState({ ...this.state, loading: false, error:apiCalls.isErrorDispaly(response) })
    }
    }
    redirectCustomerView = (items) => {
        const val = items.customerId;
        this.props.dispatch(
            setBreadcrumb({
                key: "/customers/details/" + val,
                val: items.customerName
            })
        );
        localStorage.setItem("MyURL","/customers/details/" + val)
        window.open("/customers/details/" + val,"_blank");      
    }
    docPreview = async (file) => {
        let res = await getFileURL(file.id);
        if (res.ok) {
            this.setState({ ...this.state, previewModal: true, docpreviewdata:true, previewPath: file,error:null})
        }else{
            this.setState({...this.state,error:apiCalls.isErrorDispaly(res)})
        }
    };
    filePreviewPath = () => {
        return this.state?.previewPath
    };
    handleClose = () => {
        this.setState({ ...this.state, previewModal: false })
    }
    redirectCaseView = (caseData) => {
        const val = caseData?.caseId;
        this.props.dispatch(updateCurrentScreen("cases"));
        this.props.history.push("/cases/1/" + val + "/view");
        this.props.dispatch(
            setBreadcrumb({ key: "/cases/1/" + val + "/view", val: caseData.caseNumber })
        );
    };

    docOpen=()=>{
        this.setState({...this.state,isModalVissible:true})
    }
    CloseModal=(popupdoc)=>{
        this.setState({...this.state,isModalVissible:false})
    }
    updateAttachemnts =(attachemnts) =>{
        this.setState({...this.state,docViewObj:attachemnts})
    }

    renderViewData = (titleData, value) => {
        return <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
        <span className="icon md user" />
        <div>
            <label className="kpi-label">{titleData}</label>
            <div className="kpi-val">{value || "-"}</div>
        </div>
    </Col>
     }

     renderCryptoViewData=(titleData, value)=>{
        return <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
        <span className="icon md user" />
        <div>
            <label className="kpi-label">{titleData}</label>
            <div className="kpi-val">{value || "-"}</div>
        </div>
    </Col>
     }
 

     renderCommissionViewData=(icon,commissionLable,commissionValue)=>(
        <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
        <span className={`icon md ${icon}`} />
        <div>
            <label className="kpi-label">{commissionLable}</label>
            <div className=" kpi-val">
                {commissionValue?
                    (<NumberFormat value={commissionValue} decimalSeparator="." displayType={'text'} thousandSeparator={true} />) : "0"}
            </div>
        </div>
    </Col>
     )

    renderCommissionCryptoView = (cryptoClassIcon,cryptoCommissionLable,CryptoCommissionValue) => (
        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
            <span className={`icon md ${cryptoClassIcon}`} />
            <div>
                <label className="kpi-label">{cryptoCommissionLable}</label>
                <div className=" kpi-val">
                    {CryptoCommissionValue ?
                        (<NumberFormat value={CryptoCommissionValue} decimalSeparator="." displayType={'text'} thousandSeparator={true} />) : "0"}
                </div>
            </div>
        </Col>
    )
    render() {
      
        const { fiatObj, loading, previewModal,withdrawCrypto } = this.state;

        return (
            <>
                {this?.state?.tabType === 'fiat' ?
                    <>
                        {loading && <Loader />} <Title className="page-title">Withdraw Fiat Detail View</Title>
                        {fiatObj && <>
                            {this.state.error !== undefined && this.state.error !== null && <Alert className="mb-16" type="error" showIcon description={this.state.error} />}
                            <Row gutter={10}>
                                <Col xl={24} xxl={24} className="bank-view">
                                    <Row className="kpi-List">
                                        <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                                            <span className="icon md date" />
                                            <div>
                                                <label className="kpi-label">Submission Date</label>
                                                <div className="kpi-val">
                                                    <Moment format={config?.dateFormates?.dateTimeFormate}>{fiatObj?.date && apiCalls.convertUTCToLocalTime(fiatObj?.date) || fiatObj?.date}</Moment>
                                                </div>
                                            </div>
                                        </Col>
                                        {this.renderViewData("Customer ID",fiatObj?.refrenceId)}

                                        <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                                            <span className="icon md user" />
                                            <div>
                                            <label className="kpi-label">{fiatObj?.isBusiness === true && <><b>Business Name</b>/<>Personal Name</> </> }
                                            {!fiatObj?.isBusiness &&
                                            <><>Business Name</>/<b>Personal Name</b></>}</label>
                                               
                                                <br />
                                                <span className="kpi-val c-pointer">
                                                    <a href="#/" onClick={() => this.redirectCustomerView(this.state.fiatObj)}>
                                                        {fiatObj?.customerName}
                                                    </a>
                                                </span>
                                            </div>
                                        </Col>
                                        {this.renderViewData("User Name",fiatObj?.userName)}
                                        {this.renderViewData("Email",fiatObj?.customerEmail)}
                                        {this.renderViewData("Wallet Code",fiatObj?.walletCode)}
                                        {this.renderViewData("Address Type",fiatObj?.addressType)}
                                        {this.renderViewData("Recipient Full Name",fiatObj?.recepientName)}
                                        {this.renderViewData("Sender's Bank Name",fiatObj?.externalAccountName)}
                                        {this.renderViewData("Bank Name",fiatObj?.bankName)}
                                        {this.renderViewData("Bank Account Number/IBAN",fiatObj?.accountNumber)}

                                        <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                                            <span className="icon md cash" />
                                            <div>
                                                <label className="kpi-label">Requested Amount</label>
                                                <div className=" kpi-val">
                                                    {fiatObj?.requestedAmount &&
                                                        (<NumberFormat value={fiatObj?.requestedAmount} decimalSeparator="." displayType={'text'} thousandSeparator={true} />)} {!fiatObj?.requestedAmount && ("-")}
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                                            <span className="icon md commission" />
                                            <div>
                                                <label className="kpi-label">Commission</label>
                                                <div className=" kpi-val">
                                                    {fiatObj?.comissionvalue}
                                                    {"  "}  { fiatObj?.cTypeFlat}
                                                        
                                                </div>
                                            </div>
                                        </Col>
                                        {this.renderCommissionViewData("commission","Commission in Base",fiatObj?.comissionvalueInBase)}
                                        {this.renderCommissionViewData("commission","Fees",fiatObj?.effectiveFee)}
                                        {this.renderCommissionViewData("commission","Tier Discount",fiatObj?.tierDiscount)}
                                        {this.renderCommissionViewData("commission","SuisseBase Credit Used",fiatObj?.sbCredit)}
                                        {this.renderCommissionViewData("commission","Sweep Source Commission",fiatObj?.sweepSourceComissionValue)}
                                        {this.renderCommissionViewData("commission","Sweep Source in Base",fiatObj?.sweepSourceValueInBase)}
                                        {this.renderCommissionViewData("commission","Sweep Destination Commission",fiatObj?.sweepDestinationcomissionValue)}
                                        {this.renderCommissionViewData("commission","Sweep Destination in Base",fiatObj?.sweepDestinationvalueInBase)}
                                        {this.renderCommissionViewData("commission","Provider Commission",fiatObj?.pcomissionvalue)}
                                        {this.renderCommissionViewData("commission","Provider Commission in Base",fiatObj?.pcomissionvalueInBase)}
                                        {this.renderCommissionViewData("cash","Total cost",fiatObj?.totalAmount)}
                                        {this.renderCommissionViewData("cash","Net Amount",fiatObj?.netAmount)}
                                        {this.renderCommissionViewData("commission","Profit",fiatObj?.profit)}
                                        {this.renderCommissionViewData("commission","Profit in Base",fiatObj?.profitInBase)}
                                        {this.renderViewData("State",fiatObj?.status)}                                
                                        {fiatObj?.status === "Approved" &&
                                        this.renderViewData("Approved By",fiatObj?.modifiedBy)
                                        }
                                        {fiatObj?.approvedDate && 
                                        <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                                            <span className="icon md date" />
                                            <div>
                                                <label className="kpi-label">Approval Date</label>
                                                <div className="kpi-val">

                                                    <Moment format={config?.dateFormates?.dateTimeFormate}>{fiatObj?.approvedDate && apiCalls.convertUTCToLocalTime(fiatObj?.approvedDate) || fiatObj?.approvedDate}</Moment>
                                                </div>
                                            </div>
                                        </Col>}
                                        <Col xs={24} sm={24} md={12} lg={!fiatObj.customerRemarks && 6 || 12} xxl={!fiatObj.customerRemarks && 6 || 12}>
                                            <span className="icon md file" />
                                            <div>
                                                <label className="kpi-label">Reason For Transfer</label>
                                                <div className="kpi-val" rows={2}
                                                    maxLength={1000}>
                                                    {((fiatObj?.customerRemarks === " ") && ("-")) || ((fiatObj?.customerRemarks === null) && ("-")) || ((fiatObj?.customerRemarks) || ("-"))}

                                                </div>
                                            </div>
                                        </Col>                                       
                                        <Col xs={24} sm={24} md={12} lg={!fiatObj.withdrawReference && 6 || 12} xxl={!fiatObj.withdrawReference && 6 || 12}>
                                            <span className="icon md file" />
                                            <div>
                                                <label className="kpi-label">Reference</label>
                                                <div className="kpi-val" rows={2}
                                                    maxLength={1000}>
                                                    {((fiatObj?.withdrawReference === " ") && ("-")) || ((fiatObj?.withdrawReference === null) && ("-")) || ((fiatObj?.withdrawReference) || ("-"))}

                                                </div>
                                            </div>
                                        </Col>                                        
                                        {fiatObj?.status === "Cancelled" && 
                                         this.renderViewData("Cancelled By",fiatObj?.modifiedBy)
                                        }
                                        {fiatObj?.status === "Cancelled" && <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                                            <span className="icon md status" />
                                            <div>
                                                <label className="kpi-label">Cancelled Date</label>
                                                <div className="kpi-val">

                                                    <Moment format={config?.dateFormates?.dateTimeFormate} >{fiatObj?.modifiedDate && apiCalls.convertUTCToLocalTime(fiatObj?.modifiedDate) || fiatObj?.modifiedDate}</Moment>
                                                </div>
                                            </div>
                                        </Col>}
                                        {fiatObj?.status === "Rejected" && (<Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                                            <span className="icon md file" />
                                            <div>
                                                <label className="kpi-label">Reason For Rejection</label>
                                                <div className="kpi-val" rows={2}
                                                    maxLength={1000}>
                                                        {fiatObj?.rejectReason || "-"}

                                                </div>
                                            </div>
                                        </Col>)}
                                        <Col xs={24} sm={24} md={12} lg={!fiatObj.customerRemarks && 6 || 12} xxl={!fiatObj.customerRemarks && 6 || 12}>
                                            <span className="icon md file" />
                                            <div>
                                                <label className="kpi-label">Remarks</label>
                                                <div className="kpi-val" rows={2}
                                                    maxLength={1000}>
                                                    {((fiatObj?.statusRemarks === " ") && ("-")) || ((fiatObj?.statusRemarks === null) && ("-")) || ((fiatObj?.statusRemarks) || ("-"))}

                                                </div>
                                            </div>
                                        </Col> 
                                        {fiatObj?.caseIds !== null &&  <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={6}>
                                        <span className='icon md case' />
                                                <div>
                                            <label className="kpi-label d-block">Case Number</label>
                                            <div
                                                className="fw-600 fs-14 ">
                                                {fiatObj?.caseIds?.map(item => <a className='c-pointer' href="#/" onClick={() => this.redirectCaseView(item)}>{item.caseNumber}<br /></a>)}

                                            </div>
                                            </div>
                                        </Col>}

                                    </Row>
                                    <Row>
                                        {fiatObj?.docRepositories?.map((file) => (
                                            <Col xs={24} sm={24} md={12} lg={8} xxl={8} className="">
                                                <div
                                                    className="docfile mr-8 mt-16 d-flex align-center"
                                                    key={file.id}>
                                                    <span
                                                        className={`icon xl ${(file.fileName?.slice(-3) === "zip" && "file") ||
                                                            (file.fileName?.slice(-3) !== "zip" && "") ||
                                                            ((file.fileName?.slice(-3) === "pdf" ||
                                                                file.fileName?.slice(-3) === "PDF") &&
                                                                "file") ||
                                                            (file.fileName?.slice(-3) !== "pdf" &&
                                                                file.fileName?.slice(-3) !== "PDF" &&
                                                                "image")
                                                            } mr-16`}
                                                    />
                                                    <div
                                                        className="docdetails c-pointer"
                                                        onClick={() => this.docPreview(file)}>
                                                        {file.fileName !== null ? (
                                                            <EllipsisMiddle suffixCount={6}>
                                                                {file.fileName}
                                                            </EllipsisMiddle>
                                                        ) : (
                                                            <EllipsisMiddle suffixCount={6}>Name</EllipsisMiddle>
                                                        )}
                                                        <span className="fs-12 text-secondary">
                                                            {file.fileSize ? bytesToSize(file.fileSize) : ""}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>

                                </Col>
                            </Row>
                           <p className='mt-16 mb-0'>
                         <b>Admin Upload</b> <PlusCircleOutlined onClick={()=>this.docOpen() }/></p>
                           <Row gutter={24} className="mb-24 pb-24 border-bottom">               
                                <>     
                                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>         
                                {<DocumentsUploade
                                    pop_up_cancel={()=>this.CloseModal()}
                                    docID={fiatObj?.id}
                                    previewModal={this.state.isModalVissible}
                                    attachmentUpdate={(attachemnts) =>
                                    this.updateAttachemnts(attachemnts)
                                    }
                                    docViewObj={this.state.docViewObj}
                                    screenTitle={"Send Fiat"}
                                    ModifiedBy={this.props.userConfig.userName}
                                    />}
                                    </Col>
                                </>
                             </Row>
                             <Notes  screenId={this.props.match.params.id} screenName={"SendFiat"} createdNote={fiatObj.notes}/>
                          
                        <Info Id={fiatObj.id} Status={fiatObj?.status} Createdby={fiatObj?.userName} CreatedDate={fiatObj?.createdDate} ModifiedDate={fiatObj?.modifiedDate} Modifiedby={fiatObj?.modifiedBy} isShowInfo={false} screenName={"Send"} />
                        </>}

                        {this.filePreviewModal}
                    </> :
                    <> {loading && <Loader />}
                        <Title className="page-title">Withdraw Crypto Detail View</Title>
                        {withdrawCrypto && <>
                            {this.state.error !== undefined && this.state.error !== null && <Alert className="mb-16" type="error" showIcon description={this.state.error} />}
                            <Row gutter={8}>
                                <Col xl={24} xxl={24} className="bank-view">
                                    <Row className="kpi-List">
                                        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                            <span className="icon md date" />
                                            <div>
                                                <label className="kpi-label">Submission Date</label>
                                                <div className="kpi-val">
                                                    <Moment format={config?.dateFormates?.dateTimeFormate} >{withdrawCrypto?.createdDate && apiCalls.convertUTCToLocalTime(withdrawCrypto?.createdDate) || withdrawCrypto?.createdDate}</Moment>
                                                </div>
                                            </div>
                                        </Col>
                                        
                                        {this.renderCryptoViewData("Customer ID",withdrawCrypto?.refrenceId)}

                                        
                                        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                            <span className="icon md user" />
                                            <div>
                                                <label className="kpi-label">{withdrawCrypto?.isBusiness === true && <><b>Business Name</b>/<>Personal Name</> </> || <><>Business Name</>/<b>Personal Name</b></>}</label>
                                                <br />
                                                <span className="kpi-val c-pointer">
                                                    <a href="#/" onClick={() => this.redirectCustomerView(withdrawCrypto)}>
                                                        {withdrawCrypto?.customerName || "-"}
                                                    </a>
                                                </span>
                                            </div>
                                        </Col>

                                        {this.renderCryptoViewData("User Name",withdrawCrypto?.userName)}
                                        {this.renderCryptoViewData("Email",withdrawCrypto?.customerEmail)}

                                       
                                        
                                        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                            <span className="icon md wallet" />

                                            <div>
                                                <label className="kpi-label">Coin</label>
                                                <div className="kpi-val">{`${withdrawCrypto?.walletName}(${withdrawCrypto?.walletCode})` || "-"}</div>
                                            </div>
                                        </Col>
                                        {this.renderCryptoViewData("Network",withdrawCrypto?.network)}

                                       
                                        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                            <span className="icon md wallet" />
                                            <div>
                                                <label className="kpi-label">Wallet Address</label>
                                                <div className="kpi-val">
                                                    <CopyToClipboard>
                                                        <Text
                                                            copyable
                                                            className="fs-22 text-black-30 text-center custom-display"
                                                            style={{ width: "200px" }}
                                                        >
                                                            {withdrawCrypto?.walletAddress || "-"}
                                                        </Text>

                                                    </CopyToClipboard></div>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                            <span className="icon md routing" />
                                            <div>
                                                <label className="kpi-label">Hash</label>
                                                <div className="kpi-val">
                                                    {(withdrawCrypto?.hash && <a onClick={() => window.open(`${withdrawCrypto?.explorer}${withdrawCrypto?.hash}`, '_blank')}>{withdrawCrypto?.hash}</a>) || "-"}
                                                </div>
                                            </div>
                                        </Col>
                                        {this.renderCommissionCryptoView("cash","Requested Crypto",withdrawCrypto?.receivedAmmount)}
                                        {this.renderCommissionCryptoView("commission","Commission",withdrawCrypto?.comissionvalue)}
                                        {this.renderCommissionCryptoView("commission","Commission in Base",withdrawCrypto?.comissionvalueInBase)}
                                        {this.renderCommissionCryptoView("commission","Fees",withdrawCrypto?.effectiveFee)}
                                        {this.renderCommissionCryptoView("commission","Tier Discount",withdrawCrypto?.tierDiscount)}
                                        {this.renderCommissionCryptoView("commission","Provider Commission",withdrawCrypto?.pcomissionvalue)}
                                        {this.renderCommissionCryptoView("commission","Provider Commission in Base",withdrawCrypto?.pcomissionvalueInBase)}
                                        {this.renderCommissionCryptoView("commission","Sweep Source Commission",withdrawCrypto?.sweepSourceComissionValue)}
                                        {this.renderCommissionCryptoView("commission","Sweep Source in Base",withdrawCrypto?.sweepSourceValueInBase)}
                                        {this.renderCommissionCryptoView("commission","Sweep Destination Commission",withdrawCrypto?.sweepDestinationcomissionValue)}
                                        {this.renderCommissionCryptoView("commission","Sweep Destination in Base",withdrawCrypto?.sweepDestinationvalueInBase)}
                                        {this.renderCommissionCryptoView("cash","Net Amount",withdrawCrypto?.receivedAmmount)}
                                        {this.renderCommissionCryptoView("cash","Profit",withdrawCrypto?.netAmount)}
                                        {this.renderCommissionCryptoView("cash","Profit in Base",withdrawCrypto?.profitInBase)}
                                        {this.renderCommissionCryptoView("cash","Profit in Base",withdrawCrypto?.profitInBase)}
                                        {this.renderCryptoViewData("State",withdrawCrypto?.status)}
                                        {(withdrawCrypto?.status === "Approved") && 
                                        this.renderCryptoViewData("Approved By",withdrawCrypto?.modifiedBy)
                                        }
                                        {withdrawCrypto?.approvedDate && 
                                        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                            <span className="icon md date" />
                                            <div>
                                                <label className="kpi-label">Approval Date</label>
                                                <div className="kpi-val">
                                                    <Moment format={config?.dateFormates?.dateTimeFormate} >{withdrawCrypto?.approvedDate && apiCalls.convertUTCToLocalTime(withdrawCrypto?.approvedDate) || "-"}</Moment>
                                                </div>
                                            </div>
                                        </Col>
                                        }

                                        {withdrawCrypto?.status === "Cancelled" && 
                                        this.renderCryptoViewData("Cancelled By",withdrawCrypto?.modifiedBy)
                                        }

                                        {withdrawCrypto?.status === "Cancelled" && <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                            <span className="icon md status" />
                                            <div>
                                                <label className="kpi-label">Cancelled Date</label>
                                                <div className="kpi-val">
                                                    <Moment format={config?.dateFormates?.dateTimeFormate} >{withdrawCrypto?.modifiedDate && apiCalls.convertUTCToLocalTime(withdrawCrypto?.modifiedDate) || withdrawCrypto?.modifiedDate}</Moment>
                                                </div>
                                            </div>
                                        </Col>}
                                        {withdrawCrypto?.status === "Rejected" && 
                                          this.renderCryptoViewData("Reason For Rejection",withdrawCrypto?.rejectReason)
                                       }
                                       
                                        <Col xs={24} sm={24} md={12} lg={!withdrawCrypto.customerRemarks && 8 || 12} xxl={!withdrawCrypto.customerRemarks && 8 || 12}>
                                            <span className="icon md file" />
                                            <div>
                                                <label className="kpi-label">Remarks</label>
                                                <div className="kpi-val">
                                                    {((withdrawCrypto?.statusRemarks === " ") && ("-") )|| ((withdrawCrypto?.statusRemarks === null) && ("-")) || ((withdrawCrypto?.statusRemarks) || ("-"))}
                                                </div>
                                            </div>
                                        </Col>
                                        {withdrawCrypto?.caseIds !== null && ( <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={6}>
                                            <span className='icon md case' />
                                            <div>
                                            <label className="kpi-label d-block">Case Number</label>
                                            <div
                                                className="fw-600 fs-14">
                                                {withdrawCrypto?.caseIds?.map(item => <a className='c-pointer' href="#/" onClick={() => this.redirectCaseView(item)}>{item.caseNumber}<br /></a>)}

                                            </div>
                                            </div>
                                        </Col> )}
                                    </Row>
                                </Col>
                            </Row>
                            <p className='mt-16'>
                         <b> Admin Upload </b><PlusCircleOutlined onClick={()=>this.docOpen() }/></p>
                           <Row gutter={24} className="mb-24 pb-24 border-bottom">               
                                <>     
                                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>         
                                {<DocumentsUploade
                                    pop_up_cancel={()=>this.CloseModal()}
                                    docID={withdrawCrypto?.id}
                                    previewModal={this.state.isModalVissible}
                                    attachmentUpdate={(attachemnts) =>
                                    this.updateAttachemnts(attachemnts)
                                    }
                                    docViewObj={this.state.docViewObj}
                                    screenTitle={"Send Crypto"}
                                    ModifiedBy={this.props.userConfig.userName}
                                    />}
                                    </Col>
                                </>
                             </Row>
                           <Notes  screenId={this.props.match.params.id} screenName={"SendCrypto"} createdNote={withdrawCrypto?.notes}/>
                            <Info Id={withdrawCrypto.id} Status={withdrawCrypto.status} Createdby={withdrawCrypto?.userName} CreatedDate={withdrawCrypto.createdDate} ModifiedDate={withdrawCrypto?.modifiedDate} Modifiedby={withdrawCrypto?.modifiedBy} isShowInfo={false} screenName={"Send"} />
                        </>}

                    </>}

                {previewModal &&
                    <DocumentPreview
                    previewModal={this.state?.previewModal}
					handleCancle={this.handleClose}
					upLoadResponse={ this.state?.previewPath ? this.filePreviewPath() : null}
                    />
                }
            </>
        );
    }
}
const connectStateToProps = ({ withdrawStore, userConfig }) => {
    return { withdrawStore, userConfig: userConfig.userProfileInfo }
}
const connectDispatchToProps = dispatch => {
    return {
        fetchWithdrawCryptoData: (crypto_id) => {
            dispatch(handleWithdrawCrypto(crypto_id))
        },
        fetchWithdrawFaitData: (fiat_id) => {
            dispatch(handleWithdrawFait(fiat_id))
        },
        setAction: (val) => {
            dispatch(setCurrentAction(val))
        },
        dispatch
    }
}
export default connect(connectStateToProps, connectDispatchToProps)(WithdrawCryptoSummary);

