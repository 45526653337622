import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Button, Select, Typography, Spin, Alert } from "antd";
import Loader from "../components/loader.component";
import { setCurrentAction } from "../reducers/actionsReducer";
import { validateContentRule } from "./custom.validator";
import { connect } from "react-redux";
import NumberFormat from 'react-number-format';
import { success } from "./messages";
import { getCaseLu } from "../components/cases.component/api";
import { getCasePopObj, saveCasePopObj,caseTemplateLu } from "./caseApi";
import { publishShowActions } from "../components/grid.component/subscribir";
import { LoadingOutlined } from "@ant-design/icons";
import { getCaseObject } from "../utils/service"
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;

const CreateCase = ({ ScreenName, ScreenId, closeModal, userConfig, FromScreen, trackAuditLogData }) => {
  const [form] = Form.useForm();
  const [commonModel, setCommonModel] = useState({});
  const [assignedTo, setAssignedTo] = useState([]);
  const [caseObject, setCaseObject] = useState({});
  const [docDetails, setDocDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assignedToId, setAssignedToId] = useState({})
  const [isCheck, setIsCheck] = useState(false);
  const [isChecked,setIsChecked]=useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [caseCryptoDoc,setCaseCryptoDco]=useState([]);
  const [caseTemplateLuObj,setCaseTemplate]=useState([]);
  useEffect(() => {
    publishShowActions(false);
    caseObj();
    caseTemplate();
    fetchAssignedToLu(null)
    if (ScreenId === "00000000-0000-0000-0000-000000000000") {
      caseObj();
    }
  }, []);//eslint-disable-line react-hooks/exhaustive-deps

  const caseObj = async () => {
    window.scrollTo(0,0)
    setLoading(true);
    if (ScreenId) {
      setLoading(true)
      let response = await getCasePopObj(FromScreen, ScreenId, ScreenName);
      if (response.ok) {
        let obj = response.data;
        setCaseObject(response.data);
        setDocDetails(response.data.caseDetails);
        setCaseCryptoDco(response.data.caseDetails)
        setCommonModel(response.data.commonModel);
        obj.assignedTo = response.data.assignedTo.toString()
        form.setFieldsValue(obj);
        window.scrollTo(0,0)
      }
      setLoading(false)
      window.scrollTo(0,0)
    }
  };
  const fetchAssignedToLu = async (name) => {
    let response = await getCaseLu(name ? name : null);
    if (response.ok) {
      setAssignedTo(response.data);
    }
  };
  const handleSearch = (e, type) => {
    let sval = e.target.value;
    if (type === "AssignedTo") {
      fetchAssignedToLu(sval);
    }
  };
  const caseTemplate=async()=>{
    let res= await caseTemplateLu();
    if(res.ok)
    {
      setCaseTemplate(res.data);
    }
  }
  const handleTemplateChange=(val)=>{
    form.setFieldsValue({remarks: val })
  }
  const handleAssignChange = (val, type) => {
    if (type === "AssignedTo") {
      setAssignedToId(val)
    }
  };
  const handleInputChange = (e, d) => {
    var docData = Object.assign([], docDetails);
    for (var i in docDetails) {
      if (docDetails[i].id === d.id) {
        docData[i].isChecked = docData[i].isChecked ? false : true;
      }
    }
    setDocDetails(docData);
  };
  const handleCryptoInputChanged = (e, d) => {
    let docCryptoData = Object.assign([], caseCryptoDoc); 
    for (var i in caseCryptoDoc) {
      if (caseCryptoDoc[i].id === d.id) {
        docCryptoData[i].isChecked = docCryptoData[i].isChecked ? false : true;
      }
    }
    setCaseCryptoDco(docCryptoData);
  };
  const handleChange = (e) => {
    if (e.target.checked === false) {
      let val = []
      for (let i of docDetails) {
        i.isChecked = false
        val.push(i)
      }
      setDocDetails(val)
      setIsCheck(false)
    } else {
      setIsChecked(false);
      setIsCheck(e.target.checked);
      form.setFieldsValue({ customerCaseTitle: null, remarks: null })
    }
  };

  const useDivRef = React.useRef(null);

  const saveCaseInfo = async (values) => {
    setBtnDisabled(true);
    setLoading(false);
    let obj = getCaseObject(values, caseObject, assignedToId, commonModel, userConfig, isCheck,isChecked, 
      caseCryptoDoc ? caseCryptoDoc : docDetails 
      );
    obj.info = JSON.stringify(trackAuditLogData);

    let response = await saveCasePopObj(obj, FromScreen)
    if (response.ok) {
      setBtnDisabled(false);
      success("Case details saved successfully")
      setTimeout(() => { setLoading(false) }, 1000)
      closeModal();
      form.resetFields();
      setLoading(false);
    } else {
      setErrorMessage(response.data?.title || "Something went wrong please try again!")
      useDivRef.current.scrollIntoView();
      setBtnDisabled(false);
      setLoading(false)
    }

  }
  const antIcon = () => {
    (
      <LoadingOutlined
        style={{ fontSize: 18, color: "#fff", marginRight: "16px" }}
        spin
      />
    );
  }
  return (
    <> {loading && <Loader />}
      <div ref={useDivRef}></div>
      <Form
        advanced_search="true"
        initialValues={caseObject}
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={saveCaseInfo}
        autoComplete="off"
      >
        {errorMessage !== undefined && errorMessage !== null && errorMessage !== "" && (
        <div style={{ width: '100%' }}>
          <Alert
            className="w-100 mb-16"
            type="warning"
            description={errorMessage}
            showIcon
          />
        </div>
      )}
        <>
          <Row gutter={24} className="mb-8">
            <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
              <Form.Item
                name="caseNumber"
                label="Case Number"
                className="input-label"
              >
                <Input
                  placeholder="Case Number"
                  className="cust-input"
                  disabled={true}
                  maxLength={50}

                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={16} xxl={18}>
              <Form.Item
                name="caseTitle"
                label="Case Title"
                className="input-label"
                rules={[
                  {
                    required: true,
                    message: "Is required"
                  },
                  {
                    whitespace: true,
                    message: "Is required"
                  },
                  {
                    validator: validateContentRule
                  }
                ]}
              >
                <Input
                  placeholder="Case Title"
                  className="cust-input"
                  maxLength={50}

                />
              </Form.Item>
            </Col>
          </Row>
          <div className='case-ribbon d-flex'>

            <Row gutter={[16, 16]}>
              {
                Object.entries(commonModel).map(([key, value], idx) =>{
                  const isDescription = key === 'description';
                  const columnSizes = isDescription ? { xs: 24, md: 24, lg: 24, xl: 24, xxl: 24 } : { xs: 22, md: 12, lg: 12, xl: 8, xxl: 8 };
                  <Col key={idx} {...columnSizes}>
                  <div className="ribbon-item">
                    <span className={`icon md ${key === null && "description" || ((key === "Currency" && value === "EUR") && "EUR" || key =="Whitelisting Status" && "routing" || key )}`} />
                    <div className='ml-16' style={{ flex: 1 }}>
                      <Text className='fw-300 text-white-50 fs-12 '>{key}</Text>
                      <div className='fw-600 text-white-30 fs-16 l-height-normal' style={{ wordBreak: "break-all" }}>
                        {(value === null || value === " "||value === "") && '-' || (isNaN(value) || (key === 'Transaction Id' || key === 'Bank Account number/IBAN'||key==="Wallet Address" || key === "Bank Account Number/IBAN"
                          || key === 'Bank Name') && value || <NumberFormat value={value} decimalSeparator="." displayType={'text'} thousandSeparator={true} />)}
                      </div>
                    </div>
                  </div>
                </Col>
                }
                 )}

            </Row>

          </div>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={18} xxl={18}>
              <Form.Item
                name="assignedToIds"
                label="Assigned To"
                className="input-label create-case-error"
                rules={[
                  {
                    required: true,
                    message: "Is required"
                  }
                ]}
              >
                <Select
                  showSearch
                  mode="multiple"
                  className="cust-input multi-select createcase-input"
                  placeholder="Select Assigned To"
                  optionFilterProp="children"
                  onKeyUp={(e) => handleSearch(e, "AssignedTo")}
                  onChange={(e) => handleAssignChange(e, "AssignedTo")}
                >
                  {assignedTo?.map((assign, idx) => (
                    <Option key={idx} value={assign.id}>
                      {assign.assignedTo}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
              <Form.Item
                name="caseSubState"
                label="Case Sub-State"
                className="input-label"
              >
                <Select className="cust-input"
                  placeholder="Select Sub-State"
                  disabled={true}
                  defaultValue={"Assigned"}
                >
                </Select>
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
          <div className="d-flex align-center my-16">
            <label className="text-center custom-checkbox">
              <input
                name="check"
                
                type="radio"
                className="grid_check_box c-pointer"
                onClick={handleChange}
                checked={isCheck}
              />
              <span></span>
            </label>

            <span className="ml-8">Request for documents from customer</span>
          </div>
          </Col>
          </Row>
          {(isCheck === true || isChecked === true) && (
            <div>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={16} xxl={18}>
                  <Form.Item
                    name="customerCaseTitle"
                    label="Customer Case Title"
                    className="input-label mb-0"
                    rules={(isCheck === true || isChecked === true) &&
                      [
                        {
                          required: true,
                          message: "Is required"
                        },
                        {
                          whitespace: true,
                          message: "Is required",
                        },
                        {
                          validator: validateContentRule
                        }
                      ]}
                  >
                    <Input
                      placeholder="Customer Case Title"
                      className="cust-input"
                      maxLength={50}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                  <Form.Item
                    name="useTemplate"
                    className="input-label mb-0"
                    label="Use Templates"
                  >
                    <Select
                      className="cust-input"
                      placeholder="Use Templates"
                      onSelect={(e) => handleTemplateChange(e)}
                    >
                       {caseTemplateLuObj.map((item) => (
                        <Option key={item.id} value={item.remarks}>
                          {item.templateName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={24} xxl={24}>
                  <Form.Item
                    name="remarks"
                    label="Customer Remarks"
                    className="input-label"
                    rules={[
                      {
                        whitespace: true,
                        message: "Is required"
                      },
                      {
                        validator: validateContentRule
                      }
                    ]}
                  >
                    <TextArea
                      placeholder="Customer Remarks"
                      showCount={500}
                      maxLength={500}
                      rows={4}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {isChecked === true &&<> <Title level={5} className="fs-16 fw-600 text-white-30 mb-16">
                 Crypto{" "}
              </Title>
              <Row gutter={24}>
                {caseCryptoDoc?.map((docItem, idx) => (
                  <Col xs={24} sm={24} md={12} lg={8} xxl={8} key={idx}>
                    <div className="d-flex align-center mb-8">
                      <label className="text-center custom-checkbox">
                        <input
                          name="checks"
                          type="checkbox"
                         checked={docItem.isChecked}  key={idx}
                         onChange={(e) => handleCryptoInputChanged(e, docItem)}
                          className="grid_check_box"
                        />
                        <span></span>
                      </label>
                      <span className="ml-8">{docItem.name}</span>
                    </div>
                  </Col>
                 ))} 
              </Row></>}
             {!isChecked &&<> <Title level={5} className="fs-16 fw-600 text-white-30 mb-16">
                Documents{" "}
                <Text className="fs-12 fw-100">
                  (when you select documents customer will be notified to submit
                  these documents)
                </Text>
              </Title>
              <Row gutter={24}>
                {docDetails?.map((docItem, idx) => (
                  <Col xs={24} sm={24} md={12} lg={8} xxl={8} key={idx}>
                    <div className="d-flex align-center mb-8">
                      <label className="text-center custom-checkbox">
                        <input
                          name="check"
                          type="checkbox"
                          checked={docItem.isChecked}
                          onChange={(e) => handleInputChange(e, docItem)}
                          className="grid_check_box"
                        />
                        <span></span>
                      </label>
                      <span className="ml-8">{docItem.documentName}</span>
                    </div>
                  </Col>
                ))}
              </Row></>}
            </div>)}
        </>
        <div className="text-right mt-24">
          <Button type="primary" loading={btnDisabled} className="primary-btn" htmlType="submit">
            {loading && <Spin indicator={antIcon} />}
            Save
          </Button>
          <Button
            type="primary"
            className="primary-btn cancel-btn"
            style={{ margin: "0 8px" }}
            onClick={closeModal}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
};

const connectStateToProps = ({ userConfig }) => {
  return { userConfig: userConfig.userProfileInfo, trackAuditLogData: userConfig.trackAuditLogData };
};
const connectDispatchToProps = (dispatch) => {
  return {
    setAction: (val) => {
      dispatch(setCurrentAction(val));
    }
  };
};
export default connect(connectStateToProps, connectDispatchToProps)(CreateCase);
