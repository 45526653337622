import React, { Component } from "react";
import List from "../grid.component";
import { setBreadcrumb } from '../../reducers/breadcrumbReducer';
import { connect } from 'react-redux';
import apiCalls from "../../api/apiCalls";
import { updateCurrentScreen } from "../../reducers/permissionsReducer";
import { setCurrentAction } from '../../reducers/actionsReducer';

class Swap extends Component {
    state = {
        gridUrl: process.env.REACT_APP_GRID_API + "Swap",
        param: { type: 'All' },
        type: "All",
        modal: false,
    }
    gridColumns = [
        { field: "memberName", title: "Name", width: 220, filter: true, locked:true, customCell: (props) => <td><div className="gridLink" title={props.dataItem.memberFirtName} onClick={() => this.update(props)}>{props.dataItem.memberName}</div></td> },
        { field: "userName", title: "User Name", filter: true, width: 200, },
        { field: "memberEmail", title: "Email", filter: true, width: 200, },
        { field: "date", title: "Date", width: 210, filter: true, isShowTime: true, filterType: "date", },
        { field: "fromWalletCode", title: "From Wallet", width: 177, filter: true },
        { field: "toWalletCode", title: "To Wallet", width: 170, filter: true },
        { field: "fromValue", title: "From Value", width: 180, filter: true, dataType: 'number', filterType: "numeric" },
        { field: "toValue", title: "To Value", width: 140, filter: true, dataType: 'number', filterType: "numeric" },
        { field: "exicutedPrice", title: "Provider Cost", filter: true, width: 180, dataType: 'number', filterType: "numeric" },
        { field: "comission", title: "SuisseBase Commission", filter: true, width: 230, dataType: 'number', filterType: "numeric" },
        { field: "amountInUsd", title: "Amount in USD", width: 150, filter: true, dataType: 'number', filterType: "numeric" },
        {
            field: "comission",
            title: "Commission",
            filter: true,
            width: 250,
            dataType: "number",
            filterType: "numeric"
          },
          { field: "comissionvalueinUSD", title: "Comission Value in USD", width: 200, filter: true, dataType: 'number', filterType: "numeric" },
          { field: "pcomissionvalueinUSD", title: "Provider Commission Value in USD", width: 280, filter: true, dataType: 'number', filterType: "numeric" },
          { field: "profitInUSD", title: "Profit in USD", width: 150, filter: true, dataType: 'number', filterType: "numeric" },

    ]
    componentDidMount() {
        this.props.dispatch(updateCurrentScreen('buysell'));
        this.props.dispatch(setCurrentAction(null));
        this.SwapTrack();
    }
    SwapTrack = () => {
        apiCalls.trackEvent({
            Type: "Admin",
            Action: "Swap grid page view",
            Username: this.props.userConfig?.userName,
            MemeberId: this.props.userConfig?.id,
            Feature: "Swap",
            Remarks: "Swap grid page view",
            Duration: 1,
            Url: window.location.href,
            FullFeatureName: "Swap"
        });
    };
    update = (e) => {
        const items = e.dataItem;
        const val = (items.id);
        this.props.dispatch(setBreadcrumb({ key: '/swap/' + val, val: items.memberName }))
        this.props.history.push('/swap/' + val)
        apiCalls.trackEvent({ "Action": 'Swap details page view', "Feature": 'Swap', "Remarks": "Swap details page view", "FullFeatureName": 'Swap', "userName": this.props.userConfig.userName, id: this.props.userConfig.id });
    }
    render() {
        const { gridUrl } = this.state;
        return <>
            <List
                url={gridUrl}
                key={gridUrl}
                showExcelExport ={true}
                columns={this.gridColumns} /></>
    }
}

const connectStateToProps = ({ oidc, userConfig }) => {
    return { userConfig: userConfig.userProfileInfo, oidc }
}
export default connect(connectStateToProps, (dispatch) => { return { dispatch } })(Swap);
