import React, { useEffect, useState } from "react";
import {Tooltip,Modal,Button,Form,Col, Input,Alert, Spin,Row,message} from "antd";
import { getRiskScreenDetailsData, saveRiskScreenDetailsData } from "./api";
import { connect } from "react-redux";
import RiskScreenUpLoad from "./riskScreenUpLoad";
import { validateContentRule } from "../../utils/custom.validator";
import apiCalls from "../../api/apiCalls";


const RiskDetialsPage = (props) => {
    const [form] = Form.useForm();
    const { TextArea } = Input
    const [riskData, setRiskData] = useState(null)
    const [errorMsg, setErrorMsg] = useState(null);
    const [isLoading,setIsLoading]=useState(false)
    const [loading,setLoading]=useState(false)
    const [attachmntsList,setAttachmetsList]=useState([])

    useEffect(() => {
        getRiskScreenCustomerData();
    },[])

    const getRiskScreenCustomerData = async () => { 
        setErrorMsg(null)
        setLoading(true)
        let res = await getRiskScreenDetailsData(props?.gridID)
        if (res.ok) { 
            setLoading(false)
            setAttachmetsList(res.data.files)
            setRiskData(res.data)
            form.setFieldsValue(res.data)
            setErrorMsg(null)
        }else{
            setErrorMsg(apiCalls.isErrorDispaly(res))
            setLoading(false)
        }
    }

    const updateAttachemnts =(attachemnts) =>{
        setErrorMsg(null)
        setAttachmetsList(attachemnts)
    }
    const uploadErrorMsg=(errormsg)=>{
     setErrorMsg(errormsg)
    }

    const buttonSaveRiskScreenCustomerData = async (val) => {
       
     setErrorMsg(null)
     setIsLoading(true)
     
        if(attachmntsList == null || attachmntsList.length == 0){
            setIsLoading(false)
           setErrorMsg("At least one document is required") 
        }
        else if(attachmntsList.length > 0){
         let list =attachmntsList.filter((item)=>item.recordStatus != 'Deleted')     
         if(list.length < 1){
            setIsLoading(false)
           setErrorMsg("At least one document is required")
         }
         else{
        
          let saveObj = {
              id: riskData.id, 
              description:val.description,
              customerId: props?.gridID,
              files: attachmntsList ||[],
              createdDate:riskData.createdDate,
                  modifieddate: riskData.modifieddate,
                  createdby: props?.userConfig?.userName,
              modifiedby:props?.userConfig?.userName,
              riskScreenUrl: riskData.riskScreenUrl,
              info : JSON.stringify(props.trackAuditLogData),
          }
          let res = await saveRiskScreenDetailsData(saveObj)
          if (res.ok) {
              setIsLoading(false)
              message.destroy();
      message.success({
        content: 'Details saved successfully',
        className: "custom-msg",
        duration: 3
      })
              props?.pop_up_cancel()
              setErrorMsg(null)
          }else{
              setIsLoading(false)
              setErrorMsg(apiCalls.isErrorDispaly(res))
          }
      }
        } 
       
        
       
        // else{
        //     setIsLoading(false)
        // }
    }
        
    

    

    return (
      <>
        <Modal
          visible={props.riskModal}
          title={"Screening"}
          closeIcon={
            <Tooltip title="Close">
              <span
                className="icon md x c-pointer risk-close"
                onClick={props.pop_up_cancel}
              />
            </Tooltip>
          }
          footer={null}
        >
          <Form
            form={form}
            onFinish={buttonSaveRiskScreenCustomerData}
            initialValues={riskData}
            className="risk-popup"
          >
            {errorMsg !== null && (
              <Alert
                type="error"
                description={errorMsg}
                onClose={() => setErrorMsg(null)}
                showIcon
              />
            )}
            <Spin spinning={loading}>
              <div>
                <Form.Item>
              <Row gutter={24} className="mb-24 pb-24 border-bottom">
                  <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                    <Form.Item
                      className="input-label note-label"
                      name="description"
                      label="Note"
                      rules={[
                        {
                          required: true,
                          message: "Is required"
                        },
                        {
                          validator: validateContentRule
                        }
                      ]}
                    >
                      <TextArea 
                      placeholder="Note" 
                      maxLength={200} 
                      showCount={200} 
                      rows={4}>                        
                      </TextArea>
                    </Form.Item>
                  </Col> 
                
                
                {riskData && (
                  <>       
                  <Col xs={24} sm={24} md={24} xl={24} xxl={24}>         
                    <RiskScreenUpLoad
                      getRiskData={attachmntsList}
                      getInfo={riskData}
                      riskScreenId={riskData?.id}
                      attachmentUpdate={(attachemnts) =>
                      updateAttachemnts(attachemnts)
                      }
                      uploadError={(errormsg)=>uploadErrorMsg(errormsg)}
                    />
                    </Col>
                  </>
                )}
            </Row>

               </Form.Item>
               <Form.Item className="mb-0">
                  <div className="text-right">
                    <Button
                      type="primary"
                      className="primary-btn cancel-btn mr-8"
                      onClick={props.pop_up_cancel}
                    >
                      {" "}
                      Cancel{" "}
                    </Button>
                    <Button
                      type="primary"
                      className="primary-btn"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Save{" "}
                    </Button>
                  </div>
                </Form.Item>
              </div>
              </Spin>
          </Form>
        </Modal>
      </>
    );
}
const connectStateToProps = ({ userConfig}) => {
    return {
        userConfig: userConfig?.userProfileInfo,
        trackAuditLogData: userConfig.trackAuditLogData,


    };
};
export default connect(connectStateToProps)(RiskDetialsPage);