import React, { useEffect, useState } from "react";
import { Form, Row, Col, Typography,Divider ,Button} from "antd";
import { getCustomerDetails as getCustomerDetailsApi,getDOCDetails,sumsubRefreshData} from "./api";
import Loader from "../loader.component";
import apiCalls from "../../api/apiCalls";
import IdentityDetails from "./IdentityDetails";
import Moment from "react-moment";
import Alert from "antd/lib/alert";
import POACheckComponent from "./POACheck";
import ProfileData from "./profileData";
import AddressInfo from "./addressInfo";
import UtilityBill from "./utilityBill";
import IdCard from "./idCard";
import config from "../../config";
const KYC = ({ customerId,customerDetails,screen }) => {
  const [userData, setUserData] = useState(null);
  const [poaData, setPoaData] = useState(null);
  const [proofs, setProofs] = useState(null);
  const { Title, Paragraph } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isDocLoader,setIsDocLoader]=useState(false);
  const [isRefresh,setIsRefresh]=useState(false);
  const getCustomerDetails = async () => {
    setIsLoading(true);
    try {
      const response = await getCustomerDetailsApi(`sumsubdetails/${customerId}`);
      if (response.ok) {
        setUserData(JSON.parse(response.data.personalSumubDetails.userData));
        setPoaData(JSON.parse(response.data.personalSumubDetails.poaDetails));
        setProofs(response.data.personalSumubDetails.proofs);
        setErrorMsg("");
      } else {
        setErrorMsg(apiCalls.isErrorDispaly(response));
      }
    } catch (error) {
      setErrorMsg(apiCalls.isErrorDispaly(error));
    } finally {
      setIsLoading(false);
    }
  };
  const getDocumentDetails=async()=>{
    setIsDocLoader(true)
    let res = await getDOCDetails(customerId);
    if(res.ok){    
      setIsDocLoader(false)
      setProofs(res?.data);
    }else{
      setIsDocLoader(false)
      setErrorMsg(apiCalls.isErrorDispaly(res));
    }
  }
  useEffect(() => {
    if(customerId){
      getCustomerDetails();
      getDocumentDetails();
    }
    else{
      setUserData(JSON.parse(customerDetails.uboDetails));
      setPoaData(JSON.parse(customerDetails.poaDetails));
      setProofs(customerDetails.proofs);
    }
  }, []);
  const getSumsubRefreshData=async()=>{
    setIsRefresh(true);
    let res = await sumsubRefreshData(customerId);
    if(res.ok){
      setIsRefresh(false);
      getCustomerDetails();
      getDocumentDetails();
    }else{
      setIsRefresh(false);
      setErrorMsg(apiCalls.isErrorDispaly(res));
    }
  }
  return (
    <>
      <div>
        {errorMsg && (
          <div className="mt20">
            <Alert
              closable
              type="error"
              description={errorMsg}
              onClose={() => setErrorMsg("")}
              showIcon
            />
          </div>
        )}
      </div>
      {!customerId && <div style={{marginTop:'50px'}}><Divider orientation="center" orientationMargin={0}><Title level={4}>{`${userData?.info?.firstName ? userData?.info?.firstName:''}`}</Title></Divider></div>}
      <Form
        name="advanced_search"
        className={!customerId ? "ant-advanced-search-form" :"ant-advanced-search-form  kyckyb-form"}
        autoComplete="off"
      >
        {isLoading && <Loader className="text" />}
        {!isLoading && (
          <>
          <div className={""}>
        {screen !=="KYB" && <div className="text-right"><Button
						type="primary"
						onClick={() => getSumsubRefreshData()}
            loading={isRefresh}
						className="primary-btn primary-btn ">
						Refresh
					</Button></div>}
            <ProfileData data={userData} type={"Person"} />
            <Title level={5} className="m-0 mt-5">
              Personal Info
            </Title>
            <div className="applicant-content">
              <Row gutter={[16, 24]}>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">First Name</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.firstName || userData?.fixedInfo?.firstName}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Last Name</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.lastName || userData?.fixedInfo?.lastName}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Middle Name</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.middleName || userData?.fixedInfo?.middleName}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Phone No</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.phone || userData?.fixedInfo?.phone}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Country</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.country || userData?.fixedInfo?.country}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Country of Birth</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.countryOfBirth || userData?.fixedInfo?.countryOfBirth}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">State of Birth</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.stateOfBirth || userData?.fixedInfo?.stateOfBirth}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Date of Birth</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.dob ? (
                        <Moment format={config?.dateFormates?.dateFormate}>
                          {userData?.info?.dob
                            && apiCalls.convertUTCToLocalTime(
                                userData?.info?.dob
                              )
                            || userData?.info?.dob}
                        </Moment>
                      ) : (
                        userData?.info?.dob
                      )}
                      {userData?.fixedInfo?.dob ? (
                        <Moment format={config?.dateFormates?.dateFormate}>
                          {userData?.fixedInfo?.dob
                            && apiCalls.convertUTCToLocalTime(
                                userData?.fixedInfo?.dob
                              )
                            || userData?.fixedInfo?.dob}
                        </Moment>
                      ) : (
                        userData?.fixedInfo?.dob
                      )}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Place of Birth</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.placeOfBirth || userData?.fixedInfo?.placeOfBirth}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Nationality</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.nationality ||  userData?.fixedInfo?.nationality}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Legal Name</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.legalName ||  userData?.fixedInfo?.legalName}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Gender</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.gender ||  userData?.fixedInfo?.gender}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Tax Residence Country</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.taxResidenceCountry || userData?.fixedInfo?.taxResidenceCountry}
                    </Paragraph>
                  </div>
                </Col>
                {/* <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">PAN</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.tin?.toLowerCase()}
                    </Paragraph>
                  </div>
                </Col> */}
              </Row>
            </div>
            {userData?.info?.addresses && <Title level={5} style={{ margin: "0" }}>
              Address Info
            </Title>}
            {userData?.info?.addresses?.map((address) => {
              return <AddressInfo addressDetails={address} border={true} />;
            })}
            {userData?.info?.idDocs?.map(id=>{
              if(id?.idDocType?.toLowerCase()==='passport'){
                return <IdCard details={id} type={'passport'}/>
              }
              else if(id?.idDocType?.toLowerCase()==='id_card'){
                return <IdCard details={id} type={"idCard"}/>
              }
              else if(id?.idDocType?.toLowerCase()==='residence_permit'){
                return <IdCard details={id} type={"residence"}/>
              }
              else if(id?.idDocType?.toLowerCase()==='drivers'){
                return <IdCard details={id} type={"drivers"}/>
              }
              else{
                return <UtilityBill details={id}/>
              }
            })}
            <Title level={5} style={{ margin: "0" }}>
              Documents
            </Title>
             {isDocLoader && <div className="text-center">Loading...</div>}
             {!isDocLoader && (<div className="applicant-content">
                <IdentityDetails identityDetails={proofs} />
                </div>
              )}
              </div>
          </>
        )}
               <POACheckComponent applicantId={customerDetails?.applicantId} poaData={poaData}/>
      </Form>
    </>
  );
};

export default KYC;
