const saveGridLayoutTrue = () => {
    return {
      type: 'SAVE_GRID_TRUE',
    }
  }
  
  const saveGridLayoutFalse = () => {
    return {
      type: 'SAVE_GRID_FALSE',
    }
  }
  
  const resetGridLayoutTrue = () => {
    return {
      type: 'RESET_GRID_TRUE',
    }
  }
  
  const resetGridLayoutFalse = () => {
    return {
      type: 'RESET_GRID_FALSE',
    }
  }
  
  let initialState = {
    saveGridLayout: false,
    resetGridLayout: false,
  }
  
  const saveGridModalReducer = (state, action) => {
    if (!state) {
        state = {
          ...initialState,
          ...state
        }
      }
    switch (action.type) {
      case 'SAVE_GRID_TRUE':
        return { ...state, saveGridLayout: true }
      case 'SAVE_GRID_FALSE':
        return { ...state, saveGridLayout: false }
      case 'RESET_GRID_TRUE':
        return { ...state, resetGridLayout: true }
      case 'RESET_GRID_FALSE':
        return { ...state, resetGridLayout: false }
      default:
        return state
    }
  }
  
  export default saveGridModalReducer
  export { saveGridLayoutTrue, saveGridLayoutFalse, resetGridLayoutTrue, resetGridLayoutFalse }
  