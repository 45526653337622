import React, { Component } from "react";
import {
  Row,
  Col,
  Typography,
  Collapse,
  Alert,
  Button,
  Select,
  Tooltip,
  Modal,
  Form,
  Input,
  message,
  Tabs,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import defaultUser from "../../assets/images/defaultuser.jpg";
import moment from "moment";
import Moment from "react-moment";
import Loader from "../loader.component";
import { handleMembers } from "../../reducers/customerReducer";
import { connect } from "react-redux";
import List from "../grid.component";
import NumberFormat from "react-number-format";
import { saveCustomer, getCustomer, getCaseLu, getRefferalCode,getCommissionsLU,getRiskScreenDetailsData, getRiskScreenDetails,getAvailableBanks ,getWalletDetails,getCredits } from "./api";
import apiCalls from "../../api/apiCalls";
import SharedInfo from "../shared/sharedInfo";
import Info from "../info/Info";
import { publishShowActions } from "../grid.component/subscribir";
import { validateContentRule } from "../../utils/custom.validator";
import { redirectToCaseView } from "../../utils/caseRediractions";
import { Link } from "react-router-dom";
import RiskDetailsPage from "./riskDetailsPage";
import DocumentPreview from "../shared/documentPreview";
import { bytesToSize } from "../../utils/service";
import CustomerBatchFee from "./customerBatchFee";
import CustomerAddressBook from "./customerAddressBook";
import SuisseBaseCredits from "../../utils/suissebaseCredits";
import BackupAddress from "./backupAddress";
import CopyToClipboard from "react-copy-to-clipboard";
import KYB from "./KYB";
import KYC from "./KYC";
import config from "../../config";
const { TextArea } = Input;
const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const { TabPane } = Tabs;
const EllipsisMiddle = ({ suffixCount, children }) => {
  const start = children?.slice(0, children.length - suffixCount).trim();
  const suffix = children?.slice(-suffixCount).trim();
  
  return (
    <Text
      className="mb-0 fs-14 docnames c-pointer d-block"
      style={{ maxWidth: "100% !important" }}
      ellipsis={{ suffix }}
    >
      {start}
    </Text>
  );
};
class CustomerSummary extends Component {
  constructor (props) {
    super(props);
    this.state = {
      customergridparams: {
        customerid: ""
      },
      btnDisabled: false,
      isLoading: false,
      customerObj: {
        id: [],
        riskLevel: null,
        amountThreshold: null,
        accumulatedDeposit: null
      },
      cryptoModal: false,
      loading: false,
      fiat: true,
      errorMsg: null,
      type: this.props.match.params.type,
      assignedTo: [],
      userName: null,
      customerdata: {},
      assignedToObj: {},
      accountManagerObject: [],
      getData: {},
      refferalName: "",
      isInvalidPartnerReferralCode: false,
      disable: false,
      popupSymbole: false,
      crossCheckIcon: false,
      gridUrl: process.env.REACT_APP_GRID_API + "Customer/Transactions",
      gridUrlWalletsK: process.env.REACT_APP_GRID_API + "Customer/Wallets",
      loader: false,
      activeTab: (this.props?.match?.params?.type === "fees" || this.props?.match?.params?.type === "batchedit") ? "7" : "1",
      uploadData: [],
      uploadData2: {},
      riskPage: false,
      tabclose: false,
      previewModal: false,
      previewDocDetails: null,
      riskloader: false,
      infoDetails:false,
      isModalOpen: false,
      suisseBaseCreditvisible:false,commissionsLu:[],banksLu:[],templateObj:{customerId:'',commissionId:'',modifiedBy:'',AvailableBanks:[]},banksModal:false,bankLoader:false,bankerrorMsg:null,commissionTempId:'',defaultBanks:[],
      isWalletModal:false,
      referralError:null,
      isBankModelClose:true,
      credit:null,
      isWalletLoader:false,
      getWallets: ([]),
      isWalletError:null,
    };
    this.gridRef = React.createRef();
    this.formRef = React.createRef();
    this.useDivRef = React.createRef();
  }
  DivRef = React.createRef();
  componentDidMount = () => {
    this.useDivRef.current.scrollIntoView(0,0);
    publishShowActions(false);
    let selectedId = this.props.match.params.id;
    this.props.fetchUserData(selectedId);
    this.loadCustomers();
    this.getCredit();
  };
  getCredit=async()=>{
    let response = await getCredits(this.props.match.params.id);
    if (response.ok) {
      this.setState({
        ...this.state,
        credit: response.data.credit,
      });
    }
      else{
        this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
      }
  }
  loadCommissionsLu=async(customersData)=>{
    let response = await getCommissionsLU(this.props.match.params.id);
    if (response.ok) {
      this.setState({
        ...this.state,
        commissionsLu: response.data,
      });
      for (var k in response.data) {
        if (response.data[k].templateName == customersData?.commissionTemplate) {
         this.getBanksData(response.data[k].id)
         this.setState({commissionTempId:response.data[k].id})
        }
      }
    }else{
      this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
    }
  }
  changeTab = (activeKey) => {
    if (activeKey === "1") {
      this.loadCustomers();
    }
    if (activeKey === "6") {
      this.getRiskScreenCustomerData();
      this.getRiskScreenDetailsData();
    }
    this.setState({
      activeTab: activeKey,
      errorMsg: null
    });
  };

  GoBackRiskTab = () => {
    this.getRiskScreenCustomerData();
    this.getRiskScreenDetailsData();
    this.setState({ ...this.state, riskPage: false });
  };

  loadCustomers = async () => {
    this.setState({...this.state,isLoading:true});
    let selectedId = this.props.match.params.id;
    let response = await getCustomer(selectedId);
    if (response.ok) {
      let obj = response.data;
      this.setState({
        ...this.state,
        customerdata: response.data,
        credit:response.data?.credit,
        assignedToObj: {
          id: obj.accountManager,
          assignedTo: obj.accountManagerName
        },
        crossCheckIcon: false,
        errorMsg: null,
        isLoading: false
      });
      if (response.data.partnerReferralCode !== null) {
        this.getRefferallCode(response.data?.partnerReferralCode);
        this.setState({ disable: true });
      }
      this.formRef.current?.setFieldsValue({ ...response?.data });
      
    this.loadCommissionsLu(response.data);
    this.getdefaultBanks(response.data.availableBanks)
    }else{
      this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response),isLoading:false})
    }
  };

  RiskScreenPage = () => {
    this.setState({ ...this.state, riskPage: true });
  };

  loadTrans = () => {
    let { customergridparams } = this.state;
    let customerId = this.props.match.params.id;
    customergridparams["customerid"] = customerId;
    this.setState({ ...this.state, customergridparams });
  };

  transView = (e) => {
    const items = e.dataItem;
    const val = items.id;
    window.open("/transaction/" + val, "_blank");
    this.props.fetchBreadCrumb({
      key: "/transaction/" + val,
      val: items.walletName
    });
  };
  getWalletView = async (props) => {
    this.setState({ ...this.state, isWalletModal: true ,isWalletLoader:true,isWalletError:null})
    let res = await getWalletDetails(props?.dataItem?.id);
    if (res.ok) {
      this.setState({ ...this.state, getWallets: res.data,isWalletLoader:false });
    } else {
      this.setState({ ...this.state, isWalletError: apiCalls.isErrorDispaly(res) ,isWalletLoader:false})
    }
  }
  gridColumns = [
    {
      field: "transactionId",
      title: "Transaction ID",
      filter: true,
      locked: true,
      width: 210
    },
    {
      field: "date",
      title: "Submission Date",
      filter: true,
      isShowTime: true,
      filterType: "date",
      locked: false,
      width: 210,
      customCell: (props) => (
        <td>
          <Moment format={config?.dateFormates?.dateTimeFormate}>
            {props.dataItem?.date
              ? apiCalls.convertUTCToLocalTime(props.dataItem?.date)
              : props.dataItem?.date}
          </Moment>
        </td>
      )
    },
    {
      field: "approvedDate",
      title: "Approval Date",
      filter: true,
      isShowTime: true,
      filterType: "date",
      locked: false,
      width: 210,     
    },
    { field: "status", title: "Status", filter: true, width: 160 },
    { field: "docType", title: "Transaction", filter: true, width: 120 },
    {
      field: "fromWalletCode",
      title: "From Wallet Code",
      filter: true,
      width: 180
    },
    {
      field: "fromValue",
      title: "From Value",
      width: 150,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "toWalletCode",
      title: "To Wallet Code",
      filter: true,
      width: 150
    },
    {
      field: "toValue",
      title: "To Value",
      width: 150,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
			field: "effectiveFee",
			title: "Fees",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric",
		},
    {
      field: "comission",
      title: "Commission",
      width: 150,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "comissionInBase",
      title: "Commission in Base",
      width: 200,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "providerComission",
      title: "Provider Commission",
      width: 200,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "providerComissionInBase",
      title: "Provider Commission in Base",
      width: 240,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
			field: "sweepsourceComissionValue",
			title: "Sweep Source Commission",
			filter: true,
			width: 250,
			dataType: "number",
			filterType: "numeric"
		},
		{
			field: "sweepSourceValueInBase",
			title: "Sweep Source in Base",
			filter: true,
			width: 250,
			dataType: "number",
			filterType: "numeric"
		},
		{
			field: "sweepDestinationComissionValue",
			title: "Sweep Destination Commission",
			filter: true,
			width: 260,
			dataType: "number",
			filterType: "numeric"
		},
		{
			field: "sweepDestinationValueInBase",
			title: "Sweep Destination in Base",
			filter: true,
			width: 260,
			dataType: "number",
			filterType: "numeric"
		},
    {
			field: "totalAmount",
			title: "Total Cost",
			filter: true,
			width: 130,
			dataType: "number", filterType: "numeric"
		},
    {
      field: "profit",
      title: "Profit",
      width: 130,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "profitInBase",
      title: "Profit in Base",
      width: 160,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
			field: "provider",
			title: "Provider",
			filter: true,
			 width: 160
		},
    {
      field: "fromValueBefore",
      title: "From Before Value",
      width: 180,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "fromValueAfter",
      title: "From After Value",
      width: 180,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "toValueBefore",
      title: "To Before Value",
      width: 180,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "toValueAfter",
      title: "To After Value",
      width: 150,
      filter: true,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
  ];
  gridWallets = [
    {
      field: "",
      title: "",
      width: 220,
      customCell: (props) => (
        <td>
          <img
            src={props.dataItem.impagePath}
            alt="coins"
            width="36"
            height="36"
            style={{ marginRight: 16 }}
          />
          {props.dataItem.walletCode}
        </td>
      )
    },
    { field: "walletName", title: "Wallet Name", filter: true, width: 240 },
    { field: "type", title: "Type", filter: true, width: 100 },
    { field: "walletaddress", title: "Wallet Address", filter: true, width: 480, 
    customCell: (props) => (
      <td>
        <div className="d-flex">
       <div className="batch-excel"><span>
        {props?.dataItem?.walletaddress && <Paragraph className="" >{props?.dataItem?.walletaddress}</Paragraph>}
        </span></div>
       {props.dataItem?.type!=="Fiat"&&  <div><Tooltip title="Wallet Details">
        <span className="icon md info c-pointer ml-8" onClick={()=>this.getWalletView(props)}></span></Tooltip></div>}</div>
          </td>
    ) },
    {
      field: "availableAmount",
      title: "Available Amount",
      filter: true,
      width: 240,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "coinValueinNativeCurrency",
      title: "Amount in USD",
      filter: true,
      width: 240,
      dataType: "number",
      filterType: "numeric"
    }
  ];
  bonusGridColumns = [
    {
      field: "date",
      title: "Date",
      filter: true,
      width: 250,
      isShowTime: true,
      filterType: "date"
    },
    {
      field: "name",
      title: "Business Name/Personal Name",
      filter: true,
      width: 300
    },
    { field: "walletCode", title: "Wallet code", filter: true, width: 200 },
    {
      field: "transactionType",
      title: "Transaction type",
      filter: true,
      width: 200
    },
    {
      field: "transactionValue",
      title: "Transaction value",
      filter: true,
      dataType: "number",
      filterType: "numeric",
      width: 200
    },
    {
      field: "baseCurrency",
      title: "Base Currency",
      filter: false,
      width: 200
    },
    {
      field: "baseValue",
      title: "Base Value",
      filter: true,
      dataType: "number",
      filterType: "numeric",
      width: 200
    },
    {
      field: "value",
      title: "Bonus Earned",
      filter: true,
      dataType: "number",
      filterType: "numeric",
      width: 200
    }
  ];

  ReferralGridColumns = [
    {
      field: "joiningDate",
      title: "Joining Date",
      filter: true,
      filterType: "date",
      isShowTime: true
    },
    { field: "name", title: "Business Name/Personal Name", filter: true },
    {
      field: "bonusEarned",
      title: "Bonus Earned",
      filter: false,
      dataType: "number",
      filterType: "numeric"
    },
    { field: "tradeIntiated", title: "Trade Initiated ", filter: false }
  ];

  transectionColumn = [
    {
      field: "accountNumber",
      title: "Account Number",
      filter: true,
      width: 300,
      customCell: (props) => (
        <td>
          {" "}
          <div className="gridLink" onClick={() => this.accountView(props)}>
            {props.dataItem.accountNumber}
          </div>
        </td>
      )
    },

    {
      field: "routingNumber",
      title: "Routing Number",
      filter: true,
      dataType: "number",
      filterType: "numeric",
      width: 250
    },
    { field: "type", title: "Type", filter: true, width: 100 },
    { field: "isVerified", title: "Is Verified", filter: true, width: 250 },
    { field: "currency", title: "Currency", filter: true, width: 250 },
    {
      field: "availableBalance",
      title: "Available Balance",
      filter: true,
      width: 250
    },
    {
      field: "sponsorBankName",
      title: "Sponsor Bank Name",
      filter: true,
      width: 250
    },
    { field: "pendingDebit", title: "Pending Debit", filter: true, width: 250 },
    {
      field: "pendingCredit",
      title: "Pending Credit",
      filter: true,
      width: 250
    },
    {
      field: "createdPersonId",
      title: "Created Person Id",
      filter: true,
      width: 250
    }
  ];

  transferSummary = [
    {
      field: "createdAt",
      title: "Date",
      filter: true,
      width: 200,
      customCell: (props) => (
        <td>
          {moment(new Date(props.dataItem.createdAt)).format(
            config?.dateFormates?.dateTimeFormate
          )}
        </td>
      )
    },

    {
      field: "baneficiaryName",
      title: "Name",
      filter: true,
      width: 200
    },

    {
      field: "currency",
      title: "Currency",
      filter: true,
      width: 150
    },
    {
      field: "credit",
      title: "Transfer Amount",
      filter: true,
      width: 180
    },
    {
      field: "beneficiaryAccountNumber",
      title: "Account Number ",
      filter: true,
      width: 180
    },
    {
      field: "beneficiaryRoutingNumber",
      title: "Routing Number",
      filter: true,
      width: 250
    },

    {
      field: "status",
      title: "Status",
      filter: true,
      width: 180
    }
  ];

  success = (msg, duration) => {
    message.destroy();
    message.success({
      content: msg,
      className: "custom-msg",
      duration: 4
    });
  };
  handleSearch = (e, type) => {
    let sval = e.target.value;
    if (sval != null && sval.length > 2) {
      if (type === "AssignedTo") {
        this.fetchAccountManagerLu(sval);
      }
    }
  };

  handleChange = (val, type) => {
    if (type === "AssignedTo") {
      const obj = this.state.assignedTo?.find((item) => item.id === val);
      this.setState({ ...this.state, assignedToObj: obj });
    }
  };
  fetchAccountManagerLu = async (searchName, id) => {
    let response = await getCaseLu(searchName);
    if (response.ok) {
      const obj = this.state.assignedTo?.find((item) => item.id === id);
      this.setState({
        ...this.state,
        assignedTo: response.data,
        getData: obj,
        isLoading: false,
        errorMsg:null
      });
    } else {
      this.setState({ ...this.state,isLoading: false,errorMsg:apiCalls.isErrorDispaly(response) });
    }
  };

  handleRiskLevel(val) {
    this.state.customerObj["riskLevel"] = val;
  }

  checkVerificationConditions=(count,objval)=>{
    if(count==0){
      this.setState({...this.state,loading: false,btnDisabled: false,errorMsg: 'Please select at least one bank', isLoading: false});
      this.useDivRef.current.scrollIntoView(0,0);
      return;
    }
    if (parseFloat(typeof objval.amountThreshold == "string" ? objval.amountThreshold.replace(/,/g, "") : objval.amountThreshold) <= 0) {
      this.setState({...this.state,loading: false,isLoading: false,btnDisabled: false,errorMsg: "Amount must be greater than zero."});
      this.useDivRef.current.scrollIntoView(0,0);
      return;
    }
    if (parseFloat(typeof objval.accumulatedDeposit == "string" ? objval.accumulatedDeposit.replace(/,/g, "") : objval.accumulatedDeposit) <= 0) {
      this.setState({...this.state,loading: false,btnDisabled: false, errorMsg: "Amount must be greater than zero.",isLoading: false});
      this.useDivRef.current.scrollIntoView(0,0);
      return;
    }
    if(count==0){
      this.setState({...this.state, loading: false, btnDisabled: false, errorMsg: 'Please select at least one bank', referralError:null,isLoading: false});
      window.scrollTo(0, 0);
      return;
    }
  }
  saveCustomerInfo = async (values) => {
    this.setState({
      loading: true,
      btnDisabled: true,
      errorMsg: null,
      isLoading: true,
      referralError:null
    });
    const updatedBanksLu = this.state.defaultBanks.map((item) => {
      return this.state.banksLu.map((items) => {
          if (item === items.bankName) {
              return { ...items, status: 'Active' };
          } else {
              return items;
          }
      });
  })
  this.setState({...this.state, banksLu: updatedBanksLu });
    let count=this.state.banksLu.filter(val => val.status === 'Active').length;
    let objval = values;
   this.checkVerificationConditions(count,objval);
    values.id = this.props.match.params.id;
    values.info = JSON.stringify(this.props.trackAuditLogData);
    values.accountManager = this.state.assignedToObj.id;
    values.accountManagerName = this.state.assignedToObj.assignedTo;
    values.modifiedBy = values.id ? this.props.userConfig?.userName : null;
    values.CommissionId=this.state.commissionTempId?this.state.commissionTempId:values.CommissionId;
    values.createdBy =this.props.userConfig?.userName ;
    values.commissionTemplate=this.state.customerdata.commissionTemplate;
    values.availableBanks=this.state.banksLu;
    values.amount=this.state.credit;

    values.sourceOfClient = values.sourceOfClient == "" ? null : values.sourceOfClient;
    if (this.state.isInvalidPartnerReferralCode === false) {
      let response = await saveCustomer(values);
      if (response.ok) {
        this.setState({
          ...this.state,
          disable:true,
          btnDisabled: false,
          errorMsg: null,
          referralError:null,
          loading: false,
          isLoading: false
        });
        this.useDivRef.current.scrollIntoView(0,0);
        this.success("Customer details saved successfully");
        this.props.history.push("/customers");
      } else {
        this.setState({...this.state, errorMsg: apiCalls.isErrorDispaly(response),loading: false,isLoading: false, btnDisabled: false});
        this.useDivRef.current.scrollIntoView(0,0);
        window.scrollTo(0, 0);
      }
    } else {
      this.setState({...this.state, referralError: "Invalid referral party",loading: false, isLoading: false, btnDisabled: false});
    }
    apiCalls.trackEvent({
      Action:
        this.props.match.params.id === "00000000-0000-0000-0000-000000000000"
          ? "Customer Saved"
          : "Customer updated ",
      Feature: "Customers",
      Remarks:
        this.props.match.params.id === "00000000-0000-0000-0000-000000000000"
          ? "Customer Saved"
          : "Customer updated",
      FullFeatureName: "Customers"
    });
  };

  numberValidator = async function (rule, value, callback) {
    if (value) {
      if (typeof value === "number") {
        value = value.toString();
      }
      if (
        (value.indexOf(".") > -1 && value.split(".")[0].length >= 11) ||
        (value.indexOf(".") < 0 && value.length >= 11)
      ) {
        throw new Error("Amount exceeded");
      } else {
        callback();
      }
    }
  };

  handleDepositTabs = (e) => {
    this.setState({
      ...this.state,
      fiat: e.target.value === 1
    });
  };

  redirectCaseView = (caseData) => {
    redirectToCaseView(caseData, this.props);
  };
  isUrlValid(userInput) {
    var res = userInput.match(
      /(http(s):\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return !!res;
  }
  isSumSubUrlValid(userInput) {
    var res = userInput.match(
      /(http(s):\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res === null) return false;
    else if (userInput.startsWith("https://cockpit.sumsub.com")) {
      return true;
    } else {
      return false;
    }
  }
  getRefferallCode = async (id) => {
    if (!id == " ") {
      let response = await getRefferalCode(id, this.props.match.params.id);
      if (response.ok) {
        this.setState({
          refferalName: response.data.name,
          popupSymbole: true,
          btnDisabled:true,
          errorMsg: null,
          referralError:null,
          crossCheckIcon: false,
          isInvalidPartnerReferralCode: false,
          isLoading: false
        });
      } else {
        this.setState({
          refferalName: null,
          referralError: "Invalid referral party",
          crossCheckIcon: true,
          popupSymbole: false,
          isInvalidPartnerReferralCode: true,
          isLoading: false
        });
      }
    } else {
      this.setState({
        ...this.state,
        popupSymbole: false,
        crossCheckIcon: false,
        errorMsg: null,
        referralError:null,
        isInvalidPartnerReferralCode: false,
        isLoading: false
      });
    }
  };

  antIcon = () => {
    <LoadingOutlined
      style={{ fontSize: 18, color: "#fff", marginRight: "16px" }}
      spin
    />;
  };

  getRiskScreenCustomerData = async () => {
    this.setState({ ...this.state, riskloader: true });
    let res = await getRiskScreenDetailsData(this.props.match.params?.id);
    if (res.ok) {
      this.setState({
        ...this.state,
        uploadData: res.data,
        errorMsg: null,
        riskloader: false,
        infoDetails:true
      });
    } else {
      this.setState({
        ...this.state,
        errorMsg: apiCalls.isErrorDispaly(res),
        riskloader: false,infoDetails:false
      });
    }
  };

  getRiskScreenDetailsData = async () => {
    this.setState({ ...this.state, riskloader: true });
    let response = await getRiskScreenDetails(this.props.match.params.id);
    if (response.ok) {
      this.setState({
        ...this.state,
        uploadData2: response.data,
        errorMsg: null,
        riskloader: false
      });
    } else {
      this.setState({
        ...this.state,
        errorMsg: apiCalls.isErrorDispaly(response),
        riskloader: false
      });
    }
  };
  handleSuisseBaseCreditModal = ()=>{
    this.setState({...this.state, suisseBaseCreditvisible:true})
  }
  handleSuissecreditModalClose = (amount)=>{
     this.setState({...this.state, suisseBaseCreditvisible:false,})
    if(amount){this.getCredit();
    }
  }
  handleBanks = async(value) => {
    this.state.customerdata.commissionTemplate=value;
    this.setState({customerdata:this.state.customerdata,errorMsg:null,defaultBanks:[]})
    let { commissionsLu } = this.state;
    for (var k in commissionsLu) {
      if (commissionsLu[k].templateName == value) {
        this.getBanksData(commissionsLu[k].id);this.setState({commissionTempId:commissionsLu[k].id})
      }
    }
  }
  getBanksData=async(templateId)=>{
    let { templateObj } = this.state;
    templateObj.commissionId=templateId
    let response = await getAvailableBanks(this.props.match.params.id,templateId);
    if (response.ok) {
      this.setState({
        ...this.state,
        banksLu: response.data,templateObj:templateObj,commissionTempId:templateId
      });
      this.getdefaultBanks(response.data)
    } else {
      this.setState({
        ...this.state,
        errorMsg: apiCalls.isErrorDispaly(response)
      });
    }
  }
   openBankspopUp=()=>{
    if(!this.state.customerdata.commissionTemplate){
      this.setState({
        ...this.state,
        errorMsg: 'Please select commission template'
      });
      window.scrollTo(0, 0)
    }else{
      this.setState({banksModal:true,bankerrorMsg: null})
      const updatedBanksLu = this.state.defaultBanks.reduce((acc, item) => {
        const updatedItems = this.state.banksLu.map(items => {
            if (item === items.bankName) {
                return { ...items, status: 'Active' };
            }
            return items;
        });
        return acc.concat(updatedItems);
    }, []);
    this.setState({...this.state, banksLu: updatedBanksLu });
  }
}
  checkBanks = (bank) => {
    let { banksLu } = this.state;
    for (var k in banksLu) {
      if (banksLu[k].id == bank.id) {
        banksLu[k].status = bank.status == 'Active' ? 'Inactive' : 'Active'
      }
    }
    this.setState({ ...this.state, banksLu: banksLu,bankerrorMsg:null })
  }
  saveBanks = async () => {
    let count=this.state.banksLu.filter(val => val.status === 'Active').length;
    if(count==0){
      this.setState({
        ...this.state,
        loading: false,
        btnDisabled: false,
        bankerrorMsg: 'Please select at least one bank',
        isLoading: false
      });
    }
    else {
      this.setState({
        ...this.state,
        bankerrorMsg: null,
        bankLoader: false,banksModal:false,
        banksLu:this.state.banksLu
      });
      this.getdefaultBanks(this.state.banksLu)
    }
  }
  handleBankModalClose = () => {
    let updatedBanksLu;
    if (this.state.defaultBanks.length !== 0) {
        updatedBanksLu = this.state.banksLu.map(items => {
            if (this.state.defaultBanks.includes(items.bankName)) {
                return { ...items, status: 'Active' };
            } else {
                return { ...items, status: 'Inactive' };
            }
        });
    } else {
        updatedBanksLu = this.state.banksLu.map(items => ({ ...items, status: 'Inactive' }));
    }
    
    this.setState({
        banksLu: updatedBanksLu,
        bankLoader: false,
        banksModal: false,
        isBankModelClose: false
    });
}

validateSumsubUrl=(_, value)=>{
  if (value) {
    if (!this.isSumSubUrlValid(value)) {
      return Promise.reject("Enter valid url");
    }
  }
  return Promise.resolve();

}

validateMinLength= (_, value) => {
  if (value) {
    if (value.length < 100 || value.length > 2000) {
      return Promise.reject(
        "Minimum length must be 100 characters."
      );
    }
    else{
      return validateContentRule(_,value)
    }
  }
  return Promise.resolve();
}

  getdefaultBanks=(availableBanks)=>{let data=[];
    for(let i = 0; i < availableBanks?.length; i++) {
      if(availableBanks[i].status=='Active'){
        data.push(availableBanks[i].bankName);
    }
    }
   this.setState({defaultBanks:data})
  }
  GotoProfileEdit=()=>{
    this.props.history.push(`/customers/details/${this.props.match?.params?.id}/${this.props.match?.params?.state ? this.props.match?.params?.state : ""}`)
  }
  isCheckEdit=()=>{
    let editCheck =this.props.permissions?.[(this.props?.permissions?.currentScreenTabId)||(this.props?.permissions?.currentScreenId)]?.filter((item)=>(item.permissionName === "edit")).map((item)=>(item.values))
    return editCheck[0]
  }
      getEgnyte=()=>(
       <Form.Item
       name="egnyte"
        label="Egnyte"
        className="input-label mb-0"
         rules={[
          {
            required: false,
           validator: (_, value) => {
            if (value) {
           if (!this.isUrlValid(value)) {
              return Promise.reject(
                "Enter valid url"
               );
               }
               }
                 return Promise.resolve();
                }
                }
               ]}
             >
            <Input
             placeholder="Egnyte"
             className="cust-input"
             maxLength={250}
             disabled={
             this.state.type === "disabled" ||
             this.state.customerdata.status ===
             "Inactive"
               }
            />
            </Form.Item>
             )
  getRefferalData = () => {
  return  <Form.Item
      name="partnerReferralCode"
      label="Referral Party"
      className="input-label cust-inputlabel-style mb-0"
    >
      <Input
        style={{ position: "relative" }}
        placeholder="Referral Party"
        className="cust-input"
        maxLength={250}
        disabled={
          this.state.type === "disabled" ||
            this.state.disable === true ||
            this.state.customerdata.status ===
            "Inactive"
            ? true
            : false
        }
        onBlur={(val) =>
          this.getRefferallCode(
            val.currentTarget.value
          )
        }
      />
    </Form.Item>
  }
  render() {
    const { assignedTo, errorMsg } = this.state;
    const {
      data: customer,
      isLoading,
      error
    } = this.props.customerStore?.customerData;
    const { gridUrl, gridUrlWalletsK, customerdata, customerObj } = this.state;
    if(this.state.type !== this.props.match?.params?.type){
      this.setState({...this.state,type:this.props.match?.params?.type})
    }

    return (
      <>
        <div ref={this.useDivRef}></div>
        {isLoading && <Loader />}
        <div className="d-flex justify-content align-center">
       <Title className="page-title">Customer Detail View</Title>
      {(this.props.match.params.type=="view" &&  this.state.activeTab === "1" && this.isCheckEdit()) && <div className="text-right mb-8"><Button className="primary-btn text-right" style={{ margin: "8px 8px" }} onClick={()=>this.GotoProfileEdit()}>Edit</Button></div>}
       </div>
        {error !== undefined && error !== null && (
          <Alert type="error" className="mb-16" showIcon description={error} />
        )}
        {errorMsg !== null && (
          <Alert
            className="w-100 mb-16"
            type="error"
            description={errorMsg}
            showIcon
          />
        )}
        <Tabs activeKey={this.state.activeTab} onTabClick={this.changeTab}>
          <TabPane tab="Profile" key="1">
            {this.state.activeTab === "1" && (
              <>
                {" "}
                {customer && (
                  <Form
                    name="customer"
                    autoComplete="off"
                    initialValues={customerObj}
                    onFinish={this.saveCustomerInfo}
                    ref={this.formRef}
                  >
                    <Row className="detailview">
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={9}
                        xl={6}
                        xxl={5}
                        className="view-left"
                      >
                        <div className="text-center user">
                          <img
                            src={
                              customer.imageURL
                                ? customer.imageURL
                                : defaultUser
                            }
                            alt="User"
                            className="user-circle"
                          />
                          <div className="kpi-val text-captz fw-700 fs-18 user-name">
                            {customer.userName}
                          </div>
                          <label className="kpi-label">Customer ID</label>
                          <Paragraph className="kpi-val fw-700 mb-0" copyable>
                            {customer.depositReference
                              ? customer.depositReference
                              : "-"}
                          </Paragraph>
                        </div>

                        <div className="kpi-item">
                          <span className="icon xl email" />
                          <div>
                            <label className="kpi-label">Email</label>
                            <div
                              className="kpi-val"
                              style={{ wordBreak: "break-all" }}
                            >
                              {customer.email ? customer.email : "--"}
                            </div>
                          </div>
                        </div>
                        <div className="kpi-item">
                          <span className="icon xl phone" />
                          <div>
                            <label className="kpi-label">Phone</label>
                            <div className="kpi-val">
                              {customer.phoneNo ? customer.phoneNo : "-"}
                            </div>
                          </div>
                        </div>
                        <div className="kpi-item">
                          <span className="icon xl emailverified" />
                          <div>
                            <label className="kpi-label">Email Verified</label>
                            <div className="kpi-val">
                              {customer.emailVerified
                                ? customer.emailVerified
                                : "-"}
                            </div>
                          </div>
                        </div>
                        <div className="kpi-item">
                          <span className="icon xl phoneverified" />
                          <div>
                            <label className="kpi-label">Phone Verified</label>
                            <div className="kpi-val">
                              {customer.phoneVerified
                                ? customer.phoneVerified
                                : "-"}
                            </div>
                          </div>
                        </div>
                        <div className="kpi-item">
                          <span className="icon xl sbstafficon" />
                          <div>
                            <label className="kpi-label sb-staff">
                              SB Staff
                            </label>

                            <div className="kpi-val">
                              {customer.sbStaff ? "true" : "false"}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={15}
                        xl={18}
                        xxl={19}
                        className="view-right"
                      >
                        {customer?.accountType === "Personal" && (
                          <Row className="cust-row-design">
                            <>
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                className="item"
                              >
                                {this.state.type === "view" ? (
                                  <>
                                    <label className="kpi-label">
                                      First Name
                                    </label>
                                    <p className="kpi-val fw-700 mb-0">
                                      {customer.firstName || "-"}
                                    </p>
                                  </>
                                ) : (
                                  <Form.Item
                                    name="firstName"
                                    label="First Name"
                                    className="input-label cust-inputlabel-style"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Is required",
                                        whitespace: true
                                      },
                                      {
                                        validator: validateContentRule
                                      }
                                    ]}
                                  >
                                    <Input
                                      placeholder="First Name"
                                      className="cust-input"
                                      maxLength={50}
                                      disabled={
                                        this.state.type === "disabled" ||
                                        this.state.customerdata.status ===
                                        "Inactive"
                                      }
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                className="item"
                              >
                                {this.state.type === "view" ? (
                                  <>
                                    <label className="kpi-label">
                                      Last Name
                                    </label>
                                    <p className="kpi-val fw-700 mb-0">
                                      {customer.lastName || "-"}
                                    </p>
                                  </>
                                ) : (
                                  <Form.Item
                                    name="lastName"
                                    label="Last Name"
                                    className="input-label customer-lastname"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Is required",
                                        whitespace: true
                                      },
                                      {
                                        validator: validateContentRule
                                      }
                                    ]}
                                  >
                                    <Input
                                      placeholder="Last Name"
                                      className="cust-input"
                                      maxLength={50}
                                      disabled={
                                        this.state.type === "disabled" ||
                                        this.state.customerdata.status ===
                                        "Inactive"
                                      }
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                            </>

                            {customer?.accountType === "Personal" && (<>

                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                className={"item"}
                              >
                                {this.state.type === "view" ? (
                                  <>
                                    <label className="kpi-label">
                                      Risk Level
                                    </label>
                                    <p className=" kpi-val fw-700 mb-0">
                                      {customer.riskLevel || "-"}
                                    </p>
                                  </>
                                ) : (
                                  <Form.Item
                                    name="riskLevel"
                                    className="input-label cust-inputlabel-style"
                                    label="Risk Level"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Is required"
                                      }
                                    ]}
                                  >
                                    <Select
                                      className="cust-input"
                                      onChange={(e) => this.handleRiskLevel(e)}
                                      placeholder="Select Risk Level"
                                      disabled={
                                        this.state.type === "disabled" ||
                                        this.state.customerdata.status ===
                                        "Inactive"
                                      }
                                    >
                                      <Option value="Low Risk">Low Risk</Option>
                                      <Option value="Medium Risk">
                                        Medium Risk
                                      </Option>
                                      <Option value="High Risk">
                                        High Risk
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                )}
                              </Col>
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                className="item"
                              >
                                {this.state.type === "view" && (
                                  <label className="kpi-label">
                                    Amount Threshold
                                  </label>
                                )}
                                {this.state.type === "view" ? (
                                  <div className="kpi-val fw-700">
                                    <NumberFormat
                                      value={customer.amountThreshold || "--"}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </div>
                                ) : (
                                  <Form.Item
                                    name="amountThreshold"
                                    className="input-label cust-inputlabel-style"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Is required"
                                      },
                                      {
                                        type: "number",
                                        validator: this.numberValidator
                                      }
                                    ]}
                                    label="Amount Threshold"
                                  >
                                    <NumberFormat
                                      disabled={
                                        this.state.type === "disabled" ||
                                        this.state.customerdata.status ===
                                        "Inactive"
                                      }
                                      placeholder="Amount Threshold"
                                      className="cust-input"
                                      customInput={Input}
                                      thousandSeparator={true}
                                      prefix={""}
                                      decimalSeparator={false}
                                      allowNegative={false}
                                      style={{ width: "100%" }}
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                className="item"
                              >
                                {this.state.type === "view" && (
                                  <label className="kpi-label">
                                    Accumulated Deposit
                                  </label>
                                )}
                                {this.state.type === "view" ? (
                                  <div className="kpi-val fw-700">
                                    <NumberFormat
                                      value={customer.accumulatedDeposit || "-"}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </div>
                                ) : (
                                  <Form.Item
                                    name="accumulatedDeposit"
                                    className="input-label cust-inputlabel-style"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Is required"
                                      },
                                      {
                                        type: "number",
                                        validator: this.numberValidator
                                      }
                                    ]}
                                    label="Accumulated Deposit"
                                  >
                                    <NumberFormat
                                      disabled={
                                        this.state.type === "disabled" ||
                                        this.state.customerdata.status ===
                                        "Inactive"
                                      }
                                      className="cust-input"
                                      placeholder="Accumulated Deposit"
                                      customInput={Input}
                                      thousandSeparator={true}
                                      decimalSeparator={false}
                                      prefix={""}
                                      allowNegative={false}
                                      style={{ width: "100%" }}
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                className="item"
                              >
                                {this.state.type === "view" ? (
                                  <>
                                    <label className="kpi-label">
                                      Account Manager
                                    </label>
                                    <p className=" kpi-val fw-700 mb-0">
                                      {customer.accountManagerName || "-"}
                                    </p>
                                  </>
                                ) : (
                                  <Form.Item
                                    name="accountManagerName"
                                    label="Account Manager"
                                    className="input-label  cust-inputlabel-style"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Is required"
                                      }
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      className="cust-input"
                                      onKeyUp={(e) =>
                                        this.handleSearch(e, "AssignedTo")
                                      }
                                      onChange={(e) =>
                                        this.handleChange(e, "AssignedTo")
                                      }
                                      placeholder="Select Account Manager"
                                      optionFilterProp="children"
                                      disabled={
                                        this.state.type === "disabled" ||
                                        this.state.customerdata.status ===
                                        "Inactive"
                                      }
                                    >
                                      {assignedTo?.map((assign, idx) => (
                                        <Option key={idx} value={assign.id}>
                                          {assign.assignedTo}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                )}
                              </Col>
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                className="item"
                              >
                                <label className="kpi-label">
                                  {customer.accountType === "Personal"
                                    ? "Country of Residence"
                                    : "Country of Business"}
                                </label>
                                <p className="kpi-val fw-700 mb-0">
                                  {customer.country ? customer.country : "-"}
                                </p>
                              </Col>
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                className="item"
                              >
                                <label className="kpi-label">Reg Date</label>
                                <p className=" kpi-val fw-700 mb-0">
                                  {moment(customer?.createdDate).format(
                                    config?.dateFormates?.dateFormate
                                  )}
                                </p>
                              </Col>
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                className="item"
                              >
                                <label className="kpi-label">State</label>
                                <p className=" kpi-val fw-700 mb-0">
                                  {customer?.customerState
                                    ? customer?.customerState
                                    : "-"}
                                </p>
                              </Col>
                              {(customer?.customerState === "Rejected" ||
                                customer?.customerState === "Closed") && (
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={8}
                                    xxl={8}
                                    className="item"
                                  >
                                    <label className="kpi-label">
                                      {customer?.customerState === "Rejected"
                                        ? "Reason For Rejection"
                                        : "Reason For Close"}
                                    </label>
                                    <p className=" kpi-val fw-700 mb-0">
                                      {customer?.customerState
                                        ? customer?.customerRemarks
                                        : "-"}
                                    </p>
                                  </Col>
                                )}
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                className="item"
                              >
                                <label className="kpi-label">Account Type</label>
                                <p className=" kpi-val fw-700 mb-0">
                                  {customer.accountType
                                    ? customer.accountType
                                    : "-"}
                                </p>
                              </Col>
                            </>)}
                            {customer?.accountType === "Personal" && (
                              <>
                                {" "}
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={8}
                                  xxl={8}
                                  className="item risk-compliance"
                                >
                                  <label className="kpi-label">
                                    KYC Compliance
                                  </label>
                                  <p className="kpi-val  fw-700  mb-0">
                                    {customer.kycCompleted
                                      ? customer.kycCompleted
                                      : "-"}
                                  </p>
                                </Col>
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={8}
                                  xxl={8}
                                  className="item"
                                >
                                  <label className="kpi-label">
                                    Vault Account ID
                                  </label>
                                  {customer.vaultAccountId ? (
                                    <Paragraph
                                      className="kpi-val fw-700 mb-0"
                                      copyable
                                    >
                                      {customer.vaultAccountId || "-"}
                                    </Paragraph>
                                  ) : (
                                    <Paragraph className="kpi-val fw-700 mb-0">
                                      {"-"}
                                    </Paragraph>
                                  )}
                                </Col>
                              </>)}
                          </Row>
                        )}
                        {customer?.accountType === "Personal" && (
                          <Row className="cust-row-design">

                            <>
                              {this.state.type === "view" &&
                                  customer?.caseIds !== null && (
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                className="item"
                              >
                                
                                    <>
                                      <label className="kpi-label d-block">
                                        Case Number
                                      </label>
                                      <span className="fw-600 fs-14">
                                        {customer?.caseIds?.map((item) => (
                                          <Link className="c-pointer"
                                            onClick={() =>
                                              this.redirectCaseView(item)
                                            }
                                          >
                                            {item.caseNumber}
                                            <br />
                                          </Link>
                                        ))}
                                      </span>
                                    </>
                                 
                              </Col>
                               )}
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                className="item"
                              >
                                {this.state.type === "view" && (
                                  <label className="kpi-label">Egnyte</label>
                                )}
                                {this.state.type === "view" ? (
                                  <Paragraph
                                    className="kpi-val fw-700 mb-0"
                                    copyable={customer.egnyte}
                                  >
                                    {customer.egnyte || "-"}
                                  </Paragraph>
                                ) : (
                                  this.getEgnyte()
                                )}
                              </Col>
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                className="item"
                              >
                                {this.state.type === "view" && (<>
                                  <label className="kpi-label">KYC/KYB</label><br /></>
                                )}
                                {this.state.type === "view" ? (
                                  <>
                                   {customer.sumSub &&<CopyToClipboard text={customer.sumSub} style={{ "cursor": "pointer", "align-items": "center"}}>
                                      <Paragraph Text copyable={{ tooltips: ["copy", "copied"] }} className="sumsub-url" >
                                        <Link onClick={() => window.open(`${customer.sumSub}`, '_blank')}>{customer.sumSub}</Link></Paragraph>
                                    </CopyToClipboard> || <p className=" kpi-val fw-700 mb-0">-</p>}
                                  </>
                                ) : (
                                  <Form.Item
                                    name="sumSub"
                                    label="KYC/KYB"
                                    className="input-label cust-inputlabel-style"
                                    rules={[
                                      {
                                        required: false,
                                        validator: this.validateSumsubUrl
                                      }
                                    ]}
                                  >
                                    <Input
                                      placeholder="KYC/KYB"
                                      className="cust-input"
                                      maxLength={250}
                                      disabled={
                                        this.state.type === "disabled" ||
                                        this.state.customerdata.status ===
                                        "Inactive"
                                      }
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                className="item"
                              >
                                {this.state.type === "view" ? (
                                  <>
                                    <label className="kpi-label">
                                      Commission Template
                                    </label>
                                    <p className=" kpi-val fw-700 mb-0">
                                      {customer.commissionTemplate || "-"}
                                    </p>
                                  </>
                                ) : (
                                  <Form.Item
                                    name="commissionTemplate"
                                    className="input-label cust-inputlabel-style"
                                    label="Commission Template"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Is required"
                                      }
                                    ]}
                                  >
                                    <Select
                                      className="cust-input"
                                      onChange={(e) => this.handleBanks(e)}
                                      placeholder="Select Commission Template"
                                      disabled={
                                        this.state.type === "disabled" ||
                                        this.state.customerdata.status ===
                                        "Inactive"
                                      }
                                    >
                                      {this.state.commissionsLu.map((item) => <Option value={item.templateName}>{item.templateName}</Option>)}

                                    </Select>
                                  </Form.Item>
                                )}
                              </Col>
                              <Col
                                 xs={24}
                                 sm={24}
                                 md={24}
                                 lg={24}
                                 xl={8}
                                 xxl={8}
                                 className="item"
                              >
                                  <>
                                    <label className="kpi-label">
                                     World Check
                                    </label>
                                    <p className=" kpi-val fw-700 mb-0">
                                    
                                    <Link onClick={() => window.open(`${process.env.REACT_APP_RISK_URL}`, '_blank')}>Link</Link>
                                    </p>
                                  </>                               
                              </Col>
                              <Col
                                 xs={24}
                                 sm={24}
                                 md={24}
                                 lg={24}
                                 xl={8}
                                 xxl={8}
                                 className="item"
                              >
                                  <>
                                    <label className="kpi-label">
                                  LEI Search
                                    </label>
                                    <p className=" kpi-val fw-700 mb-0">
                                    
                                    <Link onClick={() => window.open(`${process.env.REACT_APP_LIE_SEARCH}`, '_blank')}>Link</Link>
                                    </p>
                                  </>                               
                              </Col>
                            </>
                          </Row>
                        )}
                        {customer?.accountType === "Personal" && (<Row className="cust-row-design">
                          {customer?.accountType === "Personal" && (
                            <>
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                className="item available-banks"
                              >
                                {this.state.type === "view" ? (
                                  <>
                                    <label className="kpi-label">
                                      Available Banks
                                    </label>


                                    <p className=" kpi-val fw-700 mb-0">
                                      {this.state.defaultBanks?.toString() || "-"}
                                    </p>
                                  </>
                                ) : (
                                  <Form.Item
                                    name="availableBanks"
                                    className="input-label cust-inputlabel-style"
                                    label={<label> Available Banks</label>}
                                    required
                                  >
                                    {customerdata && <Select
                                      className="cust-input"
                                      placeholder="Available Banks"
                                      disabled
                                      defaultValue={this.state.defaultBanks} mode="multiple"
                                      value={this.state.defaultBanks}>

                                    </Select>}

                                    {!(customer?.customerState === "Rejected"
                                      || customer?.customerState === "Closed") &&
                                      <span onClick={() => this.openBankspopUp()} className="icon md edit banks-edit"></span>
                                    }
                                  </Form.Item>

                                )}
                              </Col>
                              {this.state.type === "view" ? (
                                <>
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={8}
                                    xxl={8}
                                    className="item"
                                  >
                                    <label className="kpi-label">
                                      SuisseBase Credits
                                    </label>
                                    <p className=" kpi-val fw-700 mb-0">
                                      {customer.credit?.toLocaleString() || "-"}
                                    </p>
                                  </Col>
                                </>
                              ) : (<Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                className="item"
                              >

                                <Form.Item
                                  name="credit"
                                  label="SuisseBase Credits"
                                  className="input-label cust-inputlabel-style"
                                >
                                 <Input
                                      placeholder="SuisseBase Credits"
                                      className="cust-input"
                                      maxLength={250}
                                      disabled 
                                      defaultValue={this.state.credit?.toLocaleString()} value={this.state.credit?.toLocaleString()}/>
                                      {!(customer?.customerState ==="Rejected"
                                    || customer?.customerState ==="Closed") &&
                                    <span onClick={this.handleSuisseBaseCreditModal} className="icon md edit banks-edit"></span>}
                                </Form.Item>
                                {this.state.suisseBaseCreditvisible && <SuisseBaseCredits showModal={this.state.suisseBaseCreditvisible} handleModalCloseCreidit={(amnt) => this.handleSuissecreditModalClose(amnt)} isShowLogs={true} customerId={this.props.match.params.id} availableBlc={this.state.credit} />}
                              </Col>)}
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                className="item"
                              >
                                <div>
                                  {this.state.type === "view" && (
                                    <>
                                      
                                      <label className="kpi-label">
                                        Referral Party
                                      </label>
                                      <p className="kpi-val fw-700 mb-0">
                                        {customer.partnerReferralCode
                                          ? customer.partnerReferralCode
                                          : "-"}
                                      </p>
                                    </>
                                  )}
                                </div>
                                <div className="p-relative">
                                  {this.state.type !== "view" && (
                                    <>
                                      {this.getRefferalData()}
                                      {this.state.referralError && <span className="referral-error"> {this.state.referralError}</span>}
                                      <span
                                        className="referralparty-icon"
                                      >
                                        {" "}
                                        {this.state.popupSymbole === true ? (
                                          <span className="icon md greenCheck" />
                                        ) : (
                                          " "
                                        )}
                                        {this.state.crossCheckIcon === true && (
                                          <span className="icon md redclose" />
                                        )}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </Col>
                            </>)}
                          {customer?.accountType === "Personal" && (<>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item risk-compliance"
                            >
                              <label className="kpi-label">
                                Referral Name
                              </label>
                              <p className="kpi-val  fw-700  mb-0">
                              {this.state.popupSymbole === true ? <> {this.state.refferalName}{" "}</> : "-"}
                              </p>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              className="item risk-compliance"
                            >
                              {this.state.type === "view" && (<>
                                <label className="kpi-label">
                                 Remarks
                                </label>
                                <p className="kpi-val  fw-700  mb-0 text-word-wrap">
                                  {customer.sourceOfClient
                                    ? customer.sourceOfClient
                                    : "-"}
                                </p>
                              </>)}
                              {this.state.type !== "view" && (<>
                                <Form.Item
                                  name="sourceOfClient"
                                  className="input-label cust-inputlabel-style"
                                  label={<label>Remarks</label>}

                                  rules={[ {
                                    validator: this.validateMinLength
                                  },
                                  ]}
                                >
                                  <TextArea
                                    placeholder="Remarks"
                                    className="input-label cust-inputlabel-style"
                                    rows={4}
                                    disabled={this.state.type === "disabled" || (customer?.state === "Approved" || customer?.state === "Rejected") || customer.isPersonal}
                                    maxLength={2000}
                                    showCount
                                  />
                                </Form.Item>
                              </>)}


                            </Col>
                          </>)}
                        </Row>)}
                        {customer?.accountType === "Business" && (<Row className="cust-row-design">
                          <>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">
                                Business Name
                              </label>
                              <p className=" kpi-val fw-700 mb-0">
                                {customer.businessName
                                  ? customer.businessName
                                  : "-"}
                              </p>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">
                                {customer.accountType === "Personal"
                                  ? "Country of Residence"
                                  : "Country of Business"}
                              </label>
                              <p className="kpi-val fw-700 mb-0">
                                {customer.country ? customer.country : "-"}
                              </p>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">Reg Date</label>
                              <p className=" kpi-val fw-700 mb-0">
                                {moment(customer?.createdDate).format(
                                 config?.dateFormates?.dateFormate
                                )}
                              </p>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">State</label>
                              <p className=" kpi-val fw-700 mb-0">
                                {customer?.customerState
                                  ? customer?.customerState
                                  : "-"}
                              </p>
                            </Col>
                            {(customer?.customerState === "Rejected" ||
                              customer?.customerState === "Closed") && (
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={8}
                                  xxl={8}
                                  className="item"
                                >
                                  <label className="kpi-label">
                                    {customer?.customerState === "Rejected"
                                      ? "Reason For Rejection"
                                      : "Reason For Close"}
                                  </label>
                                  <p className=" kpi-val fw-700 mb-0">
                                    {customer?.customerState
                                      ? customer?.customerRemarks
                                      : "-"}
                                  </p>
                                </Col>
                              )}

                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">
                                KYB Compliance
                              </label>
                              <p className="kpi-val  fw-700  mb-0">
                                {customer.kybCompleted
                                  ? customer.kybCompleted
                                  : "-"}
                              </p>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">
                                Vault Account ID
                              </label>
                              {customer.vaultAccountId ? (
                                <Paragraph
                                  className="kpi-val fw-700 mb-0"
                                  copyable
                                >
                                  {customer.vaultAccountId || "-"}
                                </Paragraph>
                              ) : (
                                <Paragraph className="kpi-val fw-700 mb-0">
                                  {"-"}
                                </Paragraph>
                              )}
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              {this.state.type === "view" ? (
                                <>
                                  <label className="kpi-label">
                                    Risk Level
                                  </label>
                                  <p className=" kpi-val fw-700 mb-0">
                                    {customer.riskLevel || "-"}
                                  </p>
                                </>
                              ) : (
                                <Form.Item
                                  name="riskLevel"
                                  className="input-label cust-inputlabel-style"
                                  label="Risk Level"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Is required"
                                    }
                                  ]}
                                >
                                  <Select
                                    className="cust-input"
                                    onChange={(e) => this.handleRiskLevel(e)}
                                    placeholder="Select Risk Level"
                                    disabled={
                                      this.state.type === "disabled" ||
                                      this.state.customerdata.status ===
                                      "Inactive"
                                    }
                                  >
                                    <Option value="Low Risk">Low Risk</Option>
                                    <Option value="Medium Risk">
                                      Medium Risk
                                    </Option>
                                    <Option value="High Risk">
                                      High Risk
                                    </Option>
                                  </Select>
                                </Form.Item>
                              )}
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              {this.state.type === "view" && (
                                <label className="kpi-label">
                                  Amount Threshold
                                </label>
                              )}
                              {this.state.type === "view" ? (
                                <div className="kpi-val fw-700">
                                  <NumberFormat
                                    value={customer.amountThreshold || "-"}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                </div>
                              ) : (
                                <Form.Item
                                  name="amountThreshold"
                                  className="input-label cust-inputlabel-style"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Is required"
                                    },
                                    {
                                      type: "number",
                                      validator: this.numberValidator
                                    }
                                  ]}
                                  label="Amount Threshold"
                                >
                                  <NumberFormat
                                    disabled={
                                      this.state.type === "disabled" ||
                                      this.state.customerdata.status ===
                                      "Inactive"
                                    }
                                    className="cust-input"
                                    placeholder="Amount Threshold"
                                    customInput={Input}
                                    thousandSeparator={true}
                                    prefix={""}
                                    decimalSeparator={false}
                                    style={{ width: "100%" }}
                                    allowNegative={false}
                                  />
                                </Form.Item>
                              )}
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              {this.state.type === "view" && (
                                <label className="kpi-label">
                                  Accumulated Deposit
                                </label>
                              )}
                              {this.state.type === "view" ? (
                                <div className="kpi-val fw-700">
                                  <NumberFormat
                                    value={
                                      customer.accumulatedDeposit !== null
                                        && customer.accumulatedDeposit
                                        || "-"
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                </div>
                              ) : (
                                <Form.Item
                                  name="accumulatedDeposit"
                                  className="input-label cust-inputlabel-style"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Is required"
                                    },
                                    {
                                      type: "number",
                                      validator: this.numberValidator
                                    }
                                  ]}
                                  label="Accumulated Deposit"
                                >
                                  <NumberFormat
                                    disabled={
                                      this.state.type === "disabled" ||
                                      this.state.customerdata.status ===
                                      "Inactive"
                                    }
                                    className="cust-input"
                                    placeholder="Accumulated Deposit"
                                    customInput={Input}
                                    thousandSeparator={true}
                                    prefix={""}
                                    decimalSeparator={false}
                                    style={{ width: "100%" }}
                                    allowNegative={false}
                                  />
                                </Form.Item>
                              )}
                            </Col>

                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              {this.state.type === "view" ? (
                                <>
                                  <label className="kpi-label">
                                    Account Manager
                                  </label>
                                  <p className=" kpi-val fw-700 mb-0">
                                    {customer.accountManagerName || "-"}
                                  </p>
                                </>
                              ) : (
                                <Form.Item
                                  name="accountManagerName"
                                  label="Account Manager"
                                  className="input-label cust-inputlabel-style"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Is required"
                                    }
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    className="cust-input"
                                    onKeyUp={(e) =>
                                      this.handleSearch(e, "AssignedTo")
                                    }
                                    onChange={(e) =>
                                      this.handleChange(e, "AssignedTo")
                                    }
                                    placeholder="Select Account Manager"
                                    optionFilterProp="children"
                                    disabled={
                                      this.state.type === "disabled" ||
                                      this.state.customerdata.status ===
                                      "Inactive"
                                    }
                                  >
                                    {assignedTo?.map((assign, idx) => (
                                      <Option key={idx} value={assign.id}>
                                        {assign.assignedTo}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              )}
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">Account Type</label>
                              <p className=" kpi-val fw-700 mb-0">
                                {customer.accountType
                                  ? customer.accountType
                                  : "-"}
                              </p>
                            </Col></>
                        </Row>)}
                        {customer?.accountType === "Business" && (<Row className="cust-row-design">
                          {this.state.type === "view" &&
                            customer.caseIds !== null && (
                              <>
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={8}
                                  xxl={8}
                                  className="item"
                                >
                                  {this.state.type === "view" &&
                                    customer?.caseIds !== null && (
                                      <>
                                        <label className="kpi-label d-block">
                                          Case Number
                                        </label>
                                        <span className="fw-600 fs-14">
                                          {customer?.caseIds?.map(
                                            (item) => (
                                              <Link className="c-pointer"
                                                onClick={() =>
                                                  this.redirectCaseView(
                                                    item
                                                  )
                                                }
                                              >
                                                {item.caseNumber}
                                                <br />
                                              </Link>
                                            )
                                          )}
                                        </span>
                                      </>
                                    )}
                                </Col>
                              </>
                            )}
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={8}
                            xxl={8}
                            className="item"
                          >
                            {this.state.type === "view" && (
                              <label className="kpi-label">Egnyte</label>
                            )}
                            {this.state.type === "view" && (
                              <Paragraph
                                className="kpi-val fw-700 mb-0"
                                copyable={customer.egnyte}
                              >
                                {customer.egnyte || "-"}
                              </Paragraph>
                            ) || (
                              this.getEgnyte()
                            )}
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={8}
                            xxl={8}
                            className="item"
                          >
                            {this.state.type === "view" && (<>
                              <label className="kpi-label">KYC/KYB</label><br /></>
                            )}
                            {this.state.type === "view" ? (
                              <>
                               {customer.sumSub && <CopyToClipboard text={customer.sumSub} style={{ "cursor": "pointer", "align-items": "center" }}>
                                  <Paragraph Text copyable={{ tooltips: ["copy", "copied"] }} className="sumsub-url">
                                    <Link onClick={() => window.open(`${customer.sumSub}`, '_blank')}>{customer.sumSub}</Link></Paragraph>
                                </CopyToClipboard> || <p className=" kpi-val fw-700 mb-0">-</p>}
                              </>
                            ) : (
                              <Form.Item
                                name="sumSub"
                                label="KYC/KYB"
                                className="input-label mb-0"
                                rules={[
                                  {
                                    required: false,
                                    validator: this.validateSumsubUrl
                                  }
                                ]}
                              >
                                <Input
                                  placeholder="KYC/KYB"
                                  className="cust-input"
                                  maxLength={250}
                                  disabled={
                                    this.state.type === "disabled" ||
                                    this.state.customerdata.status ===
                                    "Inactive"
                                  }
                                />
                              </Form.Item>
                            )}
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={8}
                            xxl={8}
                            className="item"
                          >
                            {this.state.type === "view" ? (
                              <>
                                <label className="kpi-label">
                                  Commission Template
                                </label>
                                <p className=" kpi-val fw-700 mb-0">
                                  {customer.commissionTemplate || "-"}
                                </p>
                              </>
                            ) : (
                              <Form.Item
                                name="commissionTemplate"
                                className="input-label cust-inputlabel-style"
                                label="Commission Template"
                                rules={[
                                  {
                                    required: true,
                                    message: "Is required"
                                  }
                                ]}
                              >
                                <Select
                                  className="cust-input"
                                  onChange={(e) => this.handleBanks(e)}
                                  placeholder="Select Commission Template"
                                  disabled={
                                    this.state.type === "disabled" ||
                                    this.state.customerdata.status ===
                                    "Inactive"
                                  }
                                >
                                  {this.state.commissionsLu.map((item) => <Option value={item.templateName}>{item.templateName}</Option>)}

                                </Select>
                              </Form.Item>
                            )}
                          </Col>
                          <Col
                                 xs={24}
                                 sm={24}
                                 md={24}
                                 lg={24}
                                 xl={8}
                                 xxl={8}
                                 className="item"
                              >
                                  <>
                                    <label className="kpi-label">
                                     World Check
                                    </label>
                                    <p className=" kpi-val fw-700 mb-0">
                                    
                                    <Link onClick={() => window.open(`${process.env.REACT_APP_RISK_URL}`, '_blank')}>Link</Link>
                                    </p>
                                  </>                               
                              </Col>
                              <Col
                                 xs={24}
                                 sm={24}
                                 md={24}
                                 lg={24}
                                 xl={8}
                                 xxl={8}
                                 className="item"
                              >
                                  <>
                                    <label className="kpi-label">
                                  LEI Search
                                    </label>
                                    <p className=" kpi-val fw-700 mb-0">
                                    
                                    <Link onClick={() => window.open(`${process.env.REACT_APP_LIE_SEARCH}`, '_blank')}>Link</Link>
                                    </p>
                                  </>                               
                              </Col>
                        </Row>)}
                        {customer?.accountType === "Business" && (
                          <Row className="cust-row-design">
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              className="item available-banks"
                            >
                              {this.state.type === "view" ? (
                                <>
                                  <label className="kpi-label">
                                    Available Banks
                                  </label>


                                  <p className=" kpi-val fw-700 mb-0">
                                    {this.state.defaultBanks?.toString() || "-"}
                                  </p>
                                </>
                              ) : (
                                <Form.Item
                                  name="availableBanks"
                                  className="input-label cust-inputlabel-style"
                                  label={<label> Available Banks</label>}
                                  required
                                >
                                  {customerdata && <Select
                                    className="cust-input"
                                    placeholder="Available Banks"
                                    disabled
                                    defaultValue={this.state.defaultBanks} mode="multiple"
                                    value={this.state.defaultBanks}>

                                  </Select>}
                                  {!(customerdata?.customerState === "Rejected"
                                    || customerdata?.customerState === "Closed") &&
                                    <span onClick={() => this.openBankspopUp()} className="icon md edit banks-edit"></span>
                                  }
                                </Form.Item>

                              )}
                            </Col>
                            {this.state.type === "view" ? (
                              <>
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={8}
                                  xxl={8}
                                  className="item"
                                >
                                  <label className="kpi-label">
                                    SuisseBase Credits
                                  </label>
                                  <p className=" kpi-val fw-700 mb-0">
                                    {customer.credit?.toLocaleString() || "-"}
                                  </p>
                                </Col>
                              </>
                            ) : (<Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >

                              <Form.Item
                                name="credit"
                                label="SuisseBase Credits"
                                className="input-label cust-inputlabel-style"
                              >
                                <Input
                                      placeholder="SuisseBase Credits"
                                      className="cust-input"
                                      maxLength={250}
                                      disabled 
                                      defaultValue={this.state.credit?.toLocaleString()} value={this.state.credit?.toLocaleString()}/>
                                      {!(customer?.customerState ==="Rejected"
                                    || customer?.customerState ==="Closed") &&
                                    <span onClick={this.handleSuisseBaseCreditModal} className="icon md edit banks-edit"></span>}
                              </Form.Item>
                              {this.state.suisseBaseCreditvisible && <SuisseBaseCredits showModal={this.state.suisseBaseCreditvisible} handleModalCloseCreidit={(amnt) => this.handleSuissecreditModalClose(amnt)} isShowLogs={true} customerId={this.props.match.params.id} availableBlc={this.state.credit} />}
                            </Col>)}
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <div className="p-relative">
                                {this.state.type === "view" ? (
                                  <>
                                    <label className="kpi-label">
                                      Referral Party
                                    </label>
                                    <p className="kpi-val fw-700 mb-0">
                                      {customer.partnerReferralCode || "-"}
                                    </p>
                                  </>
                                ) : (
                                  <>
                                  {this.getRefferalData()}
                                  {this.state.referralError && <span className="referral-error"> {this.state.referralError}</span>}
                                  </>
                                )}
                              
                              {this.state.type !== "view" && <span
                                className="referralparty-icon"
                                
                              >
                                {" "}
                                {this.state.popupSymbole === true ? (
                                  <span className="icon md greenCheck" />
                                ) : (
                                  ""
                                )}
                                {this.state.crossCheckIcon === true && (
                                  <span className="icon md redclose" />
                                )}
                                {this.state.crossCheckIcon === false && (
                                  <span />
                                )}
                              </span>
                              }</div>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item risk-compliance"
                            >
                              <label className="kpi-label">
                                Referral Name
                              </label>
                              <p className="kpi-val  fw-700  mb-0">
                                {this.state.popupSymbole ? <> {this.state.refferalName}{" "}</> : "-"}
                              </p>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              className="item risk-compliance"
                            >
                              {this.state.type === "view" && (<>
                                <label className="kpi-label">
                                Remarks
                                </label>
                                <p className="kpi-val  fw-700  mb-0 text-word-wrap">
                                  {customer.sourceOfClient
                                    ? customer.sourceOfClient
                                    : "-"}
                                </p>
                              </>)}
                              {this.state.type !== "view" && (<>
                                <Form.Item
                                  name="sourceOfClient"

                                  className="input-label cust-inputlabel-style"
                                  label={<label>Remarks</label>}
                                  rules={
                                    [
                                     {
                                      validator:this.validateMinLength
                                     }
                                      ]}
                                >
                                  <TextArea
                                    placeholder="Remarks"
                                    className="input-label cust-inputlabel-style"
                                    rows={4}
                                    disabled={this.state.type === "disabled" || (customer?.state === "Approved" || customer?.state === "Rejected") || customer.isPersonal}
                                    maxLength={2000}
                                    showCount
                                  />
                                </Form.Item>
                              </>)}
                            </Col>
                          </Row>)}
                      </Col>
                    </Row>
                    <Info
                      Id={customerdata.id}
                      Status={customerdata.status}
                      Createdby={customerdata?.userName}
                      CreatedDate={customerdata.createdDate}
                      ModifiedDate={customerdata?.modifiedDate}
                      Modifiedby={customerdata?.modifiedBy}
                      isShowInfo={false}
                      screenName={"Customers"}
                    />
                    {this.state.type !== "view" &&
                      this.state.type !== "disabled" &&
                      customerdata.status !== "Inactive" && (
                        <div className="text-right mt-16">
                          <Button
                            loading={this.state.isLoading}
                            type="primary"
                            className="primary-btn"
                            htmlType="submit"
                          >
                            Save
                          </Button>
                          <Button
                            type="primary"
                            className="primary-btn cancel-btn"
                            style={{ margin: "0 8px" }}
                            onClick={() =>
                              this.props.history.push("/customers")
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      )}
                  </Form>
                )}{" "}
              </>
            )}
          </TabPane>
          <TabPane tab="Wallets" key="2">
            {this.state.activeTab === "2" && (
              <>
                &emsp;&emsp;
                <List
                  style={{ margin: "-22px 0 0" }}
                  url={gridUrlWalletsK}
                  ref={this.gridRef}
                  ignoreSaved={true}
                  additionalParams={{ customerid: this.props.match.params.id }}
                  columns={this.gridWallets}
                  className="customerwallets-grid"
                />
              </>
            )}
          </TabPane>
          <TabPane tab="Transactions" key="3">
            &emsp;&emsp;
            {this.state.activeTab === "3" && (
              <>
                <List
                  ignoreSaved={true}
                  style={{ margin: "-22px 0 0" }}
                  url={gridUrl}
                  ref={this.gridRef}
                  additionalParams={{ customerid: this.props.match.params.id }}
                  columns={this.gridColumns}
                />
              </>
            )}
          </TabPane>
          <TabPane tab="Partner" key="4">
            &emsp;&emsp;
            {this.state.activeTab === "4" && (
              <>
                <Collapse
                  style={{ margin: "-22px 0 0" }}
                  className="trans-collapse"
                  onChange={() => this.loadTrans()}
                  expandIcon={() => <span className="icon md downangle" />}
                >
                  <Panel
                    header="Partner Bonus Transactions"
                    key="1"
                    className="fs-16 fw-500"
                  >
                    <List
                      ignoreSaved={true}
                      showActionBar={true}
                      pKey={"bonus"}
                      ref={this.gridRef}
                      columns={this.bonusGridColumns}
                      additionalParams={{ customerid: this.props.match.params.id }}
                      url={
                        process.env.REACT_APP_GRID_API +
                        `Partner/GetBonusessK/Customer`
                      }
                      showExcelExport={true}
                    />
                  </Panel>
                </Collapse>
                <Title></Title>
                <Collapse
                  className="trans-collapse"
                  onChange={() => this.loadTrans()}
                  expandIcon={() => <span className="icon md downangle" />}
                >
                  <Panel
                    header="Partner Referral Transactions"
                    key="1"
                    className="fs-16 fw-500"
                  >
                    <List
                      ignoreSaved={true}
                      showActionBar={true}
                      pKey={"bonus"}
                      ref={this.gridRef}
                      columns={this.ReferralGridColumns}
                      additionalParams={{
                        customerid: this.props.match.params.id
                      }}
                      url={
                        process.env.REACT_APP_GRID_API +
                        `Partner/GetRefferalCodessK`
                      }
                      showExcelExport={true}
                    />
                  </Panel>
                </Collapse>
              </>
            )}
          </TabPane>
          <TabPane tab="Screening" key="6">
            {this.state.activeTab === "6" && (
              <>
                <Spin spinning={this.state.riskloader}>
                  <Form autoComplete="off">
                    <>
                      {this.state.type !== "view" && (
                        <div className="text-right mb-8">
                          <Button
                            type="primary"
                            className="primary-btn text-right"
                            style={{ margin: "8px 8px" }}
                            onClick={this.RiskScreenPage}
                          >
                            Edit
                          </Button>
                        </div>
                      )}
                      <Row gutter={8}>
                        <Col xl={24} xxl={24} className="bank-view">
                          <Row className="company-List">
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">Name</label><br />

                              <a onClick={this.RiskScreenPage} className="kpi-val fw-700 mb-0 c-pointer">
                                {this.state.uploadData2.customerOrCompanyName
                                  ? this.state.uploadData2.customerOrCompanyName
                                  : "--"}
                              </a>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">AVM </label>
                              <Paragraph className="kpi-val fw-700 mb-0">
                                {this.state.uploadData2.isAVM
                                  ? this.state.uploadData2.isAVM
                                  : "--"}
                              </Paragraph>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">OTH </label>
                              <Paragraph className="kpi-val fw-700 mb-0">
                                {this.state.uploadData2.isOTH
                                  ? this.state.uploadData2.isOTH
                                  : "--"}
                              </Paragraph>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">PEP</label>
                              <Paragraph className="kpi-val fw-700 mb-0">
                                {this.state.uploadData2.isPEP
                                  ? this.state.uploadData2.isPEP
                                  : "--"}
                              </Paragraph>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">RCA </label>
                              <Paragraph className="kpi-val fw-700 mb-0">
                                {this.state.uploadData2.isRCA
                                  ? this.state.uploadData2.isRCA
                                  : "--"}
                              </Paragraph>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">SAN </label>
                              <Paragraph className="kpi-val fw-700 mb-0">
                                {this.state.uploadData2.isSAN
                                  ? this.state.uploadData2.isSAN
                                  : "--"}
                              </Paragraph>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">UNK </label>
                              <Paragraph className="kpi-val fw-700 mb-0">
                                {this.state.uploadData2.isUNK
                                  ? this.state.uploadData2.isUNK
                                  : "--"}
                              </Paragraph>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">WBL </label>
                              <Paragraph className="kpi-val fw-700 mb-0">
                                {this.state.uploadData2.isWBL
                                  ? this.state.uploadData2.isWBL
                                  : "--"}
                              </Paragraph>
                            </Col>

                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={8}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">Note</label>
                              <Paragraph className="kpi-val fw-700 mb-0">
                                {this.state.uploadData.description
                                  ? this.state.uploadData.description
                                  : "--"}
                              </Paragraph>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              className="item"
                            >
                              <label className="kpi-label">Attachment</label>
                              {this.state.uploadData?.files !== null && (
                                <Row>
                                  {this.state.uploadData?.files?.map((item) => (
                                    <Col
                                      xs={24}
                                      sm={24}
                                      md={24}
                                      lg={12}
                                      xl={8}
                                      xxl={8}
                                      className="item risk-uploads-col"
                                    >

                                      <div
                                        className="docfile mt-4 d-flex align-center risk-uploads"
                                        key={item?.documentId}

                                      >
                                        <span
                                          className={`icon xl ${((item?.fileName?.slice(-3) === "pdf" ||
                                              item?.fileName?.slice(-3) ===
                                              "PDF") &&
                                              "file")
                                            } mr-16`}
                                        />
                                        <div
                                          className="docdetails c-pointer"
                                          onClick={() =>
                                            this.setState({
                                              ...this.state,
                                              previewDocDetails: {
                                                id: item?.documentId,
                                                fileName: item?.fileName
                                              },
                                              previewModal: true
                                            })
                                          }
                                        >
                                          {item?.fileName !== null ? (
                                            <EllipsisMiddle suffixCount={6}>
                                              {item?.fileName}
                                            </EllipsisMiddle>
                                          ) : (
                                            <EllipsisMiddle suffixCount={6}>
                                              Name
                                            </EllipsisMiddle>
                                          )}
                                          <span className="fs-12 text-secondary">
                                            {item?.fileSize
                                              ? bytesToSize(item?.fileSize)
                                              : ""}
                                          </span>
                                        </div>
                                      </div>



                                    </Col>
                                  ))}
                                </Row>)}
                              {this.state.uploadData?.files == null &&
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={12}
                                  xl={8}
                                  xxl={8}
                                  className="item risk-uploads-col"
                                >

                                  <Paragraph className="kpi-val fw-700 mb-0">
                                    {"--"}
                                  </Paragraph>
                                </Col>
                              }
                              
                              
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                    <Info
                      Id={this.state.uploadData?.id}
                      Createdby={this.state.uploadData?.createdby}
                      CreatedDate={this.state.uploadData?.createdDate}
                      ModifiedDate={this.state.uploadData?.modifieddate}
                      Modifiedby={this.state.uploadData?.modifiedby}
                      infoDetails={this.state.infoDetails}
                    />
                  </Form>
                </Spin>
              </>
            )}
          </TabPane>
          <TabPane tab="Backup Address" key="9">
            {this.state.activeTab === "9" && (
             <BackupAddress id={this.props.match.params.id}/>
            )}
          </TabPane>
          <TabPane tab="Address Book" key="8">
            {this.state.activeTab === "8" && (
              <CustomerAddressBook params={this.props} activeTab={this.state.activeTab} gridID={this.props.match.params.id} />
            )}
          </TabPane>
          <TabPane tab="Fees" key="7">
            {this.state.activeTab === "7" && (
              <CustomerBatchFee params={this.props} activeTab={this.state.activeTab} />
            )}
          </TabPane>
          <TabPane tab={customer?.isBusiness ? "KYB":"KYC"} key="10">
            {this.state.activeTab === "10" && (
              customer?.isBusiness ? <KYB customerId={this.props.match.params.id} activeTab={this.state.activeTab}/> : <KYC customerId={this.props.match.params.id} activeTab={this.state.activeTab}/>
            )}
          </TabPane>
          {/* <TabPane tab="Fees" key="7">
            {this.state.activeTab === "7" && (
              <CustomerBatchFee params={this.props} activeTab={this.state.activeTab}/>
            )}
          </TabPane> */}
        </Tabs>

        {customer?.isBusiness &&
          process.env.REACT_APP_ISSHOWCARDS === "true" && (
            <TabPane tab="Card" key="5">
              <React.Fragment>
                <Title className="page-title mt-36">Card Status</Title>
                <Collapse
                  className="trans-collapse"
                  expandIcon={() => <span className="icon md downangle" />}
                >
                  <Panel header="Card Status" key="1" className="fs-16 fw-500">
                    <Row>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={7}
                        xxl={8}
                        className="item"
                      >
                        <label className="kpi-label fs-14">Card Status</label>
                        <Paragraph className="kpi-val fs-14 fw-700 mb-0">
                          {customer.cardStatus ? customer.cardStatus : "--"}
                        </Paragraph>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={7}
                        xxl={8}
                        className="item"
                      >
                        <label className="kpi-label fs-14">Applied On</label>
                        <Paragraph className="kpi-val fw-700 mb-0 fs-14">
                          {customer.cardAppliedOn
                            ? moment(
                                apiCalls.convertUTCToLocalTime(
                                  customer.cardAppliedOn
                                )
                              ).format(config?.dateFormates?.dateFormate)
                            : "--"}
                        </Paragraph>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={7}
                        xxl={8}
                        className="item"
                      >
                        <label className="kpi-label fs-14">Card URL</label>
                        <Paragraph className="kpi-val fs-14 fw-700 mb-0">
                          {customer.cardURL ? customer.cardURL : "--"}
                        </Paragraph>
                      </Col>
                    </Row>
                    <Row className="mt-8">
                      <Col className="item">
                        <label className="kpi-label fs-14">Remarks</label>
                        <Paragraph className="kpi-val fs-14 fw-700 mb-0">
                          {customer.cardRemarks ? customer.cardRemarks : "--"}
                        </Paragraph>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </React.Fragment>
            </TabPane>
          )}

        <Modal
          title={<p> Wallet Details</p>}
          visible={this.state.isWalletModal}
          className="crypto-list"
          destroyOnClose={true}
          width={600}
          closeIcon={
            <Tooltip title="Close ">
              <span
                className="icon md x c-pointer"
                onClick={() =>
                  this.setState({ ...this.state, isWalletModal: false })
                }
              />
            </Tooltip>
          }
          footer={
            <Button
              type="primary"
              className="primary-btn cancel-btn"
              onClick={() =>
                this.setState({ ...this.state, isWalletModal: false })
              }
            >
              Close
            </Button>
          }
        >
                    {this.state.isWalletError !== undefined && this.state.isWalletError !== null && (
            <Alert className="mb-12 send-poperror" type="error" showIcon description={this.state.isWalletError} />)}
          {this.state.isWalletLoader && <Loader />}
          <table className="commision-table table-border edit-commition-table table-responsive" border="2">
            <tr>
              <th>Network</th>
              <th>Wallet Address</th>
            </tr>
            {this.state.getWallets.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.network}</td>
                  <td><Paragraph className="mb-0" copyable>{item.walletAddress}</Paragraph></td>
                </tr>
              );
            })}
          </table>
        </Modal>

        <Modal
          title={<p>Info Details </p>}
          visible={this.state.cryptoModal}
          className="crypto-list"
          destroyOnClose={true}
          closeIcon={
            <Tooltip title="Close ">
              <span
                className="icon md x c-pointer"
                onClick={() =>
                  this.setState({ ...this.state, cryptoModal: false })
                }
              />
            </Tooltip>
          }
          footer={
            <Button
              type="primary"
              className="primary-btn cancel-btn"
              onClick={() =>
                this.setState({ ...this.state, cryptoModal: false })
              }
            >
              Close
            </Button>
          }
        >
          <SharedInfo
            id={this.state.selectedId}
            type={this.state.selectedModal}
            screen={"customers"}
          />
        </Modal>
        <Modal
          title="Available Banks"
          visible={this.state.banksModal}
          className="crypto-list bank-modal"
          destroyOnClose={true}
          closeIcon={
            <Tooltip title="Close " onClick={()=>
              this.handleBankModalClose()
            }>
              <span
                className="icon md x c-pointer"
              />
            </Tooltip>
          }
          footer={null}
        ><div>
          <Form
          
            className="risk-popup cust-popalign"
            onFinish={()=>this.saveBanks()}
            >
           
        {this.state.bankerrorMsg !== undefined && this.state.bankerrorMsg !== null && (
								<Alert className="mb-12 send-poperror" type="error" showIcon description={this.state.bankerrorMsg} />)}
					
        {this.state.banksLu?.length!=0&&<Row gutter={24} className="mb-24 pb-24 border-bottom" style={{marginTop:10,marginLeft:3}}>
								<Col xs={24} sm={24} md={12}>
                    {this.state.banksLu.map((bank)=>(<div className="d-flex align-center"><label className="text-center custom-checkbox mr-4">
                      <input type="checkbox" name="banks" checked={bank.status=='Active'} onChange={()=>this.checkBanks(bank)}></input>
                      <span></span>{" "}
                    </label>{bank.bankName}</div>))}
           
								</Col>
							</Row>}
              {this.state.banksLu?.length==0&&<span>No Banks Available</span>}
            <Form.Item className="text-right mb-0">
								<div className="text-right">
              <Button
                type="primary"
                className="primary-btn cancel-btn reject-btn"
                onClick={this.handleBankModalClose
              }
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="primary-btn"
                htmlType="submit"
              style={{marginLeft:10}} loading={this.state.bankLoader}>
                Save
              </Button></div>
            </Form.Item>
          </Form></div>
        </Modal> 
       
        {this.state.previewModal && (
          <DocumentPreview
            previewModal={this.state.previewModal}
            handleCancle={() =>
              this.setState({ ...this.state, previewModal: false })
            }
            upLoadResponse={this.state.previewDocDetails}
          />
        )}
        {this.state.riskPage && (
          <RiskDetailsPage
            pop_up_cancel={this.GoBackRiskTab}
            riskModal={this.state.riskPage}
            gridID={this.props.match.params.id}
          />
        )}
      </>
    );
  }
}
const connectStateToProps = ({
  customerStore,
  userConfig,
  trackAuditLogData,
  permissions
}) => {
  return {
    permissions,
    customerStore,
    userConfig: userConfig.userProfileInfo,
    trackAuditLogData: userConfig.trackAuditLogData
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    fetchUserData: (id) => {
      dispatch(handleMembers(id));
    },
    dispatch
  };
};

export default connect(
  connectStateToProps,
  connectDispatchToProps
)(CustomerSummary);
