

import React, { Component } from "react";
import {
	Form,
	Select,
	Radio,
	Tooltip,
	message,
	Modal,
	Button,
	Row,
	Col,
	Input,
	Alert,
	Typography,DatePicker
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import List from "../grid.component";
import {
	setBreadcrumb,
	clearBreadcrumb,
} from "../../reducers/breadcrumbReducer";
import { connect } from "react-redux";
import Loader from "../loader.component";
import { ApproveorCancel, getTransfer, getSweep, getSweepBalance,getStates,Sumsub } from "./api";
import apiCalls from "../../api/apiCalls";
import {
	getPermissions,
	updateCurrentScreen,updateTabAccessDenied
} from "../../reducers/permissionsReducer";
import { setCurrentAction } from "../../reducers/actionsReducer";
import { validateContentRule } from "../../utils/custom.validator";
import CreateCase from "../../utils/createCase";
import NumberFormat from "react-number-format";
import Info from "../shared/sharedInfo";
import AccessDenied from "../accessDined.component/accessDenied";
import {CaseRediractions} from '../../utils/caseRediractions';
import LockedComponent from "../../utils/lockunlock";
import moment from "moment";
import config from "../../config";
const {Paragraph} = Typography;
const { TextArea } = Input;
class Deposit extends Component {
	constructor (props) {
		super(props);
		this.state = {
			check: false,
			selection: [],
			selectedObj: {},
			gridUrl: process.env.REACT_APP_GRID_API + "Deposits/Fiat",
			gridUrlC: process.env.REACT_APP_GRID_API + "Deposits/Crypto",
			deposit: true,
			selectedCaseId: "",
			type: "fiat",
			errorMessage: "",
			stateLoading: true,
			isSubmitting: false,
			stateChange: {},
			error: null,
			obj: {
				id: "",
				screenName: "Deposit",
				state: "",
				statusRemarks: "",
				rejectReason:"",
				transactionId: "",
				isCheckBox: false,
				pcomission: null,
				pCommisionValue: null,
				sweepSourceCommisionValue: null,
				sweepDestinationCommissionValue: null,
				currency: "",
			},
			fillGasObj: {
				id: "",
				amount: null,
				avilableBalance: null
			},
			cryptoModal: false,
			selectedModal: "",
			alert: false,
			btnDisabled: false,
			isImpCheck: false,
			caseModal: false,
			warningMsg: null,
			isBtnApprove: false,
			appwerningMsg: null,
			fillGasModal: false,
			isFillGasLoading: false,
			sweepModal: false,
            isSweepLoading: false,
			isBalanceObh: {},
			selectedTab:1,
			istabAcessdenied:false,
			networkLu: [],
			isNetworkLoader: false,
			statesLst:[],
			previousState:null,
			isModal:false,
			getLockandUnLockDetails:[],
			isCheckLock:false,
			selectedObjs:[],
		};
		this.addNewDeposit = this.addNewDeposit.bind(this);
		this.editDeposit = this.editDeposit.bind(this);
		this.gridFiatRef = React.createRef();
		this.gridCryptoRef = React.createRef();
		this.formref = React.createRef();
		this.gridRef = React.createRef();
	}
	componentDidMount(){
		if(process.env.REACT_APP_ISTR=="true"){
			const obj ={ field: "sweep", title: "Sweep Status", filter: true, width: 160 }
			this.cryptoColumns.splice(22,0,...obj)
		}
	}
	handleCheckBox=(props)=>(
		<td>
			{" "}
			<label className="text-center custom-checkbox">
				<input
					id={props.dataItem.id}
					name="check"
					type="checkbox"
					checked={this.state.selection.indexOf(props.dataItem.id) > -1}
					onChange={(e) => this.handleSelectionChange(props, e)}
				/>
				<span></span>{" "}
			</label>
		</td>
	)
	gridColumns = [
		{
			field: "",
			title: "",
			width: 50,
			locked: true,
			customCell: this.handleCheckBox,
		},
		{
			field: "transactionId",
			title: "Transaction ID",
			filter: true,
			locked: true,
			width: 210,
			customCell: (props) => (				
					<div className="gridLink" onClick={() => this.updateFiatView(props)}>
                      {props.dataItem?.transactionId}
					</div>			
			),
		},
		{
			field: "createdDate",
			title: "Submission Date",
			filter: true,
			width: 210,
			isShowTime: true,
			locked: false,
			filterType: "date",
		},
		{field: "approvedDate", title: "Approval Date", filterType:"date", isShowTime:true, filter: true, width: 210 },
		{ field: "status", title: "Status", filter: true, width: 140 },
		{
			field: "customerName",
			title: "Business Name/Personal Name",
			filter: true,
			width: 280,
			customCell: (props) => (
				<td className="whitelist-name">
				<div className="d-flex">
				<div className="white-address address-width">{props.dataItem.customerName}</div>
					{(props?.dataItem?.isDigitaltransfer || props?.dataItem?.isDigitaltransfer==false) && (
						<div className="file-labels ml-8 fs-12 address-label address-label-width">
							{props?.dataItem?.isDigitaltransfer ? "Pyrros" : "OpenPayd"}
						</div>
					)}</div>
				</td>
			),
		},
		{ field: "currency", title: "Currency", filter: true, width: 130 },
		{
			field: "grossAmount",
			title: "Gross Amount",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "netAmount",
			title: "Net Amount",
			filter: true,
			width: 160,
			dataType: "number",
			filterType: "numeric",
		},		
		{ field: "senderName", title: "Sender's Name", filter: true, width: 200 },
		{
			field: "senderAccount",
			title: "Sender's Bank Account Number/IBAN",
			filter: true,
			width: 310,
		},
		{ field: "senderBankName", title: "Sender's Bank Name ", filter: true, width: 210 },
		{ field: "bankName", title: "Bank Name", filter: true, width: 150 },
		{
			field: "bankAccountNumber",
			title: "Bank Account Number",
			filter: true,
			width: 200,
			dataType: "number",			
		},
		{
			field: "refrenceId",
			title: "Customer ID",
			filter: true,
			width: 150,
		},	
		{ field: "depositType", title: "Deposit Type", filter: true, width: 150 },
		{
			field: "effectiveFee",
			title: "Fees",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric",
		},	
		{
			field: "comissionvalue",
			title: "Commission",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "comissionvalueInBase",
			title: "Commission in Base",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric",
		},	
		{
			field: "totalAmount",
			title: "Total Cost",
			filter: true,
			width: 130,
			dataType: "number", filterType: "numeric"
		},		
		{
			field: "profit",
			title: "Profit",
			filter: true,
			width: 150,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "profitInBase",
			title: "Profit in Base",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "pcomissionvalue",
			title: "Provider Commission",
			filter: true,
			width: 200,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "pcomissionvalueInBase",
			title: "Provider Commission in Base",
			filter: true,
			width: 250,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "sweepSourceComissionValue",
			title: "Sweep Source Commission",
			filter: true,
			width: 250,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "sweepSourceValueInBase",
			title: "Sweep Source in Base",
			filter: true,
			width: 250,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "sweepDestinationcomissionValue",
			title: "Sweep Destination Commission",
			filter: true,
			width: 260,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "sweepDestinationvalueInBase",
			title: "Sweep Destination in Base",
			filter: true,
			width: 260,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "tierDiscount",
			title: "Tier Discount",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "sbCredit",
			title: "SuisseBase Credit Used",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric",
		},
		{ field: "lockUnlock", title: "Lock/Unlock", filter: true, width: 160 ,customCell: (props) => (
			<td>
			  <div className="d-flex align-center justify-content">
			  <span>{props.dataItem?.lockUnlock}</span>
				{props.dataItem?.lockUnlockHistory && <Tooltip title="More Info">
				<span
                className="icon md info c-pointer ml-8"
                onClick={() => this.showMoreDetails(props)}
              />
				</Tooltip>}
			  </div>
			</td>)},
		{ field: "userName", title: "User Name", filter: true, width: 200 },
		{ field: "email", title: "Email", filter: true, width: 200 },
		{ field: "rejectReason", title: "Reason For Rejection", filter: true, width: 260 },
		{
			field: "caseids",
			title: "Case Number",
			
			width: 160,
			customCell: (props) => (
				<td>
						<div className="gridLink" >
				  {props.dataItem.caseIds?.map(item=><div onClick={() => this.createCaseView(item)}>{item.caseNumber}</div>)}
				</div>

				</td>
			),
		},
	];
	cryptoColumns = [
		{
			field: "",
			title: "",
			width: 50,
			locked: true,
			customCell: this.handleCheckBox,
		},
		{
			field: "transactionId",
			title: "Transaction ID",
			filter: true,
			locked: true,
			width: 210,
			customCell: (props) => (				
					<div className="gridLink" onClick={() => this.updateCryptoDetail(props)}>
                      {props.dataItem?.transactionId}
					</div>				
			),
		},
		{
			field: "createdDate",
			title: "Submission Date",
			filter: true,
			width: 210,
			isShowTime: true,
			locked: false,
			filterType: "date",
		},
		{field: "approvedDate", title: "Approval Date", filterType:"date", isShowTime:true, filter: true, width: 210 },
		{ field: "status", title: "Status", filter: true, width: 140 },
		{
			field: "customerName",
			title: "Business Name/Personal Name",
			width: 280,
			filter: true,

		},
		{ field: "coinName", title: "Coin", filter: true, width: 160 },
		{ field: "network", title: "Network", filter: true, width: 160 },
		{
			field: "availableCoins",
			title: "Deposited Coins",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric",
		},
		{ field: "netAmount", title: "Net Amount", filter: true, width: 160, dataType: "number",filterType: "numeric", },
		{
			field: "sourceWalletAddress",
			title: "Source Wallet Address",
			filter: true,
			width: 430,
		},
		{
			field: "caseids",
			title: "Case Number",
			
			width: 160,
			customCell: (props) => (
			  <td>
				<div className="gridLink" >
				  {props.dataItem.caseIds?.map(item=><div onClick={() => this.createCaseView(item)}>{item.caseNumber}</div>)}
				</div>
			  </td>
			),
		  },
		  {
			field: "amlScreeningStatus",
			title: "AML Screening Status",
			width: 195,
			filter: true,
		},
		{
			field: "effectiveFee",
			title: "Fees",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "comissionvalue",
			title: "Commission",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric"
		},
		{
			field: "comissionvalueInBase",
			title: "Commission in Base",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric"
		},
		{
			field: "profit",
			title: "Profit",
			filter: true,
			width: 150,
			dataType: "number",
			filterType: "numeric"
		},
		{
			field: "profitInBase",
			title: "Profit in Base",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric"
		},
		{
			field: "fromWalletAddress",
			title: "Wallet Address",
			filter: true,
			width: 150,
			customCell: (props) => (
				<td>
					{props.dataItem.fromWalletAddress !== null &&
						props.dataItem.fromWalletAddress !== "" ? (
						<Paragraph
							className="kpi-val mb-0"
							copyable={{ text: props.dataItem.fromWalletAddress }}>
							{props.dataItem.fromWalletAddress.slice(0, 4) +
								"..." +
								props.dataItem.fromWalletAddress.slice(-4)}
						</Paragraph>
					) : (
						""
					)}
				</td>
			)
		},
		{
			field: "totalAmount",
			title: "Total Cost",
			filter: true,
			width: 130,
			dataType: "number", filterType: "numeric"
		},
		{
			field: "sweepSourceComissionValue",
			title: "Sweep Source Commission",
			filter: true,
			width: 250,
			dataType: "number",
			filterType: "numeric"
		},
		{
			field: "sweepSourceValueInBase",
			title: "Sweep Source in Base",
			filter: true,
			width: 250,
			dataType: "number",
			filterType: "numeric"
		},
		{
			field: "sweepDestinationcomissionValue",
			title: "Sweep Destination Commission",
			filter: true,
			width: 260,
			dataType: "number",
			filterType: "numeric"
		},
		{
			field: "sweepDestinationvalueInBase",
			title: "Sweep Destination in Base",
			filter: true,
			width: 260,
			dataType: "number",
			filterType: "numeric"
		},		
		{
			field: "tierDiscount",
			title: "Tier Discount",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "inputScore",
			title: "Input Score",
			width: 150,
			filter: true,
			customCell: (props) => (
				<td>
					{props.dataItem.inputScore ? props.dataItem.inputScore : 0}
					<Tooltip title="View More">
						<span
							className="icon md info c-pointer ml-8"
							style={{ float: "right" }}
							onClick={() =>
								this.setState({
									...this.state,

									cryptoModal: true,
									selectedId: props.dataItem.id,
									selectedModal: "Input",
								})
							}
						/>
					</Tooltip>
				</td>
			),
		},
		{
			field: "outputScore",
			title: "Output Score",
			width: 150,
			filter: true,
			customCell: (props) => (
				<td>
					{props.dataItem.outputScore ? props.dataItem.outputScore : 0}
					<Tooltip title="View More">
						<span
							className="icon md info c-pointer ml-8"
							style={{ float: "right" }}
							onClick={() =>
								this.setState({
									...this.state,
									cryptoModal: true,
									warningMsg: null,
									selectedId: props.dataItem.id,
									selectedModal: "Output",
								})
							}
						/>
					</Tooltip>
				</td>
			),
		},		
		{
			field: "refrenceId",
			title: "Customer ID",
			filter: true,
			width: 150,
		},

		{ field: "userName", title: "User Name", filter: true, width: 200 },
		{ field: "email", title: "Email", filter: true, width: 250 },
		{ field: "walletScore", title: "Wallet Score", filter: true, width: 140, dataType: "number",filterType: "numeric", },
		{
			field: "amlRating",
			title: "AML Rating",
			width: 120,
			filter: true,
		},
		{ field: "lockUnlock", title: "Lock/Unlock", filter: true, width: 160 ,customCell: (props) => (
			<td>
			  <div className="d-flex align-center justify-content">
				<span>{props.dataItem?.lockUnlock}</span>
				{props.dataItem?.lockUnlockHistory && <Tooltip title="More Info">
				<span
                className="icon md info c-pointer ml-8"
                onClick={() => this.showMoreDetails(props)}
              />
				</Tooltip>}
			  </div>
			</td>)},
			{
				field: "retryCount",
				title: "Retry Count",
				width: 180,
				dataType: "numeric",
				filterType: "numeric",
				filter: true,
			},
			{ field: "rejectReason", title: "Reason For Rejection", filter: true, width: 240 },
	];
	componentDidMount() {
		this.props.dispatch(
			updateTabAccessDenied(false)

		);
		if(this.props.permissions?.currentScreenTabId){
			if(!this.props.permissions[this.props.permissions?.currentScreenTabId]){
				this.props.dispatch(
					getPermissions({
						customerId: this.props.userConfig.id,
						perKey: this.props.permissions?.currentScreenTabId,
					})
				);
			}
			const _menutabItem = this.props.permissions.menuItems.filter((item) => item.id === this.props.permissions?.currentScreenTabId)[0];
			this.setState({...this.state,selectedTab:_menutabItem?.label==='Deposit Fiat'?1:2, deposit:_menutabItem?.label==='Deposit Fiat'})
		}
		apiCalls.trackEvent({
			Type: "Admin",
			Action: "Deposit Fiat grid  page view",
			Username: this.props.userProfileInfo?.userName,
			customerId: this.props.userProfileInfo?.id,
			Feature: "Deposit",
			Remarks: "Deposit Fiat grid page view",
			Duration: 1,
			Url: window.location.href,
			FullFeatureName: "Deposit",
		});
		
	}

	handleDepositTabs = (e) => {
		this.setState({
			...this.state,
			deposit: e.target.value === 1,
			selection: [],
			selectedObj: {},
			check: false,
			warningMsg: null,
			selectedTab:e.target.value,
			istabAcessdenied:false
		});
		this.props.dispatch(
			updateCurrentScreen(
				e.target.value === 1 ? "depositfiat" : "depositcrypto"
			)

		);
		this.props.dispatch(
			updateTabAccessDenied(false)

		);
		const _menutabItem = this.props.permissions.menuItems.filter((item) => item.label === (e.target.value === 1 ? "Deposit Fiat" : "Deposit Crypto"))[0];
		this.props.dispatch(
			getPermissions({
				customerId: this.props.userConfig.id,
				perKey: _menutabItem.id,
			})
		);

		if (this.state.deposit) {
			apiCalls.trackEvent({
				Type: "Admin",
				Action: "Deposit Crypto grid page view",
				Username: this.props.userProfileInfo?.userName,
				customerId: this.props.userProfileInfo?.id,
				Feature: "Deposit",
				Remarks: "Deposit Crypto grid page view",
				Duration: 1,
				Url: window.location.href,
				FullFeatureName: "Deposit",
			});
		} else {
			apiCalls.trackEvent({
				Type: "Admin",
				Action: "Deposit Fiat  grid page view",
				Username: this.props.userProfileInfo?.userName,
				customerId: this.props.userProfileInfo?.id,
				Feature: "Deposit",
				Remarks: "Deposit Fiat  grid page view",
				Duration: 1,
				Url: window.location.href,
				FullFeatureName: "Deposit",
			});
		}
	};
	createCase = () => {
		if (this.state.selection.length==0) {
			this.setState({ ...this.state, warningMsg: 'Please select the one record' });
			setTimeout(() => this.setState({ ...this.state, alert: false }), 1000);
		}else if(this.state?.selection.length >1) {
			this.setState({ ...this.state, warningMsg: "Please select only one record",selection:[],check:false,selectedObjs:[],selectedObj:{} });
		}
		else {
			this.setState({ ...this.state, caseModal: true, check: false });
		}
	};
	editDeposit = () => {
		this.props.dispatch(clearBreadcrumb());
		if (this.state?.selection.length ==0) {
			this.setState({ ...this.state, warningMsg: 'Please select the one record' });

		} else if(this.state?.selection.length >1) {
			this.setState({ ...this.state, warningMsg: "Please select only one record",selection:[],check:false,selectedObjs:[],selectedObj:{} });
		}else{
			const obj = this.state.selectedObj;
			let val = obj.id;
			if (obj.status === "Approved" || obj.status === "Rejected") {
				this.props.history.push("/deposit/" + val + "/fiatDetails/edit");
				this.props.dispatch(
					setBreadcrumb({
						key: "/deposit/" + val + "/fiatDetails/edit",
						val: obj.transactionId + " / " + obj.status,
					})
				);
			} else {
				this.props.history.push("/deposit/" + obj.id + "/fiatDetails");
				this.props.dispatch(
					setBreadcrumb({
						key: "/deposit/" + val + "/fiatDetails",
						val: obj.transactionId + " / " + obj.status,
					})
				);
				this.setState({ ...this.state, warningMsg: null })
			}
		}
		apiCalls.trackEvent({
			Action: "Deposit Fiat edit page view",
			Feature: "Deposit",
			Remarks: "Deposit Fiat edit page view",
			FullFeatureName: "Deposit",
			userName: this.props.userConfig.userName,
			id: this.props.userConfig.id,
		});
	};

	addNewDeposit = () => {
		this.props.dispatch(clearBreadcrumb());
		this.props.history.push(
			"/deposit/00000000-0000-0000-0000-000000000000/fiatDetails"
		);
		this.props.dispatch(
			setBreadcrumb({
				key: "/deposit/00000000-0000-0000-0000-000000000000/fiatDetails",
				val: "Add Fiat Details",
			})
		);
		apiCalls.trackEvent({
			Action: "Deposit add page view",
			Feature: "Deposit",
			Remarks: "Deposit add page view",
			FullFeatureName: "Deposit",
			userName: this.props.userConfig.userName,
			id: this.props.userConfig.id,
		});
	};

	createCaseView = (CaseData) => {
		let propsData=this.props
		this.props.dispatch(updateCurrentScreen("cases"));
		CaseRediractions(CaseData,propsData)
	  };


	updateFiatView = (e) => {
		this.props.dispatch(clearBreadcrumb());
		const val = e.dataItem.id;
		this.props.history.push("/deposit/" + val + "/fiatDetails/view");
		this.props.dispatch(
			setBreadcrumb({
				key: "/deposit/" + val + "/fiatDetails/view",
				val: e.dataItem.transactionId + " / " + e.dataItem.status,
			})
		);
		apiCalls.trackEvent({
			Action: "Deposit Fiat details page view",
			Feature: "Deposit Fiat",
			Remarks: "Deposit fiat details page view",
			FullFeatureName: "Deposit Fiat",
			userName: this.props.userConfig.userName,
			id: this.props.userConfig.id,
		});
	};

	updateCryptoDetail = (e) => {
		const val = e.dataItem.id;
		this.props.history.push("/deposit/" + val + "/cryptoDetailView");
		this.props.dispatch(
			setBreadcrumb({
				key: "/deposit/" + val + "/cryptoDetailView",
				val: e.dataItem.transactionId + " / " + e.dataItem.status,
			})
		);
		apiCalls.trackEvent({
			Action: "Deposit Crypto details page View",
			Feature: "Deposit Crypto",
			Remarks: "Deposit Crypto details page view",
			FullFeatureName: "Deposit Crypto ",
			userName: this.props.userConfig.userName,
			id: this.props.userConfig.id,
		});
	};

	handleSelectionChange = (prop, e) => {
		this.formref.current?.resetFields();
		const rowObj = prop.dataItem;
		const value =
			e.target.type === "checkbox" ? e.target.checked : e.target.value;
		const name = e.target.name;
		let { selection,selectedObjs } = this.state;
		let idx = selection.indexOf(rowObj.id);
		// if (selection) {
		// 	selection = [];
		// }
		if (idx > -1) {
			selection.splice(idx, 1);
			selectedObjs.splice(idx,1);
		} else {
			selection.push(rowObj.id);
			selectedObjs.push(rowObj);
		}
		this.setState({
			...this.state,
			[name]: value,
			selectedObj: this.state.selectedObjs[0] || rowObj,
			selection,
			warningMsg: null,
			selectedObjs,
		});
		this.formref.current?.resetFields();
	};

	

	statusChange = () => {
		if (this.state.selection.length==0) {
			this.setState({
				...this.state,
				warningMsg: 'Please select the one record',
				isSubmitting:
					this.state.stateChange.status !== "Submitted" &&
						this.state.stateChange.status !== "Pending" &&
						this.state.stateChange.status !== "Swept" && 
						this.state.stateChange.status !=="Compliance Check"
						? true
						: false,
			});
			setTimeout(
				() =>
					this.setState({
						...this.state,
						isSubmitting:
							this.state.stateChange.status !== "Submitted" &&
								this.state.stateChange.status !== "Pending" &&
								this.state.stateChange.status !== "Swept" && 
								this.state.stateChange.status !=="Compliance Check"								
								? true
								: false,
					}),
				4000
			);
		} else if(this.state?.selection.length >1) {
			this.setState({ ...this.state, warningMsg: "Please select only one record",selection:[],check:false,selectedObjs:[],selectedObj:{} });
		} else if(this.state.selectedObj?.lockUnlock == "Locked"){
        this.setState({...this.state,warningMsg: 'This transaction was locked, Please unlock before state change',selectedObj:{},selection:[],check:false,selectedObjs:[]})
		} else {
		let tabName=this.state.selectedTab===1 ? ((this.state.selectedObj?.isDigitaltransfer || this.state.selectedObj?.isDigitaltransfer ==false) && "Recevie DigitalTransfer") || "Recevie Fiat" : "Recevie Crypto"
			getStates(tabName,this.state.selectedObj.status,this.state?.selectedObj?.previousState).then(response => {
                if (response.ok) {
                    this.setState({ ...this.state, statesLst: response.data, loading: false,appwerningMsg:null,btnDisabled:false
					 });
                }else{
					this.setState({ ...this.state,appwerningMsg: apiCalls.isErrorDispaly(response), });
				  }
            })
			this.setState(
				{
					...this.state,
					modal: true,
					isSubmitting:
						this.state.stateChange.status !== "Submitted" &&
							this.state.stateChange.status !== "Pending" &&
							this.state.stateChange.status !== "Swept" && 
							this.state.stateChange.status !=="Compliance Check"
							? true
							: false,
					appwerningMsg:null,
					warningMsg: null ,
					error:null,
					stateChange: {
						status: this.state.selectedObj.status,
						type: this.state.selectedObj.type,
						statusRemarks: this.state.selectedObj.statusRemarks,
						isCheckBox: this.state.selectedObj.isCheckBox,
						transactionId: this.state.selectedObj.transactionId,
						pcomission: this.state.selectedObj.pcomission,
						pCommisionValue: this.state.selectedObj.pcomissionvalue,
						sweepDestinationCommissionValue: this.state.selectedObj.sweepDestinationcomissionValue,
						rejectReason:this.state.selectedObj.rejectReason,
						sweepSourceCommisionValue: this.state.selectedObj.sweepSourceComissionValue,
						currency: this.state.selectedObj.currency,
						previousState:this.state.selectedObj.previousState,
					},
				},
				() => {
					this.setState({
						...this.state,
						stateLoading: true,
						isSubmitting:
							this.state.stateChange.status !== "Submitted" &&
								this.state.stateChange.status !== "Pending" &&
								this.state.stateChange.status !== "Swept" && 
								this.state.stateChange.status !=="Compliance Check"
								? true
								: false,
					});
					setTimeout(
						() =>
							this.setState({
								...this.state,
								stateLoading: false,
								isSubmitting:
									this.state.stateChange.status !== "Submitted" &&
										this.state.stateChange.status !== "Pending" &&
										this.state.stateChange.status !== "Swept" && 
										this.state.stateChange.status !=="Compliance Check"
										? true
										: false,
							}),
						4000
					);

					setTimeout(
						() =>
							this.formref.current.setFieldsValue({
								...this.state,
								status: this.state.selectedObj.status,
								statusRemarks: this.state.selectedObj.statusRemarks,
								isCheckBox: this.state.selectedObj.isCheckBox,
								transactionId:this.state.selectedObj.hash,
								pcomission: this.state.selectedObj.pcomission,
								pCommisionValue: this.state.selectedObj.pcomissionvalue,
								sweepDestinationCommissionValue: this.state.selectedObj.sweepDestinationcomissionValue,
								rejectReason:this.state.selectedObj.rejectReason,
								sweepSourceCommisionValue: this.state.selectedObj.sweepSourceComissionValue,
								currency: this.state.selectedObj.currency,
								previousState:this.state.selectedObj.previousState,
								BankValueDate : this.state.selectedObj.bankValueDate && moment(new Date(this.state.selectedObj.bankValueDate), "DD/MM/YYYY")
							}),
						4000
					);
				}
			);

		}
	};
	success = (status) => {
		switch (status) {
			case "Approved":
				status = "Approved";
				break;
			case "Reject":
				status = "Rejected";
				break;
			case "Pending":
				status = "Pending";
				break;
				default:
					return null;
		}
		message.success({
			content: (status=== "transferred" ? "Amount ":  "Record ") + (status=== "Approve" ? "Approved": status) + " successfully",
			className: "custom-msg",
			duration: 4,
		});
	};

	error = () => {
		message.error("Please select the one record");
	};
	transfer = async (values) => {
		let _amt=values.amount;
	  this.setState({ ...this.state, btnDisabled: true,  isBtnApprove: true, warningMsg: null })
	  _amt =typeof _amt=="string" ? _amt?.replace(/,/g, '') : _amt;
				if(_amt > this.state.fillGasObj?.avilableBalance) {
				this.setState({
					...this.state,
					isBtnApprove: false,
					btnDisabled: false,
					stateLoading: false,
					fillGasModal: true,
					appwerningMsg: "Insufficient balance."
				})
			}
		else if(values.amount > 0) {
			let statusGasObj = this.state.fillGasObj;
			statusGasObj.id = this.state.selectedObj.id;
			statusGasObj.amount = values.amount;
			statusGasObj.customerId = this.props.userConfig?.id;
			let response = await getTransfer(statusGasObj);
			if (response.ok) {
				this.props.dispatch(setCurrentAction(null));
				this.setState({ stateLoading: true, btnDisabled: false });
				message.success({content:"Fill Gas transferred successfully.",
				className: "custom-msg",
				duration: 4,});
				this.formref.current.resetFields();
				this.gridCryptoRef.current.refreshGrid()
				this.setState({
					...this.state,
					modal: false,
					fillGasModal: false,
					selection: [],
					check: false,
					isBtnApprove: false,
					stateLoading: false,
					fillGasObj:{id:"",amount: null,avilableBalance: null}
				});
					this.isLoading = false;
					
			} else {
				this.setState({
					...this.state,
					isBtnApprove: false,
					btnDisabled: false,
					stateLoading: false,
					fillGasModal: true,
					appwerningMsg: apiCalls.isErrorDispaly(response),
					error:
						response.status === 401 ? response.data.message : response.data,
				});
			}
		}
			else {
			 this.setState({
					...this.state,
					isBtnApprove: false,
					btnDisabled: false,
					stateLoading: false,
					fillGasModal: true,
					appwerningMsg: "Amount must be greater than zero."
				})
			}
	}

	saveSweep = async () => {
		this.setState({ ...this.state, saveDisable: true, isSweepLoading: true })
		let response = await getSweep(this.state.selectedObj,this.props.userConfig?.id);
		if (response.ok) {
		  this.success(
			  "sweeped"
		  );
		  this.gridCryptoRef.current.refreshGrid();
		  this.setState({ ...this.state, sweepModal: false, isSweepLoading: false, selection: [] })
	
		} else {
		  this.setState({
			...this.state,
			appwerningMsg: apiCalls.isErrorDispaly(response),
			isSweepLoading: true, sweepModal: true, selection: []
		  })
		}
	  }

	  isDepositSuccess=(response,selectedVlaue,statusObj)=>{
		if (this.state.selectedObj.isCheckBox) {
			if (response.ok) {
				this.props.dispatch(setCurrentAction(null));
				message.success({
					content: "Record " + (selectedVlaue.status || statusObj.status) + " successfully",
					className: "custom-msg",
					duration: 1
				  });
				this.setState({	...this.state,modal: false, selection: [], selectedObjs:[], selectedObj:{}, stateChange:{},
					check: false,error: null,stateLoading: true,btnDisabled: false,	appwerningMsg: null,isBtnApprove: false
				},()=>this.gridFiatRef.current?.refreshGrid());
				setTimeout(() => {
					this.isLoading = false;
				}, 2000);
				
			} else {
				this.setState({
					...this.state,
					btnDisabled: false,
					isBtnApprove: false,
					appwerningMsg: apiCalls.isErrorDispaly(response),
					error:
						response.status === 401 ? response.data.message : response.data,
				});
				setTimeout(() => {
					this.isLoading = false;
				}, 2000);
			}
		} else {
			setTimeout(() => {
				this.isLoading = false;
			}, 2000);
		}
	  }
	handleOk = async (values) => {
		this.setState({ ...this.state, btnDisabled: true, isBtnApprove: true, warningMsg: null })
		if (!this.isLoading) {
			this.isLoading = true;
			let statusObj = this.state.obj;
			statusObj.id = this.state.selectedObj.id;
			statusObj.AdminId = this.props.userConfig.id;
			statusObj.type = this.state.selectedTab===1 ? "Fiat" : "Crypto";
			statusObj.status = values.status || this.state.selectedObj.status;
			statusObj.statusRemarks = values.statusRemarks;
			statusObj.isCheckBox = this.state.selectedObj.isCheckBox;
			statusObj.transactionId = values.transactionId;
			statusObj.pcomission = values.pcomission || this.state.selectedObj.pcomissionPercentage;
			statusObj.pCommisionValue = values.pCommisionValue;
			statusObj.BankValueDate = values.BankValueDate;
			statusObj.sweepDestinationCommissionValue = values.sweepDestinationCommissionValue;
			statusObj.sweepSourceCommisionValue = values.sweepSourceCommisionValue;
			statusObj.rejectReason=values.rejectReason
			statusObj.currency = this.state.selectedObj.currency || this.state.selectedObj.walletCode;
			statusObj.AprrovedBy = this.props.userConfig.userName;
			statusObj.ModifiedBy = this.props.userConfig.userName;
			statusObj.info = JSON.stringify(this.props.trackAuditLogData);
			let response = await ApproveorCancel(statusObj);
			if (this.state.deposit) {
				this.isDepositSuccess(response,values,statusObj);
			} else {
				if (response.ok) {
					this.props.dispatch(setCurrentAction(null));
					this.setState({ stateLoading: true, btnDisabled: false, isApprove: false });
					message.success({
						content: "Record " + (values.status || statusObj.status) + " successfully",
						className: "custom-msg",
						duration: 1
					  });
					this.setState({
						...this.state,
						modal: false,
						selection: [],
						selectedObjs:[],
						selectedObj:{},
						stateChange:{},
						check: true,
						isBtnApprove: false,
					});
					setTimeout(() => {
						this.isLoading = false;
					}, 2000);
					this.gridRefresh();
				} else {
					this.setState({
						...this.state,
						isBtnApprove: false,
						btnDisabled: true,
						appwerningMsg: apiCalls.isErrorDispaly(response),
						error:
							response.status === 401 ? response.data.message : response.data,
					});
					setTimeout(() => {
						this.isLoading = false;
					}, 2000);
				}
			}
		}
		this.setState({ ...this.state, isBtnApprove:false,})
	};


	stateChange = (val, prop) => {
		this.formref.current.setFieldsValue({statusRemarks:"",rejectReason:"",BankValueDate:""})
		let { stateChange } = this.state;
		stateChange.status = val;
		this.setState({ ...this.state, stateChange,btnDisabled:true });     
		if(val ==="Reject" || val ==="Approve"){
			this.formref.current.setFieldsValue({statusRemarks:"",rejectReason:"",BankValueDate:""})
		  }
		if (val === "Pending" || val === "Approve") {
			this.formref.current.setFieldsValue({
				pcomission: this.state.selectedObj?.pcomission
					? this.state.selectedObj?.pcomission
					: null,
			});
		}
	};
	validateNumber = (_, validNumberValue) => {
		if (validNumberValue === ".") {
			return Promise.reject("Please enter valid content");
		}
		return Promise.resolve();
	}
	handleCancel = (e) => {
		this.props.dispatch(setCurrentAction(null));
		this.setState({
			...this.state,
			modal: false,
			selection: [],
			check: false,
			error: null,
			appwerningMsg: null,
			warningMsg: null,selectedObjs:[]
		});
		this.formref.current.resetFields();

	};

	onActionClick = (key) => {
		const action = {
			add: "addNewDeposit",
			edit: "editDeposit",
			"State Change": "statusChange",
			"Create Case": "createCase",
			"Fill Gas": "fillGas",
			"Reset Sweep": "resetSweep",
			"Lock/Unlock":"CheckLockUnLock",
			"Sumsub":"SumSub",
		};
		this[action[key]]();
	};

	handleImpNote = (e) => {
		if (e.currentTarget.checked) {
			this.setState({
				...this.state,
				selectedObj: { ...this.state.selectedObj, isCheckBox: true },
			});
			setTimeout(() => {
				this.loading = false;
			}, 2000);
		} else {
			this.setState({
				...this.state,
				selectedObj: { ...this.state.selectedObj, isCheckBox: false },
			});
			setTimeout(() => {
				this.loading = false;
			}, 2000);
		}
	};

	gridRefresh = () => {
		if (this.state.selectedObj.type === "Fiat") {
			this.gridFiatRef.current.refreshGrid()
		} else {
			this.gridCryptoRef.current.refreshGrid()
		}
	}

	hideCaseModal = () => {
		this.setState({ ...this.state, caseModal: false, selection: [],selectedObjs:[] },
			this.gridRefresh()
		);

	};
	hideFillGasModal = () => {
		this.setState({ ...this.state, fillGasModal: false, selection: [],selectedObjs:[],
		fillGasObj:{id:"",amount: null,avilableBalance: null},appwerningMsg: null }, () =>
			this.gridCryptoRef.current.refreshGrid()
		);
		this.formref.current.resetFields();
	}
	getSweepBalance =async (network) => {
		let response = await getSweepBalance(this.state.selectedObj.id, network || this.state.selectedObj?.network);
			if (response.ok) {
				let obj = response.data
				this.state.fillGasObj.amount = obj.avilableBalance;
				this.state.fillGasObj.avilableBalance = obj.avilableBalance;
				this.formref.current?.setFieldsValue({amount: obj.avilableBalance?obj.avilableBalance:"",avilableBalance: obj.avilableBalance})
				this.setState({...this.state,isNetworkLoader: false})
			}
			else {
				this.setState({
					...this.state,
					isBtnApprove: false,
					btnDisabled: false,
					fillGasModal: true,
					isNetworkLoader: false,
					appwerningMsg: apiCalls.isErrorDispaly(response),
					error:
						response.status === 401 ? response.data.message : response.data,
				});
				setTimeout(() => {
					this.isLoading = false;
					this.isNetworkLoader= false;
				}, 2000);
		}
		setTimeout(
			() =>
				this.setState({
					...this.state,
					stateLoading: false,
				}),
			2000
		);
	}
	fillGas = async () => {
		if (this.state.selection?.length==0) {
		  this.setState({ ...this.state, warningMsg: 'Please select the one record' })
		}  else if(this.state?.selection.length >1) {
			this.setState({ ...this.state, warningMsg: "Please select only one record",selection:[],check:false,selectedObjs:[],selectedObj:{} });
		}else if(this.state.selectedObj.status==="Rejected"){
			this.setState({ ...this.state, warningMsg: "The transaction in rejected state, So you can't transfer fill gas.",selection: [],selectedObj: {},fillGasModal:false,check:false})
			window.scrollTo(0, 0);
			return;
		}else {
			this.setState({ fillGasModal: true, saveDisable: false, warningMsg: null, stateLoading: true, check: false,appwerningMsg: null});
			this.getSweepBalance();
		}
	  }
	  resetSweep = async () => {
		if (this.state.selection.length==0) {
		  this.setState({ ...this.state, warningMsg: 'Please select the one record' })
		}  else if(this.state?.selection.length >1) {
			this.setState({ ...this.state, warningMsg: "Please select only one record",selection:[],check:false,selectedObjs:[],selectedObj:{} });
		}else {
		  this.setState({ sweepModal: true, saveDisable: false, warningMsg: null, stateLoading: true, check: false,appwerningMsg: null });
		}
	
	  }
	antIcon = () => {
		(
			<LoadingOutlined
				style={{ fontSize: 18, color: "#fff", marginRight: "16px" }}
				spin
			/>
		);	
	}
	showMoreDetails=(props)=>{
		this.setState({...this.state,isModal:true,getLockandUnLockDetails:props.dataItem.lockUnlockHistory});
	}
	CheckLockUnLock=()=>{
		if (this.state.selection.length==0) {
			this.setState({ ...this.state, warningMsg: 'Please select the one record' });
		} else if(this.state?.selection.length >1) {
			this.setState({ ...this.state, warningMsg: "Please select only one record",selection:[],check:false,selectedObjs:[],selectedObj:{} });
		}else{
			this.setState({...this.state,isCheckLock:true})
		}
	}
	LockedPopClose=()=>{
		this.setState({...this.state,isModal:false,isCheckLock:false,selection:[],selectedObj:{},check:false,selectedObjs:[]})
	}
	SumSub=async()=>{
		if (this.state.selection.length==0) {
			this.setState({ ...this.state, warningMsg: 'Please select the one record' })
		  }else{
			let obj ={
				"Ids": this.state?.selection,
				"ScreenName": this.state.selectedTab===1?'Receive Fiat':'Receive Crypto',//"Deposit",
			}
			let res = await Sumsub(obj,this.state.selectedTab===1?'Fiat':'Crypto');
			if(res.ok){
				this.setState({ ...this.state, warningMsg: null,check:false,selection:[],selectedObjs:[],selectedObj:{} })
				window.open(res.data,'_self')
			}else{
				this.setState({ ...this.state, warningMsg: apiCalls.isErrorDispaly(res),check:false,selection:[],selectedObjs:[],selectedObj:{} })
			}
		}
		  }

		  getWarningMsg=()=>(
			this.state.warningMsg !== undefined && this.state.warningMsg !== null && (
				<Alert
					className="w-100 mb-16"
					type="warning"
					description={this.state.warningMsg}
					showIcon
				/>
			)
		  )
		  getAppWarningMsg=()=>(
			this.state.appwerningMsg !== undefined && this.state.appwerningMsg !== null && (
				<Alert
					className="mb-12"
					type="error"
					description={this.state.appwerningMsg}
					showIcon
				/>
			)
		  )
	render() {
		const { gridUrl, deposit, gridUrlC, isBtnApprove, caseModal, appwerningMsg,isSweepLoading, selectedTab } = this.state;
		let isAcessdenied;
		let istabAcessdenied;
		if(this.props.permissions[this.props.permissions.currentScreenTabId]){
			const isview = this.props.permissions[this.props.permissions.currentScreenTabId].filter((item)=>item.permissionName === "view" && item.values);
			if(isview.length===0){
				isAcessdenied = true;
			}else{
				isAcessdenied = false;
			}
		}
		if(this.props.permissions['tabAccessDenied'] && istabAcessdenied!==true){
			istabAcessdenied = true;
			if(!this.state.istabAcessdenied){
			this.setState({...this.state,istabAcessdenied:true,appwerningMsg: null,cryptoModal: false,fillGasModal: false,sweepModal: false,modal:false,caseModal:false,})
			}
		}
		return (
			<>

				{this.getWarningMsg()}
				<div 
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
					className="mb-16">
					<Radio.Group
						value={selectedTab}
						onChange={this.handleDepositTabs}
						className="buysell-toggle mb-0">
						<Radio.Button value={1}> Fiat </Radio.Button>
						<Radio.Button value={2}> Crypto </Radio.Button>
					</Radio.Group>
				
				
				</div>
				{(isAcessdenied ||istabAcessdenied) && <AccessDenied />}
				{!(isAcessdenied||istabAcessdenied) && <>{(selectedTab===1) ? (
					<List
						showActionBar={true}
						onActionClick={(key) => this.onActionClick(key)}
						pKey={"Receive Fiat"}
						url={gridUrl}
						key={gridUrl}
						ref={this.gridFiatRef}
						columns={this.gridColumns}
						showExcelExport={true}
					/>
				) : (
					<List
						key={gridUrlC}
						url={gridUrlC}
						ref={this.gridCryptoRef}
						pKey={"Receive Crypto"}
						columns={this.cryptoColumns}
						showActionBar={true}
						showExcelExport={true}
						onActionClick={(key) => this.onActionClick(key)}
					/>
				)}</>}
                <LockedComponent getLockandUnLockDetails={this.state.getLockandUnLockDetails} isModal={this.state.isModal} 
				LockedPopClose={()=>this.LockedPopClose()} isCheckLock={this.state.isCheckLock} selectedObj={this.state.selectedObj}
				 isGridRefresh={()=>this.gridRefresh()} screen={selectedTab==1 ? "DepositFiat" : "Depositcrypto"} ModifiedBy={this.props.userConfig.userName}/>
				<Modal
					title="Confirm Pending/Approve/Reject?"
					className="custom-width text-break deposit-pop"
					visible={this.state.modal}
					closeIcon={
						<Tooltip title="Close">
							<span className="icon md x c-pointer" onClick={this.handleCancel} />
						</Tooltip>
					}
					footer={null}>
					<div>
						{this.state.stateLoading && <Loader />}
						<Form
							ref={this.formref}
							className="ant-advanced-search-form"
							autoComplete="off"
							onFinish={this.handleOk}>
							{this.getAppWarningMsg()}
							{deposit && (
								<div className="mb-8">
									<Alert
										message={
											<Form.Item
												name="isCheckBox"
												valuePropName="checked"
												className="mb-0"
												rules={[
													{
														validator: (_, value) =>
															value
																? Promise.resolve()
																: Promise.reject(
																	new Error("Please select checkbox")
																),
													},
												]}>
												<div className="d-flex">
													<label className="text-center custom-checkbox mr-8">
														<input
															name="check"
															type="checkbox"
															checked={this.state.selectedObj?.isCheckBox}
															onChange={(e) => this.handleImpNote(e)}
															disabled={this.state.selectedObj?.status === "Rejected" || this.state.selectedObj?.status === "Approved"}
														/>
														<span></span>{" "}
													</label>
													Important Note:
												</div>
											</Form.Item>
										}
										description={<p className="fw-400 fs-14 mb-5 l-height-normal">Please ensure that you have transferred funds from Signet to Kraken prior to approval. Please click the checkbox above to confirm.</p>}										type="info"
										className="imp-alert"
									/>
								</div>
							)}
							
							<Row gutter={24} className="mb-24 pb-24 border-bottom">
								{deposit && (
									<Col xs={24}>
										<Form.Item
											name="transactionId"
											label="Transaction ID"
											className="input-label"
											rules={[
												{
													required: this.state.stateChange.status !== "Submitted" && this.state.stateChange.status !== "Pending" && this.state.stateChange.status !== "Reject" && this.state.selectedObj.status !== "Rejected" && this.state.stateChange.status !== "Swept" && this.state.stateChange.status !== "Rejected" && this.state.stateChange.status !=="Compliance Check" ? true : false,
													message: "Is required",
													whitespace: true,
												},
												{
													validator: validateContentRule,
												},
											]}>
											<Input
												placeholder="Transaction ID"
												maxLength={150}
												className="cust-input"
												disabled={
													this.state.selectedObj.status === "Rejected" ||
														this.state.selectedObj.status === "Approved"
														? true
														: false
												}
											/>
										</Form.Item>
									</Col>
								)}
								<Col xs={24} sm={24} md={12}>
									<Form.Item
										name="status"
										label="State"
										className="input-label"
										rules={[
											{
												required: true,
												message: "Is required",
											},
											{
												validator: validateContentRule,
											},
										]}>
										<Select
											className="cust-input mb-0"
											onChange={(e) => this.stateChange(e)}
											disabled={this.state.isSubmitting}
											placeholder="Select State">
											{this.state.statesLst?.map(item => <Select.Option value={item?.code}>{item?.name}</Select.Option>)}
										</Select>
									</Form.Item>
								</Col>
								{this.state.stateChange?.previousState && deposit && <Col xs={24} sm={24} md={12}>
								<Form.Item
								name="previousState"
								label="Previous State"                      
								className="input-label"
								>
								<Input   placeholder="Previous State"
								disabled
								maxLength={150}
								className='cust-input'/>
								</Form.Item>
							    </Col>}
								{(deposit && this.state.stateChange.status !== "Submitted" && this.state.stateChange.status !== "Pending" && this.state.stateChange.status !== "Swept" && this.state.stateChange.status !=="Compliance Check" &&
									<Col xs={24} sm={24} md={12}>
										<Form.Item
											name="pCommisionValue"
											label={<div>Provider fees

											</div>}
											 rules={[                       
												{
												  validator: this.validateNumber
												}
											  ]}
											className="input-label"
										>
											<NumberFormat
												decimalScale={2}
												className="cust-input"
												disabled={
													this.state.selectedObj.status === "Approved" || this.state.selectedObj.status === "Rejected"
														? true
														: false
												}
												customInput={Input}
												thousandSeparator={true}
												prefix={""}
												placeholder="Provider fees"
												allowNegative={false}
												maxlength={13}
											/>
										</Form.Item>
									</Col>
								)}

								{(this.state.stateChange.status !== "Submitted" && this.state.stateChange.status !== "Pending" && this.state.stateChange.status !== "Swept" && this.state.stateChange.status !=="Compliance Check" &&
									<Col xs={24} sm={24} md={12}>
										<Form.Item
											name="sweepSourceCommisionValue"
											label={<div>Sweep at source

											</div>}
											 rules={[                       
												{
												  validator: this.validateNumber
												}
											  ]}
											className="input-label"
										>
											<NumberFormat
												decimalScale= {deposit ? 2 : 8}
												className="cust-input"
												disabled={
													this.state.selectedObj.status === "Approved" || this.state.selectedObj.status === "Rejected"
														? true
														: false
												}
												customInput={Input}
												thousandSeparator={true}
												prefix={""}
												placeholder="Sweep at source"
												allowNegative={false}
												maxlength={13}
											/>
										</Form.Item>
									</Col>
								)}

								{(this.state.stateChange.status !== "Submitted" && this.state.stateChange.status !== "Pending" && this.state.stateChange.status !== "Swept" && this.state.stateChange.status !=="Compliance Check" &&
									<Col xs={24} sm={24} md={12}>
										<Form.Item
											name="sweepDestinationCommissionValue"
											label={<div>Sweep at destination

											</div>}
											 rules={[                       
												{
												  validator: this.validateNumber
												}
											  ]}
											className="input-label"
										>
											<NumberFormat
												decimalScale={deposit ? 2 : 8}
												className="cust-input"
												disabled={
													this.state.selectedObj.status === "Approved" || this.state.selectedObj.status === "Rejected"
														? true
														: false
												}
												customInput={Input}
												thousandSeparator={true}
												prefix={""}
												placeholder="Sweep at destination"
												allowNegative={false}
												maxlength={13}
											/>
										</Form.Item>
									</Col>
								)}
								{((this.state.selectedObj.state === "Approved"||this.state.selectedObj.status=="Approved" || this.state.stateChange.status=="Approved") && selectedTab===1 &&<Col xs={24} sm={24} md={12}>
									<Form.Item
										name="BankValueDate"
										className="input-label cust-datepicker-cursor"
										label="Bank Value Date"
										rules={[
											{
												required: true,
												message: "Is required"
											}
										]}
									>
										<DatePicker
											format={config?.dateFormates?.dateFormate}
											placeholder="Bank Value Date"
											className="cust-input"
											disabled={
												this.state.selectedObj.status === "Approved" ||
												(this.state.selectedObj.status && this.state.selectedObj.status.includes("Approved"))
											}
											disabledDate={(current) => current && current > moment().endOf('day')}
										/>
									</Form.Item>
								</Col>)}
								<Col xs={24}>
									{this.state.stateChange.status === "Rejected" && (
											<Form.Item
												name="rejectReason"
												label="Reason For Rejection"
												className="input-label"
												rules={[
													{

														required: true,
												        message: "Is required",
														whitespace: true,
													},
													{
														validator: validateContentRule,
													},
												]}
												>
												<TextArea
													placeholder="Reason For Rejection"
													maxLength={100}
													rows={4}
													disabled={
														this.state.selectedObj.state === "Rejected"||this.state.selectedObj.status=="Rejected"
														  ? true
														  : false
														}
													showCount
												/>
											</Form.Item>
										)}
								</Col>


								<Col xs={24}>
									{this.state.stateChange.status !== "Submitted" &&
										this.state.stateChange.status !== "Pending" && this.state.stateChange.status !== "Swept"  && this.state.stateChange.status !=="Compliance Check" && (
											<Form.Item
												name="statusRemarks"
												label="Remarks"
												className="input-label"
												rules={[
													{

														message: "Is required",
														whitespace: true,
													},
													{
														validator: validateContentRule,
													},
												]}
												>
												<TextArea
													placeholder="Remarks"
													maxLength={100}
													rows={4}
													disabled={
														this.state.selectedObj.state === "Rejected"||this.state.selectedObj.status=="Rejected" ||
														this.state.selectedObj.state === "Approved"||this.state.selectedObj.status=="Approved"
														  ? true
														  : false
														}
													showCount
												/>
											</Form.Item>
										)}
								</Col>
							</Row>
							<Form.Item className="mb-0">
								<div className="text-right">
									<Button
										type="primary"
										className="primary-btn cancel-btn mr-8"
										onClick={this.handleCancel}>
										Cancel
									</Button>
									{(this.state.selectedObj.status !== "Rejected" && this.state.selectedObj.status !== "Approved") && <Button
										type="primary"
										key="submit"
										className="primary-btn"
										htmlType="submit"
										loading={isBtnApprove}
										disabled={this.state.btnDisabled === false || isBtnApprove}
									>
										
										Save
									</Button>}
								</div>
							</Form.Item>
						</Form>
					</div>
				</Modal>
				<Modal
					title="Create Case"
					visible={caseModal}
					width={1000}
					style={{ top: 16 }}
					closeIcon={
						<Tooltip title="Close">
							<span className="icon md x c-pointer" onClick={this.hideCaseModal} />
						</Tooltip>
					}
					footer={null}
					destroyOnClose>
					<CreateCase
						FromScreen={(this.state.selectedObj.type === "Fiat" && this.state.selectedObj.type) ? 'ReceiveFiat' : 'ReceiveCrypto'}
						ScreenId={this.state.selectedObj.id}
						CreateCaseId={this.state.selectedCaseId}
						ScreenName={(this.state.selectedObj.type === "Fiat" && this.state.selectedObj.type) ? 'ReceiveFiat' : 'ReceiveCrypto'}
						closeModal={this.hideCaseModal}
					/>
				</Modal>
				<Modal
					title={<p>Info Details</p>}
					visible={this.state.cryptoModal}
					className="crypto-list"
					destroyOnClose={true}
					closeIcon={
						<Tooltip title="Close ">
							<span
								className="icon md x c-pointer"
								onClick={() =>
									this.setState({ ...this.state, cryptoModal: false })
								}
							/>
						</Tooltip>
					}
					footer={
						<Button
							type="primary"
							className="primary-btn cancel-btn"
							onClick={() =>
								this.setState({ ...this.state, cryptoModal: false })
							}>
							Close
						</Button>
					}>
					<Info id={this.state.selectedId} type={this.state.selectedModal} screen={'Deposit'} />
				</Modal>
				
				<Modal
					title={
						'Fill Gas'
					}
					visible={this.state.fillGasModal}
					closeIcon={
						<Tooltip title="Close">
							<span className="icon md x c-pointer" 
							onClick={this.hideFillGasModal}
							/>
						</Tooltip>
					}
					footer={null}>

					<div>
						{this.state.stateLoading && <Loader />}
						<Form
							ref={this.formref}
							initialValues={this.state.fillGasObj}
							className="ant-advanced-search-form"
							autoComplete="off"
							onFinish={this.transfer}>
							{this.getAppWarningMsg()}
							<div>
							<p>
									<span
										className="fw-600 fs-14">
										 Available Balance:	 <NumberFormat value={this.state.fillGasObj?.avilableBalance?this.state.fillGasObj?.avilableBalance:"0"} decimalSeparator="." displayType={'text'} thousandSeparator={true} decimalScale={8}/>
									</span>
								</p>
								<Row gutter={24} className="mb-24 pb-24 border-bottom">
									<Col xs={24} sm={24} md={12}>
										<Form.Item
											name="amount"
											label={<div>Amount
											</div>}
											className="input-label"
											rules={[
												{
													required: true,
													message: "Is required",
													whitespace: true,
												},
												{
													validator: validateContentRule,
												},
											]}>
											<NumberFormat
												decimalScale={8}
												className="cust-input"
												customInput={Input}
												thousandSeparator={true}
												prefix={""}
												placeholder="Amount"
												allowNegative={false}
												maxlength={13}
											/>
										</Form.Item>
									</Col>
								</Row>
								</div>
							<Form.Item className="text-right mb-0">
								<Button
									type="primary"
									className="primary-btn cancel-btn"
									onClick={this.hideFillGasModal}
								>
									Cancel
								</Button>
								<Button
									type="primary"
									className="primary-btn ml-8"
									htmlType="submit"
									loading={isBtnApprove}
								>
									Transfer             
									 </Button>
							</Form.Item>
						</Form>
					</div>
				</Modal>

		<Modal
          title={
          "Confirm Reset Sweep"
          }
          visible={this.state.sweepModal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" 
			  	onClick={() =>
			      this.setState({ ...this.state, fillGasModal: false, sweepModal: false, selection: [] })}/>
            </Tooltip>
          }
          footer={
            <>
              <Button
                type="primary"
                className="primary-btn cancel-btn"
				onClick={() =>
					this.setState({ ...this.state, fillGasModal: false, sweepModal: false, selection: [] })}>
                No
              </Button>
              <Button
                type="primary"
                className="primary-btn"
                onClick={this.saveSweep}
                loading={isSweepLoading}
              >
                Yes
              </Button>

            </>
          }
        >
          <p className="fs-16 mb-0 ">
            {
              this.getAppWarningMsg()
            }
          </p >
          <p className="fs-16 mb-0">
            Do you really want to reset {" "}
            <span className="text-red fw-500">
              {this.state.selectedObj?.userName}
            </span>{" "} sweep ?
          </p>
        </Modal>
			</>
		);
	}
}
const connectStateToProps = ({ oidc, userConfig, permissions }) => {
	return {
		userConfig: userConfig.userProfileInfo,
		oidc,
		trackAuditLogData: userConfig.trackAuditLogData,
		permissions
	};
};
export default connect(connectStateToProps, (dispatch) => {
	return { dispatch };
})(Deposit);
