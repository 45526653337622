import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, message, Alert, Select} from 'antd';
import { getEditData, saveRole, getAppLp, getAppDetails } from './api';
import Loader from '../loader.component';
import { useHistory } from "react-router";
import { connect } from 'react-redux';
import apiCalls from '../../api/apiCalls';
import { setCurrentAction } from '../../reducers/actionsReducer';
import { validateContentRule } from '../../utils/custom.validator'
import { publishShowActions } from '../grid.component/subscribir';
import Moment from 'react-moment';
import config from "../../config";

const RolesDetails = (props) => {
    const [form] = Form.useForm();
    const useDivRef = React.useRef(null);
    const [rolesObject, setRolesObject] = useState({});
    const [obj, setObj] = useState([]);//only permissions list obj  
    const [mode, setMode] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const history = useHistory();
    const [errorRoleMsg, setErrorRoleMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [app, setApp] = useState([])
    const [appData, setAppData] = useState({})
    const [groupByDetails,setGroupByDetails]=useState([])
    useEffect(() => {
        publishShowActions(false);
        if (props.match.params.mode === 'view') {
            props.setAction(null)
        } else if (props.match.params.id === "00000000-0000-0000-0000-000000000000") {
            props.setAction('add')
        } else {
            props.setAction('edit')
        }
        window.scrollTo(0, 20);
        setMode(props.match.params.mode);
        if (props.match.params.id !== "00000000-0000-0000-0000-000000000000") {
            getAppLuDetails()
        } else {
            getAppLpData()
        }
    }, []);//eslint-disable-line react-hooks/exhaustive-deps
    const getAppLpData = async () => {
        let response = await getAppLp()
        if (response.ok) {
            setApp(response.data)
        } else {
            setErrorRoleMsg(apiCalls.isErrorDispaly(response))
            useDivRef.current.scrollIntoView();
        }
    }


    const handleAppChange = (e) => {
        let data = app.find((item) => item.name === e)
        setAppData(data)
        getAppLuDetails(data.id)
    }
    const getAppLuDetails = async (id) => {
        if (props.match.params.id === "00000000-0000-0000-0000-000000000000") {
            setIsLoading(true)
            let response = await getAppDetails(id);
            if (response.ok) {
                setGroupByDetails(response.data.features.filter((a, items) => response.data.features.findIndex((s) => a.group === s.group) === items))
                let objs = response.data
                setIsLoading(false)
                setObj(response.data);
                setRolesObject(objs);
            } else {
                setErrorRoleMsg(apiCalls.isErrorDispaly(response))
                useDivRef.current.scrollIntoView();
            }
        }
        else {
            setIsLoading(true)
            let res = await getAppLp()
            let response = await getEditData(props.match.params.id);
            if (response.ok) {
                setGroupByDetails(response.data.features.filter((a, iData) => response.data.features.findIndex((s) => a.group === s.group) === iData))
                let objs = response.data;
                setObj(response.data);
                setRolesObject(objs);
                let appName = res.data.find((item) => item.id === response.data.appId)
                form.setFieldsValue({ "roleName": props.match.params.roleName, "appName": appName.name });

                for (var i = 0; i <= response.data.features.length - 1; i++) {
                    if ((response.data.features[i].actions != null && response.data.features[i].actions.length >= 1)) {
                        if (response.data.features[i].actions == null) {
                            response.data.features[i].check = true;
                        } else {
                            let count = 0;
                            for (var k in response.data.features[i].actions) {
                                if (response.data.features[i].actions[k].values) {
                                    count = count + 1;
                                    if(count>1){
                                        response.data.features[i].actions[0].viewCheck = true;
                                    }
                                }
                                
                            }
                            if (count === response.data.features[i].actions.length) {

                                response.data.features[i].check = true;
                            } else {
                                response.data.features[i].check = false;

                            }
                            
                        }
                    } else {
                        response.data.features[i].check = true;
                    }
                }
                setIsLoading(false)
            } else {
                setErrorRoleMsg(apiCalls.isErrorDispaly(response))
                useDivRef.current?.scrollIntoView();
            }

        }
    }
  
    const backToRoles = () => {
        history.push('/roles');
        setErrorRoleMsg(null)
    }

    const checkAllChange = (event, index) => {
        setBtnDisabled(false);
        let _rolesObj = { ...rolesObject };
        if (!event.target.checked) {
            _rolesObj.features[index].check = false
        } else {
            _rolesObj.features[index].check = true
        }
        if (_rolesObj.features[index].actions) {
            for (var i = 0; i <= _rolesObj.features[index].actions.length - 1; i++) {
                _rolesObj.features[index].actions[i].values = event.target.checked;
            }
            let action1=  _rolesObj.features[index].actions.filter((item)=>item.permissionName!=="view"&&item.values===true)
            if(action1.length>0){
                _rolesObj.features[index].actions[0].viewCheck = true
            } else{
                _rolesObj.features[index].actions[0].viewCheck = false
            }
        }
        if(_rolesObj.features[index].recStatus!=='Added'){
            _rolesObj.features[index].recStatus = 'Modified';
        }
        setRolesObject(_rolesObj);
        form.setFieldsValue({ "role.check": _rolesObj.features });
    }

    const checkActionChange = (event, index, action) => {
        setBtnDisabled(false);
        let changeObj = { ...obj };
        if (!event.target.checked) {
            changeObj.features[index].check = false;
        }
        for (var i = 0; i <= changeObj.features[index].actions.length - 1; i++) {
            if (changeObj.features[index].actions[i].permissionName === action) {
                changeObj.features[index].actions[i].values = event.target.checked;
                if(changeObj.features[index].actions[i].permissionName!=="view"){
                    changeObj.features[index].actions[0].values = true
                    
                }
                let action1=  changeObj.features[index].actions.filter((item)=>item.permissionName!=="view"&&item.values===true)
                if(action1.length>0){
                  changeObj.features[index].actions[0].viewCheck = true
                } else{
                    changeObj.features[index].actions[0].viewCheck = false
                }
                
            }

        }
        if(changeObj.features[index].recStatus!=='Added'){
            changeObj.features[index].recStatus = 'Modified';
        }
        setObj(changeObj);
        let _updatedObj = { ...rolesObject };// when click on checkbox - for checked/unchecked
        if (changeObj.features[index].actions != null && changeObj.features[index].actions.length >= 1) {
            if (changeObj.features[index].actions == null) {
                changeObj.features[index].check = true;
            } else {
                let count = 0;
                for (var k in changeObj.features[index].actions) {
                    if (changeObj.features[index].actions[k].values) {
                        count = count + 1;
                    }
                }
                if (count === changeObj.features[index].actions.length) {
                    changeObj.features[index].check = true;
                } else {
                    changeObj.features[index].check = false;
                }
            }
        } else {
            changeObj.features[index].check = true;
        }
        _updatedObj = changeObj;
        setRolesObject(_updatedObj);
        form.setFieldsValue({ "action.values": changeObj.feature });
    }

    const saveRolesDetails = async (fieldsValue) => {
        setErrorRoleMsg(null);
        setBtnDisabled(true)
        let saveObj;
        var pattern = new RegExp(/^[a-zA-Z0-9\-\s]+$/);
        if (!pattern.test(fieldsValue.roleName)) {
            setIsLoading(false)
            setErrorRoleMsg('Role Name should not contain special characters')
            setBtnDisabled(false)
            useDivRef.current.scrollIntoView();
        } else {
            setErrorRoleMsg('')
            if (props.match.params.id === "00000000-0000-0000-0000-000000000000") {
                saveObj = {
                    id: "00000000-0000-0000-0000-000000000000",
                    roleName: fieldsValue.roleName,
                    appId: appData.id,
                    createdBy: props.userConfig?.userName,
                    createddate: new Date(),
                    status: "Active",
                    Features: rolesObject.features,
                }
            } else {
                saveObj = {
                    id: props.match.params.id,
                    roleName: fieldsValue.roleName,
                    appId: rolesObject.appId,
                    modifiedBy: props.userConfig?.userName,
                    modifieddate: new Date(),
                    status: rolesObject?.status,
                    Features: rolesObject.features,
                }
            }
            let cnt = 0;
            for (var k in saveObj.Features) {
                for (var l in saveObj.Features[k].actions) {
                    if (saveObj.Features[k].actions[l].values) {
                        cnt = cnt + 1
                    }
                }
            }
            if (cnt === 0) {
                setIsLoading(false)
                setErrorRoleMsg('User must have at least one role permission')
                useDivRef.current.scrollIntoView();
                setBtnDisabled(false)
            }
            else {
                setErrorRoleMsg('')
                setErrorRoleMsg(null)
                saveObj.customerId = props.userConfig?.id;
                saveObj.info = JSON.stringify(props.trackAuditLogData);
                let response = await saveRole(saveObj);
                if (response.ok) {
                    setBtnDisabled(false);
                    message.destroy();
                    message.success({
                        content: 'Roles saved successfully',
                        className: 'custom-msg',
                        duration: 4
                    });
                    apiCalls.trackEvent({
                        Action: props.match.params.id === "00000000-0000-0000-0000-000000000000" ? "Role Saved" : "Role Updated",
                        Feature: "Roles",
                        Remarks: props.match.params.id === "00000000-0000-0000-0000-000000000000" ? "Role Saved" : "Role Updated",
                        FullFeatureName: "Roles",
                        userName: props.userConfig.userName,
                        id: props.userConfig.id
                    });
                    history.push('/roles');
                    setErrorRoleMsg(null);
                } else {

                    setBtnDisabled(false);
                    setErrorRoleMsg(apiCalls.isErrorDispaly(response))
                }
               
                setBtnDisabled(false);
            }
        }
    }

    const AppOptions = app?.map((item, indx) => (
        <Select.Option key={indx} value={item.name}>
            {item.name}
        </Select.Option>
    ));
    return (
        <>
            <div ref={useDivRef}></div>

            {errorRoleMsg !== undefined && errorRoleMsg !== null && (
                <Alert className="w-100 mb-16" type="error" showIcon description={errorRoleMsg} />
            )}
            <Form
                initialValues={rolesObject}
                form={form}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={saveRolesDetails}
                autoComplete="off"
                scrollToFirstError

            >
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="roleName"
                            label="Role Name"
                            className="input-label"
                            rules={[
                                {
                                    required: true,
                                    message: 'Is required',
                                    whitespace: true,
                                }, {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <Input disabled={(mode == "view" ||obj.roleName ==='Customer') ? true : false} maxLength={20} placeholder="Role Name" className="cust-input" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>

                        <Form.Item
                            name="appName"
                            label="App Name"
                            className="input-label"
                            rules={[
                                {
                                    required: true,
                                    message: "Is required",
                                    whitespace: true
                                }
                            ]}
                        >
                            <Select
                                disabled={(mode == "view" || mode == "edit") ? true : false}
                                className={`cust-input ${(mode == "view"|| mode == "edit") && "w-80 cust-input-disabled"} `}
                                onChange={(e) => { handleAppChange(e) }}
                                placeholder="App Name"
                            >
                                {AppOptions}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                {isLoading ? <Loader /> : <>
                    {rolesObject.features && 

                    <table className="roles-custom text-left mt-24 w-100">
                       
                        <tr>
                            <th></th>
                            <th>Screen Name</th>
                            <th colspan="2">Actions</th>    
                        </tr>
                        {groupByDetails?.map((item,idx)=>
                            <>{groupByDetails.length>1 && <tr key={idx}>
                                <td style={{ width: '50px' }}>
                                </td>
                                <td><strong>{item.group}</strong></td>
                                <td></td>
                            </tr>}
            
                        {rolesObject.features?.map((feature, idxd) => {
                            if(item.group === feature.group){
                        return <tr key={idxd}>
                            <td style={{ width: '50px' }}>
                                <label className=" custom-checkbox">
                                    <Form.Item
                                        name="role.check"
                                        className="mb-0"
                                    >
                                        <input
                                            type="checkbox"
                                            checked={feature.check}
                                            onChange={(e) => checkAllChange(e, idxd)}
                                            disabled={(mode == 'view') ? true : false}
                                        />
                                        <span></span>
                                    </Form.Item>
                                </label>
                            </td>
                            <td>{feature?.featureName}</td>

                            <td>
                                <div className="">
                                    {feature?.actions?.map((action, index) => 
                                    <div className="mr-16 checklist-custom-width" key={index}>
                                        <div className="custom-checkbox action-check">
                                            <Form.Item
                                                name="action.values"
                                                className="mb-0"
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={action.values}
                                                    onChange={(e) => checkActionChange(e, idx, action.permissionName)}
                                                    disabled={(mode == 'view' || ( action.viewCheck)) ? true : false}
                                                />
                                                <span></span>
                                            </Form.Item>
                                            &nbsp;<span >{action?.toolTip}</span>
                                        </div>
                                    </div>)}
                                    {!feature?.actions && <span></span>}
                                </div>
                            </td>
                        </tr>}
                        })}</>
                        )}

                    </table>
                    }
                    </>
                    }
  <Row gutter={24}>
                                {rolesObject?.createdBy && (
                                    <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                                        <div className="record-history d-flex align-center ml-0">
                                        <div className="record-history-text">
                                            {rolesObject?.createdBy} created this on {"  "}
                                            <Moment format={config?.dateFormates?.dateTimeFormate}>
                                                {rolesObject?.createdDate
                                                    ? apiCalls.convertUTCToLocalTime(
                                                        rolesObject?.createdDate
                                                    )
                                                    : rolesObject?.createdDate}
                                            </Moment></div>
                                        </div>
                                    </Col>
                                )}

                                {rolesObject?.modifiedBy && (
                                    <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                                        <div className="record-history d-flex align-center ml-0">
                                            <div className="record-history-text">
                                                {rolesObject?.modifiedBy} modified this on {" "}
                                                <Moment format={config?.dateFormates?.dateTimeFormate}>
                                                    {rolesObject?.modifiedDate
                                                        ? apiCalls.convertUTCToLocalTime(
                                                            rolesObject?.modifiedDate
                                                        )
                                                        :rolesObject?.modifiedDate}
                                                </Moment>
                                            </div>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                <div className="text-right mt-24">
                    {(mode != 'view') ? <Button type="primary" className="primary-btn" htmlType="submit"
                     loading={btnDisabled}
                    >
                        Save</Button> : ''}
                    <Button
                        type="primary"
                        className="primary-btn cancel-btn"
                        style={{ margin: '0 8px' }}
                        onClick={backToRoles}
                    >
                        Cancel
                    </Button>
                </div>
            </Form>
        </>
    )

}

const connectDispatchToProps = dispath => {
    return {
        setAction: (val) => {
            dispath(setCurrentAction(val))
        },
        dispath
    }
}
const connectStateToProps = ({ oidc, userConfig }) => {
    return { oidc, userConfig: userConfig.userProfileInfo, trackAuditLogData: userConfig.trackAuditLogData }
}
export default connect(connectStateToProps, connectDispatchToProps)(RolesDetails);