import React, { Component } from "react";
import List from "../grid.component";
import {
  Tooltip,
  Modal,
  message,
  Button,
  Alert,
  Form,
  Row,
  Col,
  Input
} from "antd";
import apiCalls from "../../api/apiCalls";
import { connect } from "react-redux";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { setCurrentAction } from "../../reducers/actionsReducer";
import Loader from "../loader.component";
import { validateContentRule } from "../../utils/custom.validator";
import { saveApproveCancel } from "./api";

class MassPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isCheck: false,
      selectedObj: {},
      selection: [],
      modal: false,
      isValidFile: true,
      stateLoading: true,
      remarkStatus: "",
      gridUrl: process.env.REACT_APP_GRID_API + "MassPayments/GetMassPayments",
      param: { status: "Success" },
      Remarks: "",
      massPayments:true,
      obj: {
        Id: "",
        ModifyBy: "",
        Status: [],
        Remarks: ""
      },
      alert: false,
      errorMessage: ""
    };
    
    this.gridRef = React.createRef();
    this.formref = React.createRef();
  }

  gridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      customCell: (props) => (
        <td>
          {" "}
          <label className="text-center custom-checkbox">
            <input
              id={props.dataItem.id}
              name="isCheck"
              type="checkbox"
              checked={this.state.selection.indexOf(props.dataItem.id) > -1}
              onChange={(e) => this.checkChange(props, e)}
            />
            <span></span>{" "}
          </label>
        </td>
      )
    },
    {
      field: "name",
      title: "Batch Name",
      filter: true,
      customCell: (props) => (
        <td>
          <div
            className="gridLink"
            onClick={() => this.massPaymentsView(props)}
          >
            {" "}
            {props.dataItem.name}
          </div>
        </td>
      )
    },
    { field: "createdDate", title: "Date", filter: true, filterType: "date" },
    { field: "paymentStatus", title: "Payment Status", filter: true },
    { field: "remarks", title: "Remarks", filter: true }
  ];
  componentDidMount = () => {
    this.MassPaymentsTrack();
  };
  MassPaymentsTrack = () => {
    apiCalls.trackEvent({
      Type: "Admin",
      Action: " Mass Payments grid page view",
      Username: this.props.userConfig?.userName,
      MemeberId: this.props.userConfig?.id,
      Feature: " Mass Payments",
      Remarks: " Mass Payments grid page view",
      Duration: 1,
      Url: window.location.href,
      FullFeatureName: " Mass Payments"
    });
  };

  massPaymentsView = (e) => {
    const rowId = e.dataItem.id;
    this.props.history.push("/masspayments/" + rowId);
    this.props.dispatch(
      setBreadcrumb({ key: "/masspayments/" + rowId, val: e.dataItem.name })
    );
    apiCalls.trackEvent({
      Action: "Mass Payment details page View",
      Feature: "Mass Payments",
      Remarks: "Mass Payment details page view",
      FullFeatureName: "Mass Payments",
      userName: this.props.userConfig.userName,
      id: this.props.userConfig.id
    });
  };

  checkChange = (prop, e) => {
    const rowObj = prop.dataItem;
    const value =
      e.currentTarget.type === "checkbox"
        ? e.currentTarget.checked
        : e.currentTarget.value;
    const name = e.currentTarget.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowObj.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowObj.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowObj,
      selection
    });
  };
  success = () => {
    message.success({
      content:
        "Record " +
        (this.state.selectedObj.status === "Active"
          ? "deactivated"
          : "activated") +
        " successfully",
      className: "custom-msg",
      duration: 1
    });
  };

  handleCancel = (e) => {
    this.props.dispatch(setCurrentAction(null));
    this.setState({
      ...this.state,
      modal: false,
      selection: [],
      isCheck: false,
      error: null
    });
    this.formref.current.resetFields();
  };

  editMassPayments = () => {
    if (!this.state.isCheck) {
      this.setState({ alert: true });
      setTimeout(() => this.setState({ alert: false }), 1000);
    } else {
      const obj = this.state.selectedObj;
      let val = obj.id;
      if (this.state.selectedObj.status === "Inactive") {
        this.props.history.push("/masspayments/" + val + "/disabled");
        this.props.dispatch(
          setBreadcrumb({ key: "/masspayments/" + val, val: obj.title })
        );
      } else {
        this.props.history.push("/masspayments/" + val);
        this.props.dispatch(
          setBreadcrumb({ key: "/masspayments/" + val, val: obj.title })
        );
      }
    }
    apiCalls.trackEvent({
      Action: "Mass Payment edit page view",
      Feature: "Mass Payments",
      Remarks: "Mass Payment edit page view",
      FullFeatureName: "Mass Payments",
      userName: this.props.userConfig.userName,
      id: this.props.userConfig.id
    });
  };

  approvedMassPayments = () => {
    if (!this.state.isCheck) {
      this.setState({ alert: true });
      setTimeout(() => this.setState({ alert: false }), 1000);
    } else {
      this.setState(
        { ...this.state, modal: true, Remarks: null, remarkStatus: "Approved" },
        () => {
          this.setState({
            ...this.state,
            stateLoading: true,
            Remarks: null,
            remarkStatus: "Approved"
          });
          setTimeout(
            () =>
              this.setState({
                ...this.state,
                stateLoading: false,
                Remarks: null,
                remarkStatus: "Approved"
              }),
            1000
          );
        }
      );
    }
    apiCalls.trackEvent({
        Action: "Mass Payment approval page view",
        Feature: "Mass Payments",
        Remarks: "Mass Payment approval page view",
        FullFeatureName: "Mass Payments",
        userName: this.props.userConfig.userName,
        id: this.props.userConfig.id
      });
  };

  cancelledMassPayments = () => {
    if (!this.state.isCheck) {
      this.setState({ alert: true });
      setTimeout(() => this.setState({ alert: false }), 1000);
    } else {
      this.setState(
        {
          ...this.state,
          modal: true,
          Remarks: null,
          remarkStatus: "Cancelled"
        },
        () => {
          this.setState({
            ...this.state,
            stateLoading: true,
            Remarks: null,
            remarkStatus: "Cancelled"
          });
          setTimeout(
            () =>
              this.setState({
                ...this.state,
                stateLoading: false,
                Remarks: null,
                remarkStatus: "Cancelled"
              }),
            1000
          );
        }
      );
    }
    apiCalls.trackEvent({
        Action: "Mass Payment cancelled page view",
        Feature: "Mass Payments",
        Remarks: "Mass Payment cancelled page view",
        FullFeatureName: "Mass Payments",
        userName: this.props.userConfig.userName,
        id: this.props.userConfig.id
      });
  };

  handleChange = (e) => {
    this.setState({ ...this.state, Remarks: e.target.value });
  };

  saveApproveCancel = async (obj) => {
    this.setState({ ...this.state, isLoading: true });
    this.state.obj.Id = this.state.selection[0];
    this.state.obj.ModifyBy = this.props.oidc?.user?.profile?.unique_name;
    this.state.obj.Status = this.state.remarkStatus;
    this.state.obj.Remarks = this.state.Remarks;
    this.state.info = JSON.stringify(this.props.trackAuditLogData);

    if (this.state.Remarks) {
      
      let response = await saveApproveCancel(this.state.obj);
      if (response.ok) {
        message.destroy();
        message.success({
          content: "Mass payments saved successfully",
          className: "custom-msg",
          duration: 0.5
        });
        this.setState({
          ...this.state,
          modal: false,
          isLoading: true,
          selection: false,
          check: false,
          error: null
        });
        this.props.history.push("/masspayments");
      } 
      else {
        this.setState({
          ...this.state,
          isLoading: false,
          error: response.status === 401 ? response.data.message : response.data,
        });
      }
    }
    

    
  };

  onActionClick = (key) => {
    const actions = {
      edit: "editMassPayments",
      Approved: "approvedMassPayments",
      Cancelled: "cancelledMassPayments"
    };
    this[actions[key]]();
  };

  render() {
    const { gridUrl, param } = this.state;
    return (
      <>
        {this.state.alert && (
          <div className="custom-alert">
            <Alert
              message="Please select the one record"
              type="warning"
              showIcon
              closable={false}
            />
          </div>
        )}

        <List
          showActionBar={true}
          onActionClick={(key) => this.onActionClick(key)}
          pKey={"masspayments"}
          url={gridUrl}
          additionalParams={param}
          ref={this.gridRef}
          columns={this.gridColumns}
        />
        <Modal
          title="Confirm Approve/Cancel?"
          visible={this.state.modal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.handleCancel} />
            </Tooltip>
          }
          footer={null}
        >
          <div className="">
            {this.state.stateLoading && <Loader />}
            <Form
              ref={this.formref}
              className="ant-advanced-search-form"
              autoComplete="off"
              onFinish={this.handleOk}
            >
              {this.state.error !== undefined && this.state.error !== null && (
                <Alert type="error" showIcon message={this.state.error} />
              )}
              <Row gutter={24} className="mb-24 pb-24 border-bottom">
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="Remarks"
                    label="Remarks"
                    className="input-label"
                    rules={[
                      {
                        required: true,
                        message: "Is required",
                        whitespace: true
                      },
                      {
                        validator: validateContentRule
                      }
                    ]}
                  >
                    <Input
                      placeholder="Remarks"
                      className="cust-input"
                      maxLength={50}
                      onChange={this.handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}></Col>
              </Row>
              <Form.Item className="mb-0">
                <div className="text-right">
                  <Button
                    type="primary"
                    className="primary-btn cancel-btn mr-8"
                    onClick={this.handleCancel}
                  >
                    {" "}
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    key="submit"
                    className="primary-btn"
                    htmlType="submit"
                    onClick={this.saveApproveCancel}
                  >
                    {" "}
                    Save{" "}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </>
    );
  }
}
const connectStateToProps = ({ breadCrumb, oidc, userConfig }) => {
  return {
    breadCrumb,
    oidc,
    userConfig: userConfig.userProfileInfo,
    trackAuditLogData: userConfig.trackAuditLogData
  };
};

export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(MassPayments);
