import { bankApi,clientApi } from "../../api/index";
import { ApiControllers } from "../../api/config";
const getPyrrosWithdrawalDetails=(screen,id) => {
    return bankApi.get(ApiControllers.bank + `${screen}/${id}`);
}
const getEvolveWithdrawalDetails=(id) => {
    return bankApi.get(ApiControllers.bank + `GetEvolveWithdrawalDetails/${id}`);
}
const getFileURL = (obj) => {
    return clientApi.post(ApiControllers.customers + `FetchFile`, obj)
}
export {getPyrrosWithdrawalDetails,getEvolveWithdrawalDetails,getFileURL}