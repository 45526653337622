import React, { useEffect, useState } from "react";
import { getCommissions, saveCommision,synceCommission } from "../api";
import { Modal, Button, Form, Col, Input, Row, message, Alert, Tooltip } from "antd";
import Loader from '../../../components/loader.component';
import apiCalls from '../../../api/apiCalls';
import NumberFormat from 'react-number-format';
import ComissionCheckBoxModal from "../../../utils/comissionCheckBoxModal";
import { publishShowActions } from "../../grid.component/subscribir";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { validateContentRule } from '../../../utils/custom.validator'
const AddCommissions = (props) => {
  const [commissionData, setCommissionData] = useState(null);
  const [form] = Form.useForm();
  const history = useHistory();
  const [syncItem,setSyncItem]=useState(null)
  const [suisseBaseCreditvisible, setSuisseBaseCreditvisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [type] = useState(props.match.params.type);
  const [loadingBtn, setBtnLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [commissionList, setCommissionList] = useState([])
  const [commissionDataList, setCommissionDataList] = useState([])
  const [errorMessageVisible, setErrorMessageVisible] = useState(false)
  const [isCheckBoxModal, setIsCheckBoxModal] = useState(false);
  const [checkBoxErrorMsg, setCheckBoxErrorMsg] = useState(null);
  const [syncBtnLoading, setSyncBtnLoading] = useState(false);
  const [inputFieldChange, setInputFieldChange] = useState(false);
  const [cancelWarningVisible, setCancelWarningVisible] = useState(false);
  const [sycnFlag,setSyncFlag]=useState(false)
  const [syncInfoPopup,setSyncInfoPopUp]=useState(false)
  useEffect(() => {
    getCommissionsData();
    publishShowActions(false);
  }, []);//eslint-disable-line react-hooks/exhaustive-deps


  const getCommissionsData = async () => {
    setIsLoading(true)
    let res = await getCommissions(props.match.params.id);
    if (res.ok) {
      form.setFieldsValue({ templateName: res.data.templateName })
      setCommissionData(res.data, "commission")
      setCommissionDataList(res.data.commissionDetails)
      let products=res.data.commissionDetails
      const groupByCategory = products.reduce((group, product) => {
        const { operation } = product;
        group[operation] = group[operation] ?? [];
        group[operation].push(product);
        return group;
      }, {});
      const tableData = Object.entries(groupByCategory);
      setCommissionList(tableData)
      setIsLoading(false)
    }else{
      setErrorMsg(apiCalls.isErrorDispaly(res));
      setIsLoading(false)
      window.scrollTo(0, 0)
    }
    
  }


  const CommissionEdit = (value,item,key) => {
    setSyncFlag(true)
    let data=commissionDataList?.find((product)=>product===item)
    let _obj=data
         _obj[key]=value;
         let _objData=[_obj]
         commissionDataList?.map(obj => _objData.find(o => o.id === _obj.id) || obj);
  }
  const handleChange = (e, item, key) => {
    
    setInputFieldChange(true);
    let value=e.target.value
    if(e.target.value){
      let val = e.target.value?.replace(/[|&;$%@"<>()+,]/g, "");
      val=parseFloat(val?.replace(/,/g, ''));
      CommissionEdit(val,item,key)
    }else{
      CommissionEdit(value,item,key)
    }
   
  }

  const CommissionCheck = (items,val,keys) => {
    setSyncFlag(true)
    let data = commissionDataList?.find((product) => product === items)
    let _obj = data
    _obj[keys] = val;
    let _objData = [_obj]
    commissionDataList?.map(obj => _objData.find(o => o.id === _obj.id) || obj);
  }

  const handleCheck = (e,item, key) => {
    setSyncFlag(true)
    setInputFieldChange(true);
    const value = e.target.checked;
    CommissionCheck(item,value,key)
  }

  const handleModalClose = () => {
    setErrorMessageVisible(false)
  }
  const handleCancel = () => {
    setIsCheckBoxModal(false);
    setCheckBoxErrorMsg(null);
  }
  const saveCommission = async (values) => {
    setErrorMsg(null);
    setBtnLoading(true)
    let saveObj = Object.assign({}, commissionData);
    saveObj.id = commissionData.id || props.match.params.id
    saveObj.customerId = props.match.params.id
    saveObj.templateName = values?.templateName || commissionData?.templateName;
    saveObj.userCreated = props.userConfig.userName;
    saveObj.createdDate = commissionData.createdDate||new Date();
    saveObj.modifiedBy = props.userConfig.userName;
    saveObj.modifiedDate = commissionData.modifiedDate||new Date();
    saveObj.status = "Active";
    saveObj.commissionDetails = commissionDataList;
    saveObj.info = JSON.stringify(props.trackAuditLogData);
   
    if (saveObj.id === "00000000-0000-0000-0000-000000000000") {
      setBtnLoading(true)
      let response = await saveCommision("Commission", saveObj);
      if (response.ok) {
        setIsLoading(false);
        setInputFieldChange(false);
        setCancelWarningVisible(false);
        message.destroy();
        message.success({
          content: 'Temaplate saved sucessfully.',
          className: "custom-msg",
          duration: 3
        })
        props.history.push('/commissions')
      }
      else {
         setErrorMsg(apiCalls.isErrorDispaly(response))
        setIsLoading(false)
        setBtnLoading(false)
        window.scrollTo(0, 0)
      }
    }
    else if (saveObj.id != "00000000-0000-0000-0000-000000000000") {
      setBtnLoading(true)
      let response = await saveCommision("Commission", saveObj);
      if (response.ok) {
        setIsLoading(false)
        message.destroy();
        message.success({
          content: 'Template saved successfully.',
          className: "custom-msg",
          duration: 3
        })
        props.history.push('/commissions')
      }
      else {
         
         if(response.data.title=="Commissions not selected for one or more fields"){
          setIsCheckBoxModal(true);
          setCheckBoxErrorMsg("Commissions not selected for one or more fields.")
         }else if(response.data.title=="Please enter value in selected respective section"){
          setIsCheckBoxModal(true);
          setCheckBoxErrorMsg("Please enter value in selected respective section.")
         }else{
          setErrorMsg(apiCalls.isErrorDispaly(response))
         }
        setIsLoading(false)
        setBtnLoading(false)
        window.scrollTo(0, 0)
      }
    }
    setBtnLoading(false)
  }

  const putCommissionsSync = async () => {
    setErrorMsg(null);
     setSyncBtnLoading(true);
    
    if(!sycnFlag){
      let response = await synceCommission(syncItem?.id);
      if (response.ok) {
        message.success({
          content: 'Synced successfully',
          className: "custom-msg",
          duration: 3
        })
        setSuisseBaseCreditvisible(false)
        getCommissionsData();
        setSyncBtnLoading(false);
      } else {
        setSyncBtnLoading(false);
        setErrorMsg(apiCalls.isErrorDispaly(response));
        window.scrollTo(0, 0)
      }
    }
     
  }
  const handleSuisseBaseCreditModal = (item) => {
    setSyncItem(item)
    setSuisseBaseCreditvisible(true)
  }
  const handleModalCloseCreidit = () => {
    setSuisseBaseCreditvisible(false)
  }


  const handleSyncConfirm = () => {
    if(sycnFlag){
      setSyncInfoPopUp(true)
      setSuisseBaseCreditvisible(false)
  }else{
    putCommissionsSync();
    setSuisseBaseCreditvisible(false)
  }
  }

  const handleSyncclose=()=>{
    setSyncInfoPopUp(false)
  }
  const handleBack = () => {
    if (inputFieldChange) {
      setCancelWarningVisible(true);
    } else {
      history.push(`/commissions`);
    }
  }

const handleBackToCommission = () => {
  setCancelWarningVisible(false);
  history.push(`/commissions`);
}
const hadleCommission = () => {
  setCancelWarningVisible(false);
 
}
  return (
    <>
    {errorMsg !== undefined && errorMsg !== null && errorMsg !== "" && 
        <div style={{ width: '100%' }}>
          <Alert
            className="w-100 mb-16"
            type="error"
            description={errorMsg}
            showIcon
          />
        </div>}
     
      <Form
        autoComplete="off"
        className="commission-form"
        initialValues={commissionData}
        form={form}
        onFinish={saveCommission}
        scrollToFirstError={true}
      >
        {type === "add" &&
          <>
            <Row className="Commission-Template customer-feesform Template-Name">
              <Col xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={8}
                xxl={8}>
                <Form.Item
                  name="templateName"
                  label="Template Name"
                  style={{paddingLeft:"10px"}}
                  rules={[
                    {
                      required: true,
                      message: 'Is required'
                    },
                    {
                      whitespace: true,
                      message: 'Is required'
                    }, {
                      validator: validateContentRule
                    }
                  ]}
                >
                  <Input type="text"
                    placeholder="Template Name"
                    maxLength={50}
                    style={{width:"290px"}}
                    className="cust-input"
                   
                    
                    />
                </Form.Item>

              </Col>
            </Row>


            {isLoading && <Loader /> || (
              <div className="addcommission">
              <table className="commision-table table-border edit-commition-table table-responsive" border="1">
                <thead><tr className="table-header-row">
                      <th style={{ width: "150px" }}></th>
                      <th className="p-0">
                        <table className="table-partner-head">
                          <tr>
                          <th colSpan={2} className="text-center">SuisseBase Fees</th>
                            <th className="table-border-right"></th>
                            <th style={{ width: "150px" }} className="table-border-right text-center" >Partner</th>
                            <th style={{ width: "150px" }} className="table-border-right text-center">Sub-Partner</th>
                            {commissionData?.templateName=="Default"&&<th style={{width:"150px"}} ></th>}
                          </tr>
                        </table>
                      </th>
                   
                  </tr></thead>
               <tbody>
                {commissionList &&commissionList?.map(([operation,items])=>(
                    <tr key={operation}>
                    <td style={{ width: "100px" }}>{operation}</td>
                    <td style={{padding:"0"}}>
                        {items?.map((item) => (<>

                          <table className="row-border " >
                            <tr>
                              <td style={{ borderRight: "1px solid #ccc",width:"200px" }}>
                                <table>
                                  <tr>
                                  <td style={{paddingTop:"30px"}}>{item.currencyType}</td>
                                  </tr>
                                  <tr>
                                  <td>{item.currency}</td>
                                  </tr>
                                </table>
                              </td>
                              <td className="inside" style={{width:"200px"}} >{item.bankName}
                              {" "} {item.isPersonalBank && <span className="file-labels ml-8 fs-12 address-label address-label-width">Personal</span>}
                              </td>
                              <td  className=" inside" >
                              <tr>
                              <td >
                                <Form.Item className="customised-input" name="minFee" style={{ width: "200px" }}>
                                  <div className="d-flex align-center mr-8">
                                    <label className="text-center custom-checkbox">
                                      <input name="check" type="checkbox" onChange={(e) => handleCheck(e, item, "isMinMax")} defaultChecked={item?.isMinMax} />
                                      <span></span>{" "}
                                    </label>
                                  </div>
                                  <NumberFormat
                                    decimalScale={2}
                                    customInput={Input}
                                    className="cust-input"
                                    onChange={(e) => handleChange(e, item, "minFee")}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    maxLength={6}
                                    defaultValue={item?.minFee}
                                    addonAfter={<span>Min</span>}
                                  />
                                </Form.Item>
                                </td>
                               
                              <td >
                                
                                <Form.Item className="customised-input" name="maxFee" style={{ width: "200px" }}>
                                <div>
                                  <NumberFormat
                                    decimalScale={2}
                                    customInput={Input}
                                    className="cust-input"
                                    onChange={(e) => handleChange(e, item, "maxFee")}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    maxLength={6}
                                    defaultValue={item?.maxFee}
                                    addonAfter={<span>Max(%)</span>}
                                  />
                                   </div>
                                </Form.Item>
                              </td>
                              </tr>
                              <tr>
                              <td >  <Form.Item className="customised-input" name="flatFee" style={{ width: "200px" }}>
                                <div className="d-flex align-center mr-8">
                                  <label className="text-center custom-checkbox">
                                    <input name="check" type="checkbox" onChange={(e) => handleCheck(e, item, "isFlat")} defaultChecked={item?.isFlat} />
                                    <span></span>{" "}
                                  </label>
                                </div>
                                <NumberFormat
                                  decimalScale={2}
                                  customInput={Input}
                                  className="cust-input"
                                  onChange={(e) => handleChange(e, item, "flatFee")}
                                  thousandSeparator={true}
                                  allowNegative={false}
                                  maxLength={6}
                                  defaultValue={item?.flatFee}
                                  addonAfter={<span>Flat</span>}
                                />
                              </Form.Item></td>
                              </tr>
                                <tr>
                                  {item.isPersonalBank && <>
                                  <td style={{ width: "150px" }} className="commition-input-bg onetime-monthly">
                                   
                                      <Form.Item className="customised-input" name="oneTimeFee" style={{ width: "200px" }}>
                                        <div className="d-flex align-center mr-8">
                                          <label className="text-center custom-checkbox">
                                            <input name="check" type="checkbox" onChange={(e) => handleCheck(e, item, "isOneTime")} defaultChecked={item?.isOneTime} />
                                            <span></span>{" "}
                                          </label>
                                        </div>
                                        <NumberFormat
                                          decimalScale={2}
                                          customInput={Input}
                                          className="cust-input"
                                          onChange={(e) => handleChange(e, item, "oneTimeFee")}
                                          thousandSeparator={true}
                                          defaultValue={item.oneTimeFee}
                                          allowNegative={false}
                                          maxLength={6}
                                          addonAfter={<span>One Time</span>}
                                        />
                                      </Form.Item>
                              </td>
                              <td style={{ width: "150px" }} className="commition-input-bg onetime-monthly">
                             
                                <Form.Item className="customised-input" name="monthlyFee" style={{ width: "200px" }}>
                                  <div className="d-flex align-center mr-8">
                                    <label className="text-center custom-checkbox">
                                      <input name="check" type="checkbox" onChange={(e) => handleCheck(e, item, "isMonthly")} defaultChecked={item?.isMonthly} />
                                      <span></span>{" "}
                                    </label>
                                  </div>
                                  <NumberFormat
                                    decimalScale={2}
                                    customInput={Input}
                                    className="cust-input"
                                    onChange={(e) => handleChange(e, item, "monthlyFee")}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    maxLength={6}
                                    defaultValue={item?.monthlyFee}
                                    addonAfter={<span>Monthly</span>}
                                  />
                                </Form.Item>

                              </td>
                              </>}
                              </tr>
                              </td>
                              <td style={{ width: "150px" }} className="inside">
                                <Form.Item className="input-addon-style customised-input" name="partnerPercentage">
                                  <div>
                                    <NumberFormat
                                      decimalScale={2}
                                      customInput={Input}
                                      className="cust-input"
                                      onChange={(e) => handleChange(e, item, "partnerPercentage")}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                      maxLength={6}
                                      defaultValue={item?.partnerPercentage}
                                      addonAfter={<span>%</span>}
                                    />
                                  </div>
                                </Form.Item>
                              </td>
                              <td style={{ width: "150px" }} className="inside">
                                <Form.Item className="input-addon-style customised-input" name="subPartnerPercentage">
                                  <div>
                                    <NumberFormat
                                      decimalScale={2}
                                      customInput={Input}
                                      className="cust-input"
                                      onChange={(e) => handleChange(e, item, "subPartnerPercentage")}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                      maxLength={6}
                                      defaultValue={item?.subPartnerPercentage}
                                      addonAfter={<span>%</span>}
                                    />
                                  </div>
                                </Form.Item>
                              </td>
                              {commissionData?.templateName=="Default"&&<td style={{ width: "150px" }}>
                                <Button type="primary" className="primary-btn" onClick={() => handleSuisseBaseCreditModal(item)}  >Sync</Button>
                              </td>}
                            </tr>
                          </table>
                        </>
                        ))}
                      </td>
                    </tr>
                ))}
               </tbody>
                </table>
              </div>
            )}
          </> ||
           <>
            <Row className="Commission-Template customer-feesform Template-Name">
              <Col xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={8}
                xxl={8}>
                <Form.Item
                  name="templateName"
                  label="Template Name"
                  style={{paddingLeft:"10px"}}
                  rules={[
                    {
                      required: true,
                      message: 'Is required'
                    },
                    {
                      whitespace: true,
                      message: 'Is required'
                    }, {
                      validator: validateContentRule
                    }
                  ]}
                >
                  <Input type="text"
                    placeholder="Template Name"
                    maxLength={50}
                    style={{width:"290px"}}
                    className="cust-input" 
                    disabled={commissionData?.templateName === 'Default'}/>
                </Form.Item>
              </Col>
            </Row>
            {isLoading ? <Loader /> : (
                <div className="addcommission">
                <table className="commision-table table-border edit-commition-table table-responsive" border="1">
                  <thead><tr className="table-header-row">
                        <th style={{ width: "150px" }}></th>
                        <th className="p-0">
                          <table className="table-partner-head">
                            <tr>
                              <th colSpan={2} className="text-center">SuisseBase Fees</th>
                              <th className="table-border-right "></th>
                              <th style={{ width: "150px" }} className="table-border-right text-center" >Partner</th>
                              <th style={{ width: "150px" }} className="table-border-right text-center">Sub-Partner</th>
                              {commissionData?.templateName=="Default"&&<th style={{width:"150px"}} ></th>}
                            </tr>
                          </table>
                        </th>
                    </tr></thead>
                 <tbody>
                  {commissionList && commissionList?.map(([operation,items])=>(
                      <tr key={operation}> 
                      <td style={{ width: "100px" }}>{operation}</td>
                      <td style={{padding:"0"}}>
                          {items?.map((item) => (<>
                            <table className="row-border " >
                              <tr>
  
                                <td style={{ borderRight: "1px solid #ccc",width:"200px" }}>
                                  <table>
                                    <tr>
                                    <td style={{paddingTop:"30px"}}>{item.currencyType}</td>
                                    </tr>
                                    <tr>
                                    <td>{item.currency}</td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="inside" style={{width:"200px"}} >{item.bankName}
                                <div>
                                {" "} {item.isPersonalBank &&<span className="file-labels  fs-12 address-label address-label-width">Personal</span>}
                                {" "} 
                                {item.status==="Inactive" && 
                                                                <span className="file-labels fs-12 address-label address-label-width in-active">Inactive</span>
                                } 
                                </div>
                                </td>
                                <td  className=" inside" >
                                <tr>
                                <td >
                                  <Form.Item className="customised-input" name="minFee" style={{ width: "200px" }}>
                                    <div className="d-flex align-center mr-8">
                                      <label className="text-center custom-checkbox">
                                        <input name="check" type="checkbox" onChange={(e) => handleCheck(e, item, "isMinMax")} defaultChecked={item?.isMinMax} disabled={item?.status === "Inactive"}/>
                                        <span></span>{" "}
                                      </label>
                                    </div>
                                    <NumberFormat
                                      decimalScale={2}
                                      customInput={Input}
                                      className="cust-input"
                                      onChange={(e) => handleChange(e, item, "minFee")}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                      maxLength={6}
                                      defaultValue={item?.minFee}
                                      addonAfter={<span>Min</span>}
                                      disabled={item?.status === "Inactive"}
                                    />
                                  </Form.Item>
                                  </td>
                                 
                                <td >
                                  
                                  <Form.Item className="customised-input" name="maxFee" style={{ width: "200px" }}>
                                  <div>
                                    <NumberFormat
                                      decimalScale={2}
                                      customInput={Input}
                                      className="cust-input"
                                      onChange={(e) => handleChange(e, item, "maxFee")}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                      maxLength={6}
                                      defaultValue={item?.maxFee}
                                      addonAfter={<span>Max(%)</span>}
                                      disabled={item?.status === "Inactive"}
                                    />
                                     </div>
                                  </Form.Item>
                                 
                                </td>
                               
                                </tr>
                                <tr>
                                <td >  <Form.Item className="customised-input" name="flatFee" style={{ width: "200px" }}>
                                  <div className="d-flex align-center mr-8">
                                    <label className="text-center custom-checkbox">
                                      <input name="check" type="checkbox" onChange={(e) => handleCheck(e, item, "isFlat")} defaultChecked={item?.isFlat}   disabled={item?.status === "Inactive"}/>
                                      <span></span>{" "}
                                    </label>
  
                                  </div>
                                  <NumberFormat
                                    decimalScale={2}
                                    customInput={Input}
                                    className="cust-input"
                                    onChange={(e) => handleChange(e, item, "flatFee")}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    maxLength={6}
                                    defaultValue={item?.flatFee}
                                    addonAfter={<span>Flat</span>}
                                    disabled={item?.status === "Inactive"}
                                  />
                                </Form.Item></td>
                                </tr>
                                
                                  <tr>
                                    {item.isPersonalBank && <>
                                    <td style={{ width: "150px" }} className="commition-input-bg onetime-monthly">
                                        <Form.Item className="customised-input" name="oneTimeFee" style={{ width: "200px" }}>
                                          <div className="d-flex align-center mr-8">
                                            <label className="text-center custom-checkbox">
                                              <input name="check" type="checkbox" onChange={(e) => handleCheck(e, item, "isOneTime")} defaultChecked={item?.isOneTime}   disabled={item?.status === "Inactive"}/>
                                              <span></span>{" "}
                                            </label>
                                          </div>
                                          <NumberFormat
                                            decimalScale={2}
                                            customInput={Input}
                                            className="cust-input"
                                            onChange={(e) => handleChange(e, item, "oneTimeFee")}
                                            thousandSeparator={true}
                                            defaultValue={item.oneTimeFee}
                                            allowNegative={false}
                                            maxLength={6}
                                            addonAfter={<span>One Time</span>}
                                            disabled={item?.status === "Inactive"}
                                          />
                                        </Form.Item>
                                </td>
                                <td style={{ width: "150px" }} className="commition-input-bg onetime-monthly">
                               
                                  <Form.Item className="customised-input" name="monthlyFee" style={{ width: "200px" }}>
                                    <div className="d-flex align-center mr-8">
                                      <label className="text-center custom-checkbox">
                                        <input name="check" type="checkbox" onChange={(e) => handleCheck(e, item, "isMonthly")} defaultChecked={item?.isMonthly}   disabled={item?.status === "Inactive"}/>
                                        <span></span>{" "}
                                      </label>
                                    </div>
  
                                    <NumberFormat
                                      decimalScale={2}
                                      customInput={Input}
                                      className="cust-input"
                                      onChange={(e) => handleChange(e, item, "monthlyFee")}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                      maxLength={6}
                                      defaultValue={item?.monthlyFee}
                                      addonAfter={<span>Monthly</span>}
                                      disabled={item?.status === "Inactive"}
                                    />
                                  </Form.Item>
  
                                </td>
                                </>}
                                </tr>
                                </td>
  
  
                                <td style={{ width: "150px" }} className="inside">
                                  <Form.Item className="input-addon-style customised-input" name="partnerPercentage">
                                    <div>
                                      <NumberFormat
                                        decimalScale={2}
                                        customInput={Input}
                                        className="cust-input"
                                        onChange={(e) => handleChange(e, item, "partnerPercentage")}
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        maxLength={6}
                                        defaultValue={item?.partnerPercentage}
                                        addonAfter={<span>%</span>}
                                        disabled={item?.status === "Inactive"}
                                      />
                                    </div>
                                  </Form.Item>
                                </td>
                                <td style={{ width: "150px" }} className="inside">
                                  <Form.Item className="input-addon-style customised-input" name="subPartnerPercentage">
                                    <div>
                                      <NumberFormat
                                        decimalScale={2}
                                        customInput={Input}
                                        className="cust-input"
                                        onChange={(e) => handleChange(e, item, "subPartnerPercentage")}
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        maxLength={6}
                                        defaultValue={item?.subPartnerPercentage}
                                        addonAfter={<span>%</span>}
                                        disabled={item?.status === "Inactive"}
                                      />
                                    </div>
                                  </Form.Item>
                                </td>
                                {commissionData?.templateName=="Default"&&<td style={{ width: "150px" }}>
                                  <Button type="primary" className="primary-btn" onClick={() => handleSuisseBaseCreditModal(item)} disabled={item?.status === "Inactive"}>Sync</Button>
                                </td>}
                              </tr>
                            </table>
  
                          </>
                          ))}
                        </td>
                      </tr>
                    
                  ))}
                 </tbody>
                  </table>
                </div>
            )}
          </>
          }
        <Modal
          title={<p>Error Message</p>}
          visible={errorMessageVisible}
          className="crypto-list"
          closeIcon={
            <span onClick={handleModalClose}
              className="icon md x c-pointer"
            />
          }
          destroyOnClose={true}

          footer={false}
        >
          <Form
            className="risk-popup"
          >
            <div>
              <p className="mb-24">Commissions not selected for one or more fields.</p>
            </div>
            <Form.Item className="text-right mb-0">
              <Button
                type="primary"
                className="primary-btn cancel-btn reject-btn"
                onClick={handleModalClose}
              >
                Back
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <div className="text-right mt-16 mb-16">
        <Button
            type="primary"
            className="primary-btn"
            htmlType="submit"
            loading={loadingBtn}
            style={{marginLeft:"10px"}}
          >
            Save
          </Button>
            <Button
                type="primary"
                className="primary-btn cancel-btn"
                style={{ margin: "0 8px" }}
                onClick={handleBack}
              >
                Cancel
              </Button>
        </div>
        <Modal
          title={<p className="mb-0">Sync</p>}
          visible={suisseBaseCreditvisible}
          className="crypto-list"
          closeIcon={
            <span onClick={handleModalCloseCreidit}
              className="icon md x c-pointer"
            />
          }
          destroyOnClose={true}

          footer={<Form.Item className="text-right mb-0">
          <Button
            type="primary"
            className="primary-btn cancel-btn reject-btn"
            onClick={handleModalCloseCreidit}
          >
            Back
          </Button>
          <Button
            type="primary"
            className="primary-btn ml-8"
            htmlType="submit"
            onClick={handleSyncConfirm}
            loading={syncBtnLoading}
          >
            Confirm
          </Button>
        </Form.Item>}
        >
          <Form
            className="risk-popup"
          >
            <div>
              <p className="mb-16">This will sync the commission fields
                across all the customers and templates.
                Are you sure you want to proceed?</p>
            </div>
        
          </Form>
        </Modal>
        <Modal title="Confirm"
          visible={cancelWarningVisible}
          closable={true}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={() => hadleCommission()} />
            </Tooltip>
          }
          footer={<>
            <Button
              type="primary"
              className="primary-btn cancel-btn"
              onClick={() => hadleCommission()}
            >
              No
            </Button>
            <Button
              type="primary"
              className="primary-btn"
              onClick={() => handleBackToCommission()}
            >
              Yes
            </Button>
          </>}>
          <h4 className="text-white-30 fs-16 fw-400">Do you want to cancel, your changes may not be save ?</h4>
        </Modal>
        <Modal title="Info"
          visible={syncInfoPopup}
          closable={true}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={() => handleSyncclose()} />
            </Tooltip>
          }
          footer={<>
            <Button
              type="primary"
              className="primary-btn"
              onClick={() => handleSyncclose()}
            >
              Ok
            </Button>
          </>}>
          <h4 className="text-white-30 fs-16 fw-400">Before sync please save your changes.</h4>
        </Modal>
      </Form>
      {isCheckBoxModal && <ComissionCheckBoxModal showModal={isCheckBoxModal} handleCloseModal={handleCancel} errorMessage={checkBoxErrorMsg}></ComissionCheckBoxModal>}
   
   
    </>
  );
}
const connectStateToProps = ({ userConfig }) => {
	return {
		userConfig: userConfig.userProfileInfo,
		trackAuditLogData: userConfig.trackAuditLogData,
	};
};

export default connect(
	connectStateToProps
)(AddCommissions);
