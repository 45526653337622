import React, { Component } from "react";
import {
	Row,
	Col,
	Typography,
	Tooltip,
	Spin,
	Modal,
	Button,Alert
} from "antd";
import apiCalls from "../../api/apiCalls";
import { connect } from "react-redux";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { updateCurrentScreen } from "../../reducers/permissionsReducer";
import config from "./config";
import NumberFormat from "react-number-format";
import DashBoardGrid from './dashboardgrid';
const { Text,Title } = Typography;
class Dashboard extends Component {
	state = {
		cryptoBalancedetails: config.cryptoBalance,
		fiatBalancedetails: config.fiatBalance,
		YesterdayPNLdetails: config.YesterdayPNL,
		thirtydaysPNLdetails: config.thirtydaysPNL,
		gasStationBalancedetails: config.gasStationBalance,
		KrakenBalancedetails: config.krakenBalance,
		fbTotalBalancedetails: config.fbTotalBalance,
		reports: [],
		c: null,
		assetAlloction: null,
		dailyPnl: null,
		profits: null,
		assetnetWorth: null,
		cryptoModal: false,
		selectedModal: "",
		KrakenBalanceAmounts: [],
		GasBalanceAmounts: [],
		GasBalanceAmountsConf: [],
		errorMsg:null,
		cryptoGbModal: false, cryptoKbModal: false, cryptoWwModal: false, cryptoSwModal: false, cryptoManualModal: false, cryptoEnygmaModal: false
	};

	componentDidMount() {
		if (this.props.history.location.pathname === "/") {
			this.props.history.push("/dashboard");
		}

		this.props.dispatch(updateCurrentScreen("dashboard"));
		this.loadKpis();
		this.loadFbKpi();
		this.loadKrakenBal();
		this.loadGasStationBalance();
		this.loadManualGasBalance();
		this.loadDashboards(30);
		this.dashboardTrack();
		this.props.dispatch(
			setBreadcrumb({
				key: "/dashboard",
				val: "Dashboard",
			})
		);

	}
	dashboardTrack = () => {
		apiCalls.trackEvent({
			Type: "Admin",
			Action: "Dashboard page view",
			Username: this.props.userConfig?.userName,
			MemeberId: this.props.userConfig?.id,
			Feature: "Dashboard",
			Remarks: "Dashboard page view",
			Duration: 1,
			Url: window.location.href,
			FullFeatureName: "Dashboard",
		});
	};
	loadDashboards = async (days) => {
		this.setState({
			...this.state,
			profits: null,
			dailyPnl: null,
			assetnetWorth: null,
			assetAlloction: null,
		});
		await Promise.all([
			apiCalls.getprofits(days).then((_res) => {
				if (_res.ok) {
					this.setState({ ...this.state, profits: _res.data });
				}
			}),
			apiCalls.getdailypnl(days).then((_dailyPnlres) => {
				if (_dailyPnlres.ok) {
					this.setState({ ...this.state, dailyPnl: _dailyPnlres.data });
				}
			}),
			apiCalls.getAssetNetwroth(days).then((assetnetWorthres) => {
				if (assetnetWorthres.ok) {
					this.setState({
						...this.state,
						assetnetWorth: assetnetWorthres.data,
					});
				}
			}),
			apiCalls.getAssetAllowcation(days).then((assetAlloctionres) => {
				if (assetAlloctionres.ok) {
					this.setState({
						...this.state,
						assetAlloction: assetAlloctionres.data,
					});
				}
			}),
		]);
	};
	loadData = async () => {
		let response = await apiCalls.getreports("getReports");
		if (response.ok) {
			this.setState({ reports: response.data,errorMsg:null });
		}else{
			this.setState({ ...this.state,errorMsg:apiCalls.isErrorDispaly(response) });
		}
	};
	loadKpis = async () => {
		let response = await apiCalls.getdshKpis("getReports");
		if (response.ok) {
			this.setState({ ...this.state, kpis: response.data,errorMsg:null });
		}else{
			this.setState({ ...this.state,errorMsg:apiCalls.isErrorDispaly(response) });
		}
	};
	loadFbKpi = async () => {
		let response = await apiCalls.getFBTotalBalance();
		if (response.ok) {
			this.setState({ ...this.state, fbTotalBalance: response.data,errorMsg:null });
		}else{
			this.setState({ ...this.state,errorMsg:apiCalls.isErrorDispaly(response) });
		}
	};

	loadKrakenBal = async () => {
		let response = await apiCalls.getKrakenBalance('kraken');
		if (response.ok) {
			let results = response?.data !== null ? response?.data?.result : null;
			let res = results !== null ? Object.entries(results).map(([key, value]) => ({ key, value })) : results;
			this.setState({
				...this.state,
				krakenBalance: response.data ? response.data : {amount: "0"},
				KrakenBalanceAmounts: res,errorMsg:null
			});
		}else{
			this.setState({ ...this.state,errorMsg:apiCalls.isErrorDispaly(response) });
		}
	};
	
	loadManualGasBalance = async () => {
		let response = await apiCalls.getManualGasBalance("Manual Gas Station");
		if (response.ok) {
			this.setState({ ...this.state, manualGasBalance: response.data,errorMsg:null});
		}else{
			this.setState({ ...this.state,errorMsg:apiCalls.isErrorDispaly(response) });
		}
	};
	loadGasStationBalance = async () => {
		let response = await apiCalls.getGasStationBalance();
		if (response.ok) {
			let results = response?.data !== null ? response.data.balance : "";
			let res = results !== null ? Object.entries(results).map(([key, value]) => ({ key, value })) : results;
			let configuration = response?.data?.configuration !== null ? response.data.configuration : "";
			let resp = configuration != null ? Object.entries(configuration).map(([key, value]) => ({ key, value })) : configuration;
			this.setState({
				...this.state,
				gasStationBalance: response.data,
				GasBalanceAmounts: res,
				GasBalanceAmountsConf: resp,errorMsg:null
			});
		}else{
			this.setState({ ...this.state,errorMsg:apiCalls.isErrorDispaly(response) });
		}
	};

	viewReport = (elem) => {
		this.props.history.push("/dashboard/reportview/" + elem.name);
		this.props.dispatch(
			setBreadcrumb({
				key: "/dashboard/reportview" + elem.name,
				val: "Report View",
			})
		);
		apiCalls.trackEvent({
			Action: "View Reports",
			Feature: "Dashboard",
			Remarks: "View Reports",
			FullFeatureName: "Dashboard View Reports",
			userName: this.props.userConfig.userName,
			id: this.props.userConfig.id,
		});
	};
	handleFbModal = (type) => {
		this.setState({ ...this.state, cryptoModal: true, })
		if (type === "fbBal") {
			setTimeout(() => this.setState({ ...this.state, selectedModal: "fbBal", }), 500);

		}
	}
	handleGbModal = (type) => {
		this.setState({ ...this.state, cryptoGbModal: true, })
		if (type === "gBal") {
			setTimeout(() => this.setState({ ...this.state, selectedModal: "gBal", }), 500);

		}
	}
	handleSwModal = (type) => {
		this.setState({ ...this.state, cryptoSwModal: true, })
		if (type === "sWall") {
			setTimeout(() => this.setState({ ...this.state, selectedModal: "sWall", }), 500);

		}
	}
	handleWwModal = (type) => {
		this.setState({ ...this.state, cryptoWwModal: true, })
		if (type === "wWall") {
			setTimeout(() => this.setState({ ...this.state, selectedModal: "wWall", }), 500);

		}
	}
	handleManualModal = (type) => {
		this.setState({ ...this.state, cryptoManualModal: true, })
		if (type === "manualWall") {
			setTimeout(() => this.setState({ ...this.state, selectedModal: "manualWall", }), 500);

		}
	}
	handleKbModal = (type) => {
		this.setState({ ...this.state, cryptoKbModal: true, })
		if (type === "kBal") {
			setTimeout(() => this.setState({ ...this.state, selectedModal: "kBal", }), 500);

		}
	}
	handleEnygmaModal = (type) => {
		this.setState({ ...this.state, cryptoEnygmaModal: true, })
		if (type === "enigmaBal") {
			setTimeout(() => this.setState({ ...this.state, selectedModal: "enigmaBal", }), 500);

		}
	}
    renderGasBalanceAmounts = (items) => {
		return items?.map((item, index) => (
		  <div className="coin-info" key={index}>
			<Text className="hisstate">{item.key}</Text>
			<Text className="hisstate">{item.value}</Text>
		  </div>
		));
	  };

	  renderManualGasamount = (gasItem)=>{
		return gasItem?.map((item) => (
			<div className="coin-info">
				<Text className="hisstate">{item.coin}</Text>
				<Text className="hisstate">{item.amount}</Text>
			</div>
		))
	  }
	getAlertMsg = () => (
		this.state.errorMsg && <div style={{ width: '100%' }}>
			<Alert className="mb-16 w-100" type="warning"
				description={this.state.errorMsg} showIcon />
		</div>
	)
	getCryptoBalance = () => (
		<Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
			{this.state.kpis ? (<div className="db-kpi">
				<Text className="db-kpi-label">{"Crypto Balance"} </Text>
				<NumberFormat
					className="db-kpi-val"
					prefix="$"
					value={this.state.kpis.cryptoBalance}
					thousandSeparator={true}
					displayType={"text"}
				/>
				<NumberFormat
					className="badge"
					prefix="BTC=$"
					value={this.state.kpis.cryptoBTC}
					displayType={"text"}
					thousandSeparator={true}></NumberFormat>
			</div>
			) : (
				<div
					className="db-kpi p-relative text-center"
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					{" "}
					<Spin />
				</div>
			)}
		</Col>
	)
	render() {
		const { cryptoModal, cryptoGbModal, cryptoKbModal, cryptoWwModal, cryptoSwModal, cryptoManualModal, cryptoEnygmaModal } = this.state;
		return (
			<>
			{this.getAlertMsg()}
				<div className="db-container">
					<div className="mb-16 bgwhite p-16">
						<DashBoardGrid screenName={"Receive"} URL={"Dashboard/DashBoardDeposit"} prop={this.props}/>
					</div>
					<div className="mb-16 bgwhite p-16">
						<DashBoardGrid screenName={"Send"} URL={"Dashboard/DashBoardWithdrawal"} prop={this.props}/>
					</div>
					<div className="mb-16 bgwhite p-16">
						<DashBoardGrid  screenName={"Address Book"} URL={"Dashboard/DashBoardAddressBook"} type={"Dashboard AddressBook"} prop={this.props}/>
					</div>
					<Row gutter={[8, 8]} className="mb-8">
					{this.getCryptoBalance()}
						<Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
							{this.state.kpis ? (<div className="db-kpi">
								<Text className="db-kpi-label">{"Fiat Balance"}</Text>
								<NumberFormat
									className="db-kpi-val"
									prefix="$"
									value={this.state.kpis.fiatBalance}
									thousandSeparator={true}
									displayType={"text"}
								/>
								<NumberFormat
									className="badge"
									prefix="BTC=$"
									value={this.state.kpis.fiatBTC}
									displayType={"text"}
									thousandSeparator={true}></NumberFormat>
							</div>
							) : (
								<div
									className="db-kpi p-relative text-center"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}>
									{" "}
									<Spin />
								</div>
							)}
						</Col></Row>
						<Row gutter={[8, 8]} className="mb-8">
						<Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
							{this.state.fbTotalBalance ? (
								<div className="db-kpi p-relative">
									<span
										className="icon md info c-pointer"
										onClick={() => this.handleFbModal("fbBal")}
										style={{ position: "absolute", right: 12, top: 12 }}
									/>

									<Text className="db-kpi-label">{"Fireblocks Balance"} </Text>
									<NumberFormat
										className="db-kpi-val"
										prefix="$"
										value={this.state.fbTotalBalance.amount}
										thousandSeparator={true}
										displayType={"text"}
									/>
								</div>
							) : (
								<div
									className="db-kpi p-relative text-center"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}>
									{" "}
									<Spin />
								</div>
							)}
						</Col>
						<Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
							{this.state.gasStationBalance ? (
								<div className="db-kpi p-relative">
									<span
										className="icon md info c-pointer"
										onClick={() => this.handleGbModal("gBal")}
										style={{ position: "absolute", right: 12, top: 12 }}
									/>
									<Text className="db-kpi-label">{"Gas Balance"} </Text>
									<NumberFormat
										className="db-kpi-val"
										prefix="$"
										value={this.state.gasStationBalance.amount}
										thousandSeparator={true}
										displayType={"text"}
									/>
								</div>
							) : (
								<div
									className="db-kpi p-relative text-center"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}>
									{" "}
									<Spin />
								</div>
							)}
						</Col>
						
						<Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
							{this.state.manualGasBalance ? (
								<div className="db-kpi p-relative">
									<span
										className="icon md info c-pointer"
										onClick={() => this.handleManualModal("manualWall")}
										style={{ position: "absolute", right: 12, top: 12 }}
									/>
									<Text className="db-kpi-label">{"Manual Gas Wallet"} </Text>
									<NumberFormat
										className="db-kpi-val"
										prefix="$"
										value={this.state.manualGasBalance?.amount}
										thousandSeparator={true}
										displayType={"text"}
									/>
								</div>
							) : (
								<div
									className="db-kpi p-relative text-center"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}>
									{" "}
									<Spin />
								</div>
							)}
						</Col>

						<Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
							{this.state.krakenBalance ? (
								<div className="db-kpi p-relative">
									{this.state.KrakenBalanceAmounts && <span
										className="icon md info c-pointer"
										onClick={() => this.handleKbModal("kBal")}
										style={{ position: "absolute", right: 12, top: 12 }}
									/>}
									<Text className="db-kpi-label">{"Kraken Balance"} </Text>
									<NumberFormat
										className="db-kpi-val"
										prefix="$"
										value={this.state.krakenBalance?.amount}
										thousandSeparator={true}
										displayType={"text"}
									/>
								</div>
							) : (
								<div
									className="db-kpi p-relative text-center"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}>
									{" "}
									<Spin />
								</div>
							)}
						</Col>						
					</Row>

					<Row gutter={[8, 8]} className="mb-16">
					</Row>					
					<Modal
						title="Crypto Currency"
						visible={cryptoModal}
						className="crypto-list"
						closeIcon={
							<Tooltip title="Close">
								<span
									className="icon md x c-pointer"
									onClick={() =>
										this.setState({ ...this.state, cryptoModal: false, selectedModal: " " })
									}
								/>
							</Tooltip>
						}
						footer={
							<Button
								type="primary"
								className="primary-btn cancel-btn"
								onClick={() =>
									this.setState({ ...this.state, cryptoModal: false, selectedModal: " " })
								}>
								Close
							</Button>
						}>
						{this.state.selectedModal === "fbBal" ? <>{
							this.state.fbTotalBalance && this.renderManualGasamount(this.state.fbTotalBalance?.fbBalDetails)
							}</> : <div
								className="db-kpi p-relative text-center"
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
							{" "}
							<Spin />
						</div>}

					</Modal>
					<Modal
						title="Crypto Currency"
						visible={cryptoGbModal}
						className="crypto-list"
						closeIcon={
							<Tooltip title="Close">
								<span
									className="icon md x c-pointer"
									onClick={() =>
										this.setState({ ...this.state, cryptoGbModal: false, selectedModal: " " })
									}
								/>
							</Tooltip>
						}
						footer={
							<Button
								type="primary"
								className="primary-btn cancel-btn"
								onClick={() =>
									this.setState({ ...this.state, cryptoGbModal: false, selectedModal: " " })
								}>
								Close
							</Button>
						}>
						{this.state.selectedModal === "gBal" ?
							<div>
								{this.state.GasBalanceAmounts && this.renderGasBalanceAmounts(this.state?.GasBalanceAmounts)
								}
								{this.state.GasBalanceAmountsConf && <>
									<Text className="hisstate" style={{
										fontSize: "18px",
										lineHeight: "70px",
										color: "black",
										fontWeight: "bold"
									}}>Configuration</Text>
									{this.renderGasBalanceAmounts(this.state.GasBalanceAmountsConf)}
									</>
								}
							</div> : <div
								className="db-kpi p-relative text-center"
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								{" "}
								<Spin />
							</div>}
					</Modal>
					<Modal
						title="Crypto Currency"
						visible={cryptoKbModal}
						className="crypto-list"
						closeIcon={
							<Tooltip title="Close">
								<span
									className="icon md x c-pointer"
									onClick={() =>
										this.setState({ ...this.state, cryptoKbModal: false, selectedModal: " " })
									}
								/>
							</Tooltip>
						}
						footer={
							<Button
								type="primary"
								className="primary-btn cancel-btn"
								onClick={() =>
									this.setState({ ...this.state, cryptoKbModal: false, selectedModal: " " })
								}>
								Close
							</Button>
						}>
						{this.state.selectedModal === "kBal" ? <>{
							this.state.KrakenBalanceAmounts &&
							this.state.KrakenBalanceAmounts?.map((item) => (
								<div className="coin-info">
									<Text className="hisstate">{item.key}</Text>
									<Text className="hisstate">{item.value}</Text>
								</div>
							))}</> : <div
								className="db-kpi p-relative text-center"
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
							{" "}
							<Spin />
						</div>}
					</Modal>
					<Modal
						title="Crypto Currency"
						visible={cryptoEnygmaModal}
						className="crypto-list"
						closeIcon={
							<Tooltip title="Close">
								<span
									className="icon md x c-pointer"
									onClick={() =>
										this.setState({ ...this.state, cryptoEnygmaModal: false, selectedModal: " " })
									}
								/>
							</Tooltip>
						}
						footer={
							<Button
								type="primary"
								className="primary-btn cancel-btn"
								onClick={() =>
									this.setState({ ...this.state, cryptoEnygmaModal: false, selectedModal: " " })
								}>
								Close
							</Button>
						}>

					</Modal>
					<Modal
						title="Crypto Currency"
						visible={cryptoSwModal}
						className="crypto-list"
						closeIcon={
							<Tooltip title="Close">
								<span
									className="icon md x c-pointer"
									onClick={() =>
										this.setState({ ...this.state, cryptoSwModal: false, selectedModal: " " })
									}
								/>
							</Tooltip>
						}
						footer={
							<Button
								type="primary"
								className="primary-btn cancel-btn"
								onClick={() =>
									this.setState({ ...this.state, cryptoSwModal: false, selectedModal: " " })
								}>
								Close
							</Button>
						}>
					</Modal>
					<Modal
						title="Crypto Currency"
						visible={cryptoWwModal}
						className="crypto-list"
						closeIcon={
							<Tooltip title="Close">
								<span
									className="icon md x c-pointer"
									onClick={() =>
										this.setState({ ...this.state, cryptoWwModal: false, selectedModal: " " })
									}
								/>
							</Tooltip>
						}
						footer={
							<Button
								type="primary"
								className="primary-btn cancel-btn"
								onClick={() =>
									this.setState({ ...this.state, cryptoWwModal: false, selectedModal: "" })
								}>
								Close
							</Button>
						}>

					</Modal>
					<Modal
						title="Crypto Currency"
						visible={cryptoManualModal}
						className="crypto-list"
						closeIcon={
							<Tooltip title="Close">
								<span
									className="icon md x c-pointer"
									onClick={() =>
										this.setState({ ...this.state, cryptoManualModal: false, selectedModal: " " })
									}
								/>
							</Tooltip>
						}
						footer={
							<Button
								type="primary"
								className="primary-btn cancel-btn"
								onClick={() =>
									this.setState({ ...this.state, cryptoManualModal: false, selectedModal: "" })
								}>
								Close
							</Button>
						}>

						{this.state.selectedModal === "manualWall" ? <>{
							this.state.manualGasBalance &&
							this.renderManualGasamount(this.state.manualGasBalance?.fbBalDetails)
							}
							</> : <div
								className="db-kpi p-relative text-center"
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
							{" "}
							<Spin />
						</div>
						}
					</Modal>
				</div>
			</>
		);
	}
}
const connectStateToProps = ({ breadCrumb, oidc, userConfig }) => {
	return { breadCrumb, oidc, userConfig: userConfig.userProfileInfo };
};
export default connect(connectStateToProps, (dispatch) => {
	return { dispatch };
})(Dashboard);
