import React, { Component ,createRef} from "react";
import { connect } from "react-redux";
import { Select, Button,  Form,Alert, Input,Typography,Row,Col } from "antd";
import apiCalls from "../../api/apiCalls";
import {setStep,setsavewithdrawobject,} from '../../reducers/transactionComplianceReducer'
import NumberFormat from "react-number-format";
import Loader from "../loader.component";
import {getCustomerBankDetails} from './api'
const { Text,Title } = Typography;
const { Option, } = Select;
class TransactionComplianceDeatils extends Component {
  formRef = createRef();
  constructor (props) {
    super(props);
    this.state={
        accountHolder:[],
        btnDisabled:false,
        bankDetails:[],
        errorMsg:null,
        isLoading:false,
        accountHolderDetails:{},addressBookLu:[],withdrawObj: { currency: null, accountHolderName: null, AddressBook: null,ID:null },BankInfo:null,
    confirmObj:{"name":"","currencyCode":this.props.obj.currency,"favouriteName":"","totalValue":this.props.obj.amount,"isAccept":true,"customerId":this.props.obj.customerId,"memberWalletId":"","beneficiaryAccountName":"","comission":"0.0","bankName":"","accountNumber":"","country":"","state":"","routingNumber":"","WalletCode":this.props.obj.currency},
    savewithdrawObj:{"transactionId":"00000000-0000-0000-0000-000000000000","transactiontype":null,"customerId":"00000000-0000-0000-0000-000000000000","memberWalletId":"00000000-0000-0000-0000-000000000000","walletCode":null,"toWalletAddress":null,"accountNumber":null,"beneficiaryAccountName":null,"beneficiaryAccountAddress":null,"routingNumber":null,"swiftCode":null,"bankName":null,"bankAddress":null,"reference":null,"description":null,"totalValue":0,"percentage":null,"comission":null,"comissionType":null,"originalValue":null,"addressbookId":"00000000-0000-0000-0000-000000000000","createdby":null},
    selectHolderDetails:{},
    selectedBankId:null,
    selectedBank:null,
    selectedBankObj:{}
      }
    this.useDivRef=React.createRef;
  }
    componentDidMount=()=>{
        this.getAccountdetails()
        let { withdrawObj } = this.state;
        withdrawObj.amount =this.props.obj.amount;
        this.formRef.current.setFieldsValue({ ...withdrawObj });
        this.getBankDetails()
    }
     getAccountdetails = async () => {
      this.setState({...this.state,isLoading:true})
        let response = await apiCalls.getAddressBookLu(this.props.obj.customerId,"Fiat",this.props.obj.currency)
        if(response.ok){
            this.setState({...this.state,accountHolder:response.data,isLoading:false,errorMsg:null})
        }else{
          this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
        }
      }
      getBankDetails=async()=>{
        let res = await getCustomerBankDetails(this.props.obj.customerId,this.props.obj.currency);
        if(res.ok){
          this.setState({...this.state,bankDetails:res.data});
        }else{
          this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(res)})
        }
      }
       handleAccountChange = async(e) => {
        let { withdrawObj,accountHolder } = this.state;
        withdrawObj.currency =this.props.obj.currency;
        for(let k in accountHolder){
          if(accountHolder[k].id===e){withdrawObj.accountHolderName = accountHolder[k].name;}
        }
        withdrawObj.AddressBook=null;
        let response = await apiCalls.getPayeeLableLu(e,this.props.obj.currency)
        if(response.ok){
          if(response.data?.length===1){
            withdrawObj.AddressBook=response.data[0].lable;
            this.setState({...this.state,addressBookLu:response.data[0],withdrawObj:withdrawObj,BankInfo:response.data[0],errorMsg:null,selectHolderDetails:e})
          }else{
            this.setState({...this.state,addressBookLu:response.data,withdrawObj:withdrawObj,BankInfo:null,errorMsg:null})
          }
        }else{
             this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
        }
        this.formRef.current.setFieldsValue({ ...withdrawObj })
      }
  FetchaccountData = async (e) => {
  
    let { addressBookLu, withdrawObj } = this.state;
    for (let k in addressBookLu) {
      if (e === addressBookLu[k].lable) {
        withdrawObj.AddressBook = e;
        this.setState({ ...this.state, withdrawObj: withdrawObj, BankInfo: addressBookLu[k] })
      }
    }
    this.formRef.current.setFieldsValue({ ...withdrawObj })
  }
  
      savewithdrawal=async(values)=>{
        let {confirmObj,withdrawObj,BankInfo,savewithdrawObj}=this.state;
        if(values.amount===0||values.amount==="0"){
          this.setState({ ...this.state, btnDisabled: false, errorMsg: "Amount must be greater than zero." })
        }
          else {
            this.setState({ ...this.state, btnDisabled: true })
          confirmObj.name = withdrawObj.accountHolderName;
          confirmObj.favouriteName = withdrawObj.accountHolderName || withdrawObj?.favouriteName;
          confirmObj.beneficiaryAccountName = this.props.obj.fullName;
          confirmObj.bankName = BankInfo.bankName
          confirmObj.accountNumber = BankInfo.accountNumber
          confirmObj.country = BankInfo.country
          confirmObj.state = BankInfo.state
          confirmObj.routingNumber = BankInfo.swiftRouteBICNumber
          confirmObj.requestedAmount=BankInfo.requestedAmount;
          confirmObj.memberWalletId = this.props.obj.memberWalletId
          confirmObj.totalValue=values.amount
          confirmObj.PayeeId=this.state?.selectHolderDetails
          confirmObj.bankId=this.state?.selectedBankId
          confirmObj.externalAccountName=this.state?.selectedBank
          let response = await apiCalls.confirmWithdrawl("fiat",confirmObj);
          if (response.ok) {
            this.setState({ ...this.state, btnDisabled: false,errorMsg:null })
            savewithdrawObj.name = withdrawObj.accountHolderName;
            savewithdrawObj.favouriteName = response.data?.favouriteName || withdrawObj.favouriteName ||withdrawObj.accountHolderName;
            savewithdrawObj.transactionId = this.props.obj.id
            savewithdrawObj.transactiontype = this.props.obj.operationType;
            savewithdrawObj.customerId = this.props.obj.customerId;
            savewithdrawObj.memberWalletId = this.props.obj.memberWalletId
            savewithdrawObj.walletCode = this.props.obj.currency
            savewithdrawObj.accountNumber = response.data.accountNumber
            savewithdrawObj.beneficiaryAccountName = response.data.beneficiaryAccountName
            savewithdrawObj.beneficiaryAccountAddress =  response.data.beneficiaryAccountAddress
            savewithdrawObj.routingNumber =  response.data.routingNumber
            savewithdrawObj.bankName = response.data.bankName
            savewithdrawObj.bankAddress = response.data.bankAddress
            savewithdrawObj.totalValue = response.data.totalValue
            savewithdrawObj.comission = response.data.comission
            savewithdrawObj.addressbookId = withdrawObj.id
            savewithdrawObj.createdby = this.props.userConfig.userName;
            savewithdrawObj.requestedAmount=response.data.requestedAmount;
            savewithdrawObj.label=response.data.label;
            savewithdrawObj.PayeeId=this.state?.selectHolderDetails;
            savewithdrawObj.bankId=this.state?.selectedBankId;
            savewithdrawObj.tierDiscount = response.data.tierDiscount
            savewithdrawObj.totalFee = response.data.totalFee
            savewithdrawObj.sbCredit = response.data.sbCredit
            savewithdrawObj.requestedAmount = response.data.requestedAmount
            savewithdrawObj.externalAccountName=this.state?.selectedBank
            this.props.dispatch(setsavewithdrawobject(savewithdrawObj));
            this.props.dispatch(setStep("withdraw_transaction_summary"))
          }
          else{
            this.setState({...this.state,btnDisabled:false, errorMsg:apiCalls.isErrorDispaly(response)})
          }
        }
      }
      handleBankChange=(bankId)=>{
        let [newobj] =this.state.bankDetails.filter((item)=>item.bankId==bankId);
       this.setState({...this.state,selectedBankId:newobj?.bankId,selectedBank:newobj?.bankName,selectedBankObj:newobj})
      }
    render(){
      const {btnDisabled,errorMsg,withdrawObj,BankInfo,isLoading}=this.state;
        return(
          <>
            <div ref={this.useDivRef}></div>
            {errorMsg !== undefined && errorMsg !== null && (
              <Alert
                className="w-100 mb-16"
                type="warning"
                description={errorMsg}
                showIcon
              />
            )}
              {isLoading && <Loader/>}
            <Form
              onFinish={this.savewithdrawal}
              autoComplete="off"
              initialValues={{ ...withdrawObj }} ref={this.formRef}>
                <Row>
                {<Col xs={24} sm={24} md={12}>
										<Form.Item
											name="amount"
											label={<div>Amount
											</div>}
											className="input-label"
											>
											<NumberFormat
												decimalScale={8}
												className="cust-input"
												customInput={Input}
												thousandSeparator={true}
												prefix={""}
												placeholder="Amount"
												allowNegative={false}
												maxlength={13}
                        value={this.props.obj?.amount}
                        disabled />
										</Form.Item>
									</Col>}
                </Row>
              <Row style={{ alignItems: "flex-start" }} gutter={8}>
              <Col xs={24} sm={24} md={7} lg={12} xl={12} xxl={12} className="mb-24">
                  <Form.Item
                    name="Banknames"
                    className="input-label mb-0"
                    label="Banks"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Is required",
                      },
                    ]}
                  >
                    <Select
                      className="cust-input w-80"
                      onChange={(e) => this.handleBankChange(e)}
                      placeholder="Select Bank"
                      >
                      {this.state.bankDetails.map((item, indx) => (
                        <Option key={indx} value={item.bankId}>
                          {item.bankName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={7} lg={12} xl={12} xxl={12} className="mb-24">
                  <Form.Item
                    name="names"
                    className="input-label mb-0"
                    label="Payee"
                  >
                    <Select
                      className="cust-input w-80"
                      
                      onChange={(e) => this.handleAccountChange(e)}
                      placeholder="Select Payee"
                      value={withdrawObj.accountHolderName}>
                      {this.state.accountHolder.map((item, indx) => (
                        <Option key={indx} value={item.id}>
                          {item.accountHolderName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {this.state.addressBookLu?.length > 1 && withdrawObj.accountHolderName != null && <Col xs={24} sm={24} md={7} lg={12} xl={12} xxl={12} className="px-8 mb-24">
                  <Form.Item
                    name="favouriteName"
                    className="input-label mb-0"
                    label="Address Book"
                  >
                    <Select
                      className="cust-input w-80"
                      onChange={(e) => this.FetchaccountData(e)}
                      placeholder="Select Address Book"
                      value={withdrawObj.AddressBook}>
                      {this.state.addressBookLu.map((item, indx) => (
                        <Option key={indx} value={item.lable}>
                          {item.lable}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>}
                
              </Row>
                {this.state.addressBookLu?.length === 0 && withdrawObj.accountHolderName !== null &&<>
                <Text className="fs-20 text-white-30 d-block" style={{ textAlign: 'center' }}>Address Book details not available.</Text></> }
                <>

                  {BankInfo != null && <Row style={{ alignItems: "flex-end" }}>
                  <Col sm={24} md={7} lg={12} xl={8} xxl={8} className="px-8 mb-24">
                  <label className="fs-12">Currency</label>
                  <Title level={5} className="m-0 mb-8 l-height-normal">{this.props.obj.currency}</Title>
                  </Col>
                    <Col sm={24} md={7} lg={12} xl={8} xxl={8} className="px-8 mb-24">
                      <label className="fs-12">Bank Name</label>
                      <Title level={5} className="m-0 mb-8 l-height-normal">{BankInfo.bankName}</Title>
                    </Col>
                    <Col sm={24} md={7} lg={12} xl={8} xxl={8} className="px-8 mb-24">
                      <label className="fs-12">Bank Account Number/IBAN</label>
                      <Title level={5} className="m-0 mb-8 l-height-normal" copyable="Copy" with="3434546">{BankInfo.accountNumber}</Title>
                    </Col>
                    <Col sm={24} md={7} lg={12} xl={8} xxl={8} className="px-8 mb-24">
                      <label className="fs-12"> BIC/SWIFT/ABA Routing/UK Sort Code </label>
                      <Title level={5} className="m-0 mb-8 l-height-normal" copyable="Copy" with="sdsad">{BankInfo.swiftRouteBICNumber}</Title>
                    </Col>
                  </Row>}
                  {BankInfo != null && <div className="text-right">
                    <Form.Item className="mb-0 mt-16">
                      <Button
                        htmlType="submit"
                        size="large"
                        className="pop-btn primary-btn preview-btn"
                        loading={btnDisabled}
                      >
                        Preview
                      </Button>
                    </Form.Item>
                  </div>}
                </>
            </Form>
          </>
            
        )
    }
}
const connectStateToProps = ({
    userConfig,
  }) => {
    return {
      userConfig: userConfig.userProfileInfo,
    };
  };
  export default connect(connectStateToProps,)(TransactionComplianceDeatils);