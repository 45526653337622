

import { clientApi } from "../../api/index";
import { ApiControllers } from "../../api/config";
const getDepositeFiat = (CustomerId,isDeposit) => {
	if(isDeposit){
		return clientApi.get(
			ApiControllers.deposit + `Fiat/Deposits/${CustomerId}/${isDeposit}`
		);	
	}else{
		return clientApi.get(
			ApiControllers.deposit + `Fiat/Deposits/${CustomerId}`
		);
	}	
};
const saveDepositeFiat = (saveobj, CustomerId) => {
	return CustomerId === "00000000-0000-0000-0000-000000000000"
		? clientApi.post(ApiControllers.deposit + "Fiat/Deposits", saveobj)
		: clientApi.put(
				ApiControllers.deposit + `Fiat/Deposits/${CustomerId}`,
				saveobj
		  );
};
const getCurrencyBankLu = (bank,CustomerId) => {
	return clientApi.get(
		ApiControllers.deposit + `Fiat/Deposits/Currency/Banks/${bank}/${CustomerId}`
	);
};

const getReferenceId = (refId) => {
	return clientApi.get(
		ApiControllers.deposit + `Fiat/Deposits/${refId}/Account`
	);
};
const getDepositeCrypto = (cryptoId) => {
	return clientApi.get(
		ApiControllers.deposit + `Crypto/Deposits/${cryptoId}`
	);
};
const ApproveorCancel = (obj) => {
	if(obj.type==='Fiat'){
	return clientApi.put(
		ApiControllers.deposit + `DepositFiat/Status`,
		obj
	);
	}else{
		return clientApi.put(
			ApiControllers.deposit + `DepositCrypto/Status`,
			obj
		);
	}
};

const getProviderFee = (customerId, bankId, grossAmount) => {
	return clientApi.get(
		ApiControllers.deposit +
			`CalculateCommission/${customerId}/${bankId}/${grossAmount}`
	);
};
const getInfoVal = (id, type) => {
	return clientApi.get(
		ApiControllers.deposit + `GetScoreChainInfo/${id}/${type}`
	);
};

const getTransfer = (obj) => {
	return clientApi.put(
		ApiControllers.deposit + `FillGas/${obj.id}/${obj.amount}/${obj.customerId}`,
		obj
	);
};
const getSweep = (obj,customerId) => {
	return clientApi.put(
		ApiControllers.deposit + `Sweep/${obj.id}/${customerId}`,
		obj
	);
};
const getSweepBalance = (id,network) => {
	return clientApi.get(
		ApiControllers.deposit + `GetSweepBalance/${id}/${network}`
	);
};
const getCountryStateLu = () => {
    return clientApi.get(ApiControllers.common + "ControlCodes?codeCategory=country");
}
const getNetworkLu = () => {
	return clientApi.get(
		ApiControllers.common + "NetworkLU"
	);
};
const getStates = (tabName,status,previousState) => {
	return clientApi.get(
		ApiControllers.common + `statechange/${tabName}/${status}/${previousState}`
	);
};
const saveNotes=(obj)=>{
	return clientApi.put(
		ApiControllers.withdraw + `Notes`,
		obj
	);
}
const Sumsub = (obj,screen) => {
    return clientApi.post(ApiControllers.deposit + `Deposit/${screen}/Sumsub`,obj);
}; 
export {
	getDepositeFiat,
	saveDepositeFiat,
	getCurrencyBankLu,
	getReferenceId,
	getDepositeCrypto,
	ApproveorCancel,
	getProviderFee,
	getInfoVal,
	getTransfer,
	getSweep,
	getSweepBalance,
	getCountryStateLu,
	getNetworkLu,getStates,saveNotes,Sumsub
};
