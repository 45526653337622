import React, { Component } from "react";
import List from "../grid.component";
import {
  Form,
  Select,
  Modal,
  Tooltip,
  Alert,
  Input,
  Button,
  message,
  Row,
  Col,
} from "antd";
import { getComplianceLU, complianceStateChange } from "./api";
import DocumentsList from "../documents.component/documentsList";
import { sendRequest, uuidv4 } from "../documents.component/api";
import { connect } from "react-redux";
import { validateContentRule } from "../../utils/custom.validator";
import apiCalls from "../../api/apiCalls";
const { Option } = Select;
class Compliance extends Component {
  docReqForm;
  state = {
    Status: "All",
    selection: [],
    selectedObj: {},
    complianceLu: [],
    check: false,
    docModal: false,
    sendDisable: false,
    documentRequest: {
      id: uuidv4(),
      transactionId: null,
      adminId: this.props?.userConfig?.id,
      date: new Date(),
      type: "Compliance",
      CustomerId: null,
      note: "",
      remarks: "",
      details: []
    },
    alert: false,
    stateLoading: false,
    modal: false,
    error: null,
    errorMsg: null,
    isBtnLoading:false,
  };
  componentDidMount() {
    this.getComplianceLu();
    this.ComplianceTrack();
  }
  ComplianceTrack = () => {
    apiCalls.trackEvent({
      Type: "Admin",
      Action: "Compliance grid page view",
      Username: this.props.userConfig?.userName,
      CustomerId: this.props.userConfig?.id,
      Feature: "Compliance",
      Remarks: "Compliance grid page view",
      Duration: 1,
      Url: window.location.href,
      FullFeatureName: "Complaince"
    });
  };
  getComplianceLu = async () => {
    let response = await getComplianceLU();
    if (response.ok) {
      this.setState({ ...this.state, complianceLu: response.data,errorMsg:null });
    }else{
      this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
    }
  };
  constructor(props) {
    super(props);
    this.gridRef = React.createRef();
    this.formref = React.createRef();
  }
  gridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      customCell: (props) => (
        <td>
          {" "}
          <label className="text-center custom-checkbox">
            <input
              id={props.dataItem.id}
              name="check"
              type="checkbox"
              checked={this.state.selection.indexOf(props.dataItem.id) > -1}
              onChange={(e) => this.handleInputChange(props, e)}
            />
            <span></span>{" "}
          </label>
        </td>
      )
    },
    { field: "memberName", title: "Name", filter: true, width: 200 },
    { field: "userName", title: "User Name", filter: true, width: 200 },
    { field: "memberEmail", title: "Email", filter: true },
    { field: "description", title: "Reason", filter: true },
    {
      field: "createdDate",
      title: "Reg Date",
      filter: true,
      filterType: "date"
    },
    { field: "state", title: "Status", filter: true },
  ];
  onActionClick = (key) => {
    const actions = {
      Dismiss: "dismiss",
      "Doc Request": "showReqDocModal"
    };
    this[actions[key]]();
  };
  handleSearch = (val) => {
    const searchVal = `${val ? val : "All"}`;
    this.setState(
      {
        ...this.state,
        Status: val.target ? val.target.value : searchVal,selection:[],check: false,selectedObj:{}
      },
      () => {
        this.gridRef.current.refreshGrid();
      }
    );
  };
  handleInputChange = (prop, e) => {

  
    const rowChecked = prop.dataItem;
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowChecked.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowChecked.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowChecked,
      selection,  errorMsg:null,
    });
  };
  closeDocModal = () => {
    this.setState({ ...this.state, sendDisable: false });
    this.setState({
      docModal: false,
      selection: [],
      check: false,
      documentRequest: this.createDocReq(),
      errorMsg: null
    });
  };
  handleRequestedDoc = (docs) => {
    let _docDetails = [];
    for (let obj of docs) {
      let detail = {
        id: obj.id,
        documentName: obj.name,
        documentId: this.state.documentRequest.id,
        remarks: ""
      };
      _docDetails.push(detail);
    }
    this.setState({
      ...this.state,
      documentRequest: { ...this.state.documentRequest, details: _docDetails }
    });
  };
  showReqDocModal = () => {
    if (this.state.selection.length === 0) {
      this.setState({ alert: true });
      setTimeout(() => this.setState({ alert: false }), 1000);
      return;
    }
    this.setState({ docModal: true });
    apiCalls.trackEvent({
      Action: "Compliance Doc Request details page",
      Feature: " Compliance Doc Request",
      Remarks: "Compliance Doc Request details page",
      FullFeatureName: "Compliance Doc Request",
      userName: this.props.userConfig.userName,
      id: this.props.userConfig.id
    });
  };
  handleReqSend = async (values) => {
   
    let { documentRequest } = this.state;
    if (documentRequest.details.length === 0) {
      message.destroy();
      this.setState({...this.state,errorMsg:"Please select the one record"});
      return;
    }
    documentRequest.note = values.note;
    documentRequest.CustomerId = this.state.selection[0];
    documentRequest.Requestedby = this.props?.userConfig?.userName;
    this.setState({ ...this.state, sendDisable: true });
    const response = await sendRequest(documentRequest);
    if (response.ok) {
      this.setState({ ...this.state, sendDisable: false,errorMsg:null });
      this.closeDocModal();
      this.setState({ ...this.state, selection: [], check: false });
      message.destroy();
      message.success({
        content: "Documents requested successfully",
        className: "custom-msg",
        duration: 0.5
      });
      this.gridRef.current.refreshGrid();
    } else {
      this.setState({
        ...this.state,
        isLoading: false,
        selection: [],
        isCheck: false,
        sendDisable: false,
        errorMsg:apiCalls.isErrorDispaly(response)
      });
    }
    apiCalls.trackEvent({
      Action: "Compliance Doc Requested",
      Feature: "Compliance Doc Request",
      Remarks: "Compliance Doc Requested",
      FullFeatureName: "Compliance Doc Request",
      userName: this.props.userConfig.userName,
      id: this.props.userConfig.id
    });
  };
  createDocReq() {
    return {
      id: uuidv4(),
      transactionId: null,
      adminId: this.props?.userConfig?.id,
      date: new Date(),
      type: "Compliance",
      CustomerId: null,
      note: "",
      remarks: "",
      Requestedby: this.props?.userConfig?.userName,
      details: []
    };
  }
  handleRequestedNoteChange = (value) => {
    this.setState({
      ...this.state,
      documentRequest: { ...this.state.documentRequest, note: value }
    });
  };
  handleCancel = (e) => {
    this.setState({
      ...this.state,
      modal: false,
      selection: [],
      check: false,
      error: null
    });
    this.formref.current.resetFields();
  };
  handleOk = async (values) => {
    this.setState({ ...this.state, error: null, stateLoading: true });
    let obj = {
      CustomerId: this.state.selection[0],
      Status: "Dismiss",
      Remarks: "",
      Changedby: this.props?.userConfig?.userName
    };
    obj.Remarks = values.statusRemarks;
    let response = await complianceStateChange(obj);
    if (response.ok) {
      this.setState({
        ...this.state,
        modal: false,
        selection: [],
        check: false,
        stateLoading: false,
        error:null
      });
      message.destroy();
      message.success({
        content: "Record dismissed successfully",
        className: "custom-msg",
        duration: 3
      });
      this.formref.current.resetFields();
      this.gridRef.current.refreshGrid();
    } else {
      message.destroy();
      this.setState({
        ...this.state,
        error: apiCalls.isErrorDispaly(response),
        stateLoading: false,
      });
     
    }
    apiCalls.trackEvent({
      Action: "Compliance Dismissed",
      Feature: "Compliance",
      Remarks: "Compliance Dismissed ",
      FullFeatureName: "Complaince",
      userName: this.props.userConfig.userName,
      id: this.props.userConfig.id
    });
  };
 
  error = () => {
    message.error("Please select the one record");
  };
  dismiss = () => {
    if (!this.state.check) {
      this.setState({...this.state,errorMsg:"Please select the one record"});

    } else {
      this.setState({ ...this.state, errorMsg: null, modal: true });
    }
    apiCalls.trackEvent({
      Action: "Compliance dismiss page view",
      Feature: "Compliance",
      Remarks: "Compliance Dismiss page view ",
      FullFeatureName: "Complaince",
      userName: this.props.userConfig.userName,
      id: this.props.userConfig.id
    });
  };
  render() {
    return (
      <>
      {this.state.errorMsg && <div style={{ width: '100%' }}>
            <Alert className="mb-16 w-100" type="warning" description={this.state.errorMsg} showIcon/></div> }
  
        <div className="d-flex mb-16" style={{ justifyContent: "flex-end" }}>
          <Form
            className="form form-bg mb-0 d-flex align-center "
            autoComplete="off"
          >
            <div>
              <Form.Item name="gridSearch" className="">
                <Select
                  style={{ width: 250 }}
                  className="cust-input mb-0"
                  onChange={(e) => this.handleSearch(e)}
                  onSearch={(event) => this.handleSearch(event)}
                  defaultValue="All"
                >
                  {this.state.complianceLu?.map((item, idx) => (
                    <Option value={item.code}>{item.code}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Form>
        </div>
        <List
          showActionBar={true}
          onActionClick={(key) => this.onActionClick(key)}
          pKey={"compliance"}
          ref={this.gridRef}
          url={process.env.REACT_APP_GRID_API + "Compliances"}
          columns={this.gridColumns}
          additionalParams={{ status: this.state.Status }}
        />
        <Modal
          className="documentmodal-width"
          title="Documents Requests"
          visible={this.state.docModal}
          destroyOnClose={true}
          width={1000}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.closeDocModal} />
            </Tooltip>
          }
          footer={null}
        >
          {this.state.error !== undefined && this.state.error!== null && <div style={{ width: '100%' }}>(
            <Alert type="error" className="mb-16 w-100" showIcon message={this.state.error} />
          )</div>}
          <Form
            name={this.docReqForm}
            initialValues={this.state.documentRequest}
            onFinish={(values) => this.handleReqSend(values)}
          >
            <div className="mb-24">
              <Form.Item
                rules={[
                  { required: true, message: "Is required" },
                  {
                    whitespace: true,
                    message: "Is required"
                  },
                  {
                    validator: validateContentRule
                  }
                ]}
                name="note"
                label="Title"
                className="input-label"
              >
                <Input
                  maxLength="150"
                  id="note"
                  className="cust-input"
                  placeholder="Enter Title"
                />
              </Form.Item>
            </div>
            <DocumentsList
              onDocSelect={(docs) => {
                this.handleRequestedDoc(docs);
              }}
            />
            <Form.Item className="text-right mb-0 docmodal-footer">
              <Button
                type="primary"
                onClick={this.closeDocModal}
                className="primary-btn cancel-btn mr-16"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="primary-btn"
                disabled={this.state.sendDisable === true}
                loading={this.state.sendDisable}
              >
                Send
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Confirm Dismiss?"
          visible={this.state.modal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.handleCancel} />
            </Tooltip>
          }
          footer={null}
        >
          <div className="">
          
            <Form
              ref={this.formref}
              className="ant-advanced-search-form"
              autoComplete="off"
              onFinish={this.handleOk}
            >
              {this.state.error !== undefined && this.state.error !== null && (
                <Alert type="error" showIcon description={this.state.error} />
              )}
              <Row gutter={24} className="mb-24 pb-24 border-bottom">
                <Col xs={24}>
                  <Form.Item
                    name="statusRemarks"
                    label="Reason"
                    className="input-label"
                    rules={[
                      {
                        required: true,
                        message: "Is required",
                        whitespace: true
                      },
                      {
                        validator: validateContentRule
                      }
                    ]}
                  >
                    <Input
                      placeholder="Reason"
                      className="cust-input"
                      maxLength={30}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item className="mb-0">
                <div className="text-right">
                  <Button
                    type="primary"
                    className="primary-btn cancel-btn mr-8"
                    onClick={this.handleCancel}
                  >
                    {" "}
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    key="submit"
                    className="primary-btn"
                    htmlType="submit"
                    loading={this.state.stateLoading}
                  >
                  Save
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </>
    );
  }
}

const connectStateToProps = ({ userConfig }) => {
  return { userConfig: userConfig.userProfileInfo };
};
export default connect(connectStateToProps)(Compliance);
