import React, { Component } from 'react';
import { Row, Col, Typography, Alert, Form, Tabs,Spin } from "antd";
import Loader from "../loader.component";
import defaultUser from "../../assets/images/defaultuser.jpg";
import List from "../grid.component";
import Moment from "react-moment";
import apiCalls from "../../api/apiCalls";
import { connect } from "react-redux";
import { handleMembers } from "../../reducers/customerReducer";
import { getBankCustomer,showBalance } from './api';
import NumberFormat from 'react-number-format';
import BankInfo from '../info/BankInfo';
import { Link } from 'react-router-dom';
import { publishShowActions } from "../grid.component/subscribir";
import config from '../../config';

const { Title, Paragraph ,Text} = Typography;
const { TabPane } = Tabs;

class BankCustomerDetailView extends Component {
  constructor (props) {
    super(props);
    this.state = {
      activeTab: "1",
      customerdata: {},
      errorMsg: null,
      error: null,
      TransactionGridUrl: "",
      loader: false,
      isRealBalanceShow : true,
      realBalance:"",
      realBalanceBtnLoader: false,
    }
    this.gridRef = React.createRef();
    this.useDivRef = React.createRef()
    this.formRef = React.createRef();
  }
  gridColumns = [
    {
      field: "date",
      title: "Date",
      filter: true,
      isShowTime: true,
      filterType: "date",
      locked: true,
      width: 250,
      customCell: (props) => (
        <>
          <Moment format={config?.dateFormates?.dateTimeFormate}>{props.dataItem?.date ?
           apiCalls.convertUTCToLocalTime(props.dataItem?.date) : props.dataItem?.date}</Moment>
        </>
      ),
    },
    { field: "transaction", title: "Transaction Type", filter: true, width: 220 },

    {
      field: "grossAmount",
      title: "Gross Amount",
      width: 150,
      filter: true,
      dataType: "number",
      filterType: "numeric",
    },
    {
      field: "commissions",
      title: "Commission",
      width: 150,
      filter: true,
      dataType: "number",
      filterType: "numeric",
    },
    {
      field: "netAmount",
      title: "Net Amount",
      width: 200,
      filter: true,
      dataType: "number",
      filterType: "numeric",
    },
    { field: "status", title: "Status", filter: true, width: 160, },
  ];
  changeTab = activeKey => {
    if (activeKey === "1") {
      this.loadCustomers()
    }
    else if (activeKey === "2") {
      let bankTypes = this.props?.location?.pathname?.split('/');
      let Url = bankTypes[1] === 'pyrroscustomer' && process.env.REACT_APP_GRID_API + `Bank/PyrrosTransaction` || bankTypes[1] === 'openpaydcustomer' && process.env.REACT_APP_GRID_API + "Bank/OpenpaydTransaction" || process.env.REACT_APP_GRID_API + `Bank/EvolveTransaction`
      this.setState({ ...this.state, TransactionGridUrl: Url })
    }
    this.setState({
      activeTab: activeKey,isRealBalanceShow:true,errorMsg:null,realBalanceBtnLoader:false
    });
  };
  loadCustomers = async () => {
    let bankTypes = this.props?.location?.pathname?.split('/');
    let bankType = bankTypes[1] === 'pyrroscustomer' && "CreatePyrrosCustomer" || bankTypes[1] === 'openpaydcustomer' && "CreateOpenpaydCustomer" || "CreateEvolveCustomer";
    let selectedId = this.props.match.params.id;
    this.setState({ ...this.state, loader: true });
    let response = await getBankCustomer(bankType, selectedId);
    if (response.ok) {
      this.setState({
        ...this.state, customerdata: response.data, loader: false, errorMsg: null,
      });
      this.formRef.current?.setFieldsValue({ ...response.data });
    }
    else{
      this.setState({ ...this.state, errorMsg:apiCalls.isErrorDispaly(response), loader: false });
    }
  };
  DivRef = React.createRef();
  componentDidMount = () => {
    publishShowActions(false);
    this.useDivRef.current.scrollIntoView();
    this.loadCustomers();
  };
  showRealBalance = async() =>{
    this.setState({...this.state,realBalanceBtnLoader: true,isRealBalanceShow:false});
    let response = await showBalance(this.state.customerdata?.id);
    if (response.ok) {    
        this.setState({ isRealBalanceShow: false, realBalance: response.data,realBalanceBtnLoader: false,errorMsg:null});
    }
    else{
      this.setState({ ...this.state, errorMsg:apiCalls.isErrorDispaly(response), realBalanceBtnLoader: false ,realBalance :""});
      window.scrollTo(0,0)
    }
  }
  getAlertError=()=>{
    const {errorMsg} = this.state;
    return  errorMsg !== null && errorMsg !== undefined && (
      <Alert
        className="w-100 mb-16 align-center"
        type="error"
        description={errorMsg}
        showIcon
      />
    )
      }
  getCustomerDetailsData = (lable,value) => (
    <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="item">
      <label className="kpi-label">
        {lable}
      </label>
      <p className="kpi-val fw-700 mb-0">
        {value || "-"}
      </p>
    </Col>
  )
  getCustomerBalanceData = (balancelable, balanceValue) => (
    <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="item" >
      <label className="kpi-label">{balancelable}</label>
      <p className=" kpi-val fw-700 mb-0">€
        {balanceValue ?
          (<NumberFormat value={balanceValue}
            decimalSeparator="." displayType={'text'} thousandSeparator={true} />) : ("0")}
      </p>
    </Col>
  )
  getCustomedata = () => {
    const {customerdata} = this.state;
    return (<>
      {customerdata?.accountType === "Personal" && (
        <>
          {this.getCustomerDetailsData("First Name", customerdata?.firstName)}
          {this.getCustomerDetailsData("Last Name", customerdata?.lastName)}
        </>
      )}
      {customerdata?.accountType === "Business" && (
        <>
          {this.getCustomerDetailsData("Business Name", customerdata?.businessName)}
        </>
      )}
      {this.getCustomerDetailsData(customerdata?.accountType === "Personal" ? "Country of Residence" : "Country of Business", customerdata?.accountType === "Personal" && (customerdata?.countryofResidence || "-") || (customerdata?.countryOfBusiness || "-"))}
      {this.getCustomerDetailsData("Status", customerdata?.status)}
      {customerdata.status === "Rejected" && this.getCustomerDetailsData("Reason For Rejection", customerdata?.reasonofCancellation)}
      {this.getCustomerDetailsData("Account Type", customerdata?.accountType)}
      {this.getCustomerBalanceData("Available Balance", customerdata?.bankBalance)}
    </>)
  }
  getCustomerDetails=()=>{
    const {customerdata} = this.state;
    return (<>
      {this.props?.location?.pathname == `/evolvecustomer/details/${this.props.match.params.id}/view` && (
        this.getCustomerDetailsData("Account Number", customerdata?.accountNumber))}
      {this.props?.location?.pathname == `/evolvecustomer/details/${this.props.match.params.id}/view` && (
        this.getCustomerDetailsData("Routing Number", customerdata?.routingNumber))}
      {(this.props?.location?.pathname == `/pyrroscustomer/details/${this.props.match.params.id}/view` || this.props.location.pathname.includes("/openpaydcustomer")) && (
        this.getCustomerDetailsData("IBAN Number", customerdata?.iban))}
      {this.getCustomerDetailsData("Swift / BIC Code", customerdata?.bic)}
      {this.getCustomerDetailsData("Bank Reference Number", customerdata?.bankReferenceNumber)}</>)
  }
  render() {
    const { TransactionGridUrl, loader, customerdata } = this.state;
    return (
      <>
        <div ref={this.useDivRef}></div>
        {loader && <Loader />}
        <Title className="page-title">Customer Detail View</Title>
        {this.getAlertError()}
        <Tabs activeKey={this.state.activeTab} onChange={this.changeTab}>
          <TabPane tab="Profile" key="1">
            {this.state.activeTab === "1" && (
              <>
                {" "}
                {customerdata && (
                  <Form
                    name="customer"
                    autoComplete="off"
                    ref={this.formRef}
                  >
                    <Row className="detailview">
                      <Col  xs={24} sm={24} md={24}  lg={9}  xl={6}  xxl={5} className="view-left" >
                        <div className="text-center user">
                          <img src={customerdata.imageURL ? customerdata.imageURL : defaultUser} alt="User" className="user-circle" />
                          <div className="kpi-val text-captz fw-700 fs-18 user-name">
                            {customerdata.userName}
                          </div>
                        </div>

                        <div className="kpi-item">
                          <span className="icon xl email" />
                          <div>
                            <label className="kpi-label">Email</label>
                            <div
                              className="kpi-val"
                              style={{ wordBreak: "break-all" }}
                            >
                              {customerdata?.email || "--"}
                            </div>
                          </div>
                        </div>
                        <div className="kpi-item">
                          <span className="icon xl phone" />
                          <div>
                            <label className="kpi-label">Phone</label>
                            <div className="kpi-val">
                              {customerdata?.phone || "-"}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24}  sm={24} md={24} lg={15} xl={18} xxl={19} className="view-right" >
                        <Row className='cust-row-design'>
                          <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="item">
                            <label className="kpi-label">Customer ID</label>
                            <Paragraph className="kpi-val fw-700 mb-0" copyable>
                              {customerdata?.referralCode || "-"}
                            </Paragraph>
                          </Col>
                           {this.getCustomedata()}
                          <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="item" >
                            <label className="kpi-label">Real Balance</label>
                            {this.state.isRealBalanceShow ?
                            <div>
                              <Link className="kpi-val" type="primary" onClick={this.showRealBalance}>
                                Click here to see balance
                              </Link>
                              </div>
                              :
                              <div>
                                {this.state.realBalanceBtnLoader && (
                              <Spin spinning={this.state.realBalanceBtnLoader}></Spin>)}
                             {!this.state.realBalanceBtnLoader && (
                                   <p className=" kpi-val fw-700 mb-0">€
                                {this.state.realBalance &&
                                  (
                                  <NumberFormat value={this.state.realBalance}
                                     decimalSeparator="." displayType={'text'} thousandSeparator={true} />
                                     ) || ("0")}
                                  </p>
                                  )}
                                  </div>
                                  }
                          </Col>
                         {this.getCustomerDetails()}
                        </Row>
                      </Col>
                    </Row>
                    <BankInfo
                    Id={customerdata.id}
                    Status={customerdata.status}
                    Createdby={customerdata?.createdBy}
                    CreatedDate={customerdata.createdDate}
                    ModifiedDate={customerdata?.modifiedDate}
                    Modifiedby={customerdata?.modifiedBy}
                  />
                  </Form>
                )}{" "}
              </>
            )}
          </TabPane>

          <TabPane tab="Transactions" key="2" onChange={this.changeTab}>
            &emsp;&emsp;
            {this.state.activeTab === "2" && (
              <>
                <List                 
                 key={this.gridRef}
                  style={{ margin: "-22px 0 0" }}
                  url={TransactionGridUrl}
                  ref={this.gridRef}
                  additionalParams={{ customerid: this.props.match.params.id }}
                  columns={this.gridColumns}
                  showExcelExport={true}
                  pKey={this.props?.location?.pathname.includes("/pyrroscustomer") ? "Pyrros Customer Transaction History" : "Openpayd Customer Transaction History"}


                />
                  
              </>
            )}
          </TabPane>
        </Tabs>
      </>
    )
  }
}
const connectStateToProps = ({
  customerStore,
  userConfig,
  permissions
}) => {
  return { permissions, customerStore, userConfig: userConfig.userProfileInfo};
};
const connectDispatchToProps = (dispatch) => {
  return {
    fetchUserData: (id) => {
      dispatch(handleMembers(id));
    },
    dispatch
  };
};

export default connect(
  connectStateToProps,
  connectDispatchToProps
)(BankCustomerDetailView)