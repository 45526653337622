import React, { Component } from "react";
import { Typography, Row, Col, Button,Alert } from "antd";
import Loader from "../loader.component";
import apiCalls from "../../api/apiCalls";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import config from "../../config";
class BalanceDetailView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			balanceDetails: {},
			loader: false,
			errorMsg:null
		};
	}
	componentDidMount() {
		this.fetchBalanceData();
	}
	fetchBalanceData = async () => {
		this.setState({ loader: true });
		let response = await apiCalls.getBalanceValues(this.props.match.params.id);
		if (response.ok) {
			this.setState({ balanceDetails: response.data, loader: false,errorMsg:null });
		}else{
			this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
		}
	};

	backToBalances = async () => {
		this.props.history.push("/balances");
	};
	render() {
		const { Title } = Typography;
        const {errorMsg} = this.state;
		return (
			<>
			 {errorMsg !== undefined && errorMsg !== null && (
          <Alert
            className="w-100 mb-16 align-center"
            type="warning"
            description={errorMsg}
            showIcon
          />
        )}
				{this.state.loader && <Loader />}
				<Title className="page-title">Balance Detail View</Title>
				<Row gutter={24}>
					<Col  xl={24} xxl={24} className="bank-view">
						<Row className="kpi-List">
                        <Col xs={24} sm={24} md={8} lg={8} xxl={8}>
								<div>
									<label className="kpi-label">Date</label>
									<div className="kpi-val">
										<Moment format={config?.dateFormates?.dateTimeFormate}>{this.state.balanceDetails?.date && apiCalls.convertUTCToLocalTime(this.state.balanceDetails?.date)}</Moment>
									</div>
								</div>
							</Col>
                            <Col xs={24} sm={24} md={8} lg={8} xxl={8}>
								<div>
									<label className="kpi-label">Provider</label>
									<div className="kpi-val">
										{this.state.balanceDetails?.provider || "-"}
									</div>
								</div>
							</Col>
							<Col xs={24} sm={24} md={8} lg={8} xxl={8}>
								<div>
									<label className="kpi-label"> Wallet Type</label>
									<div className="kpi-val">
										{this.state.balanceDetails?.type || "-"}
									</div>
								</div>
							</Col>
							<Col xs={24} sm={24} md={8} lg={8} xxl={8}>
								<div>
									<label className="kpi-label">Wallet Code</label>
									<div className="kpi-val">
										{this.state.balanceDetails?.coinCode || "-"}
									</div>
								</div>
							</Col>
							<Col xs={24} sm={24} md={8} lg={8} xxl={8}>
								<div>
									<label className="kpi-label">Value</label>
									<div className="kpi-val">
										{this.state.balanceDetails?.value ?( <NumberFormat value={this.state.balanceDetails?.value} decimalSeparator="." displayType={'text'} thousandSeparator={true} />)
                                       : ("-")}
									</div>
								</div>
							</Col>
							<Col xs={24} sm={24} md={8} lg={8} xxl={8}>
								<div>
									<label className="kpi-label">Remarks</label>
									<div className="kpi-val">
										{this.state.balanceDetails?.remarks || "-"}
									</div>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row gutter={24}>
					{this.state.balanceDetails?.createdBy && (
						<Col xs={24} sm={24} md={12} lg={12} xxl={12}>
							<div className="record-history d-flex align-center ml-0">
							<div className="record-history-text">
								{this.state.balanceDetails?.createdBy} created this on {"  "}
								<Moment format={config?.dateFormates?.dateTimeFormate}>
									{this.state.balanceDetails?.createdDate
										? apiCalls.convertUTCToLocalTime(
												this.state.balanceDetails?.createdDate
										  )
										: this.state.balanceDetails?.createdDate}
								</Moment></div>
							</div>
						</Col>
					)}

					{this.state.balanceDetails?.modifiedDate && (
						<Col xs={24} sm={24} md={12} lg={12} xxl={12}>
							<div className="record-history d-flex align-center ml-0">
								<div className="record-history-text">
									{this.state.balanceDetails?.modifiedBy} modified this on {" "}
									<Moment format={config?.dateFormates?.dateTimeFormate}>
										{this.state.balanceDetails?.modifiedDate
											? apiCalls.convertUTCToLocalTime(
													this.state.balanceDetails?.modifiedDate
											  )
											: this.state.balanceDetails?.modifiedDate}
									</Moment>
								</div>
							</div>
						</Col>
					)}
				</Row>
				<div className="text-right mt-24">
					<Button
						type="primary"
						className="primary-btn cancel-btn"
						style={{ margin: "0 8px" }}
						onClick={() => this.backToBalances()}>
						Cancel
					</Button>
				</div>
			</>
		);
	}
}

export default BalanceDetailView;
