import React, { Component } from "react";
import {
	Radio,
	Modal,
	Form,
	Tooltip,
	Row,
	Col,
	Select,
	Button,
	message,
	Alert, Spin,Input,
} from "antd";
import { ApiControllers } from "../../api/config";
import List from "../grid.component";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { downloadDeclForm, stateSave,getStates } from "./api";
import { connect } from "react-redux";
import Info from "../shared/sharedInfo";
import { DownloadOutlined } from '@ant-design/icons'
import CreateCase from "../../utils/createCase";
import {CaseRediractions} from '../../utils/caseRediractions';
import { validateContentRule } from "../../utils/custom.validator";
import { Link } from "react-router-dom";
import apiCalls from "../../api/apiCalls";
const addressName = {"ownbusiness": "My Company",
"individuals": "Individuals",
"otherbusiness": "Other Business",
"Business": "Other Business",
"myself": "My Self"};
const { TextArea } = Input;
class AddressBook extends Component {
	state = {
		cryptoModal: false,
		selectedModal: "",
		activeTab: this.props?.match.params?.type === "type" ? 2 :1,
		modal: false,
		btnDisabled: false,
		stateObj: {},
		selection: [],
		caseModal: false,
		selectedObj: {},
		reasonReject:"",
		selectedObjs: [],
		selectedCaseId: "",
		adrsState: "",
		error: null,
		isDownloading: false,
		selectedDeclaration: null,
		selectedId: [],
		checkedRow: false,
		statesLst:[],
		selectedState:null,
		gridUrl: process.env.REACT_APP_GRID_API + ApiControllers.address + "Fiat",
		gridUrlC:
			process.env.REACT_APP_GRID_API + ApiControllers.address + "Crypto",
			errorMsg: null,
			isLoading: false,
	};
	formref = React.createRef();
	gridRef = React.createRef();
	useDivRef = React.createRef();
	componentDidMount() {
		if (process.env.REACT_APP_ISTR === "true") {
			this.addColumn(this.columnsCrypto, {
				field: "walletSource",
				title: "Wallet Source",
				width: 150,
				filter: true
			}, 6);
		} else if (process.env.REACT_APP_ISDOCUSIGN === "true") {
			const declarationObj = {
				field: "digitallySigned",
				customCell: this.renderDeclarationCell,
				title: "Declaration Form",
				filter: true,
				width: 200,
			};
	
			this.addColumn(this.columnsFiat, declarationObj, 7);
			this.addColumn(this.columnsCrypto, declarationObj, 7);
		}
	}
	
	renderDeclarationCell = (props) => (
		<td>
			{(props.dataItem?.digitallySigned === "Signed" && this.state.selectedDeclaration !== props?.dataItem.payeeAccountId) &&
			<> <Link onClick={() => {
				if (!this.state.isDownloading)
					this.downloadDeclarationForm(props?.dataItem);
			}} ><DownloadOutlined /></Link> {props.dataItem?.digitallySigned}</>}
			{props.dataItem?.digitallySigned !== "Signed" && props.dataItem?.digitallySigned}
			{(this.state.isDownloading && this.state.selectedDeclaration === props?.dataItem.payeeAccountId) &&
			<Spin size="small" />}
		</td>
	);
	
	addColumn = (columns, columnObj, index) => {
		columns.splice(index, 0, columnObj);
	};
	handleCheck = (props) => {
		return	<td>
			{" "}
			<label className="text-center custom-checkbox">
				<input
					id={props.dataItem.payeeAccountId}
					name="check"
					type="checkbox"
					checked={this.state.selection.indexOf(props.dataItem.payeeAccountId) > -1}
					onChange={(e) => this.handleCheckbox(props, e)}
				/>
				<span></span>{" "}
			</label>
		</td>
	}
	renderCaseId = (caseIds) => {
		return <td>
			<div className="gridLink" >
				{caseIds.dataItem.caseIds?.map(item => <div onClick={() => this.createCaseView(item)}>{item.caseNumber}</div>)}
			</div>
		</td>
	}
	columnsFiat = [
		{
			field: "",
			title: "",
			width: 50,
			locked: true,
			customCell: this.handleCheck
		},
		{
			field: "name",
			title: "Name",
			filter: true,
			locked: true,
			width: 200,
			customCell: (props) => (
				<td>
					<div className="gridLink" onClick={() => this.addressFiatView(props)}>
						{props?.dataItem?.name}
					</div>
				</td>
			),
		},
		{
			field: "caseids",
			title: "Case Number",
			width: 160,
			customCell: this.renderCaseId
		},
		{
			field: "whiteListName",
			customCell: (props) => (
				<td className="whitelist-name">
				<div className="d-flex">
				<div className="white-address">	{props.dataItem.whiteListName}</div>
					{props?.dataItem?.addressType && (
						<div className="file-labels ml-8 fs-12 address-label">
							{addressName[props?.dataItem?.addressType]}
						</div>
					)}</div>
				</td>
			),
			title: "Whitelist Name",
			filter: true,
			width: 300,
		},
		{
			field: "currency",
			title: "Currency",
			width: 150,
			filter: true,
			with: 150,
		},
		{
			field: "accountNumber",
			title: "Bank Account Number/IBAN",
			filter: true,
			width: 250,
		},
		{
			field: "routingNumber",
			title: "BIC/SWIFT/ABA Routing/Uk Sort Code",
			filter: true,
			width: 370,
		},
		{ field: "bankName", title: "Bank Name", filter: true, width: 200 },
		{ field: "addressState", title: "Whitelisting Status", filter: true, width: 200 },
		
		{
			field: "rejectReason",
			title: "Reason For Rejection",
			width: 250,
			filter: true,
			with: 150,
		},	
	];

	columnsCrypto = [
		{
			field: "",
			title: "",
			width: 50,
			locked: true,
			customCell: this.handleCheck
		},
		{
			field: "name",
			title: "Name",
			width: 200,
			locked: true,
			filter: true,
			customCell: (props) => (
				<td>
					<div
						className="gridLink"
						onClick={() => this.addressCryptoView(props)}>
						{props?.dataItem?.name}
					</div>
				</td>
			),
		},		
		{
			field: "caseids",
			title: "Case Number",
			
			width: 160,
			customCell: this.renderCaseId
		},
		{ field: "whiteListName", title: "Whitelist Name", width: 250, filter: true }, 
		{ field: "network", title: "Network", width: 160, filter: true },
		{ field: "walletAddress", title: "Wallet Address", width: 250, filter: true }, 
		{ field: "addressState", title: "Whitelisting Status", width: 200, filter: true },
		
		{ field: "rejectReason", title: "Reason For Rejection ", width: 200, filter: true },
	
		 
		

	];
	async downloadDeclarationForm(dataItem){
		this.setState({...this.state,selectedDeclaration: dataItem.payeeAccountId,isDownloading:true});
		const response = await downloadDeclForm(dataItem.payeeAccountId);
		if(response.ok){
			window.open(response.data,"_blank");
			this.setState({ ...this.state, isDownloading: false, selectedDeclaration: null,error:null });
		}else{
    this.setState({...this.state,error:apiCalls.isErrorDispaly(response)})
		}
	}

	createCaseView = (CaseData) => {
		let propsData=this.props
		CaseRediractions(CaseData,propsData)
	  };
	  handleCheckbox = (prop, e) => {
		this.formref.current?.resetFields();
		const rowObj = prop.dataItem;
		const value =
			e.target.type === "checkbox" ? e.target.checked : e.target.value;
		const name = e.target.name;

		let { selection, selectedObjs } = this.state;
		let idx = selection.indexOf(rowObj.payeeAccountId);
		if (idx > -1) {
			selection.splice(idx, 1);
			selectedObjs.splice(idx, 1);

		} else {
			selection.push(rowObj.payeeAccountId);
			selectedObjs.push(rowObj);
		}
		this.setState({
			...this.state,
			[name]: value,
			selectedObj: rowObj,
			stateObj: rowObj,
			selectedObjs,
			selection,
			werningMsg: null
		});
		this.formref.current?.resetFields();
	};
	
	hideCaseModal = () => {
		this.setState({ ...this.state, caseModal: false, selection: [], selectedObjs: [] }, () => this.gridRef.current.refreshGrid());
	}
	handleTabs = async (e) => {
		this.setState({
			...this.state,
			selection: [],
			activeTab: e.target.value,
			selectedObj: {},
			check: false,
			errorMsg: null,
			error:null,
			selectedObjs: []
		});
	};
	edit = () => {
		const { selection, activeTab, selectedObj } = this.state;
		let payeeId=selectedObj.payeeAccountId;
		if (selection.length > 1 || selection.length === 0) {
			this.setState({...this.state,errorMsg:"Please select the one record"});
			this.useDivRef?.current.scrollIntoView();
		} else {
			this.props.history.push({
				pathname: `/addressbook/${activeTab === 1 ? "Fiat" : "Crypto"}/${
					selectedObj.id}/${payeeId}`,
			});
			this.props.dispatch(
				setBreadcrumb({
					key: `/addressbook/${activeTab === 1 ? "Fiat" : "Crypto"}/${
						selectedObj.id
					}`,
					val: selectedObj.name,
				})
			);
		}
	};
	statusChange = async () => {
		const { selection } = this.state;
		if (selection.length === 0) {
			this.setState({ ...this.state, errorMsg: "Please select the one record" });
			this.useDivRef?.current?.scrollIntoView();
		} else {
			for (const selectedObj of this.state.selectedObjs) {
				if (selectedObj.addressState !== this.state.selectedObjs[0].addressState) {
					this.setState({ ...this.state, errorMsg: "Please select same address state records", selectedObjs: [], selection: [] });
					this.useDivRef?.current.scrollIntoView();
					return;
				}
			}
			let tabName=this.state.activeTab === 1 ? "AddressBook Fiat" : "AddressBook Crypto"
				getStates(tabName,this.state.selectedObj.addressState).then(response => {
					if (response.ok) {
						this.setState({ ...this.state, statesLst: response.data, loading: false,appwerningMsg:null });
					}else{
						this.setState({ ...this.state,appwerningMsg: apiCalls.isErrorDispaly(response), });
					  }
				})
			this.setState({ ...this.state, modal: true,errorMsg:null, selectedObj: this.state.selectedObjs[0], stateObj: this.state.selectedObjs[0], error: null }, () =>
				this.formref.current.setFieldsValue({
					addressState: this.state.selectedObjs[0]?.addressState,
					rejectReason:this.state.stateObj.rejectReason
				})
			);
		}
	};
	createCase = () => {
		const { selection} = this.state;
		if(selection.length == 0){
			this.setState({ ...this.state,errorMessage:null,errorMsg:"Please select the one record",error: null, selection:[]});
			window.scrollTo(0, 0)
		}
		else if ((selection?.length !==1 )) {
			this.setState({ ...this.state, errorMessage: null, errorMsg: "Please select only one record", error: null, selection: [], selectedObjs: []});
			window.scrollTo(0, 0)
		}		
		else {
			this.setState({ ...this.state, caseModal: true, check: false, errorMsg: null });
		}
	}
	onActionClick = (key) => {
		const actions = {
			edit: "edit",
			"State Change": "statusChange",
			"Create Case": "createCase"
		};
		this[actions[key]]();
	};

	saveState = async (values) => {
		message.destroy();
		this.setState({ ...this.state, btnDisabled: true, isLoading: false, error: null });
		let obj = {
			"ids": this.state.selection,
			"adminId": this.props.userConfig.id,
			"info": JSON.stringify(this.props.trackAuditLogData),
			"status": this.state.stateObj?.addressState,
           "rejectReason":values.rejectReason,
		   ModifiedBy:this.props.userConfig.userName,
		}
		let res = await stateSave(obj)
		if (res.ok) {
			message.destroy();
			message.success({
				content: `${this.state.selection?.length >1 ? "Records" : "Record" } ${
					this.state.stateObj?.addressState === "Approved"
						? "Approved"
						: "Rejected"
				} successfully`,
				className: "custom-msg",
				duration: 3,
			});
			this.setState({
				...this.state,
				modal: false,
				btnDisabled: false,isLoading:false,
				selection: [],
				selectedObjs: [],
				error:null,
				selectedState:null
			});
			this.gridRef.current.refreshGrid();
			setTimeout(
				() =>
					this.setState({
						...this.state,
						modal: false,
						btnDisabled: false,isLoading:false,
						selection: [],
						selectedObjs: [],
						selectedState:null
					}),
				1000
			);
		} else {
			this.setState({ ...this.state, btnDisabled: false,isLoading:false ,error:apiCalls.isErrorDispaly(res)});
			this.useDivRef.current.scrollIntoView();
		}
	};
	handleStateChange = (val,value) => {
		this.formref.current.setFieldsValue({rejectReason:""})	
		this.setState({ ...this.state,error:null, stateObj: { addressState: val,rejectReason:value},selectedState:val });
	};
	
	addressFiatView = (e) => {
		const items = e.dataItem;
		const val = items.id;
		this.props.history.push(`/addressbook/fiatView/${val}/${items.payeeAccountId}`);
		this.props.dispatch(
			setBreadcrumb({
				key: `/addressbook/fiatView/${items.id}/${items.payeeAccountId}`,
				val: items.name,
			})
		);
	};
	addressCryptoView = (e) => {
		const items = e.dataItem;
		const val = items.id;
		this.props.history.push(`/addressbook/cryptoView/${val}/${items.payeeAccountId}`);
		this.props.dispatch(
			setBreadcrumb({
				key: `/addressbook/cryptoView/${val}/${items.payeeAccountId}`,
				val: items.name,
			})
		);
	};

	render() {
		const { activeTab, selectedObj, caseModal, btnDisabled } = this.state;
		return (
			<>	<div ref={this.useDivRef}>		
				{this.state.errorMsg && <div style={{ width: '100%' }}>
					<Alert className="mb-16 w-100" type="warning"
						description={this.state.errorMsg} showIcon />
				</div>}
				<div
					style={{ display: "flex", justifyContent: "space-between" }}
					className="mb-16">
					<div>
						<Radio.Group
							defaultValue={activeTab}
							onChange={this.handleTabs}
							className="buysell-toggle mb-0">
							<Radio.Button value={1}> Fiat </Radio.Button>
							<Radio.Button value={2}> Crypto </Radio.Button>
						</Radio.Group>
					</div>
				</div>
				
				{activeTab === 1  ? (
					<List
						showActionBar={true}
						pKey={"addressbook"}
						onActionClick={(key) => this.onActionClick(key)}
						url={this.state.gridUrl}
						ref={this.gridRef}
						key={this.state.gridUrl}
						columns={this.columnsFiat}
						columnsIsNotReorderable={true}
					/>
				) : (
					<List
						showActionBar={true}
						pKey={"addressbook"}
						onActionClick={(key) => this.onActionClick(key)}
						url={this.state.gridUrlC}
						ref={this.gridRef}
						key={this.state.gridUrlC}
						columns={this.columnsCrypto}
						columnsIsNotReorderable={true}
					/>
				)}
				</div>
				<Modal
					title="Confirm Approve/Reject?"
					visible={this.state.modal}
					closeIcon={
						<Tooltip title="Close">
							<span
								className="icon md x c-pointer"
								onClick={() =>
									this.setState({
										...this.state,
										modal: false,
										selection: [],
										errorMsg: null,
										selectedObjs: [],
										selectedState:null
									})
								}
							/>
						</Tooltip>
					}
					footer={null}>
					<div>
						<Form
							ref={this.formref}
							initialValues={this.state.stateObj}
							onFinish={this.saveState}
							autoComplete="off">
								    {this.state.error !== undefined && this.state.error !== null && (
								<Alert className="mb-12 send-poperror" type="error" showIcon description={this.state.error} />)}
							<Row gutter={24} className="mb-24 pb-24 border-bottom">
								{/* {activeTab &&( */}
								<Col xs={24} sm={24} md={12}>
									<Form.Item
										name="addressState"
										label="State"
										className="input-label"
										rules={[
											{
												required: true,
												message: "Is required",
											},
										]}>
										<Select
											className="cust-input mb-0"
											onChange={(e) => this.handleStateChange(e)}
											placeholder="Select State"
											// disabled={
											// 	selectedObj.addressState === "Approved" ||
											// 	selectedObj.addressState === "Rejected"
											// }
											>
												{this.state.statesLst?.map(item => <Select.Option value={item?.code}>{item?.name}</Select.Option>)}
										</Select>
									</Form.Item>
								</Col>
								{/* )} */}
								
				<Col xs={24}>
				  {(this.state.stateObj?.addressState !== "Submitted"&& this.state.stateObj?.addressState !== "Pending"&& 
				  this.state.stateObj?.addressState !== "Approved"&&(
                    <Form.Item
                      name={"rejectReason"}
                      label={<div>Reason For Rejection

                      </div>}
                      rules={[                       
                        {
							required: true,
							message: "Is required",
							whitespace: true,
                        },
						{
							validator: validateContentRule,
						}
                      ]}
                      className="input-label"
                    >
                      <TextArea
                        disabled={
                          selectedObj.addressState === "Rejected" 
                        }
                        customInput={Input}
                        thousandSeparator={true}
                        prefix={""}
                        placeholder="Reason For Rejection"
                        allowNegative={false}
                        maxLength={100}
                        rows={4}
						showCount
                      />
                    </Form.Item>
					   ))}
                  </Col>
             
							</Row>
							<Form.Item className="mb-0">
								<div className="text-right">
									<Button
										type="primary"
										className="primary-btn cancel-btn mr-8"
										onClick={() =>
											this.setState({
												...this.state,
												modal: false,
												selection: [],
												selectedObjs: [],
												selectedState:null
											})
										}>
										Cancel
									</Button>
									{
										// (this.state.selectedObj.addressState !== "Rejected" && this.state.selectedObj.addressState !== "Approved") &&
										<Button
											type="primary"
											key="submit"
											className="primary-btn"
											htmlType="submit"
											disabled={
												this.state.selectedState == null
												//stateObj.addressState === "Submitted" 
											
											}
											loading={btnDisabled}
											>
										      Save
										</Button>
									}
								</div>
							</Form.Item>
						</Form>
					</div>
				</Modal>

				<Modal
					title={<p>INFO DETAILS</p>}
					visible={this.state.cryptoModal}
					className="crypto-list"
					destroyOnClose={true}
					closeIcon={
						<Tooltip title="Close">
							<span
								className="icon md x c-pointer"
								onClick={() =>
									this.setState({ ...this.state, cryptoModal: false })
								}
							/>
						</Tooltip>
					}
					footer={
						<Button
							type="primary"
							className="primary-btn cancel-btn"
							onClick={() =>
								this.setState({ ...this.state, cryptoModal: false })
							}>
							Close
						</Button>
					}>
					<Info
						id={this.state.selectedId}
						type={this.state.selectedModal}
						screen={"AddressBook"}
					/>
				</Modal>
				<Modal
					title="Create Case"
					visible={caseModal}
					width={1000}
					style={{ top: 16 }}
					closeIcon={
						<Tooltip title="Close">
							<span className="icon md x c-pointer" onClick={this.hideCaseModal} />
						</Tooltip>
					}
					destroyOnClose
					footer={null}
				>
					<CreateCase
						FromScreen='AddressBook'
						ScreenId={this.state.selection}
						CreateCaseId={this.state.selectedCaseId}
						ScreenName="AddressBook"
						closeModal={this.hideCaseModal} />
				</Modal>
			</>
		);
	}
}

const connectStateToProps = ({ oidc, userConfig }) => {
	return { userConfig: userConfig.userProfileInfo, oidc,  trackAuditLogData: userConfig.trackAuditLogData};
};
export default connect(connectStateToProps, (dispatch) => {
	return { dispatch };
})(AddressBook);
	