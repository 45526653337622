
import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Row,
	Col,
	Select,
	Button,
	Form,
	Tooltip,
	Modal,
	Alert
} from "antd";
import List from "../grid.component";
import apiCalls from "../../api/apiCalls";
import { userNameLuSearch, getTransactionSearch } from "./api";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { updateCurrentScreen } from "../../reducers/permissionsReducer";
import Info from "../shared/sharedInfo";

const { Option } = Select;

class Transaction extends Component {  
	constructor (props) {
		super(props);
		this.state = {
			isLoading: false,
			customerData: [],
			typeData: [],
			doctypeData: [],
			errorMsg:null,
			value: "",
			searchObj: {
				type: "All",
				docType: "All",
				customerId: "",
			},
			cryptoModal: false,
			selectedModal: "",
			tranObj: {},
			gridUrl: process.env.REACT_APP_GRID_API + "Transaction",
			
		};
		this.gridRef = React.createRef();
	}
	gridColumns = [
		{
			field: "transactionId",
			title: "Transaction ID",
			filter: true,
			locked: true,
			width: 210,
			customCell: (props) => (
					<span className="gridLink trans-id" onClick={() => this.update(props)}>
                      {props.dataItem?.transactionId }
					</span>
			),
		},
		{
			field: "date",
			title: "Submission Date",
			filter: true,
			width: 210,
			isShowTime: true,
			filterType: "date",
		},
		{
			field: "approvedDate",
			title: "Approval Date",
			filter: true,
			width: 210,
			isShowTime: true,
			filterType: "date",
		},
		{ field: "status", title: "Status", filter: true, width: 160, },		
		{
			field: "memberName",
			title: "Business Name/Personal Name",
			filter: true,
			width: 300,
			customCell: (props) => (
				<td className="whitelist-name">
				<div className="d-flex">
				<div className="white-address address-width">{props.dataItem.memberName}</div>
					{(props?.dataItem?.accountType || !!props.dataItem?.isDigitaltransfer) && (
						<div className="file-labels ml-8 fs-12 address-label address-label-width">
							{props?.dataItem?.accountType || (props.dataItem.isDigitaltransfer && "Pyrros" || !props.dataItem?.isDigitaltransfe && "OpenPayd")}
						</div>)}</div>
				</td>
			),
		},
		{ field: "docType", title: "Transaction ", filter: true, width: 150 },
		{
			field: "fromWalletCode",
			title: "From Wallet Code",
			filter: true,
			width: 180,
		},
		{
			field: "fromValue",
			title: "From Value",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "toWalletCode",
			title: "To Wallet Code",
			filter: true,
			width: 170,
		},
		{
			field: "toValue",
			title: "To Value",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "effectiveFee",
			title: "Fees",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "comission",
			title: "Commission",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "comissionInBase",
			title: "Commission in Base",
			width: 200,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "providerComission",
			title: "Provider Commission",
			width: 200,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "providerComissionInBase",
			title: "Provider Commission in Base",
			width: 270,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{ field: "sweepsourceComissionValue", title: "Sweep Source Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
		{ field: "sweepSourceValueInBase", title: "Sweep Source in Base", filter: true, width: 230, dataType: "number", filterType: "numeric" },
		{ field: "sweepDestinationComissionValue", title: "Sweep Destination Commission", filter: true, width: 260, dataType: "number", filterType: "numeric" },
		{ field: "sweepDestinationValueInBase", title: "Sweep Destination in Base", filter: true, width: 270, dataType: "number", filterType: "numeric" },
		{
			field: "totalAmount",
			title: "Total Cost",
			filter: true,
			width: 130,
			dataType: "number", filterType: "numeric"
		},
		{
			field: "profit",
			title: "Profit",
			width: 130,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "profitInBase",
			title: "Profit in Base",
			width: 160,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "provider",
			title: "Provider",
			filter: true,
			 width: 160
		},
		{
			field: "fromValueBefore",
			title: "From Before Value",
			width: 180,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "fromValueAfter",
			title: "From After Value",
			width: 180,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "toValueBefore",
			title: "To Before Value",
			width: 180,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "toValueAfter",
			title: "To After Value",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{ field: "referenceId", title: "Customer ID", filter: true, width: 150 },
		{ field: "memberEmail", title: "Email", filter: true, width: 220 },
		{ field: "walletScore", title: "Wallet Score", filter: true, width: 160,dataType: "number",
		filterType: "numeric", },
		{ field: "userName", title: "User Name", filter: true, width: 200 },
	];
	
	componentDidMount = () => {
		
		this.TransactionSearch();
		this.props.dispatch(updateCurrentScreen("transaction"));
		this.TransactionTrack();
	};
	TransactionTrack = () => {
		apiCalls.trackEvent({
			Type: "Admin",
			Action: "Transactions grid page view",
			Username: this.props.userConfig?.userName,
			MemeberId: this.props.userConfig?.id,
			Feature: "Transactions",
			Remarks: "Transactions grid page view",
			Duration: 1,
			Url: window.location.href,
			FullFeatureName: "Transactions",
		});
	};
	update = (e) => {
		const items = e.dataItem;
		const val = items.id;
		this.props.dispatch(
			setBreadcrumb({ key: "/transaction/" + val, val: items.transactionId })
		);
		this.props.history.push("/transaction/" + val);
		apiCalls.trackEvent({
			Action: "Transactions details page view",
			Feature: "Transactions",
			Remarks: "Transactions details page  view",
			FullFeatureName: "Transactions",
			userName: this.props.userConfig.userName,
			id: this.props.userConfig.id,
		});
	};

	TransactionUserSearch = async (userVal) => {
		let response = await userNameLuSearch(userVal);
		if (response.ok) {
			this.setState({
				customerData: response.data,
				errorMsg:null
			});
		}else{
          this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
		}
	};

	handleUserChange = (event) => {
		if (event.target.value != null && event.target.value.length > 2) {
			let userVal = event.target.value;
			this.TransactionUserSearch(userVal);
		}
	};

	TransactionSearch = async () => {
		let response = await getTransactionSearch();
		if (response.ok) {
			this.setState({
				typeData: response.data.types,
				doctypeData: response.data.docTypes,
				errorMsg:null
			});
		}
		else{
			this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
		}
	};
	handleChange = (value, prop) => {
		var val = "";
		let { customerData, searchObj } = this.state;
		if (prop === "customerId") {
			let index = customerData.findIndex(function (o) {
				return o.name === value;
			});
			val = customerData[index].id;
		}
		searchObj[prop] = prop === "customerId" ? val : value;
		this.setState({ ...this.state, searchObj });
	};
	handleSearch = (values) => {
		let { searchObj } = this.state;
		this.setState({ ...this.state, searchObj }, () => {
			this.gridRef.current.refreshGrid();
		});
		apiCalls.trackEvent({
			Type: "Admin",
			Action: "Transactions grid page view",
			Username: this.props.userConfig?.userName,
			MemeberId: this.props.userConfig?.id,
			Feature: "Transactions",
			Remarks: "Transactions grid page view",
			Duration: 1,
			Url: window.location.href,
			FullFeatureName: "Transactions",
		});
	};
   
	 generateOptions = (data) => {
		return data.map((d) => (
			<Option key={d.value} value={d.name}>
				{d.name}
			</Option>
		));
	};

	render() {
		const { customerData, typeData, doctypeData, gridUrl, searchObj,errorMsg } =
			this.state;
			const options = this.generateOptions(customerData);
			const options1 = this.generateOptions(typeData);
			const options2 = this.generateOptions(doctypeData);

		return (
			<>
			{errorMsg !== undefined && errorMsg !== null && (
          <div style={{ width: '100%' }}>
            <Alert
              className="w-100 mb-16 align-center"
              type="warning"
              description={errorMsg}
              showIcon
            />
          </div>
        )}
				<div>
					<Form
						initialValues={this.state.customerData}
						className="ant-advanced-search-form form form-bg search-bg pt-8"
						autoComplete="off">
						<Row style={{ alignItems: "flex-end" }}>
							<Col sm={24} xs={24} md={12} lg={7} className="px-8">
								<Form.Item
									name="customerId"
									className="input-label mb-0"
									label="User Name">
									<Select
										defaultValue="All"
										className="cust-input w-100 bgwhite"
										showSearch
										onKeyUp={(event) =>
											this.handleUserChange(event, "customerId")
										}
										onChange={(e) => this.handleChange(e, "customerId")}
										placeholder="Select Members">
										{options}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={24}xs={24} md={12} lg={7}className="px-8">
								<Form.Item
									name="type"
									className="input-label mb-0"
									label="Type">
									<Select
										defaultValue="All"
										className="cust-input w-100 bgwhite"
										showSearch
										onChange={(e) => this.handleChange(e, "type")}
										placeholder="Select Type">
										{options1}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={24}xs={24} md={12} lg={7}className="px-8">
								<Form.Item
									name="docType"
									className="input-label mb-0"
									label="Transaction ">
									<Select
										defaultValue="All"
										className="cust-input w-100 bgwhite"
										showSearch
										onChange={(e) => this.handleChange(e, "docType")}
										placeholder="Select Transaction">
										{options2}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={24} md={3} lg={3} xl={3} xxl={3} className="px-8 text-right">
             
			 <Button
			   type="primary"
			   className="primary-btn px-24 search-btn mt-20"
			   htmlType="submit"
			   onClick={this.handleSearch}
			 >Search
			 </Button>
			
		   </Col>
						</Row>
					</Form>
				</div>
				<List
					showActionBar={true}
					url={gridUrl}
					pKey={"Transactions"}
					additionalParams={searchObj}
					ref={this.gridRef}
					key={gridUrl}
					columns={this.gridColumns}
					showExcelExport={true}
					className="transaction-table-header"
				/>

			
				<Modal
					title={<p>Info Details</p>}
					visible={this.state.cryptoModal}
					className="crypto-list"
					destroyOnClose={true}
					closeIcon={
						<Tooltip title="Close ">
							<span
								className="icon md x c-pointer"
								onClick={() =>
									this.setState({ ...this.state, cryptoModal: false })
								}
							/>
						</Tooltip>
					}
					footer={
						<Button
							type="primary"
							className="primary-btn cancel-btn"
							onClick={() =>
								this.setState({ ...this.state, cryptoModal: false })
							}>
							Close
						</Button>
					}>
					<Info id={this.state.selectedId} type={this.state.selectedModal} screen={'Deposit'} />
				</Modal>
			</>
		);
	}
}

const connectStateToProps = ({ oidc, userConfig }) => {
	return { userConfig: userConfig.userProfileInfo, oidc };
};
export default connect(connectStateToProps, (dispatch) => {
	return { dispatch };
})(Transaction);
