import { Modal, Tooltip, Form, Select, Input, Button, Alert,message,Col,Row } from "antd";
import React, { Component } from "react";
import { confirmStateChange, getCustomerStates } from "./api";
import { validateContentRule } from "../../utils/custom.validator";
import apiCalls from "../../api/apiCalls";
import { connect } from "react-redux";
const { TextArea } = Input
class CustomerStateChange extends Component {
    stateChangeForm;
    state = {
        loading: false,
        statesLst: [],
        saveLoad: false,
        errorMessage: null,
        currentState: this.props?.selectedCustomer?.customerState,
        btnDisable:false,
    }
    componentDidUpdate(prevProps) {
        if ((prevProps.visible !== this.props.visible) && this.props.visible) {
            this.setState({ ...this.state, statesLst: [], loading: true,currentState:this.props?.selectedCustomer?.customerState });
            getCustomerStates("Customer", this.props.selectedCustomer?.customerState).then(response => {
                if (response.ok) {
                    this.setState({ ...this.state, statesLst: response.data, loading: false });
                }
            })
        }
    }
    onStateChange = async (values) => {
        const obj = {
            "id": this.props?.selectedCustomer?.id,
            "customerId": null,
            "info": JSON.stringify(this.props.trackAuditLogData),
            "state": values.state,
            "remarks": values.remarks,
            "modefiedBy":this.props.userConfig?.userName,
        }
        this.setState({ ...this.state, errorMessage: null, saveLoad: true });
        const response = await confirmStateChange(obj);
        if (response.ok) {
            this.setState({ ...this.state, errorMessage: null, saveLoad: false,btnDisable:false });
            this.props.onClose();
            message.success({
                content: "Record " + values.state + " successfully",
                className: "custom-msg",
                duration: 4
              });
            
        } else {
            this.setState({ ...this.state, errorMessage:apiCalls.isErrorDispaly(response), saveLoad: false});
        }
    }
    renderRemarks() {
        if (!["approval in progress", "approved","registered","under review",].includes(this.state.currentState?.toLowerCase())) {
            return <Form.Item className="input-label" name={"remarks"} label={this.state.currentState==="Rejected"?"Reason For Rejection":"Reason For Close"} rules={[{ required: true, message: "Is required",whitespace: true }, {
                validator: validateContentRule,
              },]}>
                <TextArea rows={3} className="" placeholder={this.state.currentState==="Rejected"?"Reason For Rejection":"Reason For Close"} maxLength={200} showCount disabled={this.props?.selectedCustomer?.customerState === "Rejected" || this.props?.selectedCustomer?.customerState === "Closed"}/>
            </Form.Item>
        }
    }
    render() {
        const { onClose, visible = false, selectedCustomer = {} } = this.props;
        return <Modal title={"Confirm Approve/Reject/Close Account?"}
            visible={visible}
            destroyOnClose={true}
            footer={false}
            closeIcon={
                <Tooltip title="Close">
                    <span className="icon md x c-pointer" onClick={() => {
                        this.setState({ ...this.state, errorMessage: null, saveLoad: false,btnDisable:false });
                        onClose();
                    }} />
                </Tooltip>
            }
        >
            <Form form={this.stateChangeForm} ref={ref=>this.stateChangeForm=ref} initialValues={{ state: selectedCustomer?.customerState || "", remarks: selectedCustomer?.customerRemarks || "" }} layout="vertical"
                onFinish={this.onStateChange}>

                {this.state.errorMessage && <Alert showIcon className="w-100 mb-16" type="error" description={this.state.errorMessage} />}
                <Row gutter={24} className="mb-24 pb-24 border-bottom">
				<Col xs={24} sm={24} md={12}>
                <Form.Item name={"state"} label="State" rules={[{ required: true, message: "Is required" }]}>
                    <Select className="cust-input w-80" 
                        onChange={(value) => {
                            this.stateChangeForm.setFieldsValue({remarks:""})
                            this.setState({ ...this.state, currentState:value,btnDisable:true,errorMessage:null })}}>
                        {this.state.statesLst?.map(item => <Select.Option value={item?.code}>{item?.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                {this.renderRemarks()}
                </Col>
                </Row>
                <div>
                <Form.Item className="text-right mb-0">
                    <Button
                        type="primary"
                        className="primary-btn cancel-btn"
                        onClick={() => {
                            this.setState({ ...this.state, errorMessage: null, saveLoad: false,btnDisable:false });
                            onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        className="primary-btn ml-8"
                        htmlType="submit"
                        disabled={this.state?.btnDisable===false}
                        loading={this.state?.saveLoad}
                    >
                        Save
                    </Button>
                </Form.Item>
                </div>
            </Form>
        </Modal>
    }
}

const connectStateToProps = ({ userConfig }) => {
	return {
		userConfig: userConfig.userProfileInfo,
		trackAuditLogData: userConfig.trackAuditLogData,
	};
};
export default connect(
	connectStateToProps,
)(CustomerStateChange);