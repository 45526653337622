import { clientApi } from "../../api/index";
import { ApiControllers } from "../../api/config";

const getWithdraw = (withdrawId) => {
    return clientApi.get(ApiControllers.withdraw + `Crypto/Withdraw/${withdrawId}`);
}
const getWithdrawFait = (withdrawId) => {
    return clientApi.get(ApiControllers.withdraw + `Fiat/Withdraw/${withdrawId}`);
}
const ApproveorCancelCrypto = (obj) => {
    return clientApi.put(ApiControllers.withdraw + "WithdrawCrypto/Status", obj)
}
const ApproveorCancelFiat = (obj) => {
    return clientApi.put(ApiControllers.withdraw + "WithdrawFiat/Status", obj)
}
const getStateChangeInfo=(id)=>{
    return clientApi.get(ApiControllers.withdraw+`WithdrawCrypto/${id}`)
}
const stateFiatSave = (obj) => {
    return clientApi.put(ApiControllers.withdraw + `WithdrawFiat/Status`,obj);
}
const getStates = (tabName,status,PreviousState) => {
	return clientApi.get(
		ApiControllers.common + `statechange/${tabName}/${status}/${PreviousState}`
	);
};
const Sumsub = (obj,screen) => {
    return clientApi.post(ApiControllers.withdraw + `Withdraw/${screen}/Sumsub`,obj);
}; 
export {getWithdraw,ApproveorCancelCrypto,getWithdrawFait,getStateChangeInfo, ApproveorCancelFiat,stateFiatSave,getStates,Sumsub};