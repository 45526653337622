import { fetchMenuItems, fetchScreenPermissions } from "../components/toolbar.component/api";
import { store } from "../store";

const PER_HANDLE_PERMISSIONS = "perHandlePermissions";
const UPDATE_CURRENTSCREEN = "updateCurrentScreen";
const UPDATE_CURRENTSCREENID = "updateCurrentScreenId";
const UPDATE_ACCESSDENIED = "updateAccessDenied";
const UPDATE_TAB_ACCESSDENIED = "updateTabAccessDenied";
const CLEAR_PERMISSIONS = "clearPermissions";
const UPDATE_LOADER = "updateLoader";
let permission_loading_fetures=[]
const perHandlePermissions = (payload) => {
    return {
        type: PER_HANDLE_PERMISSIONS,
        payload
    }
}
const updateLoader = (payload) => {
    return {
        type: UPDATE_LOADER,
        payload
    }
}
const updateCurrentScreen = (payload) => {
    return {
        type: UPDATE_CURRENTSCREEN,
        payload
    }
}
const updateCurrentScreenId = (payload) => {
    return {
        type: UPDATE_CURRENTSCREENID,
        payload
    }
}
const updateAccessDenied = (payload) => {
    return {
        type: UPDATE_ACCESSDENIED,
        payload
    }
}
const updateTabAccessDenied = (payload) => {
    return {
        type: UPDATE_TAB_ACCESSDENIED,
        payload
    }
}
const clearPermissions = (payload) => {
    return {
        type: CLEAR_PERMISSIONS,
        payload: null
    }
}
const setScreenIds = (perKey,permissions) =>{
    return async dispatch => {
         const _menuItem = permissions.menuItems.filter((item) => item.id === perKey)[0];
                if(_menuItem?.parentId){
                    dispatch(updateCurrentScreenId({key:'currentScreenId',data:_menuItem.parentId}))
                    dispatch(updateCurrentScreenId({key:'currentScreenTabId',data:_menuItem.id}))
                }else if(_menuItem?.isTab){
                    if(permissions.currentScreenTabId){
                        dispatch(updateCurrentScreenId({key:'currentScreenId',data:perKey}))
                    }else{
                        const _menutabItem = permissions.menuItems.filter((item) => item.parentId === _menuItem.id)[0];
                        dispatch(updateCurrentScreenId({key:'currentScreenTabId',data:_menutabItem.id}))
                        dispatch(updateCurrentScreenId({key:'currentScreenId',data:_menutabItem.parentId}))
                    }
                }else{
                    dispatch(updateCurrentScreenId({key:'currentScreenId',data:perKey}))
                    dispatch(updateCurrentScreenId({key:'currentScreenTabId',data:null}))
                }
            }
}
const getPermissions = ({ customerId, perKey }) => {
    debugger
        return async dispatch => {
            const {
                permissions,
            } = store.getState();
            if (permissions[perKey] && permissions[perKey].length > 0) {
                const _per = permissions[perKey].filter((item) => item.permissionName === 'view')[0];
                if (!_per?.values && !permissions?.currentScreenTabId) {
                    dispatch(updateAccessDenied(true))
                }
                dispatch(setScreenIds(perKey,permissions))
                dispatch(updateLoader(false))
            } else {
                const IsLoadingPermission = permission_loading_fetures.filter((key)=>key===perKey)[0];
                if(!IsLoadingPermission){
                permission_loading_fetures.push(perKey)
                dispatch(perHandlePermissions({ key: "", data: [], loading: true }));
                const response = await fetchScreenPermissions({ customerId, perKey });
                for(let i in permission_loading_fetures){
                    if(permission_loading_fetures[i]===perKey){
                        permission_loading_fetures.splice(+i,1)
                    }
                }
                if (response.ok && response.data) {
                    const _per = response.data?.actions?.filter((item) => item.permissionName === 'view')[0];
                    if (_per) {
                        const _menuItem = permissions.menuItems.filter((item) => item.id === perKey)[0];
                        if (!_per.values && !_menuItem.parentId) {
                            dispatch(updateAccessDenied(true))
                        }
                    }else{
                        dispatch(updateAccessDenied(true))
                    }
                    dispatch(setScreenIds(perKey,permissions));
                    dispatch(perHandlePermissions({ key: perKey, data: response.data.actions, loading: false, currentScreen: response.data?.screenName }))
                } else {
                    dispatch(updateAccessDenied(true))
                }
            }
        }
    }
}
const getFeatures = (customerId,appid) => {
    return async dispatch => {
        dispatch(perHandlePermissions({ key: "menuItems", data: [], loading: true }));
        const response = await fetchMenuItems(customerId,appid);
        if (response.ok) {
            dispatch(perHandlePermissions({ key: "menuItems", data: response.data, loading: true }));
        } else {
            dispatch(perHandlePermissions({ key: "menuItems", data: [], loading: true }));
        }
    }
}
const initialState = {
    loading: true,
    currentScreen: "dashboard",
    currentScreenId: null,
    currentScreenTabId:null,
    accessDenied: false,
    tabAccessDenied: false
}
const permissionsReducer = (state, action) => {
    if (!state) {
        state = {
          ...initialState,
          ...state
        }
      }
    switch (action.type) {
        case PER_HANDLE_PERMISSIONS:
            state = { ...state, [action.payload.key]: action.payload.data, loading: action.payload.loading, currentScreen: action.payload?.currentScreen || state.currentScreen };
            return state;
        case UPDATE_CURRENTSCREEN:
            state = { ...state, currentScreen: action.payload || state.currentScreen };
            return state;
        case UPDATE_CURRENTSCREENID:
            state = { ...state, [action.payload.key]: action.payload.data };
            return state;
        case UPDATE_LOADER:
            state = { ...state, loading: action.payload };
            return state;
        case UPDATE_ACCESSDENIED:
            return { ...state, accessDenied: action.payload };
        case UPDATE_TAB_ACCESSDENIED:
            return { ...state, tabAccessDenied: action.payload, accessDenied:false};
        case CLEAR_PERMISSIONS:
            return {...state, loading: false, currentScreen: "dashboard", currentScreenId: null, accessDenied: false, menuItems: [] }
        default:
            return state;
    }
}
export { getPermissions, getFeatures, updateCurrentScreen, updateAccessDenied, clearPermissions, updateCurrentScreenId, updateLoader, updateTabAccessDenied }
export default permissionsReducer;