import React, { Component } from "react";
import {
  Form,
  Select,
  Tooltip,
  message,
  Modal,
  Button,
  Row,
  Col,
  Input,
  Alert
} from "antd";
import List from "../grid.component";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { connect } from "react-redux";
import Loader from "../loader.component";
import Moment from "react-moment";
import { validateContentRule } from '../../utils/custom.validator';
import {saveBankDeposit,saveEvolveBankDeposit} from './api'
import apiCalls from "../../api/apiCalls";
import config from "../../config";
const { Option } = Select;
const { TextArea } = Input;
class BankPyrrosDeposit extends Component {
  constructor(props) {    
    super(props);
    this.state = {
      check: false,
      selection: [],
      selectedObj: {},
      gridUrl:
      this.props?.location?.pathname === "/pyrrosdeposit"
        ? process.env.REACT_APP_GRID_API + "Bank/GetPyrrosDeposits"
        : this.props?.location?.pathname === "/openpayddeposit" && process.env.REACT_APP_GRID_API + "Bank/GetOpenpaydDeposits" ||  process.env.REACT_APP_GRID_API + "Bank/GetEvolveDeposits",
      stateLoading: false,
      isSubmitting: false,
      stateChange: {},
      error: null,
      obj: {
        id: "",
        state: "",
        ReasonofCancellation: ""
      },
      warningMsg: null,
      isModal: false,
      statusLoading: false,
      errorMsg: null,
      statuserrorMsg:null,
      stateLu:[],
      selectedState:null,
    };
    this.gridDepositRef = React.createRef();
    this.formref = React.createRef();
  }
  gridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked: true,
      customCell: (props) => (

          <label className="text-center custom-checkbox">
            <input
              id={props.dataItem.id}
              name="check"
              type="checkbox"
              checked={this.state.selection.indexOf(props.dataItem.id) > -1}
              onChange={(e) => this.handleSelectionChange(props, e)}
            />
            <span></span>{" "}
          </label>
      )
    },
    {
      field: "date",
      title: "Date",
      filter: true,
      width: 220,
      isShowTime: true,
      locked: true,
      filterType: "date",
      customCell: (props) => (
          <div
            className="gridLink"
            onClick={() => this.updateDetailsView(props)}
          >
            {props.dataItem?.date ? (
              <Moment format={config?.dateFormates?.dateTimeFormate}>
                {apiCalls.convertUTCToLocalTime(props.dataItem?.date)}
              </Moment>
            ) : (
              props.dataItem?.date
            )}
          </div>

      )
    },
    {
      field: "transactionId",
      title: "Transaction ID",
      filter: true,
      width: 250
    },
    {
      field: "referenceId",
      title: "Customer ID",
      filter: true,
      width: 150
    },
    {
      field: "name",
      title: "Business Name/Personal Name",
      filter: true,
      width: 300,
      customCell: (props) => (
				<td className="whitelist-name">
				<div className="d-flex">
				<div className="white-address address-width">	{props.dataItem.name}</div>
					{(props.dataItem?.isDigitaltransfer || props?.dataItem?.accountType) && (
						<div className="file-labels ml-8 fs-12 address-label address-label-width">
							{(props.dataItem.isDigitaltransfer || props?.dataItem?.accountType) && "Personal"}
						</div>
					)}</div>
				</td>
			),
    },

    { field: "sendersName", title: "Sender's Name", filter: true, width: 200 },
    {
      field: "sendersBankName",
      title: "Sender's Bank Name ",
      filter: true,
      width: 210
    },
    {
      field: "sendersBankAccount",
      title: "Sender's Bank Account Number/IBAN",
      filter: true,
      width: 310
    },

    {
      field: "grossAmount",
      title: "Gross Amount",
      filter: true,
      width: 180,
      dataType: "number",
      filterType: "numeric"
    },

    {
      field: "commission",
      title: "Commission",
      filter: true,
      width: 180,
      dataType: "number",
      filterType: "numeric"
    },

    {
      field: "netAmount",
      title: "Net Amount",
      filter: true,
      width: 160,
      dataType: "number",
      filterType: "numeric"
    },
    
    { field: "bankTransactionStatus", title: "Bank Status", filter: true, width: 140 },

    { field: "state", title: "Status", filter: true, width: 140 }
  ];

  updateDetailsView = (e) => {
    const items = e.dataItem;
    const val = items.id;
    if (this.props?.location?.pathname === "/pyrrosdeposit") {
      this.props.history.push("/pyrrosdeposit/details/" + val + "/view");
    } else if(this.props?.location?.pathname === "/evolvedeposit") {
      this.props.history.push("/evolvedeposit/details/" + val + "/view");
    }else{
      this.props.history.push("/openpayddeposit/details/" + val + "/view");
    }
    this.props.dispatch(
      setBreadcrumb({
        key:
          (this.props?.location?.pathname === "/pyrrosdeposit" && "/pyrrosdeposit" || this.props?.location?.pathname === "/evolvedeposit" && "/evolvedeposit" || "/openpayddeposit") +
          "/details/" +
          val +
          "/view",
        val: `${(e.dataItem.userName || e.dataItem.name)+" " + "/" + " " + e.dataItem.state}`
      })
    );
  };

  statusChange = () => {
    const { check, selectedObj, stateChange } = this.state;
    const isSubmitted = stateChange.state !== "Submitted";
    
    if (!check) {
      this.setState({
        ...this.state,
        warningMsg: "Please select the one record",
        isSubmitting: isSubmitted
      });
  
      setTimeout(() => {
        this.setState({
          ...this.state,
          alert: false,
          isSubmitting: isSubmitted
        });
      }, 1000);
    } else {
      this.getStatesLu();
      this.setState({
        ...this.state,
        isModal: true,
        stateChange: {
          status: selectedObj.state,
          customerId: selectedObj.customerId,
          ReasonofCancellation: selectedObj?.state === "Approved" ? selectedObj?.remarks : selectedObj?.reasonOfCancellation,
        },
        stateLoading: true,
        isSubmitting: isSubmitted
      });
  
      setTimeout(() => {
        this.setState({
          ...this.state,
          stateLoading: false,
          isSubmitting: isSubmitted
        });
      }, 1000);
  
      setTimeout(() => {
        this.formref.current.setFieldsValue({
          status: selectedObj.state,
          customerId: selectedObj.customerId,
          ReasonofCancellation: selectedObj?.reasonOfCancellation,
          remarks: selectedObj?.remarks
        });
      }, 1000);
    }
  };
  

  handleSelectionChange = (prop, e) => {
    const rowObj = prop.dataItem;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowObj.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowObj.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowObj,
      selection,
      warningMsg: null,
      errorMsg:null,
      statuserrorMsg:null,
    });
  };

  success = (status) => {
    switch (status) {
      case "Approved":
        status = "Approved";
        break;
      case "Reject":
        status = "Rejected";
        break;
      case "Pending":
        status = "Pending";
        break;
        default:
        return null;
    }
    message.success({
      content:
        (status === "transferred" ? "Amount " : "Record ") +
        (status === "Approve" ? "Approved" : status) +
        " successfully",
      className: "custom-msg",
      duration: 4
    });
  };

  error = () => {
    message.error("Please select the one record");
  };

  stateChange = (val, prop) => {
    
    let { stateChange } = this.state;
    stateChange.state = val;
    this.setState({ ...this.state, stateChange,selectedState:val });
  };
  
  handleCancel = (e) => {
    this.setState({
      ...this.state,
      isModal: false,
      selection: [],
      check: false,
      error: null,
      errorMsg:null,
      warningMsg: null,
      statuserrorMsg:null,
      selectedState:null,
    });
  };

  depositStatusChange = async (values) => {
    const isPyrrosDeposit = this.props?.location?.pathname === "/pyrrosdeposit";
    const saveFunction = isPyrrosDeposit ? saveBankDeposit : saveEvolveBankDeposit;
    this.setState({...this.state, selection: [], check: true, stateLoading: false, isBtnApprove: true, warningMsg: null, errorMsg: null, statusLoading: true, statuserrorMsg: null});
    if (!this.isLoading) {
      let statusObj = {
        ...this.state.obj,
        id: this.state.selectedObj.id,
        customerId: this.state.selectedObj.customerId,
        state: values.status,
        ModifiedBy: isPyrrosDeposit ? this.props?.userConfig?.userName : undefined
      };
      if (values?.status === "Rejected") {
        statusObj.ReasonofCancellation = values.ReasonofCancellation;
        statusObj.remarks = "";
      } else if (values?.status === "Approved") {
        statusObj.remarks = values.remarks;
        statusObj.ReasonofCancellation = "";
      }
      let response = await saveFunction(statusObj);
      if (response.ok) {
        this.success(statusObj.state);
        this.setState({...this.state, isModal: false,selection: [],stateLoading: false,isBtnApprove: false,warningMsg: null,statusLoading: false,errorMsg: null,statuserrorMsg: null,selectedState: null, check: false});
        setTimeout(() => {
          this.isLoading = false;
          this.gridDepositRef.current?.refreshGrid();
        }, 2000);
      } else {
        this.setState({...this.state,isLoading: false, stateLoading: false,isBtnApprove: false,statusLoading: false,statuserrorMsg: apiCalls.isErrorDispaly(response),errorMsg: null,warningMsg: null });
      }
    }
  };
  
  getStatesLu=async()=>{
    let res = await apiCalls.getStates("deposit",this.state.selectedObj.state)
    if(res.ok){
      this.setState({...this.state,stateLu:res.data})
    }else{
      this.setState({ ...this.state,stateErrorMsg: apiCalls.isErrorDispaly(res) });
    }
  }
  onActionClick = (key) => {
    const action = {
      "State Change": "statusChange",
    };
    this[action[key]]();
  };

  
  render() {
    const { gridUrl, stateChange, warningMsg,errorMsg ,statuserrorMsg} = this.state;

    return (
      <>
        {warningMsg !== undefined && warningMsg !== null && (
          <Alert
            className="w-100 mb-16"
            type="warning"
            description={warningMsg}
            showIcon
          />
        )}
         {errorMsg !== null && errorMsg !== '' &&
                    <Alert
                        className="w-100 mb-16"
                        type="error"
                        description={errorMsg}
                        showIcon
                    />}
                    
        <List
            showActionBar={true}
            pKey={this.props?.location?.pathname==="/pyrrosdeposit" && "Pyrros Deposit" || this.props?.location?.pathname === "/openpayddeposit" && "OpenPayd Deposit" || "Evolve Deposit"}
            onActionClick={(key) => this.onActionClick(key)}
            url={gridUrl}
            ref={this.gridDepositRef}
            key={gridUrl}
            columns={this.gridColumns}
            showExcelExport={true}
            className="customer-table-header"

          />

        <Modal
          title="Confirm Approve ?"
          className="custom-width"
          visible={this.state.isModal}
          closeIcon={
            <Tooltip title="Close">
              <span
                className="icon md x c-pointer"
                onClick={this.handleCancel}
              />
            </Tooltip>
          }
          footer={null}
        >
          <div>
          {statuserrorMsg != null && statuserrorMsg !== null &&
                    <Alert
                        className="w-100 mb-16"
                        type="error"
                        description={statuserrorMsg}
                        showIcon
                    />}
         
          <Form ref={this.formref}   onFinish={this.depositStatusChange}>
            <Row gutter={24} className="mb-24 pb-24 border-bottom">
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="status"
                  label="State"
                  className="input-label"
                  rules={[
                    {
                      required: true,
                      message: "Is required"
                    }
                  ]}
                >
                  <Select
                    className="cust-input mb-0"
                    onChange={(e) => this.stateChange(e)}
                    disabled={
                      this.state.selectedObj.state === "Approved" ||
                      this.state.selectedObj.state === "Rejected"
                    }
                    placeholder="Select State"
                  >
                     {this.state?.stateLu?.map((item, idx) => <Select.Option value={item?.code} key={idx} >{item?.name}</Select.Option>)}
                  </Select>
                </Form.Item>
              </Col>
              
              {(this.state.selectedObj.state === "Rejected" || stateChange.state === "Rejected") &&(
              <Col xs={24}>
                  <Form.Item
                    name="ReasonofCancellation"
                    className="input-label"
                    label= "Reason Of Cancellation"
                    rules={[
                      {
                        required: true,
                        message: "Is required"
                      },
                      {
                        validator:validateContentRule
                      }
                    ]}
                    >
                    <TextArea
                      placeholder= "Reason Of Cancellation"
                      maxLength={100}
                      rows={4}
                      disabled={
                        this.state.selectedObj.state === "Rejected" ||
                        this.state.selectedObj.state === "Approved"
                          ? true
                          : false
                      }
                      showCount
                    />
                  </Form.Item>
              </Col>)}

            </Row>
            <div>
              {this.state.stateLoading && <Loader />}
              <Form.Item className="mb-0">
                <div className="text-right">
                  <Button
                    type="primary"
                    className="primary-btn cancel-btn mr-8"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </Button>
                  {(this.state.selectedObj.state !== "Approved" && this.state.selectedObj.state !== "Rejected") && 
                  <Button
                    type="primary"
                    key="submit"
                    className="primary-btn"
                    htmlType="submit"
                    loading={this.state.statusLoading}
                    disabled={!this.state?.selectedState}

                  >
                    Save
                  </Button>}
                </div>
              </Form.Item>
            </div>
          </Form>
          </div>
        </Modal>
      </>
    );
  }
}
const connectStateToProps = ({ oidc, userConfig, permissions }) => {
  return {
    userConfig: userConfig.userProfileInfo,
    oidc,
    trackAuditLogData: userConfig.trackAuditLogData,
    permissions
  };
};
export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(BankPyrrosDeposit);
