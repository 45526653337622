import React, { useState, useEffect } from "react";
import moment from "moment";
import {
	message,
	Form,
	Modal,
	Row,
	Col,
	Input,
	Select,
	Tooltip,
	DatePicker,
	Button,
	Alert,
} from "antd";
import { useHistory } from "react-router";
import apiCalls from "../../api/apiCalls";
import Loader from "../loader.component";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import { validateContentRule } from '../../utils/custom.validator'
import config from "../../config";
const { Option } = Select;

const BalancesDetails = (props) => {
	const [form] = Form.useForm();
	const history = useHistory();
	const [providers, setProvider] = useState([]);
	const [coinType, setCoinType] = useState();
	const [coinData, setCoinData] = useState([]);
	const [wallet, setWallet] = useState([]);
	const [balanceObj, setObj] = useState({});
	const [loader, setLoader] = useState(true);
	const [errorMsg, setErrorMsg] = useState(null);
	const [walletCode, setWalletCode] = useState();
	const [isBtnLoading, setBtnLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [formData, setFormData] = useState({});
	const [value, setValue] = useState(null);

	useEffect(() => {
		getProviderValue();
	}, []);//eslint-disable-line react-hooks/exhaustive-deps

	const getProviderValue = async () => {
		setLoader(true);
		try {
			const _response = await apiCalls.getProvider();
			if (_response.ok) {
				setErrorMsg(null);
				setProvider(_response.data);
				getCoinValue(_response.data);
			} else {
				setErrorMsg(apiCalls.isErrorDispaly(_response));
			}
		} catch (error) {
			setErrorMsg(apiCalls.isErrorDispaly(error));
		} finally {
			setLoader(false);
		}
	};

	const getCoinValue = async (providerData) => {
		setLoader(true);
		try {
			const _response = await apiCalls.getCoinType();
			if (_response.ok) {
				setErrorMsg(null);
				setCoinType(_response.data);
				if (props.match.params.id !== "00000000-0000-0000-0000-000000000000") {
					loadData(_response.data, providerData);
				}
			} else {
				setErrorMsg(apiCalls.isErrorDispaly(_response));
			}
		} catch (error) {
			setErrorMsg(apiCalls.isErrorDispaly(error));
		} finally {
			setLoader(false);
		}
	};

	const loadData = async (coinTypes, providerData) => {
		setLoader(true);
		try {
			const response = await apiCalls.getBalanceValues(props.match.params.id);
			if (!response.ok) {
				setErrorMsg(apiCalls.isErrorDispaly(response));
				return;
			}

			const { data } = response;
			const dateValue = data.date ? moment(new Date(data.date)) : "";
			const amountSymbol = data.value.includes("-") ? "-" : "+";
			let amount;
			if (data.value.includes("e")) {
				amount = walletCode === "Fiat" ? Number(data.value).toFixed(2) : Number(data.value).toFixed(8);
			} else {
				amount = data.value.split("-")[1];
			}
			handleProvider(data.provider, providerData);
			handleChange(data.type, coinTypes);

			setObj({
				...data,
				date: dateValue,
				value: amountSymbol,
				amountValue: amount
			});

			setValue(amountSymbol);
			form.setFieldsValue({ ...data });
		} catch (error) {
			setErrorMsg(apiCalls.isErrorDispaly(error));
		} finally {
			setLoader(false);
		}
	};

	const handleProvider = (type, providerData) => {
		form.setFieldsValue({ type: null, coinCode: null });
		setWallet([]);
		const providerDuplicateData = providerData ? providerData : providers;
		providerDuplicateData?.forEach((item) => {
			if (item.code === type) {
				setCoinData(item.walletTypes);
			}
		});
	};

	const handleChange = (code, coinTypes) => {
		setWalletCode(code);
		let values;
		form.setFieldsValue({ coinCode: null });
		if (coinTypes) {
			values = coinTypes?.filter((item) => item.code === code);
		}
		setWallet(values);
		form.setFieldsValue({ type: code });
	};
	const onSelectAddons = (e) => {
		setValue(e);
	}
	const handleWallet = (code) => {
		form.setFieldsValue({ CoinCode: code });
	};
	const backToBalances = () => {
		history.push("/balances");
	};

	const validateNumber = (_, validNumberValue) => {
		if (validNumberValue === ".") {
			return Promise.reject("Please enter valid content");
		}
		return Promise.resolve();
	};

	const saveBalanceInfo = async (values) => {
		setFormData(values);
		const currentDate = new Date();
		if (isDateValid(values.date, currentDate)) {
			saveBalance(values);
		} else {
			setShowModal(true);
		}
	};

	const isDateValid = (inputDate, currentDate) => {
		const inputDateObj = new Date(inputDate);
		return inputDateObj >= currentDate;
	};

	const saveBalance = async (values) => {
		setBtnLoading(true);
		try {
			if (!isValidAmount(values.amountValue)) {
				throw new Error("Amount must be greater than zero.");
			}
			const formattedAmount = formatAmount(values.amountValue);
			const balanceData = createBalanceData(values, formattedAmount);
			const response = await apiCalls.saveBalanceData(balanceData);
			if (response.ok) {
				handleSuccessfulSave();
			} else {
				handleSaveError(response);
			}
		} catch (error) {
			handleSaveError(error.message);
		} finally {
			setBtnLoading(false);
		}
	};

	const isValidAmount = (amount) => {
		return amount !== null && amount > 0 && amount !== ".";
	};

	const formatAmount = (amountValue) => {
		const fixedAmountVal = parseFloat(amountValue.replace(/,/g, ""));
		return walletCode === "Fiat" ? fixedAmountVal.toFixed(2) : fixedAmountVal.toFixed(8);
	};

	const createBalanceData = (values, formattedAmount) => {
		const { id } = props.match.params;
		const { provider, type, coinCode, date, remarks } = values;

		return {
			Id: id !== "00000000-0000-0000-0000-000000000000" ? id : "00000000-0000-0000-0000-000000000000",
			Provider: provider,
			Type: type,
			CoinCode: coinCode,
			Date: moment(date).format("MM/DD/YYYY HH:mm:ss"),
			Value: `${value}${formattedAmount}`,
			CreatedDate: new Date(),
			CreatedBy: props?.userConfig?.userName,
			Remarks: remarks,
			customerId: props?.userConfig?.id,
			info: JSON.stringify(props.trackAuditLogData),
		};
	};

	const handleSuccessfulSave = () => {
		message.destroy();
		message.success({
			content: "Balance details saved successfully.",
			className: "custom-msg",
			duration: 3,
		});
		props.history.push(`/balances`);
	};

	const handleSaveError = (error) => {
		message.destroy();
		setErrorMsg(apiCalls.isErrorDispaly(error));
	};

	const handleCancel = () => {
		setShowModal(false);
	};

	const handleOk = () => {
		saveBalance(formData);
		setShowModal(false);
	};

	const options = providers?.map((data, idx) => (
		<Option key={idx} value={data.code}>
			{data.code}
		</Option>
	));

	const options1 = coinData?.map((item, idx) => (
		<Option key={idx} value={item}>
			{item}
		</Option>
	));

	const options2 = wallet?.map((data, idx) => (
		<Option key={idx} value={data.defaultValue}>
			{data.defaultValue}
		</Option>
	));

	return (
		<>
			{errorMsg && (
				<Alert
					type="error"
					description={errorMsg}
					onClose={() => setErrorMsg(null)}
					showIcon
				/>
			)}
			{loader && <Loader />}
			<Form
				initialValues={balanceObj}
				form={form}
				name="advanced_search"
				className="ant-advanced-search-form"
				onFinish={saveBalanceInfo}
				autoComplete="off">
				<Row gutter={24}>
					<Col xs={24} sm={24} md={12} lg={8} xxl={6}>
						<Form.Item
							name="date"
							className="input-label"
							label="Date"
							rules={[{ required: true, message: "Is required" }]}>
							<DatePicker
								showTime={{ format: "HH:mm:ss" }}
								format={"DD/MM/YYYY HH:mm:ss"}
								className="cust-input"
								placeholder="Select Date"
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={8} xxl={6}>
						<Form.Item
							name="provider"
							label="Provider"
							className="input-label"
							rules={[
								{
									required: true,
									message: "Is required",
								},
							]}>
							<Select
								className="cust-input"
								onChange={(e) => handleProvider(e)}
								placeholder="Select Provider">
								{options}
							</Select>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={8} xxl={6}>
						<Form.Item
							name="type"
							label=" Wallet Type"
							className="input-label"
							rules={[
								{
									required: true,
									message: "Is required",
								},
							]}>
							<Select
								className="cust-input"
								onChange={(e) => handleChange(e, coinType)}
								placeholder="Select Wallet Type">
								{options1}
							</Select>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={8} xxl={6}>
						<Form.Item
							name="coinCode"
							label="Wallet Code"
							className="input-label"
							rules={[
								{
									required: true,
									message: "Is required",
								},
							]}>
							<Select
								className="cust-input"
								onChange={(e) => handleWallet(e)}
								placeholder="Select Wallet Code">
								{options2}
							</Select>
						</Form.Item>
					</Col>
					<Col xs={24}
						sm={24}
						md={12}
						lg={8}
						xxl={6}
						className="">

						<Form.Item
							name="amountValue"
							label="Value"
							className="input-label"
							type="number"
							rules={[
								{
									required: true,
									message: "Is required",
								},
								{
									validator: validateNumber
								}
							]}>
							<NumberFormat
								decimalScale={walletCode === "Fiat" ? 2 : 8}
								className="cust-input value-field cust-addon mt-0"
								customInput={Input}
								thousandSeparator={true}
								prefix={""}
								placeholder="Enter Value"
								allowNegative={false}
								maxlength={walletCode === "Fiat" ? 13 : 20}
								addonBefore={<Select value={value}
									className="cust-input "
									showSearch
									onChange={(e) => onSelectAddons(e)}
									placeholder="Select">
									<option value="+">+</option>
									<option value="-">-</option>
								</Select>}

							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={8} xxl={6}>
						<Form.Item
							name="remarks"
							className="input-label"
							label="Remarks"
							rules={[{ required: false, message: "Is required" },
							{
								validator: validateContentRule
							}]}>
							<Input
								placeholder="Remarks"
								className="cust-input"
								maxLength={100}
							/>
						</Form.Item>
					</Col>
				</Row>
				{balanceObj !== null && balanceObj !== undefined && props.match.params.id !== "00000000-0000-0000-0000-000000000000" &&
					<Row gutter={24}>

						<Col xs={24} sm={24} md={12} lg={12} xxl={12}>
							<div className="record-history d-flex align-center ml-0">
								<div className="record-history-text">
									{balanceObj?.createdBy} created this on  {"  "}
									<Moment format={config?.dateFormates?.dateTimeFormate}>
										{balanceObj?.createdDate
											? apiCalls.convertUTCToLocalTime(balanceObj?.createdDate)
											: balanceObj?.createdDate}
									</Moment></div>
							</div>
						</Col>

						{balanceObj?.modifiedBy && (
							<Col xs={24} sm={24} md={12} lg={12} xxl={12}>
								<div className="record-history d-flex align-center ml-0">
									<div className="record-history-text">
										{balanceObj?.modifiedBy} modified this on{" "}
										<Moment format={config?.dateFormates?.dateTimeFormate}>
											{balanceObj?.modifiedDate
												? apiCalls.convertUTCToLocalTime(balanceObj?.modifiedDate)
												: balanceObj?.modifiedDate}
										</Moment>
									</div>
								</div>
							</Col>
						)}
					</Row>}
				<div className="text-right mt-24">
					<Button
						type="primary"
						className="primary-btn"
						htmlType="submit"
						loading={isBtnLoading}>
						Save
					</Button>
					<Button
						type="primary"
						className="primary-btn cancel-btn"
						style={{ margin: "0 8px" }}
						onClick={() => backToBalances()}>
						Cancel
					</Button>
				</div>
			</Form>
			<Modal
				title={"Balance Alert"}
				visible={showModal}
				closeIcon={
					<Tooltip title="Close">
						<span className="icon md x c-pointer" onClick={handleCancel} />
					</Tooltip>
				}
				footer={
					<>
						<Button
							type="primary"
							className="primary-btn cancel-btn"
							onClick={handleCancel}>
							No
						</Button>
						<Button type="primary" className="primary-btn" onClick={handleOk}>
							Yes
						</Button>
					</>
				}>
				<p className="fs-16 mb-0">
					Do you really want to continue with past date?
				</p>
			</Modal>
		</>
	);
};

const connectStateToProps = ({ userConfig }) => {
	return { userConfig: userConfig.userProfileInfo, trackAuditLogData: userConfig.trackAuditLogData };
};
export default connect(connectStateToProps)(BalancesDetails);
