
import React, { Component } from "react";
import {
  Row,
  Col,
  Tooltip,
  Modal,
  Button,
} from "antd";
import List from "../grid.component";
import apiCalls from "../../api/apiCalls";
import Moment from "react-moment";
import config from "../../config";

class info extends Component {
  constructor (props) {
    super(props);
    this.state = {
      createInfoModal: false,
      infoModal: false,
      loading: true,
      stateLoading: true,
    };
   
    this.gridRef = React.createRef();
    this.creategridRef = React.createRef();
  }
  renderDateColumns=(rendereColumnDate)=>(
    <td>
          <div>
            <Moment format={config?.dateFormates?.dateTimeFormate} >{rendereColumnDate.dataItem.createdDate ? apiCalls.convertUTCToLocalTime(rendereColumnDate.dataItem.createdDate) : rendereColumnDate.dataItem.createdDate}</Moment>
          </div>
        </td>
  )

  createdColumns = [
    {
      field: "date",
      title: "Date",
      width: 210,
      isShowTime: true,
      filter:false,
      customCell:this.renderDateColumns
    },
    {
      field: "action", title: "Action", width: 200,filter:false
    },
    { field: "city", title: "City", width: 100, filter:false },
    { field: "state", title: "State", width: 110, filter:false },
    { field: "country", title: "Country", width: 100, filter:false },
    { field: "postalCode", title: "Postal Code", width: 110, filter:false },
    { field: "description", title: "Description", width: 140, filter:false },
    { field: "status", title: "Status", width: 110, filter:false },
  ];

  cryptoColumns = [
    {
      field: "createdBy", title: "Name", width: 160,filter:false
    },
    { field: "status", title: "State", width: 120,filter:false },

    {
      field: "createdDate",
      title: "Date",
      width: 160,
      isShowTime: true,
      filter:false,
      customCell:this.renderDateColumns

    },

    { field: "remarks", title: "Remarks", width: 180,filter:false },

  ];
  hideCaseModal = () => {
    this.setState({ ...this.state, infoModal: false});
  }
  openCaseModal = () => {
    this.setState({ ...this.state, infoModal: true});
  }
  hideCreateModal = () => {
    this.setState({ ...this.state, createInfoModal: false});
  }
  openCreateModal = () => {
    this.setState({ ...this.state, createInfoModal: true});
  }
  render() {
    const { infoModal, createInfoModal } = this.state;

    return (
      <div>
        <Row gutter={24}>
          {this.props.Createdby && <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
            <div className="record-history d-flex align-center ml-0">
            {this.props.isShowInfo && <Tooltip title="Click here to audit logs history">
                <span
                  className="icon md info c-pointer"
                  onClick={this.openCreateModal}
                />
              </Tooltip> }
              <div className="record-history-text ">
              <div style={{ marginLeft: "5px" }}>
                {this.props.Createdby}{" "} created this on {" "}
                <Moment format={config?.dateFormates?.dateTimeFormate} >{this.props.CreatedDate ? apiCalls.convertUTCToLocalTime(this.props.CreatedDate) : this.props.CreatedDate}</Moment>
              </div>
              </div> 
              </div>
          </Col>}

          {(this.props.Modifiedby) && <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
            <div className="record-history d-flex align-center ml-0">
              {!this.props?.infoDetails &&(
              <Tooltip title="Click here to view status history">
                <span
                  className="icon md info c-pointer"
                  onClick={this.openCaseModal}
                />
              </Tooltip>)}
              <div className="record-history-text">

                <div style={{ marginLeft: "5px" }}>
                  {this.props.Modifiedby}{" "} modified this on {" "}

                  <Moment format={config?.dateFormates?.dateTimeFormate} >{this.props.ModifiedDate ? apiCalls.convertUTCToLocalTime(this.props.ModifiedDate) : this.props.ModifiedDate}</Moment>
                </div>
              </div>
            </div>
          </Col>}
        </Row>

        <Modal
          title="Status History"
          visible={infoModal}
          width={1000}
          className="crypto-list footer-remove hide-filter status-histry"
          destroyOnClose={true}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.hideCaseModal} />
            </Tooltip>
          }
          footer={
            <Button
              type="primary"
              className="primary-btn cancel-btn"
              onClick={this.hideCaseModal}
              >
              Close
            </Button>
          }
        >
          <List
            ignoreSaved={true}
            columnsIsNotReorderable={true}
            url={process.env.REACT_APP_GRID_API + "Withdraw/WithDrawStatus"}
            additionalParams={{ TypeId: this.props.Id }}
            columns={this.cryptoColumns}
            ref={this.gridRef}
            className="custom-status-grid send-custom-grid"
            sortable={false}
            pSize={"All"}
          />

        </Modal>

        <Modal
          title="Audit Logs History"
          visible={createInfoModal}
          width={1000}
          className="crypto-list footer-remove"
          destroyOnClose={true}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.hideCreateModal} />
            </Tooltip>
          }
          footer={
            <Button
              type="primary"
              className="primary-btn cancel-btn"
              onClick={this.hideCreateModal}
              >
              Close
            </Button>
          }
        >
          <List
            url={process.env.REACT_APP_GRID_API + "AuditLogs/CustomerCreationStatus"}
            additionalParams={{ TypeId: this.props.Id, ScreenName: this.props.screenName }}
            columns={this.createdColumns}
            ref={this.creategridRef}
            className="custom-status-grid"
            sortable={false}
          />

        </Modal>
      </div>
    );
  }
}
export default info;