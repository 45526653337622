import React, { Component } from 'react';
import { Tooltip, message, Modal, Button, Alert } from 'antd';
import { connect } from 'react-redux';
import List from "../grid.component";
import apiCalls from '../../api/apiCalls';
import { setBreadcrumb } from '../../reducers/breadcrumbReducer';
import { setCurrentAction } from '../../reducers/actionsReducer';

class ControlCodes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check: false,
            selection: [],
            selectedObj: {},
            modal: false,
            obj: {
                "Id": [],
                "TableName": "Common.ControlCodeCategory",
                "modifiedBy": "",
                "Status": [],
            },
            alert: false,
            error: null,
            warningMsg:null,
        }

        this.editCategory = this.editCategory.bind(this);
        this.addControlCodes = this.addControlCodes.bind(this)
        this.gridRef = React.createRef();
    }
    componentDidMount() {
        this.props.dispatch(setCurrentAction(null));
        this.ControlcodesTrack();
    }
    ControlcodesTrack = () => {
        apiCalls.trackEvent({
          Type: "Admin",
          Action: "Control codes grid page view",
          Username: this.props.userConfig?.userName,
          MemeberId: this.props.userConfig?.id,
          Feature: "ControlCode",
          Remarks: "Control codes grid page view",
          Duration: 1,
          Url: window.location.href,
          FullFeatureName: "Controlcode"
        });
      };
    handleSelectionChange = (prop, e) => {
        const rowObj = prop.dataItem;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const name = e.target.name;
        let { selection } = this.state;
        let idx = selection.indexOf(rowObj.id);
        if (selection) {
            selection = [];
        }
        if (idx > -1) {
            selection.splice(idx, 1);
        }
        else {
            selection.push(rowObj.id);
        }
        this.setState({ ...this.state, [name]: value, selectedObj: rowObj, selection,warningMsg:null });
    }
   


    statusUpdate = () => {
        const _obj = this.state.selectedObj;
        if (!this.state.check) {
            this.setState({ alert: true })
            setTimeout(() => this.setState({ alert: false }), 2000)
        } else if (_obj.userCreated === "system" || _obj.userCreated === "System") {
            message.destroy();
            message.warning({ content: "System record shouldn't allow to status change", className: 'custom-msg' });
            return;
        } else {
            this.setState({ modal: true, })
        }

    }
    handleCancel = e => {
        this.props.dispatch(setCurrentAction(null));
        this.setState({ modal: false, selection: [], check: false });
    }
    statusSuccess = () => {
        message.success({
            content: 'Record ' + (this.state.selectedObj.status === 'Active' ? 'deactivated' : 'activated') + ' successfully',
            className: 'custom-msg',
            duration: 3
        });
    };
    handleOk = async () => {
        let statusObj = this.state.obj;
        statusObj.Id.push(this.state.selectedObj.id);
        statusObj.modifiedBy = this.props.userConfig?.userName;
        statusObj.Status.push(this.state.selectedObj.status);
        statusObj.info=JSON.stringify(this.props.trackAuditLogData);
        statusObj.customerId = this.props.userConfig?.id;
        const response = await apiCalls.activeInactive(statusObj);
        if (response.ok) {
            this.setState({...this.state,error:null})
            this.props.dispatch(setCurrentAction(null));
            this.gridRef.current.refreshGrid();
            this.statusSuccess();
        } else {
            this.setState({ ...this.state, error:apiCalls.isErrorDispaly(response) })
        }
        this.setState({ modal: false, selection: [], check: false,warningMsg:null })

        apiCalls.trackEvent({
            Action:  
            " Record " +
            (this.state.selectedObj.status === "Active"
              ? "deactivated"
              : "activated"),
            Feature: "Control codes",
            Remarks:  
              " Record " +
              (this.state.selectedObj.status === "Active"
                ? "deactivated"
                : "activated"),
            FullFeatureName: "Control codes",
            userName: this.props.userConfig.userName,
            id: this.props.userConfig.id
          });    };

    gridColumns = [
        {
            field: "", title: "", width: 50,
            customCell: (props) => (
                <td className="text-center">
                    <label className="text-center custom-checkbox">
                        <input
                            id={props.dataItem.id}
                            name="check"
                            type="checkbox"
                            checked={this.state.selection.indexOf(props.dataItem.id) > -1}
                            onChange={(e) => this.handleSelectionChange(props, e)}
                        />
                        <span></span>
                    </label>
                </td>
            )
        },
        { field: "controlCategoryCode", title: "Category",width: 300 , filter: true, customCell: (props) => <td><div className="gridLink" onClick={() => this.update(props)}>{props.dataItem.controlCategoryCode}</div></td> },
        { field: "userCreated", title: "Created By", filter: true, width: 300 },
        { field: "createdDate", title: "Created Date", filter: true, filterType: "date", width: 330  },
    ]

    addControlCodes = () => {
        this.props.history.push('/controlcodes/00000000-0000-0000-0000-000000000000');
        this.props.dispatch(setBreadcrumb({ key: `/controlcodes/00000000-0000-0000-0000-000000000000`, val: "Add Control Code" }))
        apiCalls.trackEvent({ "Action": 'Control code add page view', "Feature": 'Control codes', "Remarks": "Control code add page view", "FullFeatureName": 'Control codes', "userName": this.props.userConfig.userName, id: this.props.userConfig.id });
    }
    editCategory = () => {
        if (!this.state.check) {
            this.setState({ ...this.state, warningMsg: 'Please select the one record' })
        } else {
            const obj = this.state.selectedObj;
            const val = obj.id;
            if (obj.status === 'Inactive' || obj.userCreated.toLowerCase() === 'system') {
                this.props.history.push(`/controlcodes/${val}/disabled`);
                this.props.dispatch(setBreadcrumb({ key: `/controlcodes/${val}/disabled`, val: obj.controlCategoryCode }))
            }
            else {
                this.props.history.push('/controlcodes/' + val );
                this.props.dispatch(setBreadcrumb({ key: '/controlcodes/' + val, val: obj.controlCategoryCode }))
            }
        }
        apiCalls.trackEvent({ "Action": 'Control code edit page view', "Feature": 'Control codes', "Remarks": "Control code edit page view", "FullFeatureName": 'Control codes', "userName": this.props.userConfig.userName, id: this.props.userConfig.id });
    }
    update = (e) => {
        const items = e.dataItem;
        this.props.dispatch(setCurrentAction(null));
        const val = (items.id);
       this.props.history.push(`/controlcodes/${val}/view`);
         this.props.dispatch(setBreadcrumb({ key: `/controlcodes/${val}/view`, val: items.controlCategoryCode }))
        apiCalls.trackEvent({ "Action": 'Control code details page view', "Feature": 'Control codes', "Remarks": "Control code details page view", "FullFeatureName": 'Control codes', "userName": this.props.userConfig.userName, id: this.props.userConfig.id });
    }
   
    onActionClick = (key) => {
        const actions = {
            add: "addControlCodes",
            edit: 'editCategory',
            disable: 'statusUpdate',
        }
        this[actions[key]]();
    }

    render() {
        const { error,warningMsg } = this.state;
        return (
            <>
                {error !== undefined && error !== null && <Alert type="error" className="mb-16" showIcon message={error} />}
                {warningMsg !== undefined && warningMsg !== null && warningMsg !== "" && (
                    <div style={{ width: '100%' }}>
                        <Alert
                            className="w-100 mb-16"
                            type="warning"
                            description={warningMsg}
                            showIcon
                        />
                    </div>
                )}
                <List showActionBar={true} onActionClick={(key) => this.onActionClick(key)} pKey={"controlcodes"} ref={this.gridRef} url={process.env.REACT_APP_GRID_API + "ControlCodes"}
                    columns={this.gridColumns} className="custom-control-codes" />
                <Modal
                    title={this.state.selectedObj.status === 'Active' ? 'Confirm Deactivate?' : 'Confirm Activate?'}
                    visible={this.state.modal}
                    closeIcon={<Tooltip title="Close"><span className="icon md x c-pointer" onClick={this.handleCancel} /></Tooltip>}
                    footer={<>
                        <Button
                            type="primary"
                            className="primary-btn cancel-btn"
                            onClick={this.handleCancel}
                        >
                            No
                        </Button>
                        <Button type="primary" className="primary-btn" onClick={this.handleOk}>
                            Yes
                        </Button>
                    </>}
                >
                    <p className="fs-16 mb-0">Record is {this.state.selectedObj.status === 'Active' ? 'inactive?' : 'active?'} </p>
                </Modal>
            </>
        )
    }
}
const connectStateToProps = ({ breadCrumb, oidc, userConfig }) => {
    return { breadCrumb, oidc, userConfig: userConfig.userProfileInfo,trackAuditLogData:userConfig.trackAuditLogData }
}

export default connect(connectStateToProps, (dispatch) => { return { dispatch } })(ControlCodes);