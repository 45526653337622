import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Button, Alert } from "antd";
import Loader from "../loader.component";
import { publishShowActions } from "../grid.component/subscribir";
import { getPayeeData } from "./api";
import { bytesToSize } from "../../utils/service";
import { connect } from 'react-redux';
import { setCurrentAction } from '../../reducers/actionsReducer';
import { redirectToCaseView } from "../../utils/caseRediractions";
import { Link } from "react-router-dom";
import apiCalls from "../../api/apiCalls";
import DocumentPreview from "../shared/documentPreview";
import Info from "../info/Info";
import DocumentsUploade from '../../utils/documents';
import { PlusCircleOutlined } from '@ant-design/icons';
import Notes from "../../utils/notes";

const { Title, Text } = Typography;
const EllipsisMiddle = ({ suffixCount, children }) => {
	const start = children?.slice(0, children.length - suffixCount).trim();
	const suffix = children?.slice(-suffixCount).trim();
	return (
		<Text
			className="mb-0 fs-14 docnames c-pointer d-block"
			style={{ maxWidth: "100% !important" }}
			ellipsis={{ suffix }}>
			{start}
		</Text>
	);};
const AddressFiatView = (props) => {
    const [loading, setLoading] = useState(false);
    const [fiatData, setFiatData] = useState({});
    const [error, setError] = useState(null);
    const [docPreviewModal, setDocPreviewModal] = useState(false);
    const [docPreviewDetails, setDocPreviewDetails] = useState(null);
    const [isModalVisible, setIsVissible] = useState(false);
	const [docViewObj,setIsDocViewObj]=useState([]);

    useEffect(() => {
        publishShowActions(false);
        getLoadData();
    }, []);

    const getLoadData = async () => {
        setLoading(true);
        try {
            const response = await getPayeeData(props.match.params.payeeId, props.match.params.id);
            if (response.ok) {
                setError(null);
                setFiatData(response.data);
                setIsDocViewObj(response.data.adminDocuments);
            } else {
                setError(apiCalls.isErrorDisplay(response));
            }
        } catch (catchError) {
            setError(apiCalls.isErrorDisplay(catchError));
        } finally {
            setLoading(false);
        }
    };
	const backToAddressBook = () => {
		props.history.push("/addressbook");
	};
	const redirectCaseView = (caseData) => {
		redirectToCaseView(caseData, props);
	};
	const docPreviewOpen = (data) => {
		setDocPreviewModal(true);
		setDocPreviewDetails({ id: data.id, fileName: data.fileName });
	};
	const docPreviewClose = () => {
		setDocPreviewModal(false);
		setDocPreviewDetails(null);
	};
	const docOpen = () => {
		setIsVissible(true);
	};
	const closeModal = () => {
		setIsVissible(false);
	};
	const updateAttachemnts = (attachments) => {
		setIsDocViewObj(attachments);
	};
	  
    const renderFiles = () => {
        return fiatData?.docRepoistryLists?.map((file) => (
            <Col xs={24} sm={24} md={12} lg={8} xxl={8} className="" key={file.id}>
                <div className="docfile mr-8 mt-24 d-flex align-center">
                    <span className={`icon xl ${getFileIconClass(file.fileName)} mr-16`} />
                    <div className="docdetails c-pointer" onClick={() => docPreviewOpen(file)}>
                        {file.fileName !== null ? (
                            <EllipsisMiddle suffixCount={6}>
                                {file.fileName}
                            </EllipsisMiddle>
                        ) : (
                            <EllipsisMiddle suffixCount={6}>Name</EllipsisMiddle>
                        )}
                        <span className="fs-12 text-secondary">{bytesToSize(file.fileSize)}</span>
                    </div>
                </div>
            </Col>
        ));
    };

    const renderCaseNumbers = () => {
        return fiatData?.caseIds?.map(item => (
            <Link key={item.id} className="c-pointer" onClick={() => redirectCaseView(item)}>
                {item.caseNumber}<br />
            </Link>
        ));
    };

    const renderKpiItem = (label, value) => (
        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
            <div>
                <label className="kpi-label">{label}</label>
                <div className="kpi-val">{value || "-"}</div>
            </div>
        </Col>
    );

    const getAddressTypeLabel = (addressType) => {
        switch (addressType?.toLowerCase()) {
            case "myself":
                return "My Self";
            case "individuals":
                return "Individuals";
            case "ownbusiness":
            case "business":
            case "otherbusiness":
                return "My Company";
            default:
                return "-";
        }
    };
	const getRelationshipLabel = (relationship) => {
		return relationship === "Others" ? `Others (${fiatData?.others})` : relationship || "-";
	};

    const getFileIconClass = (fileName) => {
        if (fileName?.slice(-3) === "zip") return "file";
        if (fileName?.slice(-3) === "pdf" || fileName?.slice(-3) === "PDF") return "file";
        if (fileName?.slice(-3) !== "pdf" && fileName?.slice(-3) !== "PDF") return "image";
        return "";
    };

    const getAlert = () => (
        error !== undefined && error !== null && <Alert type="error" className="mb-16" showIcon message={error} />
    );

    const getAddressBookFiatView = () => (
        <Row gutter={8}>
            <Col xl={24} xxl={24} className="bank-view">
                {((fiatData?.walletCode !== "CHF" && fiatData?.transferType !== "internationalIBAN") &&
                    ((fiatData?.walletCode === "EUR" || fiatData?.walletCode === 'USD' || fiatData?.walletCode === "GBP") &&
                        (fiatData?.transferType === "swifttransfer" || fiatData?.transferType === "domestic" || fiatData?.transferType === "international"))) && (
                            <Row className="kpi-List" style={{ paddingTop: '10' }}>
                                {renderKpiItem("Whitelist Name", fiatData?.whiteListName)}
                                {renderKpiItem("Address Type", getAddressTypeLabel(fiatData?.addressType))}
                                {renderKpiItem("Currency", fiatData?.walletCode)}
                                {renderKpiItem("Bank Account Number/IBAN", fiatData?.accountNumber)}
                                {renderKpiItem("BIC/SWIFT/ABA Routing/Uk Sort Code", fiatData?.swiftRouteBICNumber || fiatData?.ukShortCode)}
                                {renderKpiItem("Bank Name", fiatData?.bankName)}
                                {renderKpiItem("Bank Address", fiatData?.bankaddress)}
                            </Row>
                        )}
                {((fiatData?.walletCode === "CHF" || fiatData?.transferType === "internationalIBAN") ||
                    !(fiatData?.transferType === "swifttransfer" || fiatData?.transferType === 'domestic' || fiatData?.transferType === "international")) && (
                            <Row className="kpi-List" style={{ paddingTop: '10' }}>
                                {renderKpiItem("Whitelist Name", fiatData?.whiteListName)}
                                {renderKpiItem("Address Type", getAddressTypeLabel(fiatData?.addressType))}
                                {renderKpiItem("Currency", fiatData?.walletCode)}
                                {renderKpiItem("IBAN", fiatData?.iban || fiatData?.accountNumber)}
                                {renderKpiItem("Bank Name", fiatData?.bankName)}
                                {renderKpiItem("BIC", fiatData?.bic)}
                                {renderKpiItem("Branch", fiatData?.bankBranch)}
                                {renderKpiItem("Country", fiatData?.country)}
                                {renderKpiItem("State", fiatData?.state)}
                                {renderKpiItem("City", fiatData?.city)}
                                {renderKpiItem("Zip", fiatData?.postCode)}
                            </Row>
                        )}
                <br />
                <Title className="page-title">RECIPIENT DETAILS</Title>
                <Row className="kpi-List">
                    {renderKpiItem("Recipient Full Name", fiatData?.name)}
                    {(fiatData?.addressType === "individuals" || fiatData?.addressType === "otherbusiness") && (
                        renderKpiItem("Relationship To Beneficiary", getRelationshipLabel(fiatData?.beneficiaryRelation)))}
                    {renderKpiItem("Recipient Address", fiatData?.recipientAddress)}
                    {renderKpiItem("Whitelisting Status", fiatData?.addressState)}
                    {fiatData?.addressState === "Rejected" && (
                        renderKpiItem("Reason For Rejection", fiatData?.rejectReason)
                    )}
                    {fiatData?.caseIds !== null && (
                        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={6}>
                            <div>
                                <label className="kpi-label d-block">Case Number</label>
                                <div className="fw-600 fs-14">
                                    {renderCaseNumbers()}
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>
                <Row>
                    {renderFiles()}
                </Row>
            </Col>
        </Row>
    );

    return (
        <>
            {loading && <Loader />}
            {getAlert()}
            <Title className="page-title"> RECIPIENT BANK DETAILS</Title>
            {fiatData && (
                <>
                    {getAddressBookFiatView()}
                    <p className="mt-16"><b> Admin Upload</b> <PlusCircleOutlined onClick={() => docOpen()} /></p>
                    <Row gutter={24} className="mb-24 pb-24 border-bottom">
                        <>
                            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                                {<DocumentsUploade
                                    pop_up_cancel={() => closeModal()}
                                    docID={fiatData?.id}
                                    previewModal={isModalVisible}
                                    attachmentUpdate={(attachments) =>
                                        updateAttachemnts(attachments)
                                    }
                                    docViewObj={docViewObj}
                                    screenTitle={"Address Book Fiat"}
                                    ModifiedBy={props.userConfig.userName}
                                />}
                            </Col>
                        </>
                    </Row>
                    <Notes screenId={props.match.params.payeeId} screenName={"AddressbookFiat"} createdNote={fiatData.notes}></Notes>
                    <Info Id={fiatData.id} Status={fiatData?.status} Createdby={fiatData?.userCreated} CreatedDate={fiatData?.createddate} ModifiedDate={fiatData?.modifiedDate} Modifiedby={fiatData?.modifiedBy} isShowInfo={false} screenName={"AddressBook Fiat"} />
                    {docPreviewModal &&
                        <DocumentPreview
                            previewModal={docPreviewModal}
                            handleCancle={docPreviewClose}
                            upLoadResponse={docPreviewDetails}
                        />
                    }
                </>
            )}
            <div className="text-right mt-24">
                <Button
                    type="primary"
                    className="primary-btn cancel-btn"
                    style={{ margin: "0 8px" }}
                    onClick={backToAddressBook}>
                    Cancel
                </Button>
            </div>
        </>
    );
};

const connectStateToProps = ({ userConfig }) => {
    return { userConfig: userConfig.userProfileInfo }
}

const connectDispatchToProps = dispatch => {
    return {
        setAction: (val) => {
            dispatch(setCurrentAction(val))
        },
        dispatch
    }
}

export default connect(connectStateToProps, connectDispatchToProps)(AddressFiatView);
