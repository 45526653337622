import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { Form, Row, Col, Input, Button, Select, DatePicker, message, Alert } from 'antd';
import { getNotices, saveNotices } from './api';
import { connect } from 'react-redux';
import moment from 'moment';
import Loader from '../loader.component';
import apiCalls from '../../api/apiCalls';
import { setCurrentAction } from '../../reducers/actionsReducer';
import { validateContentRule } from '../../utils/custom.validator';
import { publishShowActions } from '../grid.component/subscribir';
import Moment from "react-moment";
import config from "../../config";

const { TextArea } = Input;
const NoticesDetails = (props) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const useDivRef = React.useRef(null);
    const [noticeObject, setNoticeObject] = useState({});
    const [type] = useState(props.match.params.type)
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        publishShowActions(false);
        if (props.match.params.type) {
            props.setAction(null)
        } else if (props.match.params.id !== "00000000-0000-0000-0000-000000000000") {
            props.setAction('edit')
        } else {
            props.setAction('add')
        }
        if (props.match.params.id !== "00000000-0000-0000-0000-000000000000") {
            loadData();
        }
    }, []);//eslint-disable-line react-hooks/exhaustive-deps



    const loadData = async () => {
        setLoading(true)
        let response = await getNotices(props.match.params.id);
        if (response.ok) {
            bindEditableData(response.data)
            setLoading(false)
            setErrorMsg(null)
        } else {
            setLoading(false)
            setErrorMsg(apiCalls.isErrorDispaly(response))
        }
    }
    const bindEditableData = (obj) => {
        obj.fromDate = obj.fromDate ? moment(moment(new Date(obj.fromDate))) : "";
        obj.todate = obj.todate ? moment(moment(new Date(obj.todate))) : "";
        setNoticeObject({ ...obj });
        form.setFieldsValue({ ...obj });
    };
    const handleChange = (prop, val) => {
        let v = prop.currentTarget ? prop.currentTarget.value : val;
        noticeObject[prop] = val ? v : "";
        setNoticeObject({ ...noticeObject });
    }
    const saveNoticesInfo = async (values) => {
        setIsLoading(true);
        setErrorMsg(null);
        if (!loading) {
            let idx = props.match.params.id
            if (idx === "00000000-0000-0000-0000-000000000000") {
                values.status = "Active";
            } else {
                values.status = noticeObject.status;
            }
            values.fromDate = moment(values.fromDate).format('YYYY-MM-DD HH:mm:ss');
            values.todate = moment(values.todate).format('YYYY-MM-DD HH:mm:ss');
            values.createdBy = props.oidc?.user?.profile?.unique_name ? props.oidc?.user?.profile?.unique_name  : props.userConfig?.userName;
            values.modifiedBy = props.oidc?.user?.profile?.unique_name ? props.oidc?.user?.profile?.unique_name : props.userConfig?.userName;
            values.id = idx;
            values = Object.assign({}, values);
            values.createdBy = apiCalls.encryptValue(values.createdBy, props.userConfig?.sk);
            values.modifiedBy = apiCalls.encryptValue(values.modifiedBy, props.userConfig?.sk);
            values.noticeFor = apiCalls.encryptValue(values.noticeFor, props.userConfig?.sk);
            values.info = JSON.stringify(props.trackAuditLogData);
            values.customerId = props.userConfig?.id;
         
            setLoading(false)
            let response = await saveNotices(values, props.match.params.id);
            if (response.ok) {
                setIsLoading(false)
                message.destroy()
                message.success({
                    content: 'Notice details saved successfully',
                    className: 'custom-msg',
                    duration: 4
                });
                apiCalls.trackEvent({
                    Action: props.match.params.id === "00000000-0000-0000-0000-000000000000" ? "Notice added" : "Notice Updated",
                    Feature: "Notices",
                    Remarks: props.match.params.id === "00000000-0000-0000-0000-000000000000" ? "Notice added" : "Notice Updated",
                    FullFeatureName: "Notices",
                    userName: props.userConfig.userName,
                    id: props.userConfig.id
                });
                history.push('/notices');
                setErrorMsg(null);
            } else {
                setIsLoading(false)
                useDivRef.current.scrollIntoView();
                setErrorMsg(apiCalls.isErrorDispaly(response))
            }



        }

    }
   
    const backToNotices = () => {
        history.push({
            pathname: '/notices'
        });
    }
    return (<>
        <div ref={useDivRef}></div>
        {loading && <Loader />}
        {errorMsg !== undefined && errorMsg !== null && (
            <Alert className="w-100 mb-16" type="error" showIcon description={errorMsg} />
        )}
        <Form
            initialValues={noticeObject}
            form={form}
            name="advanced_search"
            className="ant-advanced-search-form"
            onFinish={saveNoticesInfo}
            autoComplete="off"
        >
            <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                    <Form.Item
                        name="noticeFor"
                        label="Type"
                        className="input-label"
                        rules={[
                            {
                                required: true,
                                message: 'Is required',
                            },
                        ]}
                    >
                        <Select
                            className={`cust-input ${type === "disabled" && "w-80 cust-input-disabled"} `}
                            onChange={(e, val) => handleChange(e, val)}
                            placeholder="Select Type"
                            disabled={type === 'disabled' ? true : false}
                        >
                            <option value="Business">Business</option>
                            <option value="Personal">Personal</option>

                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                    <Form.Item
                        name="fromDate"
                        label="From Date"
                        className="input-label"
                        rules={[
                            { required: true, message: "Is required" }

                        ]}
                    >
                        <DatePicker
                            className="cust-input"
                            placeholder="From Date"
                            format={config?.dateFormates?.dateFormate}
                            disabled={type === 'disabled' ? true : false}
                            onChange={(value) =>
                                handleChange("fromDate", value)
                            }
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                    <Form.Item
                        name="todate"
                        label="To Date"
                        className="input-label"
                        rules={[
                            { required: true, message: "Is required" }, {
                                type: "date", validator: async (rule, value, callback) => {
                                    if (value && noticeObject.todate) {
                                        if (new Date(value) <= new Date(noticeObject.fromDate) && new Date(noticeObject.todate) < new Date(noticeObject.fromDate)) {
                                            throw new Error("To date should be greater than or equal to from date")
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            }
                        ]} >
                        <DatePicker
                            className="cust-input"
                            placeholder="To Date"
                            format={config?.dateFormates?.dateFormate}
                            disabled={type === 'disabled' ? true : false}
                            onChange={(value) =>
                                handleChange("todate", value)
                            }
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={16} xxl={6}>
                    <Form.Item
                        name="title"
                        label="Title"
                        className="input-label"
                        rules={[
                            {
                                required: true,
                                message: 'Is required',
                                whitespace: true,
                            },
                            {
                                validator: validateContentRule
                            }
                        ]} >
                        <Input placeholder="Title" className="cust-input" maxLength={250} disabled={type === 'disabled' ? true : false} onChange={(e, val) => handleChange(e, val)} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                    <Form.Item
                        name="noticeContent"
                        label="Message"
                        className="input-label"
                        rules={[
                            {
                                required: true,
                                message: 'Is required',
                                whitespace: true,
                            },
                        ]} >
                        <TextArea rows={15} showCount={4000} maxLength={4000} placeholder="Message" className="text-area" disabled={type === 'disabled' ? true : false} onChange={(e) => handleChange(e)} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
					{noticeObject?.createdBy && (
						<Col xs={24} sm={24} md={12} lg={12} xxl={12}>
							<div className="record-history d-flex align-center ml-0">
							<div className="record-history-text">
								{noticeObject?.createdBy} created this on {"  "}
								<Moment format={config?.dateFormates?.dateTimeFormate}>
									{noticeObject?.createdDate
										? apiCalls.convertUTCToLocalTime(
												noticeObject?.createdDate
										  )
										: noticeObject?.createdDate}
								</Moment></div>
							</div>
						</Col>
					)}

					{noticeObject?.modifiedBy && (
						<Col xs={24} sm={24} md={12} lg={12} xxl={12}>
							<div className="record-history d-flex align-center ml-0">
								<div className="record-history-text">
									{noticeObject?.modifiedBy} modified this on {" "}
									<Moment format={config?.dateFormates?.dateTimeFormate} >
										{noticeObject?.modifiedDate
											? apiCalls.convertUTCToLocalTime(
													noticeObject?.modifiedDate
											  )
											:noticeObject?.modifiedDate}
									</Moment>
								</div>
							</div>
						</Col>
					)}
				</Row>
            <div className="text-right mt-24">
                {
                    type !== "disabled" && 
                    <Button type="primary" className="primary-btn" htmlType="submit" 
                        loading={isLoading}
                    >

                        Save
                    </Button>}
                <Button
                    type="primary"
                    className="primary-btn cancel-btn"
                    style={{ margin: '0 8px' }}
                    onClick={backToNotices}
                >
                    Cancel
                </Button>
            </div>
        </Form>
    </>)
}
const connectStateToProps = ({ oidc, userConfig }) => {
    return { oidc, userConfig: userConfig.userProfileInfo, trackAuditLogData: userConfig.trackAuditLogData }
}
const connectDispatchToProps = dispath => {
    return {
        setAction: (val) => {
            dispath(setCurrentAction(val))
        },
        dispath
    }
}
export default connect(connectStateToProps, connectDispatchToProps)(NoticesDetails);