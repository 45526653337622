import React, { useEffect, useState } from 'react';
import { Collapse,Typography } from 'antd';
import Alert from "antd/lib/alert";

const POACheck = (props) => {
  const { Title } = Typography;
  const [PoaDetails,setIsPoaDetails]=useState(props?.poaData?.checks)
  const [errorMsg,setErrorMsg]=useState(null)
  const { Panel } = Collapse;


  useEffect(()=>{
    setIsPoaDetails(props?.poaData?.checks)
  },[props?.poaData?.checks])
    
  return (
    <div className='poa-accordian'>
          {errorMsg && (
          <div className="mt20">
            <Alert
              closable
              type="error"
              description={errorMsg}
              onClose={() => setErrorMsg(null)}
              showIcon
            />
          </div>
        )}
          <Collapse  className='mt20 trans-collapse'>
          { <Panel header={<span className=''><span className='icon md downangle ant-collapse-arrow'></span><span>Proof of Address Check</span></span>} key="1">
        
            <div className='d-flex justify-content wrap-auto'>      
                 <div>
                 <Title level={5} className=''>Check Data</Title> 
                  <div className='poatable-feilds'>
                    <label className='kpi-label'>Country : </label>
                    {PoaDetails && <p className='kpi-val fw-700'>{PoaDetails[0]?.inputDoc?.country}</p> }
                  </div>
                  <div className='poatable-feilds'>
                    <label className='kpi-label'>Document Type : </label>
                    {PoaDetails && <p className='kpi-val fw-700'>Proof of address</p> }
                  </div>
                  <div className='poatable-feilds'>
                    <label className='kpi-label'>First Name : </label>
                    {PoaDetails && <p className='kpi-val fw-700'>{PoaDetails[0]?.inputDoc?.firstName}</p> }
                  </div>
                 </div>
                 <div>
                 <Title level={5} className=''>Document Organisation</Title>
                    <label className='kpi-label'>ID as Proof of Address : {PoaDetails && <span className={`status-dot ${PoaDetails?.[0]?.answer?.toLowerCase()}`}></span>} </label> 
                    <div className='poatable-feilds'>
                        <label className='kpi-label'>
                            Company Type :
                        </label>
                       {PoaDetails && <p className='kpi-val fw-700'>{PoaDetails?.[0]?.poaCheckInfo?.companyContact?.type}</p>}
                    </div>
                    </div>
                  </div>
                  <table className='poa-table'>
                    <thead>
                        <tr>
                            <th className='text-left'>Check</th>
                            <th className='text-left'>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr>
                    <td className='kpi-val fw-700'>Unexpired</td>
                    <td>{PoaDetails && <span className={`status-dot ${PoaDetails[0]?.poaCheckInfo?.unexpired?.toLowerCase()}`}></span>}</td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'>Acceptable Type</td>
                    <td>{PoaDetails &&<span className={`status-dot ${PoaDetails[0]?.poaCheckInfo?.acceptableType?.toLowerCase()}`}></span>}</td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'>Valid Address</td>
                    <td>{PoaDetails &&<span className={`status-dot ${PoaDetails[0]?.poaCheckInfo?.validAddress?.toLowerCase()}`}></span>}</td>
                    </tr>
                    <tr>
                    <td className='kpi-val fw-700'>Unique Type/Sub Type</td>
                    <td>{PoaDetails && <span className={`status-dot ${PoaDetails[0]?.poaCheckInfo?.answer?.toLowerCase()}`}></span>}</td>
                    </tr>                                  
                    </tbody>
                  </table>
                  
              </Panel>}
              
          </Collapse>
    </div>
  );
};

export default POACheck;
