

import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Alert } from 'antd';
import Loader from '../loader.component';
import { handleDepositViewData } from '../../reducers/depositReducer';
import { connect } from 'react-redux';
import { publishShowActions } from '../grid.component/subscribir';
import apiCalls from "../../api/apiCalls";
import Moment from 'react-moment';
import NumberFormat from "react-number-format";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Info from "../info/Info";
import { redirectToCaseView } from '../../utils/caseRediractions';
import { Link } from 'react-router-dom';
import DocumentsUploade from '../../utils/documents';
import { PlusCircleOutlined } from '@ant-design/icons';
import { getDepositeCrypto } from './api';
import Notes from '../../utils/notes';
import config from "../../config";
const { Title, Text } = Typography;

const DepositCryptoSummary = (props) => {
  const [loading, setIsLoading] = useState(false);
  const [docViewObj, setDocIsObj] = useState([]);
  const [isModalVissible, setisModalVissible] = useState(false)
  const [depositData, setDepositData] = useState([]);
  const [error, setError] = useState(null)
  useEffect(() => {
    getDepositData()
    publishShowActions(false);
  }, [])
  const getDepositData = async () => {
    setIsLoading(true)
    let res = await getDepositeCrypto(props.match.params.id)
    if (res.ok) {
      setIsLoading(false)
      setDepositData(res.data)
      setDocIsObj(res.data.adminDocuments)
    } else {
      setError(
        res.status === 401 ? res.data.message : res.data
      );
    }
  }
  const redirectCustomerView = () => {
    const items = depositData;
    const val = items.customerId;
    props.dispatch(
      setBreadcrumb({
        key: "/customers/details/" + val,
        val: items.customerName
      })
    );
    localStorage.setItem("MyURL", "/customers/details/" + val);
    window.open("/customers/details/" + val);
  };

  const redirectCaseView = (caseData) => {
    redirectToCaseView(caseData, props)
  };
  const docOpen = () => {
    setisModalVissible(true)
  }
  const CloseModal = () => {
    setisModalVissible(false)
  }
  const updateAttachemnts = (attachemnts) => {
    setDocIsObj(attachemnts)
  }
  const renderViewData = (titleData, value) => {
    return  <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
    <span className="icon md user" />
    <div>
      <label className="kpi-label">{titleData}</label>
      <div className="kpi-val">
        {value || "-"}
      </div>
    </div>
  </Col>
  }

  return (
    <>
      {loading && <Loader />}
      <Title className="page-title">Deposit Crypto Detail View</Title>
      {error !== undefined && error !== null && (
        <Alert type="error" className="mb-16" showIcon description={error} />
      )}
      <Row gutter={8}>
        <Col xl={24} xxl={24} className="bank-view">
          <Row className="kpi-List">
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md date" />
              <div>
                <label className="kpi-label">Submission Date</label>

                <div className="kpi-val"><Moment format={config?.dateFormates?.dateTimeFormate} >{depositData?.createdDate ? apiCalls.convertUTCToLocalTime(depositData?.createdDate) : depositData?.createdDate}</Moment>
                </div>
              </div>
            </Col>
           
            {renderViewData("Customer ID", depositData?.refrenceId)}

            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md user" />
              <div>

                <label className="kpi-label">{depositData?.isBusiness === true ? <><b>Business Name</b>/<>Personal Name</> </> : <><>Business Name</>/<b>Personal Name</b></>}</label>
                <br />
                <span className="kpi-val  c-pointer">
                  <Link onClick={() => redirectCustomerView()}>
                    {depositData?.customerName}
                  </Link>
                </span>
              </div>

            </Col>
           
            {renderViewData("User Name", depositData?.userName)}
            {renderViewData("Email", depositData?.customerEmail)}

            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md wallet" />
              <div>
                <label className="kpi-label">Coin</label>
                <div className="kpi-val">{`${depositData?.coinName}(${depositData?.walletCode})`}</div>
              </div>
            </Col>
           
            {renderViewData("Network", depositData?.network)}
            {renderViewData("Source Wallet Address", depositData?.sourceWalletAddress)}

            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md wallet" />
              <div>
                <label className="kpi-label">Wallet Address</label>

                <div className="kpi-val">
                  <CopyToClipboard>
                    <Text
                      copyable
                      className="fs-22 text-black-30 text-center custom-display"
                    >
                      {depositData?.fromWalletAddress}
                    </Text>
                  </CopyToClipboard>

                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md wallet" />
              <div>
                <label className="kpi-label">Deposited Coins</label>
                <div className="kpi-val">{depositData?.availableCoins ?
                  (<NumberFormat value={depositData?.availableCoins} decimalSeparator="." displayType={'text'} thousandSeparator={true} />)
                  : ("0")}</div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md routing" />
              <div>
                <label className="kpi-label">Hash</label>
                <div className="kpi-val">
                  {depositData?.hash && <Link onClick={() => window.open(`${depositData?.explorer}${depositData?.hash}`, '_blank')}>{depositData?.hash}</Link>}
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md commission" />
              <div>
                <label className="kpi-label">Commission</label>
                <div className=" kpi-val">
                  {depositData?.comissionvalue ?
                    (<NumberFormat value={depositData?.comissionvalue} decimalSeparator="." displayType={'text'} thousandSeparator={true} />)
                    : ("0")}
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md commission" />
              <div>
                <label className="kpi-label">Commission in Base</label>
                <div className=" kpi-val">
                  {depositData?.comissionvalueInBase ?
                    (<NumberFormat value={depositData?.comissionvalueInBase} decimalSeparator="." displayType={'text'} thousandSeparator={true} />)
                    : ("0")}
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md commission" />
              <div>
                <label className="kpi-label">Fees</label>
                <div className=" kpi-val">
                  {depositData?.effectiveFee ?
                    (<NumberFormat value={depositData?.effectiveFee} decimalSeparator="." displayType={'text'} thousandSeparator={true} decimalScale={8} />)
                    : ("0")}
                </div>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md commission" />
              <div>
                <label className="kpi-label">Tier Discount</label>
                <div className=" kpi-val">
                  {depositData?.tierDiscount ?
                    (<NumberFormat value={depositData?.tierDiscount} decimalSeparator="." displayType={'text'} thousandSeparator={true} decimalScale={8} />)
                    : ("0")}
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md commission" />
              <div>
                <label className="kpi-label">Sweep Source Commission</label>
                <div className=" kpi-val">
                  {depositData?.sweepSourceComissionValue ?
                    (<NumberFormat value={depositData?.sweepSourceComissionValue} decimalSeparator="." displayType={'text'} thousandSeparator={true} />)
                    : ("0")}</div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md commission" />
              <div>
                <label className="kpi-label">Sweep Source in Base</label>
                <div className=" kpi-val">
                  {depositData?.sweepSourceValueInBase ?
                    (<NumberFormat value={depositData?.sweepSourceValueInBase} decimalSeparator="." displayType={'text'} thousandSeparator={true} />)

                    : ("0")} </div>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md commission" />
              <div>
                <label className="kpi-label">Sweep Destination Commission</label>
                <div className=" kpi-val">
                  {depositData?.sweepDestinationcomissionValue ?
                    (<NumberFormat value={depositData?.sweepDestinationcomissionValue} decimalSeparator="." displayType={'text'} thousandSeparator={true} />)
                    : ("0")}</div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md commission" />
              <div>
                <label className="kpi-label">Sweep Destination in Base</label>
                <div className=" kpi-val">
                  {depositData?.sweepDestinationvalueInBase ?
                    (<NumberFormat value={depositData?.sweepDestinationvalueInBase} decimalSeparator="." displayType={'text'} thousandSeparator={true} />)
                    : ("0")}
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md cash" />
              <div>
                <label className="kpi-label">Profit</label>
                <div className=" kpi-val">
                  {depositData?.profit ?
                    (<NumberFormat value={depositData?.profit} decimalSeparator="." displayType={'text'} thousandSeparator={true} />)
                    : ("0")} </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md commission" />
              <div>
                <label className="kpi-label">Profit in Base</label>
                <div className=" kpi-val">
                  {depositData?.profitInBase ?
                    (<NumberFormat value={depositData?.profitInBase} decimalSeparator="." displayType={'text'} thousandSeparator={true} />)
                    : ("0")}</div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md file" />
              <div>
                <label className="kpi-label">AML Rating</label>
                <div className="kpi-val">{depositData?.amlRating ? depositData?.amlRating : "-"}</div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md file" />
              <div>
                <label className="kpi-label">AML Screening Status</label>
                <div className="kpi-val">{depositData?.amlScreeningStatus ? depositData?.amlScreeningStatus : "-"}</div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md wallet" />
              <div>
                <label className="kpi-label">Net Amount</label>
                <div className="kpi-val">{depositData?.netAmount && <NumberFormat value={depositData?.netAmount} decimalSeparator="." displayType={'text'} thousandSeparator={true} /> || "0"}</div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md status" />
              <div>
                <label className="kpi-label">State</label>
                <div className="kpi-val">{depositData?.status || "-"}</div>
              </div>
            </Col>

            {depositData.status === "Approved" && (
              <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                <span className="icon md Approved" />
                <div>
                  <label className="kpi-label">Approved By</label>
                  <div className="kpi-val">{depositData?.aprrovedBy ? depositData?.aprrovedBy : "-"}</div>
                </div>
              </Col>
            )}
            {depositData?.approvedDate && (
              <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                <span className="icon md date" />
                <div>
                  <label className="kpi-label">Approval Date</label>
                  <div className="kpi-val">{depositData?.approvedDate && <Moment format={config?.dateFormates?.dateTimeFormate} >{depositData?.approvedDate && apiCalls.convertUTCToLocalTime(depositData?.approvedDate)}</Moment> || "_"}
                  </div>
                </div>
              </Col>
            )}
            {depositData.status === "Cancelled" && (
              <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                <span className="icon md Approved" />
                <div>
                  <label className="kpi-label">Cancelled By</label>
                  <div className="kpi-val">{depositData?.modifiedby || "-"}</div>
                </div>
              </Col>
            )}
            {depositData.status === "Cancelled" && (
              <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                <span className="icon md date" />
                <div>
                  <label className="kpi-label">Cancelled Date</label>
                  <div className="kpi-val">{depositData?.modifiedDate || "-"}</div>
                </div>
              </Col>
            )}
            {depositData.status === "Rejected" && (
              <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                <span className="icon md commission" />
                <div>
                  <label className="kpi-label">Reason For Rejection</label>
                  <div className="kpi-val">
                    {(depositData?.rejectReason || "-")}
                  </div>
                </div>
              </Col>)}
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md file" />
              <div>
                <label className="kpi-label">Remarks</label>
                <div className="kpi-val">
                  {(depositData?.statusRemarks === " " && ("-" || depositData?.statusRemarks === null) && "-") || (depositData?.statusRemarks || "-")}

                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <span className="icon md commission" />
              <div>
                <label className="kpi-label">Wallet Score</label>
                <div className="kpi-val">
                  {(depositData?.walletScore || "-")}

                </div>
              </div>
            </Col>

            {depositData?.caseIds !== null && (
              <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={6}>
                <span className='icon md case' />
                <div>
                  <label className="kpi-label d-block">Case Number</label>
                  <div
                    className="fw-600 fs-14">
                    {depositData?.caseIds?.map(item => <Link onClick={() => redirectCaseView(item)} className=' c-pointer'>{item.caseNumber}<br /></Link>)}

                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <p className='mt-16'>
        <b> Admin Upload </b><PlusCircleOutlined onClick={() => docOpen()} /></p>

      <Row gutter={24} className="mb-24 pb-24 border-bottom">
        <>
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            {<DocumentsUploade
              pop_up_cancel={() => CloseModal()}
              docID={depositData?.id}
              previewModal={isModalVissible}
              attachmentUpdate={(attachemnts) =>
                updateAttachemnts(attachemnts)
              }
              docViewObj={docViewObj}
              screenTitle={"Receive Crypto"}
              ModifiedBy={props?.userConfig?.userName}
            />}
          </Col>
        </>
      </Row>
      <Notes screenId={props.match.params.id} screenName={"ReceiveCrypto"} createdNote={depositData.notes} />
      <Info Id={depositData.id} Status={depositData.status} Createdby={depositData?.userName} CreatedDate={depositData.createdDate} ModifiedDate={depositData?.modifiedDate} Modifiedby={depositData?.modifiedby} isShowInfo={false} screenName={"Receive"} />
    </>
  );
}

const connectStateToProps = ({ depositStore, userConfig, }) => {
  return { depositStore, userConfig: userConfig.userProfileInfo, }
}
const connectDispatchToProps = dispatch => {
  return {
    fetchDepositData: (cryptoId) => {
      dispatch(handleDepositViewData(cryptoId))
    },
    dispatch
  }
}
export default connect(connectStateToProps, connectDispatchToProps)(DepositCryptoSummary);