import { clientApi } from "../../api/index";
import { ApiControllers } from "../../api/config";

const getTransaction = (transId) => {
	return clientApi.get(ApiControllers.transaction + `${transId}`);
};
const userNameLuSearch = (CustomerUserName) => {
	return clientApi.get(
		ApiControllers.transaction + `Customers/${CustomerUserName}`
	);
};
const getTransactionSearch = () => {
	return clientApi.get(ApiControllers.transaction + "Types");
};
const getInfoVal = (id, type) => {
	return clientApi.get(
		ApiControllers.deposit + `GetScoreChainInfo/${id}/${type}`
	);
};

export { getTransaction, userNameLuSearch, getTransactionSearch, getInfoVal };
