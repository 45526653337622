import React, { Component } from "react";
import { Form, Tooltip, Select, Modal, Alert} from "antd";
import List from "../grid.component";
import apiCalls from "../../api/apiCalls";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { connect } from "react-redux";
import { updateCurrentScreen } from "../../reducers/permissionsReducer";
import CreateCase from "../../utils/createCase";
import {CaseRediractions} from '../../utils/caseRediractions';

const { Option } = Select;
class BuySell extends Component {
  constructor (props) {
    super(props);
    this.state = {
      gridUrl: process.env.REACT_APP_GRID_API + "BuySell",
      param: { type: "All" },
      type: "",
      selection: [],
      caseModal: false,
      selectedObj: {},
      warningMsg: null,

    };
    this.gridRef = React.createRef();
    this.add = this.add.bind(this);
  }
  gridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked: true,
      customCell: (props) => (
        <td className="text-center">
          <label className="text-center custom-checkbox">
            <input
              id={props.dataItem.id}
              name="check"
              type="checkbox"
              checked={this.state.selection.indexOf(props.dataItem.id) > -1}
              onChange={(e) => this.handleInputChange(props, e)}
              className="grid_check_box"
            />
            <span></span>
          </label>
        </td>
      )
    },
    {
			field: "transactionId",
			title: "Transaction ID",
			filter: true,
			locked: true,
			width: 210,
			customCell: (props) => (
					<div className="gridLink" onClick={() => this.update(props)}>
                      {props.dataItem?.txId }
					</div>			
			),
		},
    {
      field: "createdDate",
      title: "Submission Date",
      filter: true,
      isShowTime: true,
      filterType: "date",
      locked: false,
      width: 210,
    },
    { field: "referenceId", title: "Customer ID", filter: true, width: 150 },
    {
      field: "customerName",
      title: "Business Name/Personal Name",
      filter: true,
      width: 280,

    },
    { field: "userName", title: "User Name", filter: true, width: 200 },
    { field: "customerEmail", title: "Email", filter: true, width: 200 },
    {
      field: "caseids",
      title: "Case Number",
     
      width: 160,
      customCell: (props) => (
        <td>
          <div className="gridLink" >
            {props.dataItem.caseIds?.map(item=><div onClick={() => this.createCaseView(item)}>{item.caseNumber}</div>)}
          </div>
        </td>
      ),
    },
    { field: "type", title: "Transaction ", filter: true, width: 150 },
    { field: "fromWalletCode", title: "From Wallet", filter: true, width: 150 },
    {
      field: "fromValue",
      title: "From Value",
      filter: true,
      width: 150,
      dataType: "number",
      filterType: "numeric"
    },
    { field: "toWalletCode", title: "To Wallet", filter: true, width: 150 },
    {
      field: "tovalue",
      title: "To Value",
      filter: true,
      width: 150,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "beforeValue",
      title: "Before Value",
      filter: true,
      width: 180,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "afterValue",
      title: "After Value",
      filter: true,
      width: 180,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "comission",
      title: "Commission",
      filter: true,
      width: 180,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "comissionvalueinBase",
      title: "Commission in Base",
      filter: true,
      width: 210,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
			field: "effectiveFee",
			title: "Fees",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric",
		},
    {
			field: "tierDiscount",
			title: "Tier Discount",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric",
		},
    {
			field: "sbCredit",
			title: "SuisseBase Credit Used",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric",
		},
    {
      field: "pComissionValue",
      title: "Provider Commission",
      filter: true,
      width: 200,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "pcomissionvalueinBase",
      title: "Provider Commission in Base",
      filter: true,
      width: 260,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "profit",
      title: "Profit",
      filter: true,
      width: 180,
      dataType: "number",
      filterType: "numeric"
    },


    {
      field: "profitInBase",
      title: "Profit In Base",
      filter: true,
      width: 150,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "createdDate",
      title: "Approval Date",
      filter: true,
      isShowTime: true,
      filterType: "date",
      locked: false,
      width: 210,
    },
    {
      field: "provider",
      title: "Provider",
      filter: true,
      width: 160
    }
  ];
  componentDidMount() {
    this.props.dispatch(updateCurrentScreen("buysell"));
    this.BuySellTrack();
  }

  handleInputChange = (prop, e) => {
    const rowObj = prop.dataItem;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowObj.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowObj.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowObj,
      selection,
      warningMsg: null,
    });
  };
  createCaseView = (CaseData) => {
    let propsData=this.props
    CaseRediractions(CaseData,propsData)
  };
  createCase = () => {
    if (!this.state.check) {
      this.setState({ ...this.state, errorMessage: null, warningMsg: "Please select the one record", error: null, });
    }
    else {
      this.setState({ ...this.state, caseModal: true, check: false });
    }
  }
  hideCaseModal = () => {
    this.setState({ ...this.state, caseModal: false, selection: [] }, () => this.gridRef.current.refreshGrid());
  }
  BuySellTrack = () => {
    apiCalls.trackEvent({
      Type: "Admin",
      Action: "Buy/Sell grid page view",
      Username: this.props.userConfig?.userName,
      CustomerId: this.props.userConfig?.id,
      Feature: "Buy/Sell",
      Remarks: "Buy/Sell grid page view",
      Duration: 1,
      Url: window.location.href,
      FullFeatureName: "Buy/Sell"
    });
  };
  update = (e) => {
    const items = e.dataItem;
    const val = items.id;
    this.props.history.push("/buysell/" + val + "/buysellsummary");
    this.props.dispatch(
      setBreadcrumb({ key: "/buysell/" + val + "/buysellsummary", val: items.txId })
    );
    apiCalls.trackEvent({
      Action: "Buy/Sell details page view",
      Feature: "Buy/Sell",
      Remarks: "Buy/Sell details page view",
      FullFeatureName: "Buy/Sell",
      userName: this.props.userConfig.userName,
      id: this.props.userConfig.id
    });
  };
  add = () => {
    this.props.history.push({
      pathname: "/buysell/00000000-0000-0000-0000-000000000000/addbuysell",
      state: { pKey: "buysell", action: "add" }
    });
    this.props.dispatch(
      setBreadcrumb({
        key: "/buysell/00000000-0000-0000-0000-000000000000/addbuysell",
        val: "Add Trade"
      })

    );

    apiCalls.trackEvent({
      Action: "Buy/Sell add page view",
      Feature: "Buy/Sell",
      Remarks: "Buy/Sell add page view",
      FullFeatureName: "Buy/Sell",
      userName: this.props.userConfig.userName,
      id: this.props.userConfig.id
    });
  };
  onActionClick = (key) => {
    const actions = {
      add: "add",
      "Create Case": "createCase"
    };
    this[actions[key]]();
  };

  handleSearch = (val, props) => {
    let { type, param } = this.state;
    const searchVal = `${val ? val : ""}`;
    type = val.target ? val.target.value : searchVal;
    param[props] = type;
    this.setState({
      ...this.state,
      param
    }, () => this.gridRef.current.refreshGrid());

  };
  render() {
    const { caseModal, warningMsg } = this.state;
    return (
      <>
        {warningMsg !== undefined && warningMsg !== null && (
          <div style={{ width: '100%' }}>
            <Alert
              className="w-100 mb-16"
              type="warning"
              description={warningMsg}
              showIcon
            />
          </div>
        )}
        <div className="d-flex mb-16" style={{ justifyContent: "flex-end" }}>
          <Form
            initialValues={this.state.memberData}
            className="form form-bg mb-0 d-flex align-center "
            autoComplete="off"
          >
            <div>
              <Form.Item name="gridSearch" className="">
                <Select
                  showSearch
                  style={{ width: 250 }}
                  className="cust-input mb-0"
                  onChange={(e) => this.handleSearch(e, "type")}
                  defaultValue="All"
                >
                  <Option value="All">All</Option>
                  <Option value="buy">Buy</Option>
                  <Option value="sell">Sell</Option>
                </Select>
              </Form.Item>
            </div>
          </Form>
        </div>

        <List
          showActionBar={true}
          onActionClick={(key) => this.onActionClick(key)}
          pKey={"Trade"}
          ref={this.gridRef}
          showExcelExport={true}
          url={
            process.env.REACT_APP_GRID_API + `/Buysell/${this.state.param.type}`
          }
          columns={this.gridColumns}
        />

        <Modal
          title="Create Case"
          visible={caseModal}
          width={1000}
          style={{ top: 16 }}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.hideCaseModal} />
            </Tooltip>
          }
          destroyOnClose
          footer={null}
        >
          <CreateCase FromScreen='Trade'
            ScreenId={this.state.selectedObj.id}
            CreateCaseId={this.state.selectedCaseId}
            ScreenName="Trade" closeModal={this.hideCaseModal} />
        </Modal>
      </>
    );
  }
}

const connectStateToProps = ({ oidc, userConfig }) => {
  return { userConfig: userConfig.userProfileInfo, oidc };
};
export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(BuySell);
