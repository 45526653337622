
import { bankApi} from "../../api/index";
import { ApiControllers } from "../../api/config";
const getAccountLu = () => {
    return bankApi.get(ApiControllers.bank + "AccountTypes");
}
const getNameLu = (email, name, bankType) => {
    return bankApi.get(ApiControllers.bank + `${bankType}/${email}/${name}`)
}
const getBankCustomer = (bankType,userId) => {
    return bankApi.get(ApiControllers.bank + `${bankType}/${userId}`);
}
const evolveSaveStatusChange = (obj) => {
    return bankApi.put(ApiControllers.bank + `EvolveAccountStatusChange`,obj)
}
const pyrrosSaveStatusChange = (obj) => {
    return bankApi.put(ApiControllers.bank + `PyrrosAccountStatusChange`,obj)
}
const oPenPaydStatus = (obj) => {
    return bankApi.put(ApiControllers.bank + `OpenpaydAccountStatusChange`,obj)
}
const showBalance = (accId) =>{
   return bankApi.get(ApiControllers.bank + `accounts/${accId}`);
}
const saveOpenpydStatus = (obj) => {
    return bankApi.put(ApiControllers.bank + 'OpenpaydStatusUpdate',obj);
}
export {getAccountLu,getNameLu,getBankCustomer,evolveSaveStatusChange,showBalance,pyrrosSaveStatusChange,oPenPaydStatus,saveOpenpydStatus}