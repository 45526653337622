import React,{useEffect} from "react";
import {useHistory} from "react-router-dom"


const CallbackPage = (props) => {
    const history = useHistory()
    useEffect(() => {
        handleRedirect();
    }, []);
    const handleRedirect = (user) => {
        history.push("/dashboard");
        localStorage.removeItem("__url");
     }

    return (
        <div className="loader">Loading .....</div>
    );

}
export default CallbackPage;