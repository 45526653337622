import React, { Component } from "react";
import {
  Form,
  Select,
  Radio,
  Tooltip,
  message,
  Modal,
  Button,
  Row,
  Col,
  Input,
  Alert,
  Drawer,
  Typography,
  Empty,
  Spin,
  DatePicker
} from "antd";
import List from "../grid.component";
import { ApproveorCancelCrypto, getStateChangeInfo,stateFiatSave,getStates,Sumsub } from "./api";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { connect } from "react-redux";
import Loader from "../loader.component";
import apiCalls from "../../api/apiCalls";
import Moment from "react-moment";
import moment from "moment";
import NumberFormat from "react-number-format";
import {
  getPermissions,
  updateCurrentScreen,updateTabAccessDenied
} from "../../reducers/permissionsReducer";
import { setCurrentAction } from "../../reducers/actionsReducer";
import { validateContentRule } from "../../utils/custom.validator"; 
import AccessDenied from "../accessDined.component/accessDenied";
import CreateCase from "../../utils/createCase";
import {CaseRediractions} from '../../utils/caseRediractions';
import LockedComponent from "../../utils/lockunlock";
import config from "../../config";

const { Title, Text ,Paragraph} = Typography;
const { TextArea } = Input;

class Withdraw extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      isBtnApprove: false,
      statuserrorMsg: null,
      werningMsg: null,
      check: false,
      withdraw: true,
      selection: [],
      selectedObj: {},
      caseModal: false,
      gridUrl: process.env.REACT_APP_GRID_API + "Withdraw/Fiat",
      gridUrlC: process.env.REACT_APP_GRID_API + "Withdraw/Crypto",
      errorMessage: "",
      type: "fiat",
      stateLoading: true,
      isSubmitting: false,
      error: null,
      obj: {
        id: "",
        screenName: "Withdrawal",
        state: "",
        statusRemarks: "",
        pCommisionValue: null,
        sweepSourceCommisionValue: null,
        sweepDestinationCommissionValue: null,
      },
      stateChange: {},
      alert: false,
      stateHistory: false,
      stateHistoryData: [],
      btnDisabled: false,
      selectedTab:1,
			istabAcessdenied:false,
      selectedObjs:[],
      statesLst:[],
      isModal:false,
			getLockandUnLockDetails:[],
			isCheckLock:false,
    };
    this.gridFiatRef = React.createRef();
    this.gridCryptoRef = React.createRef();
    this.formref = React.createRef();
  }
  handleCheck=(props)=>{
    return <label className="text-center custom-checkbox">
    <input
      id={props.dataItem.id}
      name="check"
      type="checkbox"
      checked={this.state.selection.indexOf(props.dataItem.id) > -1}
      onChange={(e) => this.handleCheckbox(props, e)}
    />
    <span></span>{" "}
  </label>
  }
  handleTransactionID=(prosp)=>{
    return <div className="gridLink" onClick={() => this.gridView(prosp)}>
            {prosp.dataItem?.transactionId }
           </div>
  }
  handleCaseViewdata=(caseData)=>{
    return <td>
    <div className="gridLink" >
      {caseData.dataItem.caseIds?.map(item=><div onClick={() => this.createCaseView(item)}>{item.caseNumber}</div>)}
    </div>
  </td>
  }
  gridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked: true,
      customCell:this.handleCheck
    },
    {
			field: "transactionId",
			title: "Transaction ID",
			filter: true,
			locked: true,
			width: 210,
			customCell: this.handleTransactionID,
		},

    {field: "createdDate", title: "Submission Date", filterType:"date", isShowTime:true, filter: true, width: 210 },
    {field: "approvedDate", title: "Approval Date", filterType:"date", isShowTime:true, filter: true, width: 210 },
    { field: "status", title: "Status", filter: true, width: 150 },
    {
      field: "customerName",
      title: "Business Name/Personal Name",
      filter: true,
      width: 250,
      customCell: (props) => (
				<td className="whitelist-name">
				<div className="d-flex">
				<div className="white-address address-width">	{props.dataItem.customerName}</div>
					{props?.dataItem?.bankAccountType!==null  && (
						<div className="file-labels ml-8 fs-12 address-label address-label-width">
							{props?.dataItem?.bankAccountType}
						</div>
					)}</div>
				</td>
			),
    },
    { field: "walletCode", title: "Wallet Code", filter: true, width: 130 },
    { field: "requestedAmount", title: "Requested Amount", filter: true, width: 200, dataType: "number", filterType: "numeric" },
    { field: "netAmount", title: "Net Amount", filter: true, width: 160, dataType: "number", filterType: "numeric" },
    { field: "externalAccountName", title: "Sender's Bank Name", filter: true, width: 200 },
    { field: "addressType", title: "Address Type", filter: true, width: 150 },
    { field: "recepientName", title: "Recipient Full Name", filter: true, width: 200 },
    {
      field: "accountNumber",
      title: "Bank Account Number/IBAN",
      width: 250,
      filter: true
    },
    { field: "bankName", title: "Bank Name", filter: true, width: 160 },
    {
			field: "effectiveFee",
			title: "Fees",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric",
		},
    {
			field: "tierDiscount",
			title: "Tier Discount",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric",
		},
    { field: "comissionvalue", title: "Commission", filter: true, width: 180, dataType: "number", filterType: "numeric" },
   
    { field: "comissionvalueInBase", title: "Commission in Base", filter: true, width: 220, dataType: "number", filterType: "numeric" },
    {
      field: "profit",
      title: "Profit",
      filter: true,
      width: 150,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "profitInBase",
      title: "Profit in Base",
      filter: true,
      width: 180,
      dataType: "number",
      filterType: "numeric"
    },
    { field: "pcomissionvalue", title: "Provider Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "pcomissionvalueInBase", title: "Provider Commission in Base", filter: true, width: 260, dataType: "number", filterType: "numeric" },
    { field: "sweepSourceComissionValue", title: "Sweep Source Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "sweepSourceValueInBase", title: "Sweep Source in Base", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "sweepDestinationcomissionValue", title: "Sweep Destination Commission", filter: true, width: 260, dataType: "number", filterType: "numeric" },
    { field: "sweepDestinationvalueInBase", title: "Sweep Destination in Base", filter: true, width: 270, dataType: "number", filterType: "numeric" },
    {
      field: "caseids",
      title: "Case Number",     
      width: 160,
      customCell: this.handleCaseViewdata,
    },
    { field: "addressBookStatus", title: "Recipient Status", filter: true, width: 200 },
    { field: "username", title: "User Name", filter: true, width: 160 },
    { field: "email", title: "Email", filter: true, width: 200 },
    { field: "refrenceId", title: "Customer ID", filter: true, width: 150 },
    { field: "rejectReason", title: "Reason For Rejection", filter: true, width: 240 },
    { field: "lockUnlock", title: "Lock/Unlock", filter: true, width: 160 ,customCell: (props) => (
      <td>
        <div className="d-flex align-center justify-content">
        {props.dataItem?.lockUnlock}
        {props.dataItem?.lockUnlockHistory && <Tooltip title="More Info">
        <span
                className="icon md info c-pointer ml-8"
                onClick={() => this.showMoreDetails(props)}
              />
        </Tooltip>}
        </div>
      </td>)},
  ];
  withdrawColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked: true,
      customCell:this.handleCheck
    },
    {
			field: "transactionId",
			title: "Transaction ID",
			filter: true,
			locked: true,
			width: 210,
			customCell: this.handleTransactionID,
		},
    {field: "createdDate", title: "Submission Date", filterType:"date", isShowTime:true, filter: true, width: 210 },
    {field: "approvedDate", title: "Approval Date", filterType:"date", isShowTime:true, filter: true, width: 210 },
    {
      field: "status",
      title: "Status",
      width: 160,
      filter: true,
    },
    {
      field: "customerName",
      title: "Business Name/Personal Name",
      filter: true,
      width: 280,
    },
    { field: "walletName", title: "Coin", filter: true, width: 130 },
    { field: "network", title: "Network", filter: true, width: 160 },
    { field: "walletAddress", title: " Wallet Address", filter: true, width: 170,customCell: (props) => (
      <td>
        {props.dataItem.walletAddress != null &&
          props.dataItem.walletAddress !== "" ? (
          <Paragraph
            className="kpi-val mb-0"
            copyable={{ text: props.dataItem.walletAddress }}>
            {props.dataItem.walletAddress.slice(0, 4) +
              "..." +
              props.dataItem.walletAddress.slice(-4)}
          </Paragraph>
        ) : (
          ""
        )}
      </td>
    )
    },
    {
      field: "addressBookStatus",
      title: "Address Book State",
      width: 200,
      filter: true,
    },
    { field: "requestedAmount", title: "Requested Crypto", filter: true, width: 180, dataType: "number", filterType: "numeric" },
    { field: "receivedAmount", title: "Net Amount", filter: true, width: 180, dataType: "number", filterType: "numeric" },
    { field: "comission", title: "Commission", filter: true, width: 180, dataType: "number", filterType: "numeric" },
    {
      field: "profit",
      title: "Profit",
      filter: true,
      width: 150,
      dataType: "number",
      filterType: "numeric"
    },
    { field: "comissionValueInBase", title: "Commission in Base", filter: true, width: 220, dataType: "number", filterType: "numeric" },

    {
      field: "profitInBase",
      title: "Profit in Base",
      filter: true,
      width: 180,
      dataType: "number",
      filterType: "numeric"
    },
    {
			field: "effectiveFee",
			title: "Fees",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric",
		},
    {
			field: "tierDiscount",
			title: "Tier Discount",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric",
		},
    { field: "pcomissionvalue", title: "Provider Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "pcomissionvalueInBase", title: "Provider Commission in Base", filter: true, width: 260, dataType: "number", filterType: "numeric" },
    { field: "sweepSourceComissionValue", title: "Sweep Source Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "sweepSourceValueInBase", title: "Sweep Source in Base", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "sweepDestination", title: "Sweep Destination Commission", filter: true, width: 260, dataType: "number", filterType: "numeric" },
    { field: "sweepDestinationInBase", title: "Sweep Destination in Base", filter: true, width: 270, dataType: "number", filterType: "numeric" },
    { field: "username", title: "User Name", filter: true, width: 160 },
    { field: "email", title: "Email", filter: true, width: 200 },
    { field: "refrenceId", title: "Customer ID", filter: true, width: 150 },
    {
      field: "caseids",
      title: "Case Number",
      width: 160,
      customCell: this.handleCaseViewdata,
    },
    {
      field: "rejectReason",
      title: "Reason For Rejection",
      width: 260,
      filter: true,
    },
    { field: "lockUnlock", title: "Lock/Unlock", filter: true, width: 160 ,customCell: (props) => (
			<td>
			  <div className="d-flex align-center justify-content">
				{props.dataItem?.lockUnlock}
				{props.dataItem?.lockUnlockHistory && <Tooltip title="More Info">
				<span
                className="icon md info c-pointer ml-8"
                onClick={() => this.showMoreDetails(props)}
              />
				</Tooltip>}
			  </div>
			</td>)},
  ];
  componentDidMount() {
    this.props.dispatch(
			updateTabAccessDenied(false)

		);
    setTimeout(() => {
      this.props.dispatch(updateCurrentScreen("withdrawfiat"));
    }, 250);
    if(this.props.permissions?.currentScreenTabId){
      if(!this.props.permissions[this.props.permissions?.currentScreenTabId]){
				this.props.dispatch(
					getPermissions({
						customerId: this.props.userConfig.id,
						perKey: this.props.permissions?.currentScreenTabId,
					})
				);
			}
			const _menutabItem = this.props.permissions.menuItems.filter((item) => item.id === this.props.permissions?.currentScreenTabId)[0];
      this.setState({ ...this.state, selectedTab: _menutabItem?.label === 'Withdraw Fiat' ? 1 : 2,withdraw: _menutabItem?.label === 'Withdraw Fiat' })
		}
    apiCalls.trackEvent({
      Type: "Admin",
      Action: "Withdraw Fiat grid page view",
      Username: this.props.userProfileInfo?.userName,
      customerId: this.props.userProfileInfo?.id,
      Feature: "Withdraw",
      Remarks: "Withdraw Fiat grid page view",
      Duration: 1,
      Url: window.location.href,
      FullFeatureName: "Withdraw"
    });
  }
  createCaseView = (CaseData) => {
    let propsData=this.props
    this.props.dispatch(updateCurrentScreen("withdrawfiat"));
    CaseRediractions(CaseData,propsData)
  };
 
  gridRefresh=()=>{
		if(this.state.selectedObj.type==="Fiat"){
			this.gridFiatRef.current.refreshGrid()
		}else{
			this.gridCryptoRef.current.refreshGrid()
		}
	}
  redirectTransactionView = (e) => {
    const items = e.dataItem;
    const val = items?.walletCode;
    const batchID = items?.batchId;
    this.props.history.push(`/batchPayments/${items?.batchTransactionId}/${val}`);
    this.props.dispatch(
     setBreadcrumb({
       key: `/batchPayments/${items?.batchTransactionId}`,
       val: `${batchID + " / " + val}`
     })
   );
 };
  hideCaseModal = () => {
    this.setState({ ...this.state, caseModal: false, selection:[],selectedObjs:[]});
    this.gridRefresh()
    }
  createCase = () => {
    const { selection } = this.state;
      if(selection.length == 0){
      this.setState({ ...this.state,errorMessage:null,selection:[],selectedObjs:[],werningMsg:"Please select the one record",error: null, });
    }
     else if(selection.length >1){
      this.setState({ ...this.state,errorMessage:null,selection:[],selectedObjs:[],werningMsg:"Please select only one record",error: null });
    }
     else {
    this.setState({ ...this.state, caseModal: true,check: false });
    }
  }
  showStateHistory = (e) => {
    this.setState({
      ...this.state,
      stateHistory: true
    });
    this.fetchStateChangeInfo(e.dataItem.id);
  };
  hideStateHistory = () => {
    this.setState({
      ...this.state,
      stateHistory: false,
      stateHistoryData: []
    });
  };
  fetchStateChangeInfo = async (id) => {
    this.setState({
      stateLoading: true
    });
    let res = await getStateChangeInfo(id);
    if (res.ok) {
      this.setState({

        ...this.state,
        stateHistoryData: res.data,
        stateLoading: false
      });
    }
  };
  handleWithdraw = (e) => {
      this.setState({
        ...this.state,
        withdraw: e.target.value === 1?true:false,
        selection: [],
        selectedObj: {},
        check: false,
        werningMsg: null,
        selectedTab:e.target.value,
        istabAcessdenied:false,type:e.target.value === 1?'Fiat':'Crypto'
      });
    this.props.dispatch(
      updateCurrentScreen(
        e.target.value === 1 ? "withdrawfiat" : "withdrawcrypto"
      )
    );
    this.props.dispatch(
			updateTabAccessDenied(false)

		);
    const _menutabItem = this.props.permissions.menuItems.filter((item) => item.label === (e.target.value === 1 ? "Withdraw Fiat" : "Withdraw Crypto"))[0];
		this.props.dispatch(
			getPermissions({
				customerId: this.props.userConfig.id,
				perKey: _menutabItem.id,
			})
		);

    if (this.state.withdraw) {
      apiCalls.trackEvent({
        Type: "Admin",
        Action: "Withdraw Crypto grid page view",
        Username: this.props.userProfileInfo?.userName,
        customerId: this.props.userProfileInfo?.id,
        Feature: "Withdraw",
        Remarks: "Withdraw Crypto grid page view",
        Duration: 1,
        Url: window.location.href,
        FullFeatureName: "Withdraw"
      });
    } else {
      apiCalls.trackEvent({
        Type: "Admin",
        Action: "Withdraw Fiat  grid page view",
        Username: this.props.userProfileInfo?.userName,
        customerId: this.props.userProfileInfo?.id,
        Feature: "Withdraw Fiat",
        Remarks: "Withdraw Fiat grid page view",
        Duration: 1,
        Url: window.location.href,
        FullFeatureName: "Withdraw"
      });
    }
  };
   validateNumber = (_, validNumberValue) => {
    if (validNumberValue === ".") {
        return Promise.reject("Please enter valid content");
    }
    return Promise.resolve();
}
  gridView = (e) => {
    const items = e.dataItem; 
    const val = items.id;
    this.props.dispatch(
      setBreadcrumb({
        key: "/withdraw/" + val,
        val: ((items.transactionId) + ((items.transactionId || items.transactionId === null) ? ("  " + "/") : " ") + " " + items.status)
      })
    );
   if(items.type==="Fiat"){
    this.props.history.push(`/withdraw/${val}/fiat`);
   }else{
    this.props.history.push(`/withdraw/${val}/crypto`);
   }
    

  };
  handleCheckbox = (prop, e) => {
    this.formref.current?.resetFields();
    const rowObj = prop.dataItem;
		const value =
			e.target.type === "checkbox" ? e.target.checked : e.target.value;
		const name = e.target.name;
		let { selection, selectedObjs } = this.state;
		let idx = selection.indexOf(rowObj.id);
     
		if (idx > -1) {
			selection.splice(idx, 1);
			selectedObjs.splice(idx, 1);

		} else {
        selection.push(rowObj.id);
        selectedObjs.push(rowObj);
      }
		this.setState({
			...this.state,
			[name]: value,
			selectedObj: selectedObjs[0] || rowObj,
			stateChange: rowObj,
			selectedObjs,
			selection,
			werningMsg: null
		});
    this.formref.current?.resetFields();
  };
  statusUpdate = () => {
    const { selection, selectedTab } = this.state;
    this.setState({...this.state, stateLoading: true }); // Setting stateLoading to true initially

    if (selection.length === 0) {
        this.handleEmptySelection();
    } else if (selectedTab === 2 && selection.length > 1) {
        this.handleMultipleSelection();
    } else {
        this.handleValidSelection();
    }
};

handleEmptySelection = () => {
    this.setState({...this.state, selection: [], selectedObjs: [], werningMsg: "Please select the one record" }, () => {
        this.useDivRef?.current?.scrollIntoView();
    });
};

handleMultipleSelection = () => {
    this.setState({...this.state, errorMessage: null, selection: [], selectedObjs: [], werningMsg: "Please select only one record", error: null });
};

handleValidSelection = () => {
    const { selectedTab, selectedObjs } = this.state;
    for (const selectedObj of selectedObjs) {
        if (selectedObj.status !== selectedObjs[0].status) {
            this.setState({...this.state, selection: [], selectedObjs: [], werningMsg: "Please select same state records" }, () => {
                this.useDivRef?.current.scrollIntoView();
            });
            return;
        } else if (selectedObj.lockUnlock === "Locked") {
            this.setState({...this.state, werningMsg: 'This transaction was locked, Please unlock before state change', selection: [], selectedObjs: [], selectedObj: {} }, () => {
                this.useDivRef?.current.scrollIntoView();
            });
            return;
        }
    }
    this.loadStates(selectedTab);
};

loadStates = (selectedTab) => {
    const tabName = selectedTab === 1 ? 'Send Fiat' : 'Send Crypto';
    const { selectedObjs } = this.state;
    getStates(tabName, selectedObjs[0].status, this.state?.selectedObj?.previousState).then(response => {
        if (response.ok) {
            this.setState({...this.state, statesLst: response.data, stateLoading: false, statuserrorMsg: null, btnDisabled: false });
        } else {
            this.setState({...this.state, statuserrorMsg: apiCalls.isErrorDispaly(response) });
        }
    });
    this.openModal(selectedObjs[0]);
};

openModal = (selectedObj) => {
    this.setState({
        modal: true,
        werningMsg: null,
        selectedObj: selectedObj,
        stateChange: selectedObj,
        error: null
    }, () => {
        this.setFormFields();
    });
};

setFormFields = () => {
    const { selectedObj } = this.state;
    this.formref.current.setFieldsValue({
        status: selectedObj.status,
        type: selectedObj.type,
        statusRemarks: selectedObj.statusRemarks,
        isCheckBox: selectedObj.isCheckBox,
        pCommisionValue: selectedObj.pcomissionvalue,
        sweepDestinationCommissionValue: selectedObj.sweepDestinationcomissionValue,
        rejectReason: selectedObj.rejectReason,
        sweepSourceCommisionValue: selectedObj.sweepSourceComissionValue,
        network: selectedObj.network,
        hash: selectedObj.hash,
        isSubmitting: selectedObj.status !== "Submitted",
        previousState: selectedObj.previousState
    });
    setTimeout(() => {
        this.formref.current.setFieldsValue({
            status: selectedObj.status,
            statusRemarks: selectedObj.statusRemarks,
            isCheckBox: selectedObj.isCheckBox,
            transactionId: selectedObj.transactionId,
            pCommisionValue: selectedObj.pcomissionvalue,
            sweepDestinationCommissionValue: selectedObj.sweepDestinationcomissionValue,
            rejectReason: selectedObj.rejectReason,
            sweepSourceCommisionValue: selectedObj.sweepSourceComissionValue,
            previousState: selectedObj.previousState,
            BankValueDate: selectedObj.bankValueDate && moment(new Date(selectedObj.bankValueDate), "DD/MM/YYYY")
        });
    }, 1000);
};


  success = (status) => {
    switch (status) {
      case "Approved":
        status = "Approved";
        break;
      case "Approve":
        status = "Approved";
        break;
      case "Rejected":
        status = "Rejected";
        break;
      case "Reject":
        status = "Rejected";
        break;
        case "Pending":
        status = "Pending";
        break;
      case "Failed":
        status = "Failed";
        break;
      case "Queued":
        status = "Queued";
        break;
      case "Completed":
        status = "Completed";
        break;
        default:
          return null;
    }
    message.success({
      content: "Record " + status + " successfully",
      className: "custom-msg",
      duration: 1
    });
  };
  error = () => {
    message.error("Please select the one record");
  };
  approveSave = async (values) => {
    this.setState({ ...this.state,isLoading: true, isBtnApprove: true, statuserrorMsg: null });

    const statusObj = this.constructStatusObject(values);

    if (statusObj.type === "Crypto") {
        await this.handleCryptoApproval(statusObj,values);
    } else {
        await this.handleFiatApproval(statusObj);
    }

    this.trackEvent();
};

constructStatusObject = (values) => {
    const { selectedTab, selectedObj, selection } = this.state;
    return {
        ids: selection,
        id: selectedObj.id,
        AdminId: this.props.userConfig.id,
        type: selectedTab === 1 ? 'Fiat' : 'Crypto',
        status: values.status || selectedObj.status,
        statusRemarks: values.statusRemarks,
        isCheckBox: values.status !== "Pending" ? selectedObj.isCheckBox : false,
        hash: values?.hash,
        ModifiedBy: this.props.userConfig.userName,
        pCommisionValue: values.pCommisionValue,
        sweepDestinationCommissionValue: values.sweepDestinationCommissionValue,
        rejectReason: values.rejectReason,
        sweepSourceCommisionValue: values.sweepSourceCommisionValue,
        currency: selectedObj.currency || selectedObj.walletCode,
        BankValueDate: values?.BankValueDate,
        info: JSON.stringify(this.props.trackAuditLogData)
    };
};

handleCryptoApproval = async (statusObj,values) => {
    const { selection } = this.state;
    if (this.state.selectedObj.isCheckBox || values.status === "Pending" || values.status === "Swept") {
        try {
            const response = await ApproveorCancelCrypto(statusObj);
            if (response.ok) {
                this.handleApprovalSuccess(selection.length > 1 ? "Records" : "Record");
            } else {
                this.handleApprovalFailure(response);
            }
        } catch (error) {
            this.handleApprovalFailure(error);
        }
    }
};

handleFiatApproval = async (statusObj) => {
    try {
        const response = await stateFiatSave(statusObj);
        if (response.ok) {
            this.handleApprovalSuccess(this.state.selection.length > 1 ? "Records" : "Record", statusObj.status);
        } else {
            this.handleApprovalFailure(response);
        }
    } catch (error) {
        this.handleApprovalFailure(error);
    }
};

handleApprovalSuccess = (recordType, status) => {
    this.props.dispatch(setCurrentAction(null));
    message.success({
        content: `${this.state.selection?.length > 1 ? recordType : "Record"} ${status ? status : "status"}  successfully`,
        className: "custom-msg",
        duration: 3
    });
    this.setState({
      ...this.state,
        modal: false,
        selection: [],
        selectedObjs: [],
        check: true,
        isLoading: false,
        stateLoading: true,
        isBtnApprove: false,
        statuserrorMsg: null
    });
    setTimeout(() => {
        this.isLoading = false;
    }, 2000);
    this.refreshGrid();
};

handleApprovalFailure = (error) => {
    this.setState({...this.state,
        isLoading: false,
        statuserrorMsg: apiCalls.isErrorDispaly(error),
        isBtnApprove: false
    });
    setTimeout(() => {
        this.isLoading = false;
    }, 2000);
};

trackEvent = () => {
    const { selectedObj } = this.state;
    const { userName, id } = this.props.userConfig;
    apiCalls.trackEvent({
        Action: `${selectedObj.username} Withdraw ${(selectedObj.status === "Approved" || selectedObj.status === "Approve") ? "Approved" : "Rejected"}`,
        Feature: "Withdraw",
        Remarks: `${selectedObj.username} Withdraw ${selectedObj.type} ${(selectedObj.status === "Approved" || selectedObj.status === "Approve") ? "Approved" : "Rejected"}`,
        FullFeatureName: "Withdraw",
        userName: userName,
        id: id
    });
};

refreshGrid = () => {
    if (this.state.withdraw) {
        this.gridFiatRef.current?.refreshGrid();
    } else {
        this.gridCryptoRef.current?.refreshGrid();
    }
};


  handleImpNote = (e) => {
    if (e.currentTarget.checked) {
      this.setState({ ...this.state, selectedObj: { ...this.state.selectedObj, isCheckBox: true } });
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    } else {
      this.setState({ ...this.state, selectedObj: { ...this.state.selectedObj, isCheckBox: false } });
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    }
  }
  stateChange = (val, prop) => {
    if(val ==="Reject" || val ==="Approve"){
      this.formref.current.setFieldsValue({statusRemarks:"",rejectReason:"",BankValueDate:""})
    }
    this.setState({ ...this.state, btnDisabled:true,stateChange:{ status: val }});
    this.formref.current.setFieldsValue({statusRemarks:"",rejectReason:"",BankValueDate:""})
  };
  handleCancel = (e) => {
    this.props.dispatch(setCurrentAction(null));
    this.setState({
      ...this.state,
      modal: false,
      selection: [],
      selectedObjs:[],
      check: false,
      error: null,
      statuserrorMsg: null,
      isBtnApprove:false
    });
    this.formref.current?.resetFields();

    if (this.state.withdraw) {
      this.gridFiatRef.current?.refreshGrid();
    } else {
      this.gridCryptoRef.current?.refreshGrid();
    }
  };
  onActionClick = (key) => {
    const action = {
      "Create Case": "createCase",
      "State Change": "statusUpdate",
      "Lock/Unlock":"CheckLockUnLock",
      "Sumsub":"SumSub",
    };
    this[action[key]]();
  };
  showMoreDetails=(props)=>{
		this.setState({...this.state,isModal:true,getLockandUnLockDetails:props.dataItem.lockUnlockHistory});
	}
  CheckLockUnLock=()=>{
    const {selection} =this.state;
    if(selection.length == 0){
      this.setState({ ...this.state,errorMessage:null,selection:[],selectedObjs:[],werningMsg:"Please select the one record",error: null,selectedObj:{} });
    }
     else if(selection.length >1){
      this.setState({ ...this.state,errorMessage:null,selection:[],selectedObjs:[],werningMsg:"Please select only one record",error: null,selectedObj:{} });
    }else{
      this.setState({...this.state,isCheckLock:true})
    }

	}
	LockedPopClose=()=>{
		this.setState({...this.state,isModal:false,isCheckLock:false,selection:[],selectedObj:{},check:false,selectedObjs:[]})
	}
  SumSub=async()=>{
    const {selection} =this.state;
    let obj = {
      "Ids": this.state?.selection,
      "ScreenName": this.state.selectedTab===1?'Send Fiat':'Send Crypto' ,
    }
    if(selection.length == 0){
      this.setState({ ...this.state,errorMessage:null,selection:[],selectedObjs:[],werningMsg:"Please select the one record",error: null, });
    }else{
  let res = await Sumsub(obj,this.state.selectedTab===1?'Fiat':'Crypto');
   if(res.ok){
    window.open(res.data,'_self')
    this.setState({...this.state,isCheckLock:false,selection:[],selectedObj:{},check:false})
   }else{
    this.setState({...this.state,werningMsg:apiCalls.isErrorDispaly(res),selection:[],selectedObj:{},check:false,selectedObjs:[]})
   }
  }
  }
  getErrorMsg=()=>(
    this.state.werningMsg !== undefined && this.state.werningMsg !== null && (
      <Alert
        className="mb-16 w-100"
        type="warning"
        description={this.state.werningMsg}
        showIcon
      />
    )
  )
  renderIsCheckBox = () => (
    !this.state.withdraw && this.state.stateChange.status !== "Submitted" && this.state.stateChange.status !== "Pending" && this.state.stateChange.status !== "Swept" && this.state.stateChange.status !== "Compliance Check" &&
      <div className="mb-8">
        <Alert message={<Form.Item
          name="isCheckBox"
          valuePropName="checked"
          className="mb-0"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Please select checkbox')),
            },
          ]}
        ><div className="d-flex">
            <label className="text-center custom-checkbox mr-8">
              <input
                name="check"
                type="checkbox"
                checked={this.state.selectedObj.isCheckBox}
                onChange={(e) => this.handleImpNote(e)}
                disabled={this.state.selectedObj.status === "Rejected" || this.state.selectedObj.status === "Approved" || this.state.selectedObj.status?.indexOf("Approved") > -1}
              />
              <span></span>
            </label>Important Note:</div></Form.Item>}
          description={<p className="fw-400 fs-14 mb-5 l-height-normal">Please ensure that transaction has been successfully completed prior to approval. Please click the checkbox above to confirm. </p>}
          type="info"
          className="imp-alert"
        /></div>
  )
  renderHashField = () => (
    !this.state.withdraw && <Col xs={24}>
      <Form.Item
        name="hash"
        label="Hash"
        className="input-label"
        rules={[
          {
            required: this.state.stateChange.status !== "Submitted" && this.state.stateChange.status !== "Pending" && this.state.stateChange.status !== "Rejected" && this.state.selectedObj.status !== "Rejected" && this.state.stateChange.status !== "Swept" && this.state.stateChange.status !== "Compliance Check" ? true : false,
            message: "Is required",
            whitespace: true
          },
          {
            validator: validateContentRule
          }
        ]}
      >
        <Input
          placeholder="Hash"
          maxLength={150}
          className='cust-input'
          disabled={
            this.state.selectedObj.status === "Rejected" ||
              this.state.selectedObj.status === "Approved" || this.state.selectedObj.status?.indexOf("Approved") > -1
              ? true
              : false
          }
        />
      </Form.Item>
    </Col>
  )
  renderNetWork = () => (
    this.state.selectedObjs[0]?.type != "Fiat" && this.state.stateChange.status !== "Submitted" && this.state.stateChange.status !== "Pending" && this.state.stateChange.status !== "Swept" && this.state.stateChange.status !== "Compliance Check" && this.state.selection.length === 1 &&
      <Col xs={24} sm={24} md={12}>
        <Form.Item
          name="network"
          label="Network"
          className="input-label"
        >
          <Input placeholder="Net Work"
            disabled
            maxLength={150}
            className='cust-input' />
        </Form.Item>
      </Col>
    
  )
  renderFormBtns = () => {
    const { selectedObj,isBtnApprove } = this.state;
    return <div className="text-right">
      <Button
        type="primary"
        className="primary-btn cancel-btn mr-8"
        onClick={this.handleCancel}
      >
        Cancel
      </Button>
      {((selectedObj.status === "Approved" &&
        selectedObj.status === "Rejected" && selectedObj.status === "Pending") ||
        selectedObj.status === "Approve" ||
        selectedObj.status === "Reject" ||
        selectedObj.status === "Pending" ||
        selectedObj.status === "Submitted" || selectedObj.status === "Swept" || selectedObj?.status === "Compliance Check") && (
          <Button
            type="primary"
            key="submit"
            className="primary-btn"
            htmlType="submit"
            disabled={this.state.btnDisabled === false}
            loading={isBtnApprove}
          >
            Save
          </Button>
        )}
    </div>
}
  render() {
    const {
      gridUrl,
      gridUrlC,
      withdraw,
      stateHistory,
      stateHistoryData,
       caseModal,
      statuserrorMsg,
      selectedTab,
      selection,selectedObj
    } = this.state;
    let isAcessdenied;
    let istabAcessdenied;
		if(this.props.permissions[this.props.permissions.currentScreenTabId]){
			const isview = this.props.permissions[this.props.permissions.currentScreenTabId]?.filter((item)=>item.permissionName === "view" && item.values);
			if(isview?.length===0){
				isAcessdenied = true;
			}else{
				isAcessdenied = false;
			}
		}
    if(this.props.permissions['tabAccessDenied'] && istabAcessdenied!==true){
			istabAcessdenied = true;
			if(!this.state.istabAcessdenied){
			this.setState({...this.state,istabAcessdenied:true,statuserrorMsg: null,cryptoModal: false,fillGasModal: false,sweepModal: false,modal:false,caseModal:false,})
			}
		}
    return (
      <>
        {this.getErrorMsg()}
        <div className="d-flex justify-content align-center">
          <Radio.Group
            value={selectedTab}
            onChange={this.handleWithdraw}
            className="buysell-toggle"
          >
            <Radio.Button value={1}> Fiat </Radio.Button>
            <Radio.Button value={2}> Crypto </Radio.Button>
          </Radio.Group>
        </div>
        {(isAcessdenied ||istabAcessdenied) && <AccessDenied />}
				{!(isAcessdenied||istabAcessdenied) &&  <>{(selectedTab===1) ? (
          <List
            showActionBar={true}
            pKey={"Send Fiat"}
            onActionClick={(key) => this.onActionClick(key)}
            url={gridUrl}
            ref={this.gridFiatRef}
            key={gridUrl}
            columns={this.gridColumns}
            showExcelExport={true}
          />
        ) : (
          <List
            showActionBar={true}
            pKey={"Send Crypto"}
            onActionClick={(key) => this.onActionClick(key)}
            url={gridUrlC}
            ref={this.gridCryptoRef}
            key={gridUrlC}
            columns={this.withdrawColumns}
            showExcelExport={true}
          />
        )}</>}
         <LockedComponent getLockandUnLockDetails={this.state.getLockandUnLockDetails} isModal={this.state.isModal} 
				LockedPopClose={()=>this.LockedPopClose()} isCheckLock={this.state.isCheckLock} selectedObj={selectedObj}
         isGridRefresh={()=>this.gridRefresh()} screen={selectedTab==1 ? "WithdrawFiat" : "Withdrawcrypto"} ModifiedBy={this.props.userConfig.userName}/>
        <Modal className="text-break"
          title="Confirm Pending/Approve/Reject?"
          visible={this.state.modal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.handleCancel} />
            </Tooltip>
          }
          footer={null}
        >
          <div>
            {this.state.stateLoading && <Loader />}
            {statuserrorMsg !== undefined && statuserrorMsg !== null && (
              <Alert
                className="mb-12 send-poperror"
                type="error"
                description={statuserrorMsg}
                showIcon
              />
            )}
            <Form
              ref={this.formref}
              className="ant-advanced-search-form"
              autoComplete="off"
              onFinish={this.approveSave}
            >
              {this.renderIsCheckBox()}
              <Row gutter={24} className="mb-24 pb-24 border-bottom">
                {this.renderHashField()}
                {this.renderNetWork()}
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="status"
                    label="State"
                    className="input-label"
                    rules={[
                      {
                        required: true,
                        message: "Is required"
                      }
                    ]}
                  >
                    <Select
                      className="cust-input mb-0"
                      onChange={(e) => this.stateChange(e)}
                      disabled={this.state.selectedObj.status === "Approved" || this.state.selectedObj.status?.indexOf("Approved") > -1 || this.state.selectedObj.status === "Rejected"}
                      placeholder="Select State"
                    >
                      {this.state.statesLst?.map(item => <Select.Option value={item?.code}>{item?.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                {this.state.selectedObj.previousState &&  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="previousState"
                      label="Previous State"                      
                      className="input-label"
                    >
                     <Input   placeholder="Previous State"
                      disabled
                      maxLength={150}
                      className='cust-input'/>
                    </Form.Item>
                  </Col>}
                {(this.state.stateChange.status !== "Submitted" && this.state.stateChange.status !== "Pending" && this.state.stateChange.status !== "Swept" && this.state.stateChange.status !=="Compliance Check" && selection.length ===1 && 
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="pCommisionValue"
                      label={<div>Provider fees
                      </div>}
                      rules={[                       
                        {
                          validator: this.validateNumber
                        }
                      ]}
                      className="input-label"
                    >
                      <NumberFormat
                       decimalScale={withdraw ? 2 : 8}
                        className="cust-input"
                        disabled={
                          this.state.selectedObj.status === "Approved" || this.state.selectedObj.status?.indexOf("Approved") > -1 || this.state.selectedObj.status === "Rejected"
                            ? true
                            : false
                        }
                        customInput={Input}
                        thousandSeparator={true}
                        prefix={""}
                        placeholder="Provider fees"
                        allowNegative={false}
                        maxLength={13}
                      />
                    </Form.Item>
                  </Col>
                )}

                {(this.state.stateChange.status !== "Submitted" &&  this.state.stateChange.status !== "Pending" && this.state.stateChange.status !== "Swept" && this.state.stateChange.status !=="Compliance Check" && selection.length ===1 && 
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="sweepSourceCommisionValue"
                      label={<div>Sweep at source

                      </div>}
                      className="input-label"
                      rules={[                       
                        {
                          validator: this.validateNumber
                        }
                      ]}
                    >
                      <NumberFormat
                        decimalScale={withdraw ? 2 : 8}
                        className="cust-input"
                        disabled={
                          this.state.selectedObj.status === "Approved" || this.state.selectedObj.status?.indexOf("Approved") > -1 || this.state.selectedObj.status === "Rejected"
                            ? true
                            : false
                        }
                        customInput={Input}
                        thousandSeparator={true}
                        prefix={""}
                        placeholder="Sweep at source"
                        allowNegative={false}
                        maxLength={13}
                      />
                    </Form.Item>
                  </Col>
                )}


                {(this.state.stateChange.status !== "Submitted" && this.state.stateChange.status !== "Pending" && this.state.stateChange.status !== "Swept" && this.state.stateChange.status !=="Compliance Check" && selection.length ===1 && 
                  <><Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="sweepDestinationCommissionValue"
                      label={<div>Sweep at destination

                      </div>}
                      rules={[                       
                        {
                          validator: this.validateNumber
                        }
                      ]}
                      className="input-label"
                    >
                      <NumberFormat
                       decimalScale={withdraw ? 2 : 8}
                        className="cust-input"
                        disabled={
                          this.state.selectedObj.status === "Approved" || this.state.selectedObj.status?.indexOf("Approved") > -1 || this.state.selectedObj.status === "Rejected"
                            ? true
                            : false
                        }
                        customInput={Input}
                        thousandSeparator={true}
                        prefix={""}
                        placeholder="Sweep at destination"
                        allowNegative={false}
                        maxLength={13}
                      />
                    </Form.Item>
                  </Col>
                {this.state.stateChange.status !== "Rejected" && selectedTab === 1 &&<Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="BankValueDate"
                      className="input-label cust-datepicker-cursor"
                      label="Bank Value Date"
                      rules={[
                        {
                          required: true,
                          message: "Is required"
                        }
                      ]}
                    >
                      <DatePicker
                        format={config?.dateFormates?.dateFormate}
                        placeholder="Bank Value Date"
                        className="cust-input"
                        disabled={
                          this.state.selectedObj.status === "Approved" ||
                          (this.state.selectedObj.status && this.state.selectedObj.status.includes("Approved"))
                        }
                        disabledDate={(current) => current && current > moment().endOf('day')}
                      />
                    </Form.Item>
                  </Col>}
                </>
                )}
                 {(this.state.stateChange.status ==="Rejected" &&(
                  <Col xs={24}>
                    <Form.Item
                      name="rejectReason"
                      label={<div>Reason For Rejection

                      </div>}
                       className="input-label"
                      rules={[                       
                        {
                          required: true,
												  message: "Is required",
                          whitespace: true,
                        },
                        {
                          validator: this.validateNumber
                        }
                      ]}
                     
                    >
                      <TextArea
                       placeholder="Reason For Rejection"
                       maxLength={100}
                       rows={4}
                       disabled={
                          this.state.selectedObj.state === "Rejected" ||this.state.selectedObj.status==="Rejected"
                            ? true
                            : false
                          }
                       showCount
                     />
                    </Form.Item>
                  </Col>
                ))}


                <Col xs={24}>
                  {(this.state.stateChange.status !== "Submitted" && this.state.stateChange.status !== "Pending") && this.state.stateChange.status !== "Swept" && this.state.stateChange.status !=="Compliance Check" && (
                    <Form.Item
                      name="statusRemarks"
                      label="Remarks"
                      className="input-label"
                      rules={[
                        {
                          whitespace: true,
                          message: "Is required"
                        },
                        {
                          validator: validateContentRule
                        }
                      ]}
                    >
                      <TextArea
                        placeholder="Remarks"
                        maxLength={100}
                        rows={4}
                        disabled={
                          this.state.selectedObj.state === "Rejected" ||this.state.selectedObj.status==="Rejected" ||
                          this.state.selectedObj.state === "Approved"|| this.state.selectedObj.status?.indexOf("Approved") > -1
                            ? true
                            : false
                          }
                        showCount
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Form.Item className="mb-0">
                {this.renderFormBtns()}
              </Form.Item>
            </Form>
          </div>
        </Modal>

        <Drawer
          title={[
            <div className="side-drawer-header">
              <span />
              <div className="text-center fs-16">
                <Title className="text-white-30 fs-16 fw-600 text-upper mb-4 d-block">
                  Status History
                </Title>
              </div>
              <span
                onClick={this.hideStateHistory}
                className="icon md close c-pointer"
              />
            </div>
          ]}
          placement="right"
          closable={true}
          visible={stateHistory}
          closeIcon={null}
          onClose={this.hideStateHistory}
          className="side-drawer"
        >
          {this.state.stateLoading ? (
            <div className="text-center">
              <Spin />
            </div>
          ) : (
            <>
              {stateHistoryData.map((item, idx) => (
                <div className="coin-info" key={idx}>
                  <div>
                    <Text className="hislbl">Status</Text>
                    <Text className="hisstate">{item.status}</Text>
                  </div>
                  <Text className="hislbl">
                    <Moment format={config?.dateFormates?.dateTimeFormate}>
                      {item?.createdDate
                        ? apiCalls.convertUTCToLocalTime(item?.createdDate)
                        : item?.createdDate}
                    </Moment>
                  </Text>
                </div>
              ))}
            </>
          )}
          {stateHistoryData.length === 0 && !this.state.stateLoading && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Data Found"
            ></Empty>
          )}
        </Drawer>
        <Modal
          title="Create Case"
          visible={caseModal}
          width={1000}
          style={{top: 16}}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.hideCaseModal} />
            </Tooltip>
          }
          destroyOnClose
          footer={null}
        >
         <CreateCase 
         FromScreen={this.state.selectedObjs[0]?.type==="Fiat"?'SendFiat':'SendCrypto'}
          ScreenId={this.state.selectedObjs[0]?.id}
           CreateCaseId={this.state.selectedCaseId}
            ScreenName={this.state.selectedObjs[0]?.type==="Fiat"?'SendFiat':'SendCrypto'}
            closeModal={this.hideCaseModal} />
        </Modal>
      </>
    );
  }
}
const connectStateToProps = ({ oidc, userConfig,permissions }) => {
  return { userConfig: userConfig.userProfileInfo, oidc, trackAuditLogData: userConfig.trackAuditLogData,permissions };
};
export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(Withdraw);

