import { clientApi } from '../../api'
import { ApiControllers } from '../../api/config'

const getCommision = (id, type) => {
    return clientApi.get(ApiControllers.commissions + `${type}/${id}`)
}
const getZonesLu = (type) => {
    return clientApi.get(ApiControllers.commissions + `${type}/Zones`)
}
const getCurrencies = () => {
    return clientApi.get(ApiControllers.commissions + `Fiat/Currency/Banks`)
}
const saveCommision = (type,obj) => {
    return clientApi.post(ApiControllers.commissions + `${type}`, obj) ;
}
const getBanksLu = () => {
    return clientApi.get(ApiControllers.commissions + `Fiat/Banks`)
}
const changeStaus = (obj) => {
    return clientApi.put(ApiControllers.master + `Commission/Status`, obj)
}
const commissionsTierGet = (id) => {
    return clientApi.get(ApiControllers.commissions + `Tier/${id}`)
}
const getCommissions=(id)=>{
    return clientApi.get(ApiControllers.commissions + `Commission/${id}`)
}
const deleteCommissionTier=(obj)=>{
    return clientApi.post(ApiControllers.commissions + `Tier`,obj)
}
const saveTier = (obj) => {
    return clientApi.post(ApiControllers.commissions + `Tier`, obj)
}

const deleteCommission = (id) => {
    return clientApi.delete(ApiControllers.commissions + `Commission/${id}`)
}

const synceCommission = (commissionDetailId) => {
    return clientApi.put(ApiControllers.commissions + `Sync/${commissionDetailId}`)
}

export { getCommision, getZonesLu, getCurrencies, saveCommision, getBanksLu, changeStaus,
    commissionsTierGet,getCommissions,deleteCommissionTier,saveTier,deleteCommission,synceCommission }