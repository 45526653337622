import React, { useEffect, useState } from "react";
import { Form, Row, Col, Typography ,Button} from "antd";
import Loader from "../loader.component";
import { getCustomerDetails as getCustomerDetailsApi,sumsubRefreshData } from "./api";
import Alert from "antd/lib/alert";
import apiCalls from "../../api/apiCalls";
import IdentityDetails from "./IdentityDetails";
import Moment from "react-moment";
import ProfileData from "./profileData";
import AddressInfo from "./addressInfo";
import KYC from "./KYC";
import CompanyCheckComponent from './companyCheck'
import config from "../../config";
const KYB = ({ customerId }) => {
  const { Title, Paragraph } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const [isUboLoading, setUboIsLoading] = useState(false);
  const [userData,setUserData]=useState(null)
  const [errorMsg, setErrorMsg] = useState("");
  const [uboDetails,setUboDetails]=useState(null)
  const [companyCheckData, setCompanyCheckData] = useState(null);
  const [proofs, setProofs] = useState(null);
  const [isRefresh,setIsRefresh]=useState(false);
  const getBusinessDetails = async () => {
    setIsLoading(true);
    try {
      const response = await getCustomerDetailsApi(
        `sumsubdetails/${customerId}`
      );
      if (response.ok) {
        setUserData(JSON.parse(response.data?.companySumsubDetails?.userData))
        setCompanyCheckData(JSON.parse(response.data.companySumsubDetails?.companyCheckData));
        setProofs(response.data.companySumsubDetails?.proofs);
        setErrorMsg('')
      } else {
        setErrorMsg(apiCalls.isErrorDispaly(response));
      }
    } catch (error) {
      setErrorMsg(apiCalls.isErrorDispaly(error));
    } finally {
      setIsLoading(false);
    }
  };

  const getUBOsDetails=async()=>{
    setUboIsLoading(true);
    try {
      const response = await getCustomerDetailsApi(
        `ubodetails/${customerId}`
      );
      if (response.ok) {
        setUboDetails(response.data)
        setErrorMsg('')
      } else {
        setErrorMsg(apiCalls.isErrorDispaly(response));
      }
    } catch (error) {
      setErrorMsg(apiCalls.isErrorDispaly(error));
    } finally {
      setUboIsLoading(false);
    }
  }
  useEffect(() => {
    getBusinessDetails();
    getUBOsDetails();
  }, []);

  const getSumsubRefreshData=async()=>{
    setIsRefresh(true);
    let res = await sumsubRefreshData(customerId);
    if(res.ok){
      setIsRefresh(false);
      getBusinessDetails();
      getUBOsDetails();
    }else{
      setIsRefresh(false);
      setErrorMsg(apiCalls.isErrorDispaly(res));
    }
  }
  return (
    <>
      <div>
        {errorMsg && (
          <div className="mt20">
            <Alert
              closable
              type="error"
              description={errorMsg}
              onClose={() => setErrorMsg('')}
              showIcon
            />
          </div>
        )}
      </div>

      <Form
        name="advanced_search"
        className="ant-advanced-search-form kyckyb-form"
        autoComplete="off"
      >
        {isLoading && <Loader />}
        {!isLoading && (
          <>
           <div className="text-right">  <Button
						type="primary"
						onClick={() => getSumsubRefreshData()}
            loading={isRefresh}
						className="primary-btn primary-btn ">
						Refresh
					</Button></div>
           <ProfileData data={userData} type={"Company"} />
            <Title level={5} className="m-0 mt-5">Company Data</Title>
            <div className="applicant-content">
              <Row gutter={[16, 24]}>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Company Name</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.companyInfo?.companyName}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Registration Number</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.companyInfo?.registrationNumber}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">
                      Location of Registration
                    </label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.companyInfo?.registrationLocation}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">
                     Company Type
                    </label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.companyInfo?.type}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">
                    Incorporated On
                    </label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                    {userData?.info?.companyInfo?.incorporatedOn ? (
               <Moment format={config?.dateFormates?.dateFormate}>
                 {userData?.info?.companyInfo?.incorporatedOn
                   && apiCalls.convertUTCToLocalTime(
                       userData?.info?.companyInfo?.incorporatedOn
                     )
                   || userData?.info?.companyInfo?.incorporatedOn}
               </Moment>
             ) : (
               userData?.info?.companyInfo?.incorporatedOn
             )}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">
                      Country
                    </label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.companyInfo?.country}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Legal Address</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.companyInfo?.legalAddress}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Postal Address</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.companyInfo?.postalAddress}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">
                      Applicant Position
                    </label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.companyInfo?.applicantPosition}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">
                      Email
                    </label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.companyInfo?.email}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">
                      Phone
                    </label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.companyInfo?.phone}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Tax ID</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.companyInfo?.taxId}
                    </Paragraph>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xxl={6}
                >
                  <div>
                    <label className="kpi-label">Website</label>
                    <Paragraph className="kpi-val fw-700 mb-0">
                      {userData?.info?.companyInfo?.website}
                    </Paragraph>
                  </div>
                </Col>


              </Row>
              <Title level={5} className="mt20">Address</Title>
              <div className="px-8 py-8">
              <div >
                <AddressInfo addressDetails={userData?.info?.companyInfo?.address} border={false}/>
              </div>
              </div>
              <Title className="mt20" level={5}>
                Documents
              </Title>
              <IdentityDetails identityDetails={proofs} />
            </div>
            <Title level={5}>UBO's Details</Title>
            <div>
              {isUboLoading && <div className="text-center">Loading...</div>}
              {!isUboLoading && uboDetails?.length >0 &&
                uboDetails?.map((detail, index) => {
                  return (
                    <KYC key={index} customerId={null} activeTab={null} customerDetails={detail} number={index+1} screen={"KYB"}/>
                  );
                })}
            </div>
            <Title className="mt20" level={5}>Company Check Details</Title>
            <div>
            <CompanyCheckComponent customerId={null} activeTab={null} companyCheckData={companyCheckData} />
            </div>
          </>
        )}
      </Form>
    </>
  );
};

export default KYB;
