import React, { Component } from "react";
import {
  Form,
  Select,
  Tooltip,
  message,
  Modal,
  Button,
  Row,
  Col,
  Alert,
  Radio,
  Input
} from "antd";
import List from "../grid.component";
import apiCalls from "../../api/apiCalls";
import { connect } from "react-redux";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { updateCurrentScreen } from "../../reducers/permissionsReducer";
import { setCurrentAction } from "../../reducers/actionsReducer";
import Loader from "../loader.component";
import { statusCases,getStates } from "./api";
import Moment from "react-moment";
import CaseTemplate from "./caseTemplate";
import { validateContentRule } from "../../utils/custom.validator";
import 'moment-timezone';
import config from "../../config";
const { TextArea } = Input
class Cases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check: false,
      selection: [],
      selectedObj: {},
      modal: false,
      alert: false,
      currencyLU: [],
      error: null,
      btnDisabled: false,
      stateChange: {},
      isSubmitting: false,
      errorMsg:null,
      case: false,
      cases: this.props.match.params.key?this.props.match.params.key:1,
      casesTemplate:false,
      obj: {
        id: "",
        screenName: "cases",
        pathname:"",
        state: ""
      },
      addModalshow:false,
      deleteCaseTempleteModal:false,
      statesLst:[],
      stateLoading:false,
      saveDisabled: true,
      gridUrlCase: process.env.REACT_APP_GRID_API + "Case/GetCasesK",
      gridUrlMyCase: process.env.REACT_APP_GRID_API + `Case/GetMyCasesK/${this.props.userConfig.id}`,
      gridUrlCaseTemplate: process.env.REACT_APP_GRID_API + "/Case/CaseTemplates",
    };
    this.gridRef = React.createRef();
    this.formref = React.createRef();
 
  }
  componentDidMount() {
    this.props.dispatch(updateCurrentScreen("cases"));
    this.props.dispatch(setCurrentAction(null));
    this.CasesTrack();
   
  }
 
  CasesTrack = () => {
    apiCalls.trackEvent({
      Type: "Admin",
      Action: "Cases grid page view",
      Username: this.props.userConfig?.userName,
      MemeberId: this.props.userConfig?.id,
      Feature: "Cases",
      Remarks: "Cases grid page view",
      Duration: 1,
      Url: window.location.href,
      FullFeatureName: "Cases"
    });
  };

  
   renderCustomCheckbox = (props) => (
    <div className="text-center">
      <label className="text-center custom-checkbox">
        <input
          id={props?.dataItem?.id}
          name="check"
          type="checkbox"
          checked={this.state?.selection?.indexOf(props?.dataItem?.id) > -1}
          onChange={(e) => this.handleInputChange(props, e)}
          className="grid_check_box"
        />
        <span></span>
      </label>
    </div>
  );
  renderDate=(DateData)=>(
    <td>
    <div className="gridLink" onClick={() => this.viewCase(DateData)}>
      <Moment format={config?.dateFormates?.dateTimeFormate}>{DateData.dataItem?.createdDate ? apiCalls.convertUTCToLocalTime(DateData.dataItem?.createdDate) : DateData.dataItem?.createdDate}</Moment>
    </div>
  </td>
  )
  caseGridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked:true,
      customCell: this.renderCustomCheckbox
    },
    {
      field: "createdDate",
      title: "Date",
      width: 260,
      filter: true,
      locked:true,
      filterType: "date",
      customCell: this.renderDate
    },
    { field: "caseNumber", title: "Case Number", width: 150, filter: true },

    { field: "caseTitle", title: "Case Title", width: 300, filter: true },

    { field: "name", title: " Name", width: 160, filter: true },
    { field: "email", title: "Email", width: 250, filter: true },

    { field: "createdBy", title: "Created By", width: 200, filter: true },

    { field: "modifiedBy", title: "Modified By", width: 210, filter: true },
    { field: "state", title: "Status", width: 150, filter: true }
  ];

  myCaseGridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked:true,
      customCell: this.renderCustomCheckbox
    },
    {
      field: "createdDate",
      title: "Date",
      width: 260,
      filter: true,
      filterType: "date",
      locked:true,
      customCell: this.renderDate
    },
    { field: "caseNumber", title: "Case Number", width: 150, filter: true },

    { field: "caseTitle", title: "Case Title", width: 300, filter: true },

    { field: "name", title: " Name", width: 160, filter: true },
    { field: "email", title: "Email", width: 250, filter: true },
   
    { field: "createdBy", title: "Created By", width: 200, filter: true },
    { field: "modifiedBy", title: "Modified By", width: 210, filter: true },
    { field: "state", title: "Status", width: 150, filter: true }
  ];
  columnsTemplate = [
    {
      field: "",
      title: "",
      width: 25,
      locked:true,
      customCell: this.renderCustomCheckbox
    },
    { field: "templateName", title: "Template Name", filter: true, width: 160,
    customCell: (props) => (
      <td>
        <div className="gridLink" onClick={() => this.viewCaseTemplate(props)}>
          {props.dataItem.templateName}
        </div>
      </td>
    ) },
    { field: "remarks", title: "Remarks", filter: true, width: 160 },
    { field: "createdBy", title: "Created By", filter: true, width: 160 },
    { field: "modifiedBy", title: "Modified By", filter: true, width: 160 },

  ]
  handleInputChange = (prop, e) => {
    this.setState({errorMsg:null});
    const rowChecked = prop.dataItem;
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowChecked.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowChecked.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowChecked,
      selection,
      errorMsg:null,
      error:null
    });
    
  };

  addCase = () => {
    this.props.history.push({
      pathname: "/cases/00000000-0000-0000-0000-000000000000/casedetails/add/00000000-0000-0000-0000-000000000000",
      state: { pKey: "cases", action: "add" }
    });
    this.props.dispatch(
      setBreadcrumb({
        key: "/cases/00000000-0000-0000-0000-000000000000/casedetails/add/00000000-0000-0000-0000-000000000000",
        val: "Add Case"
      })
    );
    apiCalls.trackEvent({
      Action: "Case add page view",
      Feature: "Cases",
      Remarks: "Case add page view",
      FullFeatureName: "Cases",
      userName: this.props.userConfig?.userName,
      id: this.props.userConfig?.id
    });
  };
  viewCase = (e) => {
    let caseActiveKey= Number(this.state.cases);
    const items = e.dataItem;
    const val = items.id;
    this.props.history.push("/cases/"+`${caseActiveKey}/` + val + "/view");
    this.props.dispatch(
      setBreadcrumb({ key: "/cases/"+`${caseActiveKey}/` + val + "/view", val: items.caseNumber })
    );
    apiCalls.trackEvent({
      Action: "Case detail page View",
      Feature: "Cases",
      Remarks: "Case detail page View",
      FullFeatureName: "Cases",
      userName: this.props.userConfig?.userName,
      id: this.props.userConfig?.id
    });
  };

  editCase = () => {
    let caseActiveKey= Number(this.state.cases);
    this.setState({...this.state,errorMsg:null});
    if (!this.state.check) {
      message.destroy();
      this.setState({...this.state,errorMsg:"Please select the one record"});
    } else {
      this.setState({...this.state,errorMsg: null,selection:[]});
      const obj = this.state.selectedObj;
      const val = obj.id;
      const alertVal = obj.alertId;
      if (obj.state === "Approved" || obj.state === "Rejected") {
        this.props.history.push("/cases/"+`${caseActiveKey}/` + val + `/casedetails/disabled/${alertVal}`);
        this.props.dispatch(
          setBreadcrumb({
            key: "/cases/"+`${caseActiveKey}/` + val + "/casedetails/disabled",
            val: obj.caseNumber
          })
        );
       
      } else {
        this.props.history.push("/cases/"+`${caseActiveKey}/` +val + `/casedetails/edit/${alertVal}`);
        this.props.dispatch(
          setBreadcrumb({
            key: "/cases/"+`${caseActiveKey}/` + val + "/casedetails/edit",
            val: obj.caseNumber
            
          })
        );
      }
    }
    apiCalls.trackEvent({
      Action: "Case edit page View",
      Feature: "Cases",
      Remarks: "Case edit page View",
      FullFeatureName: "Cases",
      userName: this.props.userConfig?.userName,
      id: this.props.userConfig?.id
    });
  };
  statusChange = () => {
    this.setState({...this.state,errorMsg:null,error:null});
    if (!this.state.check) {
      message.destroy();
     this.setState({
        ...this.state,errorMsg:"Please select the one record",
        isSubmitting:
          this.state.stateChange.status !== "Submitted" &&
            this.state.stateChange.status !== "Pending"
            ? true
            : false,
            saveDisabled: true,
      });
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            alert: false,
          }),
        1000
      );
    } else {
      getStates(this.state.selectedObj.state).then(response => {
        if (response.ok) {
            this.setState({ ...this.state, statesLst: response.data, stateLoading: false });
        }else{
          this.setState({ ...this.state,  error: apiCalls.isErrorDispaly(response) });
        }
    })
      this.setState(
        {
          ...this.state,
          modal: true,
          errorMsg:null,
          stateChange: {
            status: this.state.selectedObj.state,
          }
        },
        () => {
          this.setState({
            ...this.state,
             stateLoading: true,
          });
          setTimeout(
            () =>
              this.setState({
                ...this.state,
                stateLoading: false,
                isSubmitting:
                  this.state.stateChange.status !== "Submitted" &&
                    this.state.stateChange.status !== "Pending"
                    ? true
                    : false
              }),
            1000
          );

          setTimeout(
            () =>
              this.formref.current.setFieldsValue({
                ...this.state,
                status: this.state.selectedObj.state,
                reason:this.state.selectedObj.reason
              }),
            1000
          );
        }
      );
    }
  };
  success = (state) => {
    switch (state) {
      case "Approved":
        state = "Approved";
        break;
      case "Rejected":
        state = "Rejected";
        break;
      case "Submitted":
        state = "Submitted";
        break;
        default:
        console.log('Submitted');
        
    }
    message.success({
      content: "Case " + state + " successfully",
      className: "custom-msg",
      duration: 3
    });
  };

  handleOk = async (values) => {
    this.setState({ btnDisabled: true });
      let statusObj= {}
      statusObj.id = this.state.selectedObj.id;
      statusObj.status = values.status || this.state.selectedObj.state;
      statusObj.customerId = this.props.userConfig?.id;
      statusObj.info = JSON.stringify(this.props.trackAuditLogData);
      statusObj.reason=values.status==="Reopened"? values.reason:null
      statusObj.modifiedBy=this.props.userConfig?.userName;
      let response = await statusCases(statusObj);
      if (response.ok) {
       message.destroy();
        this.props.dispatch(setCurrentAction(null));
        this.props.history.push("/cases");
        this.setState({ btnDisabled: false });
        this.success(statusObj.status);
        this.formref.current.resetFields();
        this.setState({
          ...this.state,
          modal: false,
          selection: [],
          check: true,
          btnDisabled:false,
          saveDisabled: true,
        });
        this.gridRef.current.refreshGrid();
      } else {
        this.setState({
          ...this.state,
          error: apiCalls.isErrorDispaly(response),
          btnDisabled:false,
          saveDisabled: false

        });
    
    }
  };

	
  stateChange = (val, prop) => {
    let { stateChange } = this.state;
    stateChange.status = val;
    this.setState({ ...this.state, stateChange,errorMsg:null,error:null,saveDisabled: val !== this.state.selectedObj.state ? false : true});
  };

  handleCancel = (e) => {
    this.props.dispatch(setCurrentAction(null));
    this.setState({
      ...this.state,
      modal: false,
      selection: [],
      check: false,
      error: null,
      saveDisabled: true,
    });
    this.formref.current.resetFields();

  };
  onActionClick = (key) => {
    const actions = {
      add: "addCase",
      edit: "editCase",
      "State Change": "statusChange"
    };
    this[actions[key]]();
  };

  handleCasesTabs = (e) => {
    this.props.history.push(`/cases/${e.target.value}`)
    this.setState({
      ...this.state,
      cases: e.target.value,
      selection: [],
      selectedObj: {},
      check: false,
      errorMsg:null,
      error:null
    });
  };
  addModal=()=>{
this.setState({...this.state,addModalshow:true, selection: [],
  selectedObj:{},errorMsg:null});
  }
  handleCaseTemplateModelDelete=()=>{
    if (!this.state.check) {
      message.destroy();
      this.setState({...this.state,errorMsg:"Please select the one record", selection: [], selectedObj:{},});
    } else{
      this.setState({...this.state,deleteCaseTempleteModal:true,errorMsg:null});
    }
  }
  handleCaseTemplateModelDeleteClose=()=>{
    this.setState({...this.state,deleteCaseTempleteModal:false,errorMsg:null,selection: [],selectedObj:{},check:false});
  }
  viewCaseTemplate = (e) => {
    
    const items = e.dataItem;
    const val = items.id;
    this.props.history.push("/cases/caseTemplateView/" + val );
    this.props.dispatch(
      setBreadcrumb({ key: "/cases/caseTemplateView/" + val , val: items.templateName })
    );
    apiCalls.trackEvent({
      Action: "Case detail page View",
      Feature: "Cases",
      Remarks: "Case detail page View",
      FullFeatureName: "Cases",
      userName: this.props.userConfig?.userName,
      id: this.props.userConfig?.id
    });
  };
  handleEditTemplate=()=>{
    if (!this.state.check) {
      message.destroy();
      this.setState({...this.state,errorMsg:"Please select the one record", selection: [],
      selectedObj:{},});
    } else{
      this.setState({...this.state,addModalshow:true});
    }
  }
  handleModelClose=()=>{
    this.props.dispatch(setCurrentAction(null));
    this.setState({
      ...this.state,
      modal: false,
      selection: [],
      selectedObj:{},
      check: false,
      error: null,
      saveDisabled: true,
      addModalshow:false,
      deleteCaseTempleteModal:false
    });
    this.formref.current?.resetFields();
  }
  handleModelSaveClose=()=>{
    this.props.dispatch(setCurrentAction(null));
    this.setState({
      ...this.state,
      modal: false,
      selection: [],
      selectedObj:{},
      check: false,
      error: null,
      saveDisabled: true,
      addModalshow:false,
      deleteCaseTempleteModal:false
    });
    this.gridRef.current?.refreshGrid();
    this.formref.current?.resetFields();
  }
  render() {
    const { cases, gridUrlCase, gridUrlMyCase} = this.state;
    let caseActiveTab=Number(cases);
    return (
      <>
		{this.state.errorMsg !== null && <div style={{ width: '100%' }}>
      <Alert className="mb-16 w-100" type="warning" description={this.state.errorMsg} showIcon/></div> }
      <div className="d-flex align-center justify-content">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
          className="mb-16"
        >
          <Radio.Group
          defaultValue={cases ? caseActiveTab : 1}
            onChange={this.handleCasesTabs}
            className="buysell-toggle mb-0 custcase-toggle"
          >
            <Radio.Button value={1}>Cases</Radio.Button>
            <Radio.Button value={2}>My Cases</Radio.Button>
            <Radio.Button value={3}>Templates</Radio.Button>
          </Radio.Group>
        </div>
        {caseActiveTab===3 &&
        <div className="text-right mb-16">
            <Tooltip title="Add"><span className="icon c-pointer md add mr-12"
           onClick={()=>this.addModal()} /></Tooltip>
            <Tooltip title="Edit"><span className="icon c-pointer md edit mr-12"
            onClick={this.handleEditTemplate}/></Tooltip>
            <Tooltip title="Delete"><span className="icon c-pointer md delete mr-0 ant-tooltip-open" onClick={this.handleCaseTemplateModelDelete}
            ></span></Tooltip>

          </div> 
  }
          </div>
        {(caseActiveTab===1 )&& <List
          showActionBar={true}
          onActionClick={(key) => this.onActionClick(key)}
          key={'Cases'}
          ref={this.gridRef}
          url={gridUrlCase}
          columns={this.caseGridColumns}
        /> }
        {(cases===2 ||cases==="2")&& <List
          showActionBar={true}
          onActionClick={(key) => this.onActionClick(key)}
          key={gridUrlMyCase}
          ref={this.gridRef}
          url={gridUrlMyCase}
          columns={this.myCaseGridColumns}
          className="transaction-table-header"
        />
        }
        {(cases===3  || cases==="3")&&
          <List
            columns={this.columnsTemplate}
            url={this.state.gridUrlCaseTemplate}
            ref={this.gridRef}
            />
        }
{caseActiveTab===3 && (this.state.addModalshow || this.state.deleteCaseTempleteModal) &&
        <CaseTemplate addModalshow={this.state.addModalshow} handleModelClose={this.handleModelClose} ref={this.formref} deleteCaseTempleteModal={this.state.deleteCaseTempleteModal} handleCaseTemplateModelDelete={this.handleCaseTemplateModelDelete} handleCaseTemplateModelDeleteClose={this.handleCaseTemplateModelDeleteClose} selectedObj={this.state.selectedObj}
        handleModelSaveClose={this.handleModelSaveClose}
        />}
        <Modal
          title="Confirm Approve/Reject/Reopen?"
          visible={this.state.modal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.handleCancel} />
            </Tooltip>
          }
          footer={null}
        >
          <div>
            {this.state.stateLoading && <Loader />}

            <Form
              ref={this.formref}
              className="ant-advanced-search-form"
              autoComplete="off"
              onFinish={this.handleOk}
            >
              {this.state.error !== undefined && this.state.error !== null && (
                <Alert type="error" showIcon message={this.state.error} />
              )}
              <Row gutter={24} className="mb-24 pb-24 border-bottom">
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="status"
                    label="State"
                    className="input-label"
                    rules={[
                      {
                        required: true,
                        message: "Is required"
                      }
                    ]}
                  >
                    <Select
                      className="cust-input mb-0"
                      onChange={(e) => this.stateChange(e)}
                      placeholder="Select State"
                    >
                   {this.state.statesLst?.map(item => <Select.Option key={item?.id} value={item?.code}>{item?.name}</Select.Option>)}

                    </Select>
                  </Form.Item>
                </Col>
                {this.state.stateChange.status==="Reopened" &&
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Form.Item
                    className="input-label"
                    name="reason"
                    label="Reason"
                    rules={[
                      {
                        required: true,
                        message: 'Is required',
                      }, {
                        whitespace: true,
                        message: 'Is required',
                      },
                      {
                        validator: validateContentRule,
                      }
                    ]}
                  >
                    <TextArea
                    disabled={this.state.selectedObj.state==="Reopened"}
                      placeholder="Reason"
                      maxLength={100}
                      showCount
                      rows={4}>
                    </TextArea>
                  </Form.Item>
                </Col>
                  }
              </Row>
              <Form.Item className="mb-0">
                <div className="text-right">
                  <Button
                    type="primary"
                    className="primary-btn cancel-btn mr-8"
                    onClick={this.handleCancel}
                  >
                    {" "}
                    Cancel
                  </Button>
                    <Button
                     disabled={
                      this.state.stateChange.status === "Submitted" || this.state.saveDisabled
                    }
                      type="primary"
                      key="submit"
                      className="primary-btn"
                      htmlType="submit"
                      loading={this.state.btnDisabled}
                    >
                  Save
                    </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </>
    );
  }
}

const connectStateToProps = ({ oidc, userConfig }) => {
  return {
    userConfig: userConfig.userProfileInfo,
    oidc,
    trackAuditLogData: userConfig.trackAuditLogData
  };
};
export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(Cases);



