import React, { Component } from "react";
import { connect } from "react-redux";
import { Select, Button, Form,Typography,Row,Col,Alert,Input } from "antd";
import apiCalls from "../../api/apiCalls";
import {setStep,setsaveWDCryptoObject} from '../../reducers/transactionComplianceReducer'
import Loader from "../loader.component";
import NumberFormat from "react-number-format";
const { Text} = Typography;
const { Option } = Select;
class TransactionCryptoDeatils extends Component {
    state={
        toWalletAddress:[],saveWDCrypto:{amount:null,address:null},
        errorMsg:null,
        isLoading:false,
        btnDisabled:false,
        savewithdrawObj:{"transactionId":"00000000-0000-0000-0000-000000000000","transactiontype":null,"customerId":"00000000-0000-0000-0000-000000000000","memberWalletId":"00000000-0000-0000-0000-000000000000","walletCode":null,"toWalletAddress":null,"accountNumber":null,"beneficiaryAccountName":null,"beneficiaryAccountAddress":null,"routingNumber":null,"swiftCode":null,"bankName":null,"bankAddress":null,"reference":null,"description":null,"totalValue":0,"percentage":null,"comission":null,"comissionType":null,"originalValue":null,"addressbookId":"00000000-0000-0000-0000-000000000000","createdby":null},
        selectedAddress:false
      }
    componentDidMount=()=>{
        this.getAccountdetails()
    }
     getAccountdetails = async () => {
      this.setState({...this.state,isLoading:true,errorMsg:null})
        let response = await apiCalls.getAddressBookLu(this.props.obj.customerId,"crypto",this.props.obj?.currency)
        if(response.ok){
          this.setState({...this.state,isLoading:false,errorMsg:null})
            this.setState({...this.state,toWalletAddress:response.data})
        }
        else{
          this.setState({...this.state,isLoading:false, errorMsg:apiCalls.isErrorDispaly(response)})
        }
      }
      savewithdrawal=async(values)=>{
        let {savewithdrawObj}=this.state;
        this.setState({...this.state,btnDisabled:true,errorMsg:null})
        values.address=savewithdrawObj.address;
          let obj={
            "customerId": this.props.obj?.customerId,
            "addressBookId": savewithdrawObj?.addressbookId,
            "coinAmount": this.props.obj?.amount,
            "address": savewithdrawObj.address,
            "coin": this.props.obj?.currency,
            "network": savewithdrawObj?.network,
          }
        let response = await apiCalls.confirmWithdrawl("cripto",obj)
        if(response.ok){
          this.setState({...this.state,btnDisabled:false,errorMsg:null})
          savewithdrawObj.transactionId = this.props.obj.id
          savewithdrawObj.transactiontype = this.props.obj.operationType;
          savewithdrawObj.customerId = this.props.obj.customerId;
          savewithdrawObj.memberWalletId = this.props.obj.memberWalletId
          savewithdrawObj.walletCode = this.props.obj.currency
          savewithdrawObj.totalValue = response.data.amount
          savewithdrawObj.sbCredit = response.data?.sbCredit
          savewithdrawObj.tierDiscount = response.data?.tierDiscount
          savewithdrawObj.totalFee = response.data?.totalFee
          savewithdrawObj.comission = response.data.comission
          savewithdrawObj.createdby = this.props.userConfig.userName;
          savewithdrawObj.toWalletAddress=response.data.address
          savewithdrawObj.amountInUsd=response.data.amountInUsd
          savewithdrawObj.exchangeRate=response.data.exchangeRate
          this.props.dispatch(setsaveWDCryptoObject(savewithdrawObj));
          this.props.dispatch(setStep("withdraw_transaction_crypto_summary"))
        }
        else{
          this.setState({...this.state,btnDisabled:false, errorMsg:apiCalls.isErrorDispaly(response)})
        }
      }
      
  handleAccountChange = (e) => {let {savewithdrawObj,toWalletAddress}=this.state;

    for (let k in toWalletAddress) {
      if (toWalletAddress[k].id === e) {
        savewithdrawObj.addressbookId=toWalletAddress[k].id
        savewithdrawObj.address=toWalletAddress[k].address
        savewithdrawObj.network=toWalletAddress[k].network
        this.setState({...this.state,savewithdrawObj,selectedAddress:true})
      }
    }
      }
    render(){
      let {saveWDCrypto,errorMsg,isLoading}=this.state;
        return(
            <>
            {errorMsg !== undefined && errorMsg !== null && (
              <Alert
                className="w-100 mb-16"
                type="warning"
                description={errorMsg}
                showIcon
              />
            )}
            {isLoading && <Loader/>}
                  <Form
             onFinish={this.savewithdrawal}
              autoComplete="off"
            initialValues={{...saveWDCrypto}}>
          
              <Row style={{ alignItems: "flex-end" }}>
                <Col xs={24} sm={24} md={12}>
										<Form.Item
											name="amount"
											label={<div>Amount
                        </div>}
											className="input-label"
											>
											<NumberFormat
												decimalScale={8}
												className="cust-input"
												customInput={Input}
												thousandSeparator={true}
												prefix={""}
												placeholder="Amount"
												allowNegative={false}
												maxlength={13}
                        defaultValue={this.props.obj?.amount}
                        disabled />
										</Form.Item>
									</Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="mb-16">
                <Form.Item
                  name="address"
                  className="input-label mb-0"
                  label="Payee"
                  rules={[
                    {
                      required: true,
                      message: "Is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    className="cust-input w-80"
                    onChange={(e) => this.handleAccountChange(e)}
                    placeholder="Select Payee"
                    optionLabelProp="label">
                     {this.state.toWalletAddress.map((item, indx) => (
                        <Option key={indx} className="addCryptoList" value={item.id} label={item.accountHolderName}>
                         <div>
                              <div>Whitelist Name : {item.accountHolderName}</div> 
                              <div><span>Wallet Address : </span>{item.address}</div>
                         </div>
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              </Row>
              {this.state?.selectedAddress &&<div className="text-right">
                      <Form.Item className="mb-0 mt-16">
                        <Button
                          htmlType="submit"
                          size="large"
                          className="pop-btn primary-btn preview-btn"
                          loading={this.state.btnDisabled}
                        >
                         Preview
                        </Button>
                      </Form.Item>
                      </div>}
            </Form>
             </>
            
        )
    }
}
const connectStateToProps = ({
    userConfig,
  }) => {
    return {
      userConfig: userConfig.userProfileInfo,
    };
  };
  export default connect(connectStateToProps,)(TransactionCryptoDeatils);