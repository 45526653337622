

import React, { Component } from "react";
import {
	Row,
	Col,
	Typography,
	Alert,
	Button,
	Select,
	Tooltip,
	Modal,
	Form,
	Input,
	message,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import defaultUser from "../../assets/images/defaultuser.jpg";
import Moment from "react-moment";
import Loader from "../loader.component";
import { handleMembers } from "../../reducers/customerReducer";
import { connect } from "react-redux";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import NumberFormat from "react-number-format";
import { saveCustomer, getCustomer, getCaseLu, getRefferalCode } from "./api";
import apiCalls from "../../api/apiCalls";
import SharedInfo from "../shared/sharedInfo";
import Info from "../info/Info"
import { publishShowActions } from "../grid.component/subscribir";
import { Link } from "react-router-dom";
import config from "../../config";
const { Title, Paragraph } = Typography;
const { Option } = Select;
class CustomerProfile extends Component {
	constructor (props) {
		super(props);
		this.state = {
			btnDisabled: false,
			isLoading: false,
			customerObj: {
				id: [],
				riskLevel: null,
				amountThreshold: null,
				accumulatedDeposit: null,
			},
			cryptoModal: false,
			loading: false,
			fiat: true,
			errorMsg: null,
			type: this.props.match.params.type,
			assignedTo: [],
			userName: null,
			customerdata: {},
			assignedToObj: {},
			accountManagerObject: [],
			getData: {},
			refferalName: "",
		
			disable: false,
			popupSymbole: false,
			gridUrl: process.env.REACT_APP_GRID_API + "Customer/Transactions",
			gridUrlWalletsK: process.env.REACT_APP_GRID_API + "Customer/Wallets",
			loader: false,
		};
		this.gridRef = React.createRef();
		this.formRef = React.createRef();
		this.useDivRef = React.createRef();
	}
	DivRef = React.createRef();
	componentDidMount = () => {
		publishShowActions(false);
		let selectedId = this.props.match.params.id;
		this.props.fetchUserData(selectedId);
		this.loadCustomers();
		this.useDivRef.current.scrollIntoView();
	};


	loadCustomers = async () => {
		let selectedId = this.props.match.params.id;
		let response = await getCustomer(selectedId);
		if (response.ok) {
			let obj = response.data;
			this.setState({
				...this.state,
				customerdata: response.data,
				assignedToObj: {
					id: obj.accountManager,
					assignedTo: obj.accountManagerName,
          errorMsg:null,
				},
			});
			if (response.data.referralCode !== null) {
				this.getRefferallCode(response.data.referralCode);
				this.setState({ disable: true });
			}
			this.formRef.current.setFieldsValue({ ...response.data });
		}else{
      this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
    }
	};


	transView = (e) => {
		const items = e.dataItem;
		const val = items.id;
		window.open("/transaction/" + val, "_blank");
		this.props.fetchBreadCrumb({
			key: "/transaction/" + val,
			val: items.walletName,
		});
	};



	gridColumns = [
		{
			field: "date",
			title: "Date",
			filter: true,
			isShowTime: true,
			filterType: "date",
			locked: true,
			width: 250,
			customCell: (props) => (
				<td>
					
					<Moment format={config?.dateFormates?.dateTimeFormate}>{props.dataItem?.date ? apiCalls.convertUTCToLocalTime(props.dataItem?.date) : props.dataItem?.date}</Moment>
				</td>
			),
		},

		{
			field: "inputScore",
			title: "Input Score",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
			customCell: (props) => (
				<td>
					{props.dataItem.inputScore ? props.dataItem.inputScore : 0}
					<Tooltip title="View More">
						<span
							className="icon md info c-pointer ml-8"
							style={{ float: "right" }}
							onClick={() =>
								this.setState({
									...this.state,

									cryptoModal: true,
									selectedId: props.dataItem.docid,
									selectedModal: "Input",
								})
							}
						/>
					</Tooltip>
				</td>
			),
		},
		{
			field: "outputScore",
			title: "Output Score",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
			customCell: (props) => (
				<td>
					{props.dataItem.outputScore ? props.dataItem.outputScore : 0}
					<Tooltip title="View More">
						<span
							className="icon md info c-pointer ml-8"
							style={{ float: "right" }}
							onClick={() =>
								this.setState({
									...this.state,
									cryptoModal: true,
									selectedId: props.dataItem.docid,
									selectedModal: "Output",
								})
							}
						/>
					</Tooltip>
				</td>
			),
		},

		{ field: "docType", title: "Transaction", filter: true, width: 120 },
		{
			field: "fromWalletCode",
			title: "From Wallet Code",
			filter: true,
			width: 180,
		},
		{
			field: "fromValue",
			title: "From Value",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "toWalletCode",
			title: "To Wallet Code",
			filter: true,
			width: 150,
		},
		{
			field: "toValue",
			title: "To Value",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "fromValueBefore",
			title: "From Before Value",
			width: 180,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "fromValueAfter",
			title: "From After Value",
			width: 180,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "toValueBefore",
			title: "To Before Value",
			width: 180,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "toValueAfter",
			title: "To After Value",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "comission",
			title: "Commission",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "comissionInBase",
			title: "Commission in Base",
			width: 200,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "providerComission",
			title: "Provider Commission",
			width: 200,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "providerComissionInBase",
			title: "Provider Commission in Base",
			width: 240,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "sweepFee",
			title: "Sweep Fee",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "sweepFeeInBase",
			title: "Sweep Fee in Base",
			width: 180,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "profit",
			title: "Profit",
			width: 130,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "profitInBase",
			title: "Profit in Base",
			width: 160,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{ field: "status", title: "State", filter: true, width: 160, },
	];
	gridWallets = [
		{
			field: "",
			title: "",
			width: "180",
			customCell: (props) => (
				<td>
					<img
						src={props.dataItem.impagePath}
						alt="coins"
						width="36"
						height="36"
						style={{ marginRight: 16 }}
					/>
					{props.dataItem.walletCode}
				</td>
			),
		},
		{ field: "walletName", title: "Wallet Name", filter: true, width: "220" },
		{ field: "walletCode", title: "Wallet Code", filter: true, width: "180" },
		{ field: "type", title: "Type", filter: true, width: "100" },
		{
			field: "availableAmount",
			title: "Available Amount",
			filter: true,
			width: "220",
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "coinValueinNativeCurrency",
			title: "Amount in USD",
			filter: true,
			width: "200",
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "walletaddress",
			title: "Wallet Address",
			filter: true,
			width: 240,
			customCell: (props) => (
				<td>
					{props.dataItem.walletaddress !== null &&
						props.dataItem.walletaddress !== "" ? (
						<Paragraph
							className="kpi-val mb-0"
							copyable={{ text: props.dataItem.walletaddress }}>
							{props.dataItem.walletaddress.slice(0, 4) +
								"..." +
								props.dataItem.walletaddress.slice(-4)}
						</Paragraph>
					) : (
						""
					)}
				</td>
			),
		},
	];
	bonusGridColumns = [
		{ field: "name", title: "Name", filter: true },
		{ field: "walletCode", title: "Wallet Code", filter: true },
		{ field: "transactionValue", title: "Transaction Value ", filter: true, dataType: "number", filterType: "numeric" },
		{ field: "value", title: "Bonus Earned", filter: true, dataType: "number", filterType: "numeric" },
		{ field: "date", title: "Transaction Date", filter: true, isShowTime: true, filterType: "date" },

	];

	ReferralGridColumns = [
		{ field: "joiningDate", title: "Joining Date", filter: true, isShowTime: true, filterType: "date" },
		{ field: "name", title: "Name", filter: true },
		{ field: "baseCurrency", title: "Base Currency  ", filter: true },
		{ field: "bonusEarned", title: "Bonus Earned ", filter: true, dataType: "number", filterType: "numeric" },
		{ field: "tradeIntiated", title: "Trade Initiated", filter: true, dataType: "boolean", filterType: "boolean" },
	];

	transectionColumn = [
        {
			field: "accountNumber",
			title: "Account Number",
			filter: true,
			width: 300,
			customCell: (props) => (
				<td>
					{" "}
					<div
						className="gridLink"
						onClick={() => this.accountView(props)}>
						{props.dataItem.accountNumber}
					</div>
				</td>
			),
		},
     
        {  field: "routingNumber", title: "Routing Number", filter: true,dataType: "number",  filterType: "numeric",  width: 250},
        {  field: "type",  title: "Type",  filter: true,  width: 100   },
        {  field: "isVerified", title: "Is Verified", filter: true,  width: 250   },
        {  field: "currency",   title: "Currency",  filter: true, width: 250     },
        {  field: "availableBalance",  title: "Available Balance",   filter: true,   width: 250  },
        {  field: "sponsorBankName", title: "Sponsor Bank Name", filter: true, width: 250 },
        {  field: "pendingDebit", title: "Pending Debit",filter: true,width: 250 },
        {  field: "pendingCredit",title: "Pending Credit",filter: true, width: 250 },
        {  field: "createdPersonId", title: "Created Person Id", filter: true, width: 250 },

 ];

 transferSummary = [
	{
		field: "createdAt",
		title: "Date",
		filter: true,
		width: 200,
		customCell: (props) => (
			<td>
        <Moment format={config?.dateFormates?.dateTimeFormate}>
            {props.dataItem.createdAt
              ? apiCalls.convertUTCToLocalTime(props.dataItem.createdAt)
              : props.dataItem.createdAt}
          </Moment>
					
			</td>
		),	
	},
 
	{
		field: "baneficiaryName",
		title: "Name",
		filter: true,
		width: 200
	},

	{
		field: "currency",
		title: "Currency",
		filter: true,
		width: 150
	},
	{
		field: "credit",
		title: "Transfer Amount",
		filter: true,
		width: 180
	},
	{
		field: "beneficiaryAccountNumber",
		title: "Account Number ",
		filter: true,
		width: 180
	},
	{
		field: "beneficiaryRoutingNumber",
		title: "Routing Number",
		filter: true,
		width: 250
	},
   
	 {
		field: "status",
		title: "Status",
		filter: true,
		width: 180
	},
	
];
	  
	success = (msg, duration) => {
		message.destroy();
		message.success({
			content: msg,
			className: "custom-msg",
			duration: 4,
		});
	};

	handleSearch = (e, type) => {
		let sval = e.target.value;
		if (sval != null && sval.length > 2) {
			if (type === "AssignedTo") {
				this.fetchAccountManagerLu(sval);
			}
		}
	};

	handleChange = (val, type) => {
		if (type === "AssignedTo") {
			const obj = this.state.assignedTo?.find((item) => item.id === val);
			this.setState({ ...this.state, assignedToObj: obj });
		}
	};

	fetchAccountManagerLu = async (searchName, id) => {
		this.setState({ loading: true });
		let response = await getCaseLu(searchName);
		if (response.ok) {
			const obj = this.state.assignedTo?.find((item) => item.id === id);
			this.setState({
				...this.state,
				assignedTo: response.data,
				getData: obj,
				loading: false,
			});
		} else {
			this.setState({ loading: false });
		}
	};

	handleRiskLevel(val) {
		this.state.customerObj["riskLevel"] = val;
	}

	saveCustomerInfo = async (values) => {
		this.setState({ loading: true, btnDisabled: true, errorMsg: null, isLoading: true });
		let objval = values;
		if (
			parseFloat(
				typeof objval.amountThreshold == "string"
					? objval.amountThreshold.replace(/,/g, "")
					: objval.amountThreshold
			) <= 0
		) {

			this.setState({ ...this.state, loading: false, isLoading: false, btnDisabled: false, errorMsg: "Amount must be greater than zero." });
			this.useDivRef.current.scrollIntoView();
			return;
		}
		if (
			parseFloat(
				typeof objval.accumulatedDeposit == "string"
					? objval.accumulatedDeposit.replace(/,/g, "")
					: objval.accumulatedDeposit
			) <= 0
		) {

			this.setState({ ...this.state, loading: false, btnDisabled: false, errorMsg: "Amount must be greater than zero.", isLoading: false });
			this.useDivRef.current.scrollIntoView();
			return;
		}
		values.id = this.props.match.params.id;
		values.info = JSON.stringify(this.props.trackAuditLogData);
		values.accountManager = this.state.assignedToObj.id;
		values.accountManagerName = this.state.assignedToObj.assignedTo;
		let response = await saveCustomer(values);
		if (response.ok) {
			this.setState({ ...this.state, btnDisabled: false, errorMsg: null, loading: false, isLoading: false });
			this.useDivRef.current.scrollIntoView();
			this.success("Customer details saved successfully");
			this.props.history.push("/customers");
		} else {
			this.setState({ ...this.state, errorMsg:apiCalls.isErrorDispaly(response), loading: false, isLoading: false, btnDisabled: false });
			this.useDivRef.current.scrollIntoView();
			window.scrollTo(0, 0);

		}
		apiCalls.trackEvent({
			Action:
				this.props.match.params.id === "00000000-0000-0000-0000-000000000000"
					? "Customer Saved"
					: "Customer updated ",
			Feature: "Customers",
			Remarks:
				this.props.match.params.id === "00000000-0000-0000-0000-000000000000"
					? "Customer Saved"
					: "Customer updated",
			FullFeatureName: "Customers",
		});
	};
	
	numberValidator = async function (rule, value, callback) {
		if (value) {
			if (typeof value === "number") {
				value = value.toString();
			}
			if (
				(value.indexOf(".") > -1 && value.split(".")[0].length >= 11) ||
				(value.indexOf(".") < 0 && value.length >= 11)
			) {
				throw new Error("Amount exceeded");
			} else {
				callback();
			}
		}
	};

	handleDepositTabs = (e) => {
		this.setState({
			...this.state,
			fiat: e.target.value === 1,
		});
	};
	redirectCaseView = () => {
		const items = this.state.customerdata;
		const val = items.caseId;
		this.props.history.push("/cases/1/" + val + "/view");
		this.props.dispatch(
			setBreadcrumb({ key: "/cases/1/" + val + "/view", val: items.caseNumber })
		);
	};
	isUrlValid(userInput) {
		var res = userInput.match(/(http(s):\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
		if (res == null)
		  return false;
		else if (userInput.startsWith("https://suissebase.egnyte.com")) {
		  return true;
		}
		else {
		  return false
		}
	
	  }
	  isSumSubUrlValid(userInput) {
		var res = userInput.match(/(http(s):\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
		if (res == null)
		  return false;
		else if (userInput.startsWith("https://cockpit.sumsub.com")) {
		  return true;
		}
		else {
		  return false
		}
	}
  validateValidUrl = (_, value) => {
   
      if (value) {
        if (!this.isUrlValid(value)) {
          return Promise.reject("Enter valid url");
        }
      }
      return Promise.resolve();
    
  
};
validateSumsubUrl=(_, value)=>{
    if (value) {
      if (!this.isSumSubUrlValid(value)) {
        return Promise.reject("Enter valid url");
      }
    }
    return Promise.resolve();
 
}
	getRefferallCode = async (id) => {
		let response = await getRefferalCode(id);
		if (response.ok) {
		this.setState({ refferalName: response.data.name, popupSymbole: true, errorMsg: null });
		}
		else {
			this.useDivRef.current.scrollIntoView(0, 0);
			this.setState({ refferalName: null, errorMsg: "Invalid Referral Id" })
		}
	}
	
	antIcon = () => {
		(
			<LoadingOutlined
				style={{ fontSize: 18, color: "#fff", marginRight: "16px" }}
				spin
			/>
		);
	}
  getErrorMsg=(errorMsgs)=>(
    errorMsgs !== null && (
      <Alert
        className="w-100 mb-16"
        type="error"
        description={errorMsgs}
        showIcon
      />
    )
  )
  renderCustomerData=(customerValue)=>{
   return customerValue ?? "-"
  }

	render() {
		const {  assignedTo, errorMsg } = this.state;
		const {
			data: customer,
			isLoading,
			error,
		} = this.props.customerStore?.customerData;
		const {
			customerdata,
			customerObj,
		} = this.state;
		return (
      <>
        <div ref={this.useDivRef}></div>
        {isLoading && <Loader />}
        <Title className="page-title">Customer Detail View</Title>
        {this.getErrorMsg(error)}
        {this.getErrorMsg(errorMsg)}
      
        {customer && (
          <Form
            name="customer"
            autoComplete="off"
            initialValues={customerObj}
            onFinish={this.saveCustomerInfo}
            ref={this.formRef}
          >
            <Row className="detailview">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={9}
                xl={6}
                xxl={5}
                className="view-left"
              >
                <div className="text-center user">
                  <img
                    src={customer.imageURL ? customer.imageURL : defaultUser}
                    alt="User"
                    className="user-circle"
                  />
                  <div className="kpi-val text-captz fw-700 fs-18 user-name">
                    {customer.userName}
                  </div>
                </div>

                <div className="kpi-item">
                  <span className="icon xl email" />
                  <div>
                    <label className="kpi-label">Email</label>
                    <div className="kpi-val" style={{ wordBreak: "break-all" }}>
                      {this.renderCustomerData(customer.email)}
                    </div>
                  </div>
                </div>
                <div className="kpi-item">
                  <span className="icon xl phone" />
                  <div>
                    <label className="kpi-label">Phone</label>
                    <div className="kpi-val">
                    {this.renderCustomerData(customer.phoneNo)}
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={15}
                xl={18}
                xxl={19}
                className="view-right"
              >
                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={7}
                    xxl={8}
                    className="item mt-8"
                  >
                    <label className="kpi-label ">Customer Id</label>
                    <Paragraph className="kpi-val fw-700 mb-0" copyable>
                    {this.renderCustomerData(customer.depositReference)}
                    </Paragraph>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={9}
                    xxl={8}
                    className="item"
                  >
                    <label className="kpi-label">First Name</label>
                    <p className="kpi-val fw-700 mb-0">
                    {this.renderCustomerData(customer.firstName)}
                    </p>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={8}
                    xxl={8}
                    className="item"
                  >
                    <label className="kpi-label">Last Name</label>
                    <p className="kpi-val fw-700 mb-0">
                    {this.renderCustomerData(customer.lastName)}
                    </p>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={7}
                    xxl={8}
                    className="item"
                  >
                    <label className="kpi-label">Middle Name</label>
                    <p className="kpi-val fw-700 mb-0">
                    {this.renderCustomerData(customer.middleName)}
                    </p>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={9}
                    xxl={8}
                    className="item"
                  >
                    <label className="kpi-label">
                      {customer.accountType === "Personal"
                        ? "Country of Residence"
                        : "Country of Business"}
                    </label>
                    <p className="kpi-val fw-700 mb-0">
                    {this.renderCustomerData(customer.country)}
                    </p>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={8}
                    xxl={8}
                    className="item"
                  >
                    <label className="kpi-label">Email Verified</label>
                    <p className=" kpi-val fw-700 mb-0">
                    {this.renderCustomerData(customer.emailVerified)}
                    </p>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={7}
                    xxl={8}
                    className="item"
                  >
                    <label className="kpi-label">Phone Verified</label>
                    <p className=" kpi-val fw-700 mb-0">
                    {this.renderCustomerData(customer.phoneVerified)}

                    </p>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={9}
                    xxl={8}
                    className="item"
                  >
                    <label className="kpi-label">Reg Date</label>
                    <p className=" kpi-val fw-700 mb-0">
                    <Moment format={config?.dateFormates?.dateFormate}>
                    {customer?.createdDate
                      ? apiCalls.convertUTCToLocalTime(customer?.createdDate)
                      : customer?.createdDate}
                  </Moment>
                    </p>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={8}
                    xxl={8}
                    className="item"
                  >
                    <label className="kpi-label">Status</label>
                    <p className=" kpi-val fw-700 mb-0">
                    {this.renderCustomerData(customer.status)}
                    </p>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={7}
                    xxl={8}
                    className="item"
                  >
                    <label className="kpi-label">Account Type</label>
                    <p className=" kpi-val fw-700 mb-0">
                    {this.renderCustomerData(customer.accountType)}
                    </p>
                  </Col>
                  {customer?.accountType === "Personal" && (
                    <>
                      {" "}
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={9}
                        xxl={8}
                        className="item"
                      >
                        <label className="kpi-label">KYC Compliance</label>
                        <p className="kpi-val  fw-700  mb-0">
                        {this.renderCustomerData(customer.kycCompleted)}
                        </p>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={8}
                        xxl={8}
                        className={
                          this.state.type === "view" ? "item" : "item pt-8"
                        }
                      >
                        {this.state.type === "view" ? (
                          <>
                            <label className="kpi-label">Risk Level</label>
                            <p className=" kpi-val fw-700 mb-0">
                            {this.renderCustomerData(customer.riskLevel)}
                            </p>
                          </>
                        ) : (
                          <Form.Item
                            name="riskLevel"
                            className="input-label mb-0"
                            label="Risk Level"
                            rules={[{ required: true, message: "Is required" }]}
                          >
                            <Select
                              className="cust-input"
                              onChange={(e) => this.handleRiskLevel(e)}
                              placeholder="Select Risk Level"
                              disabled={this.state.type === "disabled"}
                            >
                              <Option value="Low Risk">Low Risk</Option>
                              <Option value="Medium Risk">Medium Risk</Option>
                              <Option value="High Risk">High Risk</Option>
                            </Select>
                          </Form.Item>
                        )}
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={8}
                        xxl={8}
                        className="item"
                      >
                        {this.state.type === "view" && (
                          <label className="kpi-label">Amount Threshold</label>
                        )}
                        {this.state.type === "view" ? (
                          <div className="kpi-val fw-700">
                            <NumberFormat
                              value={customer.amountThreshold || "--"}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </div>
                        ) : (
                          <Form.Item
                            name="amountThreshold"
                            className="input-label mb-0"
                            rules={[
                              { required: true, message: "Is required" },
                              {
                                type: "number",
                                validator: this.numberValidator
                              }
                            ]}
                            label="Amount Threshold"
                          >
                            <NumberFormat
                              disabled={this.state.type === "disabled"}
                              placeholder="Amount Threshold"
                              className="cust-input"
                              customInput={Input}
                              thousandSeparator={true}
                              prefix={""}
                              decimalSeparator={false}
                              allowNegative={false}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        )}
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={8}
                        xxl={8}
                        className="item"
                      >
                        {this.state.type === "view" && (
                          <label className="kpi-label">
                            Accumulated Deposit
                          </label>
                        )}
                        {this.state.type === "view" ? (
                          <div className="kpi-val fw-700">
                            <NumberFormat
                              value={customer.accumulatedDeposit || "-"}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </div>
                        ) : (
                          <Form.Item
                            name="accumulatedDeposit"
                            className="input-label mb-0"
                            rules={[
                              { required: true, message: "Is required" },
                              {
                                type: "number",
                                validator: this.numberValidator
                              }
                            ]}
                            label="Accumulated Deposit"
                          >
                            <NumberFormat
                              disabled={this.state.type === "disabled"}
                              className="cust-input"
                              placeholder="Accumulated Deposit"
                              customInput={Input}
                              thousandSeparator={true}
                              decimalSeparator={false}
                              prefix={""}
                              allowNegative={false}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        )}
                      </Col>
                      <>
                        {" "}
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={8}
                          xxl={8}
                          className="item"
                        >
                          <div
                            className="d-flex justify-content align-center"
                            style={{
                              color: "green",
                              marginLeft: "154px",
                              fontSize: "15px",
                              position: "absolute",
                              fontWeight: "bold"
                            }}
                          >
                            {this.state.refferalName}{" "}
                          </div>
                          <div>
                            <Form.Item
                              name="referralCode"
                              label="Referral Code"
                              className="input-label"
                            >
                              <Input
                                style={{ position: "relative" }}
                                placeholder="Please enter your referral code"
                                className="cust-input"
                                maxLength={250}
                                disabled={this.state.disable}
                                onBlur={(val) =>
                                  this.getRefferallCode(val.currentTarget.value)
                                }
                              />
                            </Form.Item>
                          </div>
                          <span
                            className="refer-code"
                            style={{
                              position: "absolute",
                              right: "40px",
                              top: "63px"
                            }}
                          >
                            {" "}
                            {this.state.popupSymbole === true && (
                              <span className="icon md greenCheck" />
                            )}
                          </span>
                        </Col>
                      </>
                      {this.state.type === "view" &&
                        customer.caseNumber !== null && (
                          <>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={9}
                              xxl={8}
                              className="item"
                            >
                              <label className="kpi-label">Case Number</label>

                              <p>
                                <span className="fw-600 fs-14">
                                  <Link onClick={this.redirectCaseView} className="c-pointer">
                                  {this.renderCustomerData(customer.caseNumber)}
                                  </Link>
                                </span>
                              </p>
                            </Col>
                          </>
                        )}
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        className="item"
                      >
                        {this.state.type === "view" && (
                          <label className="kpi-label">Egnyte</label>
                        )}
                        {this.state.type === "view" ? (
                          <Paragraph
                            className="kpi-val fw-700 mb-0"
                            copyable={customer.egnyte}
                          >
                             {this.renderCustomerData(customer.egnyte)}
                          </Paragraph>
                        ) : (
                          <Form.Item
                            name="egnyte"
                            label="Egnyte"
                            className="input-label mb-0"
                            rules={[
                              {
                                required: false,
                                validator: this.validateValidUrl
                              }
                            ]}
                          >
                            <Input
                              placeholder="Egnyte"
                              className="cust-input"
                              maxLength={250}
                            />
                          </Form.Item>
                        )}
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        className="item"
                      >
                        {this.state.type === "view" && (
                          <label className="kpi-label">Sumsub</label>
                        )}
                        {this.state.type === "view" ? (
                          <Paragraph
                            className="kpi-val fw-700 mb-0"
                            copyable={customer.sumSub}
                          >
                            {this.renderCustomerData(customer.sumSub)}
                          </Paragraph>
                        ) : (
                          <Form.Item
                            name="sumSub"
                            label="Sumsub"
                            className="input-label"
                            rules={[
                              {
                                required: false,
                                validator: this.validateSumsubUrl
                              }
                            ]}
                          >
                            <Input
                              placeholder="Sumsub"
                              className="cust-input"
                              maxLength={250}
                            />
                          </Form.Item>
                        )}
                      </Col>
                    </>
                  )}
                  {customer?.accountType === "Business" && (
                    <>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={9}
                        xxl={8}
                        className="item"
                      >
                        <label className="kpi-label">Business Name</label>
                        <p className=" kpi-val fw-700 mb-0">
                        {this.renderCustomerData(customer.businessName)}
                        </p>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={8}
                        xxl={8}
                        className="item"
                      >
                        <label className="kpi-label">KYB Compliance</label>
                        <p className="kpi-val  fw-700  mb-0">
                        {this.renderCustomerData(customer.kybCompleted)}
                        </p>
                      </Col>

                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={7}
                        xxl={8}
                        className="item"
                      >
                        {this.state.type === "view" ? (
                          <>
                            <label className="kpi-label">Risk Level</label>
                            <p className=" kpi-val fw-700 mb-0">
                            {this.renderCustomerData(customer.riskLevel)}
                            </p>
                          </>
                        ) : (
                          <Form.Item
                            name="riskLevel"
                            className="input-label mb-0"
                            label="Risk Level"
                            rules={[{ required: true, message: "Is required" }]}
                          >
                            <Select
                              className="cust-input"
                              onChange={(e) => this.handleRiskLevel(e)}
                              placeholder="Select Risk Level"
                              disabled={this.state.type === "disabled"}
                            >
                              <Option value="Low Risk">Low Risk</Option>
                              <Option value="Medium Risk">Medium Risk</Option>
                              <Option value="High Risk">High Risk</Option>
                            </Select>
                          </Form.Item>
                        )}
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={9}
                        xxl={8}
                        className="item"
                      >
                        {this.state.type === "view" && (
                          <label className="kpi-label">Amount Threshold</label>
                        )}
                        {this.state.type === "view" ? (
                          <div className="kpi-val fw-700">
                            <NumberFormat
                              value={customer.amountThreshold || "-" }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </div>
                        ) : (
                          <Form.Item
                            name="amountThreshold"
                            className="input-label mb-0"
                            rules={[
                              { required: true, message: "Is required" },
                              {
                                type: "number",
                                validator: this.numberValidator
                              }
                            ]}
                            label="Amount Threshold"
                          >
                            <NumberFormat
                              disabled={this.state.type === "disabled"}
                              className="cust-input"
                              placeholder="Amount Threshold"
                              customInput={Input}
                              thousandSeparator={true}
                              prefix={""}
                              decimalSeparator={false}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        )}
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={8}
                        xxl={8}
                        className="item"
                      >
                        {this.state.type === "view" && (
                          <label className="kpi-label">
                            Accumulated Deposit
                          </label>
                        )}
                        {this.state.type === "view" ? (
                          <div className="kpi-val fw-700">
                            <NumberFormat
                              value={
                                this.renderCustomerData(customer.accumulatedDeposit)
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </div>
                        ) : (
                          <Form.Item
                            name="accumulatedDeposit"
                            className="input-label mb-0"
                            rules={[
                              { required: true, message: "Is required" },
                              {
                                type: "number",
                                validator: this.numberValidator
                              }
                            ]}
                            label="Accumulated Deposit"
                          >
                            <NumberFormat
                              disabled={this.state.type === "disabled"}
                              className="cust-input"
                              placeholder="Accumulated Deposit"
                              customInput={Input}
                              thousandSeparator={true}
                              prefix={""}
                              decimalSeparator={false}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        )}
                      </Col>

                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={8}
                        xxl={8}
                        className="item"
                      >
                        {this.state.type === "view" ? (
                          <>
                            <label className="kpi-label">Account Manager</label>
                            <p className=" kpi-val fw-700 mb-0">
                            {this.renderCustomerData(customer.accountManagerName)}
                            </p>
                          </>
                        ) : (
                          <Form.Item
                            name="accountManagerName"
                            label="Account Manager"
                            className="input-label"
                            rules={[
                              {
                                required: true,
                                message: "Is required"
                              }
                            ]}
                          >
                            <Select
                              showSearch
                              className="cust-input"
                              onKeyUp={(e) =>
                                this.handleSearch(e, "AssignedTo")
                              }
                              onChange={(e) =>
                                this.handleChange(e, "AssignedTo")
                              }
                              placeholder="Select Account Manager"
                              optionFilterProp="children"
                              disabled={this.state.type === "disabled"}
                            >
                              {assignedTo?.map((assign, idx) => (
                                <Option key={idx} value={assign.id}>
                                  {assign.assignedTo}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={8}
                        xxl={8}
                        className="item"
                      >
                        <div
                          className="d-flex justify-content align-center"
                          style={{
                            color: "green",
                            marginLeft: "154px",
                            fontSize: "15px",
                            position: "absolute",
                            fontWeight: "bold"
                          }}
                        >
                          {this.state.refferalName}{" "}
                        </div>
                        <div>
                          <Form.Item
                            name="referralCode"
                            label="Referral Code"
                            className="input-label"
                          >
                            <Input
                              style={{ position: "relative" }}
                              placeholder="Please enter your referral code"
                              className="cust-input"
                              maxLength={250}
                              disabled={this.state.disable}
                              onBlur={(val) =>
                                this.getRefferallCode(val.currentTarget.value)
                              }
                            />
                          </Form.Item>
                        </div>
                        <span
                          style={{
                            position: "absolute",
                            right: "40px",
                            top: "63px"
                          }}
                        >
                          {" "}
                          {this.state.popupSymbole === true && (
                            <span className="icon md greenCheck" />
                          )}
                        </span>
                      </Col>

                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={8}
                        xxl={8}
                        className="item"
                      >
                        {this.state.type === "view" &&
                          customer.caseNumber !== null && (
                            <>
                              <label className="kpi-label">Case Number</label>

                              <p>
                                <span className="fw-600 fs-14">
                                  <Link onClick={this.redirectCaseView} className="c-pointer">
                                  {this.renderCustomerData(customer.caseNumber)}
                                  
                                  </Link>
                                </span>
                              </p>
                            </>
                          )}
                      </Col>
                   

                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        className="item"
                      >
                        {this.state.type === "view" && (
                          <label className="kpi-label">Egnyte</label>
                        )}
                        {this.state.type === "view" ? (
                          <Paragraph
                            className="kpi-val fw-700 mb-0"
                            copyable={customer.egnyte}
                          >
                             {this.renderCustomerData(customer.egnyte)}
                          </Paragraph>
                        ) : (
                          <Form.Item
                            name="egnyte"
                            label="Egnyte"
                            className="input-label mb-0"
                            rules={[
                              {
                                required: false,
                                validator: this.validateValidUrl
                              }
                            ]}
                          >
                            <Input
                              placeholder="Egnyte"
                              className="cust-input"
                              maxLength={250}
                            />
                          </Form.Item>
                        )}
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        className="item"
                      >
                        {this.state.type === "view" && (
                          <label className="kpi-label">Sumsub</label>
                        )}
                        {this.state.type === "view" ? (
                          <Paragraph
                            className="kpi-val fw-700 mb-0"
                            copyable={customer.sumSub}
                          >
                            {this.renderCustomerData(customer.sumSub)}
                          </Paragraph>
                        ) : (
                          <Form.Item
                            name="sumSub"
                            label="Sumsub"
                            className="input-label"
                            rules={[
                              {
                                required: false,
                                validator: this.validateSumsubUrl
                              }
                            ]}
                          >
                            <Input
                              placeholder="Sumsub"
                              className="cust-input"
                              maxLength={250}
                            />
                          </Form.Item>
                        )}
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
              <Info
                Id={customerdata.id}
                Status={customerdata.status}
                Createdby={customerdata?.createdby}
                CreatedDate={customerdata.createdDate}
                ModifiedDate={customerdata?.modifiedDate}
                Modifiedby={customerdata?.modifiedby}
              />
            </Row>
            {this.state.type !== "view" && this.state.type !== "disabled" && (
              <div className="text-right mt-16">
                <Button
                  loading={this.state.isLoading}
                  type="primary"
                  className="primary-btn"
                  htmlType="submit"
                >
                  Save
                </Button>
                <Button
                  type="primary"
                  className="primary-btn cancel-btn"
                  style={{ margin: "0 8px" }}
                  onClick={() => this.props.history.push("/customers")}
                >
                  Cancel
                </Button>
              </div>
            )}
          </Form>
        )}
        
        <Modal
         title={<p>Info Details</p>}
          visible={this.state.cryptoModal}
          className="crypto-list"
          closeIcon={
            <Tooltip title="Close">
              <span
                className="icon md x c-pointer"
                onClick={() =>
                  this.setState({ ...this.state, cryptoModal: false })
                }
              />
            </Tooltip>
          }
          footer={
            <Button
              type="primary"
              className="primary-btn cancel-btn"
              onClick={() =>
                this.setState({ ...this.state, cryptoModal: false })
              }
            >
              Close
            </Button>
          }
        >
          <SharedInfo
            id={this.state.selectedId}
            type={this.state.selectedModal}
            screen={"customers"}
          />
        </Modal>
      </>
    );
	}
}
const connectStateToProps = ({
	customerStore,
	userConfig,
	trackAuditLogData
}) => {
	return { customerStore, trackAuditLogData: userConfig.trackAuditLogData };
};
const connectDispatchToProps = (dispatch) => {
	return {
		fetchUserData: (id) => {
			dispatch(handleMembers(id));
		},
		dispatch
	};
};

export default connect(
	connectStateToProps,
	connectDispatchToProps
)(CustomerProfile);
