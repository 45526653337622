import React, { Component } from 'react';
import success from '../../assets/images/success.png';
import { connect } from "react-redux";
import apiCalls from '../../api/apiCalls';
class WithdrawCryptoSuccess extends Component {
    componentDidMount() {
        this.successTrack();
    }
    successTrack = () => {
        apiCalls.trackEvent({ "Type": 'User', "Action": 'Withdraw Crypto success page view', "Username": this.props.userProfileInfo?.userName, "customerId": this.props.userProfileInfo?.id, "Feature": 'Withdraw Crypto', "Remarks": 'Withdraw Crypto success page view', "Duration": 1, "Url": window.location.href, "FullFeatureName": 'Withdraw Crypto' });
    }
    render() {
      
        return (
            <>
                <div className="success-pop text-center mt-10">
                    <img src={success} className="confirm-icon" alt={"success"} />
                    <div className="text-white-30 decalration-title fw-200 mb-4" >Your transaction has been processed successfully</div>
                </div>
            </>
        );
    }
}
const connectStateToProps = ({ sendReceive, userConfig }) => {
    return {
        sendReceive,
        userProfile: userConfig.userProfileInfo,
        trackAuditLogData: userConfig.trackAuditLogData
    };
};
export default connect(connectStateToProps, null)(WithdrawCryptoSuccess);
